import { NavigationGuard } from "vue-router";

import { UserChallenge } from "@/types/graphql";
import { UserChallenges } from "@/types/challenges";
import { challengeOn } from "@/util/challenges";

import store from "@/store";

const CHALLENGES_ROUTES: Array<UserChallenges> = [
  UserChallenges.ConfirmPhone,
  UserChallenges.ResetPassword,
  UserChallenges.CompleteProfile,
];

export const ChallengesGuard: NavigationGuard = async (to, from, next) => {
  if (String(to.name).startsWith("challenge/")) {
    return next();
  }

  if (store.getters.hasChallenges) {
    await store.dispatch("useTreatments");

    while (store.getters.hasChallenges) {
      const challenge: UserChallenge = store.getters.nextChallenge;

      const name = challenge.name as UserChallenges;
      const route = `challenge/${name}`;

      if (challengeOn(name)) {
        if (CHALLENGES_ROUTES.includes(name)) {
          return next({ name: route });
        }
      } else {
        await store.dispatch("completeChallenge", name);
      }
    }
  }

  return next();
};
