import { NavigationGuard, RouteRecordName } from "vue-router";

import store from "@/store";
import {
  getConsumerData,
  getConsumerOnLogin,
  // getConsumerOnInvite,
  redirectOnExpectation,
  redirectOnComplete,
  redirectOnInvite,
} from "@/util/consumer";
import { getSAMLClientData } from "@/util/saml";
import { isURL } from "@/util/helper";
import * as FullStory from "@/util/fullStory";
import { getChallenge } from "@/services/user.service";

const EXCEPT_ROUTES = ["link/impersonate", "link/impersonate_logout"];

const PARTICIPANTS_PATH: Record<string, RouteRecordName> = {
  "/participants/sign-up": "participants-signup",
  "/participants/login": "participants-login",
  "/participants/reset-password": "participants-reset-password",
  "/participants/verification": "participants-verification",
};

export const ConsumerGuard: NavigationGuard = async (to, from, next) => {
  // Protect from invalid redirectTo param
  if (to.query.redirectTo && !isURL(to.query.redirectTo as string)) {
    next();
  }

  // Special logic for participants app
  if (
    to.query.redirectTo &&
    !Object.values(PARTICIPANTS_PATH).includes(to.name as RouteRecordName) // Prevent infinity redirect
  ) {
    const redirectTo = new URL(to.query.redirectTo as string);
    const routeName = PARTICIPANTS_PATH[redirectTo.pathname];
    const joinCode = redirectTo.searchParams.get("join");

    if (routeName) {
      return next({
        name: routeName,
        query: { redirectTo: to.query.redirectTo, join: joinCode },
      });
    }
  }

  if (to.query.challengeId && typeof to.query.challengeId === "string") {
    const challenge = await getChallenge(to.query.challengeId);
    if (challenge && challenge.id == to.query.challengeId) {
      await store.dispatch("setChallenges", [challenge]);
    }
  }

  // The first entry from external provider e.g. `azure`
  if (to.query.provider) {
    FullStory.event("Successful Login", {
      user_email: store.getters.me?.email,
    });

    await store.dispatch("setConsumeData", await getConsumerOnLogin());
  }

  // Entry with faked consumer or from consumer with `redirectTo` param
  if (to.query.consumer || to.query.redirectTo) {
    await store.dispatch("setConsumeData", await getConsumerData(to.query));
  }

  // Entry from SAML client
  if (to.query.SAMLRequest) {
    await store.dispatch("setConsumeData", await getSAMLClientData(to.query));
  }

  if (
    store.getters.isAuthorized &&
    !store.getters.hasChallenges &&
    !EXCEPT_ROUTES.includes(to.name as string)
  ) {
    await redirectOnComplete();
    await redirectOnExpectation("redirect");
    await redirectOnExpectation("permission");
    await redirectOnInvite();
  }

  next();
};
