import { Module, GetterTree, ActionTree, MutationTree } from "vuex";
import type { RootState } from "./index";

export type ChannelDialogState = {
  showChannelCreatedDialog: boolean;
};

const state: ChannelDialogState = {
  showChannelCreatedDialog: false,
};

const getters: GetterTree<ChannelDialogState, RootState> = {
  showChannelCreatedDialog: (state) => state.showChannelCreatedDialog,
};

const mutations: MutationTree<ChannelDialogState> = {
  setShowChannelCreatedDialog(state, value: boolean) {
    state.showChannelCreatedDialog = value;
  },
};

const actions: ActionTree<ChannelDialogState, RootState> = {
  setShowChannelCreatedDialog({ commit }, value: boolean) {
    commit("setShowChannelCreatedDialog", value);
  },
};

export const channelDialog: Module<ChannelDialogState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
