import { RouteRecordRaw } from "vue-router";

import ExternalPage from "@/layouts/ExternalPage.vue";

export const ChallengesRoutes: Array<RouteRecordRaw> = [
  {
    path: "/challenge/complete-profile",
    name: "challenge/complete_profile",
    component: () =>
      import(
        /* webpackChunkName: "challenges" */ "@/views/challenges/CompleteProfile.vue"
      ),

    meta: {
      title: "Complete Your Profile",
      requiresAuth: false,
      layout: ExternalPage,
    },
  },

  {
    path: "/challenge/self-identify",
    name: "challenge/set_org",
    component: () =>
      import(
        /* webpackChunkName: "challenges" */ "@/views/challenges/SetOrg.vue"
      ),
    // props: true,

    meta: {
      title: "Self Identify",
      requiresAuth: true,
      layout: ExternalPage,
    },
  },

  {
    path: "/challenge/confirm-phone",
    name: "challenge/confirm_phone",
    component: () =>
      import(
        /* webpackChunkName: "challenges" */ "@/views/challenges/ConfirmPhone.vue"
      ),

    meta: {
      title: "Confirm Your Phone Number",
      requiresAuth: false,
      layout: ExternalPage,
    },
  },

  {
    path: "/challenge/reset-password",
    name: "challenge/reset_password",
    component: () =>
      import(
        /* webpackChunkName: "challenges" */ "@/views/challenges/ResetPassword.vue"
      ),

    meta: {
      title: "Set your new password",
      requiresAuth: false,
      layout: ExternalPage,
    },
  },
];
