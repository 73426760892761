import { createStore, createLogger } from "vuex";
import VuexPersistence from "vuex-persist";

import type { User } from "@/types/graphql";

import type { ChallengesState } from "./challenges.module";
import type { ConsumerData } from "./consumer.module";
import type { ConfirmationData } from "./confirmation.module";
import type { UIState } from "./ui.module";
import type { ResetPasswordData } from "./resetPassword.module";
import type { TreatmentsState } from "./treatments.module";
import type { InviteState } from "./invite.module";
import type { ChannelDialogState } from "./channelDialog.module";

export interface RootState {
  challenges: ChallengesState;
  consumer?: ConsumerData;
  confirmation?: ConfirmationData;
  user?: User;
  ui?: UIState;
  resetPassword?: ResetPasswordData;
  treatments?: TreatmentsState;
  invite?: InviteState;
  channelDialog?: ChannelDialogState;
}

import { challenges } from "./challenges.module";
import { consumer } from "./consumer.module";
import { confirmation } from "./confirmation.module";
import { user } from "./user.module";
import { ui } from "./ui.module";
import { reset } from "./resetPassword.module";
import { treatments } from "./treatments.module";
import { invite } from "./invite.module";
import { channelDialog } from "./channelDialog.module";

import { config } from "@/services/config";

const vuexLocal = new VuexPersistence<RootState>({
  modules: ["confirmation", "reset"],
  storage: window.localStorage,
});

const vuexSession = new VuexPersistence<RootState>({
  modules: ["consumer", "ui", "invite", "challenges"],
  storage: window.sessionStorage,
});

const plugins = config.IS_PRODUCTION
  ? [vuexLocal.plugin, vuexSession.plugin]
  : [vuexLocal.plugin, vuexSession.plugin, createLogger()];

export default createStore<RootState>({
  modules: {
    challenges,
    consumer,
    confirmation,
    user,
    ui,
    reset,
    treatments,
    invite,
    channelDialog,
  },
  plugins,
});
