export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  Buffer: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  DateType: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
  /** The `Int32` scalar type represents non-fractional signed whole numeric values. Int32 can represent values between -(2^31) and 2^31 - 1.  */
  Int32: { input: any; output: any; }
  /** The `Int64` scalar type represents non-fractional signed whole numeric values. Int64 can represent values between -(2^63) and 2^63 - 1.  */
  Int64: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  NonEmptyString: { input: any; output: any; }
  Time: { input: any; output: any; }
  /** A `Timestamp` represents a point in time independent of any time zone or calendar, represented as seconds and fractions of seconds at nanosecond resolution in UTC Epoch time. */
  Timestamp: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Uint64: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Void: { input: any; output: any; }
  udid: { input: any; output: any; }
};

export type Account = ExternalBankAccount | StripeAccount;

/** Account balance information */
export type AccountBalanceReturn = {
  __typename?: 'AccountBalanceReturn';
  /** Available balance in cents */
  available?: Maybe<Scalars['Int']['output']>;
  /** Pending balance in cents */
  pending?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AccountManager = DriveUser & {
  __typename?: 'AccountManager';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type AccountTransactionsInput = {
  accountId: Scalars['String']['input'];
  createdAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  createdBefore?: InputMaybe<Scalars['Timestamp']['input']>;
  exclude?: InputMaybe<Array<InputMaybe<TransactionFilterType>>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  status?: InputMaybe<Status>;
};

export type AccountTransactionsPaginatedInput = {
  accountId: Scalars['String']['input'];
  createdAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  createdBefore: Scalars['Timestamp']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  /** Currently only support 1000 */
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Status>;
};

export type AccountTransactionsPaginatedOutput = {
  __typename?: 'AccountTransactionsPaginatedOutput';
  nextCursor?: Maybe<Scalars['String']['output']>;
  transactions: Array<Maybe<Transaction>>;
};

export enum AccountType {
  ExternalBank = 'EXTERNAL_BANK',
  /** more TK */
  Stripe = 'STRIPE'
}

export enum ActivityStatus {
  Blocked = 'BLOCKED',
  Bounce = 'BOUNCE',
  Click = 'CLICK',
  Deferred = 'DEFERRED',
  Delivered = 'DELIVERED',
  Dropped = 'DROPPED',
  Failed = 'FAILED',
  Open = 'OPEN',
  Pending = 'PENDING',
  Resubscribe = 'RESUBSCRIBE',
  Sent = 'SENT',
  SpamReport = 'SPAM_REPORT',
  Unsubscribe = 'UNSUBSCRIBE'
}

export type AdhocGiftShopIntentInput = {
  fundraiserId: Scalars['Int']['input'];
  fundraiserUserId?: InputMaybe<Scalars['Int']['input']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']['input']>;
  status: PurchaseIntentStatus;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** See CampaignMembership for details */
export type AdminCampaign = {
  __typename?: 'AdminCampaign';
  id: Scalars['ID']['output'];
};

export type AdminUser = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export enum Affiliate {
  Booster = 'BOOSTER',
  Foundation = 'FOUNDATION',
  Other = 'OTHER',
  ParentAssociation = 'PARENT_ASSOCIATION',
  ParentOrganization = 'PARENT_ORGANIZATION',
  ParentTeacherAssociation = 'PARENT_TEACHER_ASSOCIATION',
  ParentTeacherOrganization = 'PARENT_TEACHER_ORGANIZATION',
  ParentTeacherStudentAssociation = 'PARENT_TEACHER_STUDENT_ASSOCIATION',
  ParentTeacherStudentOrganization = 'PARENT_TEACHER_STUDENT_ORGANIZATION'
}

export enum AffiliationTitle {
  ActivitiesDirector = 'ACTIVITIES_DIRECTOR',
  AdministrativeAssistant = 'ADMINISTRATIVE_ASSISTANT',
  AssistantCoach = 'ASSISTANT_COACH',
  AthleticDirector = 'ATHLETIC_DIRECTOR',
  BoosterClubLeader = 'BOOSTER_CLUB_LEADER',
  BoosterClubMember = 'BOOSTER_CLUB_MEMBER',
  BusinessOwner = 'BUSINESS_OWNER',
  ClubSportsAdministrator = 'CLUB_SPORTS_ADMINISTRATOR',
  ClubSportsDirector = 'CLUB_SPORTS_DIRECTOR',
  ClubSportEmployee = 'CLUB_SPORT_EMPLOYEE',
  Coach = 'COACH',
  DistrictAdministrator = 'DISTRICT_ADMINISTRATOR',
  FinancialAdministrator = 'FINANCIAL_ADMINISTRATOR',
  FineArtsDirector = 'FINE_ARTS_DIRECTOR',
  Other = 'OTHER',
  Parent = 'PARENT',
  President = 'PRESIDENT',
  SchoolAdministrator = 'SCHOOL_ADMINISTRATOR',
  SchoolClubAdvisor = 'SCHOOL_CLUB_ADVISOR',
  Sponsor = 'SPONSOR',
  StateAdministrator = 'STATE_ADMINISTRATOR',
  Student = 'STUDENT',
  TeacherInstructor = 'TEACHER_INSTRUCTOR',
  Volunteer = 'VOLUNTEER'
}

export type AlumniDonor = {
  donateIntentId: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  schoolAddress?: InputMaybe<Scalars['String']['input']>;
  schoolName?: InputMaybe<Scalars['String']['input']>;
};

export type AlumniDonorSchoolDetails = {
  __typename?: 'AlumniDonorSchoolDetails';
  orgId?: Maybe<Scalars['String']['output']>;
  schoolAddress?: Maybe<Scalars['String']['output']>;
  schoolName?: Maybe<Scalars['String']['output']>;
};

export type AndroidNotificationInput = {
  channelId?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  sound?: InputMaybe<Scalars['String']['input']>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['Date']['output'];
  expiresAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  maxRps: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  permissions: Array<ApiKeyPermission>;
  secret: Scalars['String']['output'];
  user: User;
};

export type ApiKeyPermission = {
  __typename?: 'ApiKeyPermission';
  permission: Permission;
};

export type ApprovalDateFilterInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type ApprovalDetailType = {
  __typename?: 'ApprovalDetailType';
  amount?: Maybe<Scalars['Int']['output']>;
  dateReviewed?: Maybe<Scalars['String']['output']>;
  dateSubmitted: Scalars['String']['output'];
  from?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<SpendDebitCardShippingType>;
  submittedBy: Scalars['String']['output'];
  to?: Maybe<Scalars['String']['output']>;
  transferType?: Maybe<Scalars['String']['output']>;
};

export type ApprovalFilterInput = {
  date?: InputMaybe<ApprovalDateFilterInput>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  staffName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ApprovalType = {
  __typename?: 'ApprovalType';
  amount?: Maybe<Scalars['Int']['output']>;
  approverIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['String']['output'];
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  numberOfApprovals?: Maybe<Scalars['Int']['output']>;
  numberOfApprovers?: Maybe<Scalars['Int']['output']>;
  receipent?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  submittedBy: Scalars['String']['output'];
  title: Scalars['String']['output'];
  transferType?: Maybe<Scalars['String']['output']>;
};

export type ApproveFundraiserApprovalSubmissionData = {
  __typename?: 'ApproveFundraiserApprovalSubmissionData';
  isSsoApprover: Scalars['Boolean']['output'];
  submitterName: Scalars['String']['output'];
};

export type ApproveFundraiserApprovalSubmissionResult = ApproveFundraiserApprovalSubmissionData | InvalidToken;

export type AssignmentInput = {
  id: Scalars['String']['input'];
  type: ChannelTypesEnum;
};

export type AuditLogEntry = {
  __typename?: 'AuditLogEntry';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  service: AuditLogService;
  source: AuditLogSource;
  type: AuditLogType;
  userId?: Maybe<Scalars['String']['output']>;
};

export type AuditLogList = {
  __typename?: 'AuditLogList';
  list: Array<Maybe<AuditLogEntry>>;
};

export enum AuditLogService {
  Connect = 'Connect',
  Donors = 'Donors',
  Drive = 'Drive',
  Home = 'Home',
  Insights = 'Insights',
  Manage = 'Manage',
  Orgs = 'Orgs',
  Raise = 'Raise',
  Spend = 'Spend',
  Sponsor = 'Sponsor',
  Store = 'Store',
  UserDirectory = 'UserDirectory',
  Vault = 'Vault',
  Wallet = 'Wallet'
}

export enum AuditLogSource {
  Backend = 'Backend',
  Db = 'Db',
  Frontend = 'Frontend'
}

export enum AuditLogType {
  Debug = 'Debug',
  Error = 'Error',
  Info = 'Info',
  Warning = 'Warning'
}

export type Auth = Tokens & {
  __typename?: 'Auth';
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type AuthChallenge = Tokens & {
  __typename?: 'AuthChallenge';
  accessToken?: Maybe<Scalars['String']['output']>;
  challenges?: Maybe<Array<Maybe<UserChallenge>>>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type AuthenticationFlow = {
  __typename?: 'AuthenticationFlow';
  name?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type AuthorizedUser = {
  __typename?: 'AuthorizedUser';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type AuthorizedUsersAdded = {
  __typename?: 'AuthorizedUsersAdded';
  usersAdd: Array<AuthorizedUser>;
};

export type Award = {
  __typename?: 'Award';
  award?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  school?: Maybe<Scalars['String']['output']>;
  years?: Maybe<Scalars['String']['output']>;
};

export type BaseOrgInput = {
  city: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  einNumber: Scalars['String']['input'];
  formationYear?: InputMaybe<Scalars['Int']['input']>;
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  legalName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  stateCode: StateCode;
  streetAddress: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export enum BasicCampaignStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Upcoming = 'UPCOMING'
}

/** BoolFilter is used for filtering based on boolean values. */
export type BoolFilter = {
  /** Checks if the boolean field is equal to the specified value. */
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  /** Nested filter for negating a condition. */
  not?: InputMaybe<NestedBoolFilter>;
};

/** Filtering options for nullable Boolean type */
export type BoolNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Brand = {
  __typename?: 'Brand';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type BudgetTransaction = {
  __typename?: 'BudgetTransaction';
  id: Scalars['UUID']['output'];
  status?: Maybe<Status>;
};

export type BulkRewards = {
  __typename?: 'BulkRewards';
  id: Scalars['Int']['output'];
  incentiveId?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
};

/** Owned by Vault Only used in legacy createCard */
export enum Card_Status {
  Active = 'active',
  Canceled = 'canceled',
  Inactive = 'inactive'
}

/** Owned by Vault Only used in legacy createCard */
export enum Card_Type {
  Physical = 'physical',
  Virtual = 'virtual'
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/** @deprecated: will be merged with DriveCampaign instead This type is for Drive's Campaign Management. Users must have the Drive's permission to access any data. We are working on migrating this type to DriveCampaign. */
export type Campaign = {
  __typename?: 'Campaign';
  /** Account Manager of Campaign */
  accountManager?: Maybe<AccountManager>;
  /** Activity type of campaign. e.g. 'football', 'baseball', etc. */
  activityType?: Maybe<Scalars['String']['output']>;
  approvedFunds?: Maybe<ApprovedFunds>;
  /** URL for Generated Report of (Active) Campaign */
  campaignActiveReportUrl?: Maybe<Scalars['String']['output']>;
  /** City + State string of campaign. e.g. 'Dallas, TX' */
  campaignTerritory?: Maybe<Scalars['String']['output']>;
  coachJoinCode?: Maybe<Scalars['String']['output']>;
  /** Donations statistics of campaign. All values will be 0 if campaign is 'upcoming' */
  donations?: Maybe<Donations>;
  /** Participant email delivery info of campaigns */
  emails?: Maybe<EmailsInfo>;
  /** End date of fundraiser */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID of campaign. This ID used to obtain financial/payment info */
  entityId?: Maybe<Scalars['Int']['output']>;
  /** Date when campaign is finalized */
  finalizedDate?: Maybe<Scalars['DateTime']['output']>;
  /** Shipping status for gear items. Values are 'delivered','pre_transit','in_transit','failure','available_for_pickup','unknown','out_for_delivery', 'return_to_sender','cancelled','error' */
  gearStatus?: Maybe<Scalars['String']['output']>;
  /** Group Leader */
  groupLeader?: Maybe<GroupLeader>;
  /** Deal ID of campaign in Hubspot. */
  hubspotId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Cover image of campaign */
  image?: Maybe<Scalars['String']['output']>;
  /** Initial goal amount to fundraise in cents */
  initialGoalCents?: Maybe<Scalars['Int']['output']>;
  /** Link to invite participants to the campaign */
  inviteParticipantUrl?: Maybe<Scalars['String']['output']>;
  /** Unique join-code of campaign */
  joinCode?: Maybe<Scalars['Int']['output']>;
  /** URL for the fundraiser KYC Form. */
  kycFormUrl?: Maybe<Scalars['String']['output']>;
  /** Campaign kyc status */
  kycStatus?: Maybe<CampaignKycStatusResult>;
  /** Mailing address of campaign. */
  mailingAddress?: Maybe<Scalars['String']['output']>;
  /** MDM Contact of Campaign */
  mdm?: Maybe<MdmDetails>;
  name?: Maybe<Scalars['String']['output']>;
  /** Statistics info of campaign participants */
  participants?: Maybe<ParticipantsInfo>;
  /** Last withdrawal payment status. Values are 'Initializing', 'Processing', 'Transferred', 'Cut', 'Deposited', 'Expired' or 'Failed' */
  paymentStatus?: Maybe<Scalars['String']['output']>;
  /** Payment type when campaign finalized. Values are 'Check to Office', 'Check to Salesrep', 'Check to Fundraiser', or 'Direct Deposit'. */
  paymentType?: Maybe<Scalars['String']['output']>;
  /** Base64 generated CSV file for preload emails report of event */
  preloadReport?: Maybe<Scalars['String']['output']>;
  /** Download URL for Final Receipt (PDF) of settled campaigns */
  receiptDownloadUrl?: Maybe<Scalars['String']['output']>;
  /** Settlement Details for Closed Campaigns */
  settlementDetails?: Maybe<SettlementDetails>;
  /** Settlement Confirmation Status for Closed Campaigns. Values are 'UNSUBMITTED', 'IN_REVIEW' and 'VERIFIED' */
  settlementStatus?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** Start date of fundraiser */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  /** Size of team/group of campaign */
  teamSize?: Maybe<Scalars['Int']['output']>;
  /** Not in-use. Deprecated */
  testEmails?: Maybe<Scalars['String']['output']>;
  /** Total amount raised in cents */
  totalRaisedCents?: Maybe<Scalars['Int']['output']>;
  /** The total raised combined this organization has previously ran campaigns */
  totalRaisedHistory?: Maybe<Scalars['Int']['output']>;
  /** Tracking link for shipping gear items. */
  trackingLink?: Maybe<Scalars['String']['output']>;
};

export type CampaignDates = {
  __typename?: 'CampaignDates';
  endDateTime: Scalars['String']['output'];
  isAllDay?: Maybe<Scalars['Boolean']['output']>;
  startDateTime: Scalars['String']['output'];
};

/** Resigned campaigns */
export type CampaignHistoryList = {
  __typename?: 'CampaignHistoryList';
  CampaignHistoryEndDate?: Maybe<Scalars['String']['output']>;
  CampaignHistorySlug?: Maybe<Scalars['String']['output']>;
  CampaignHistoryStartDate?: Maybe<Scalars['String']['output']>;
  campaignName?: Maybe<Scalars['String']['output']>;
  campaignTotalRaised?: Maybe<Scalars['Int']['output']>;
};

export type CampaignKyc = {
  __typename?: 'CampaignKyc';
  status?: Maybe<CampaignKycStatusEnum>;
};

/** Fixed statuses for a campaigns kyc */
export enum CampaignKycStatus {
  ActionRequired = 'action_required',
  InReview = 'in_review',
  RequireDocs = 'require_docs',
  Unsubmitted = 'unsubmitted',
  Unverified = 'unverified',
  Verified = 'verified'
}

export enum CampaignKycStatusEnum {
  ActionNeeded = 'ACTION_NEEDED',
  Pending = 'PENDING',
  Unsubmitted = 'UNSUBMITTED',
  Verified = 'VERIFIED'
}

export type CampaignKycStatusResult = {
  __typename?: 'CampaignKycStatusResult';
  data?: Maybe<CampaignKycStatus>;
  type?: Maybe<CampaignKycType>;
};

/** Fixed type for a campaigns kyc */
export enum CampaignKycType {
  Stripe = 'stripe',
  Wepay = 'wepay'
}

export type CampaignList = {
  __typename?: 'CampaignList';
  count?: Maybe<Scalars['Int']['output']>;
  list: Array<Maybe<Campaign>>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export enum CampaignMemberAssociation {
  Admin = 'ADMIN',
  Coach = 'COACH',
  Cocoach = 'COCOACH',
  Coparticipant = 'COPARTICIPANT',
  Participant = 'PARTICIPANT',
  Supporter = 'SUPPORTER'
}

/** CampaignMembership is our way of securing information based on a users membership type. When a user queries campaignMemberships they can get information on their memberships based on who they are as defined in the JWT. Example of how to use:https://www.notion.so/snap-mobile/Campaign-1b4c4a055bc84aaf8290f078f57a5193 */
export type CampaignMembership = AdminCampaign | GroupLeaderCampaign | ParticipantCampaign | SupporterCampaign;

export type CampaignPersonList = {
  __typename?: 'CampaignPersonList';
  id: Scalars['Int']['output'];
};

export type CampaignPersonListData = {
  __typename?: 'CampaignPersonListData';
  email: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
};

export type CampaignPersonListEntries = {
  __typename?: 'CampaignPersonListEntries';
  invalidEntries?: Maybe<Array<Maybe<InvalidCampaignPersonListData>>>;
  validEntries?: Maybe<Array<Maybe<CampaignPersonListData>>>;
};

export type CampaignPersonListEntryDelete = {
  __typename?: 'CampaignPersonListEntryDelete';
  id: Scalars['Int']['output'];
};

export type CampaignSearchFilter = {
  __typename?: 'CampaignSearchFilter';
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Collection (key/values) of saved search criteria. */
  filterCriteria?: Maybe<Scalars['JSON']['output']>;
  /** Name of the saved search filter. */
  filterName?: Maybe<Scalars['String']['output']>;
  /** ID of saved search filters. */
  id?: Maybe<Scalars['Int']['output']>;
};

export type CampaignSmsDataResponse = {
  __typename?: 'CampaignSmsDataResponse';
  invalidEntries: Array<InvalidCampaignSmsInviteData>;
  status?: Maybe<CampaignSmsInviteStatus>;
  validEntries: Array<CampaignSmsInviteData>;
};

export type CampaignSmsInviteData = {
  __typename?: 'CampaignSmsInviteData';
  id: Scalars['Int']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type CampaignSmsInviteDeleteResponse = {
  __typename?: 'CampaignSmsInviteDeleteResponse';
  status: Scalars['String']['output'];
};

export enum CampaignSmsInviteStatus {
  Preloaded = 'PRELOADED',
  PreloadQueued = 'PRELOAD_QUEUED',
  Queued = 'QUEUED'
}

export enum CampaignStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Archive = 'ARCHIVE',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Final = 'FINAL',
  PendingApproval = 'PENDING_APPROVAL',
  Settled = 'SETTLED'
}

/** Owned by vault Extended by wallet Card issued by vault via Stripe */
export type Card = {
  __typename?: 'Card';
  cardNumber?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<VaultCardType>;
  cardholderId?: Maybe<Scalars['String']['output']>;
  cvv?: Maybe<Scalars['String']['output']>;
  expirationMonth?: Maybe<Scalars['Int']['output']>;
  expirationYear?: Maybe<Scalars['Int']['output']>;
  gatewayId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  last4?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<VaultCardMetadata>;
  spendingLimitAmount?: Maybe<Scalars['Int']['output']>;
  spendingLimitInterval?: Maybe<VaultSpendingLimitInterval>;
  status?: Maybe<VaultCardStatus>;
};

export type CarouselItem = {
  __typename?: 'CarouselItem';
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  media_type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum CategoryFilterEnum {
  Name = 'name',
  Type = 'type'
}

export enum CategoryTypeEnum {
  Expense = 'expense',
  Income = 'income'
}

export type Champion = {
  __typename?: 'Champion';
  division?: Maybe<Division>;
  id: Scalars['ID']['output'];
  school?: Maybe<Scalars['String']['output']>;
  years?: Maybe<Scalars['String']['output']>;
};

export type Channel = {
  __typename?: 'Channel';
  chatProviderId: Scalars['String']['output'];
  chatType: ChannelTypesEnum;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isReadOnly?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  status: ChannelStatusEnum;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ChannelAddModeratorsError = Error & {
  __typename?: 'ChannelAddModeratorsError';
  message: Scalars['String']['output'];
};

export type ChannelAddUsersError = Error & {
  __typename?: 'ChannelAddUsersError';
  message: Scalars['String']['output'];
};

export type ChannelCreateError = Error & {
  __typename?: 'ChannelCreateError';
  message: Scalars['String']['output'];
};

export type ChannelInvite = {
  __typename?: 'ChannelInvite';
  contact: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  method: ContactMethodEnum;
  role: ChannelRolesEnum;
  status: ChannelInviteStatusEnum;
};

export type ChannelInviteAcceptError = Error & {
  __typename?: 'ChannelInviteAcceptError';
  message: Scalars['String']['output'];
};

export type ChannelInviteAcceptedContacts = {
  __typename?: 'ChannelInviteAcceptedContacts';
  contact: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  method: ContactMethodEnum;
  role: ChannelRolesEnum;
};

export type ChannelInviteFailedContacts = {
  __typename?: 'ChannelInviteFailedContacts';
  contact: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  method: ContactMethodEnum;
  role: ChannelRolesEnum;
};

export type ChannelInviteInput = {
  channelId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  messagesInviteId: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['String']['input'];
};

export type ChannelInviteMembers = {
  __typename?: 'ChannelInviteMembers';
  acceptedContacts: Array<ChannelInviteAcceptedContacts>;
  failedContacts: Array<ChannelInviteFailedContacts>;
};

export type ChannelInviteMembersError = Error & {
  __typename?: 'ChannelInviteMembersError';
  message: Scalars['String']['output'];
};

export type ChannelInviteResendError = Error & {
  __typename?: 'ChannelInviteResendError';
  message: Scalars['String']['output'];
};

export enum ChannelInviteStatusEnum {
  Accepted = 'ACCEPTED',
  AutoAccepted = 'AUTO_ACCEPTED',
  Pending = 'PENDING'
}

export type ChannelInvites = {
  __typename?: 'ChannelInvites';
  count?: Maybe<Scalars['Int']['output']>;
  invites: Array<ChannelInvite>;
};

export type ChannelMember = {
  __typename?: 'ChannelMember';
  createdAt?: Maybe<Scalars['Date']['output']>;
  role: Scalars['String']['output'];
  user?: Maybe<UserPublic>;
  userId: Scalars['String']['output'];
};

export type ChannelMembers = {
  __typename?: 'ChannelMembers';
  channelMembers: Array<ChannelMember>;
  /** @deprecated Use channelMembers instead */
  members: Array<UserPublic>;
};

export type ChannelNotFound = Error & {
  __typename?: 'ChannelNotFound';
  message: Scalars['String']['output'];
};

export type ChannelOrg = {
  __typename?: 'ChannelOrg';
  orgId: Scalars['ID']['output'];
};

export type ChannelOrgError = Error & {
  __typename?: 'ChannelOrgError';
  message: Scalars['String']['output'];
};

export type ChannelRemoveMembersError = Error & {
  __typename?: 'ChannelRemoveMembersError';
  message: Scalars['String']['output'];
};

export enum ChannelRolesEnum {
  ChannelMember = 'channel_member',
  ChannelModerator = 'channel_moderator'
}

export enum ChannelStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Disabled = 'DISABLED',
  Frozen = 'FROZEN'
}

export enum ChannelTypesEnum {
  Fundraiser = 'fundraiser',
  RosterMessaging = 'roster_messaging'
}

export type ChannelUpdateError = Error & {
  __typename?: 'ChannelUpdateError';
  message: Scalars['String']['output'];
};

export type ChannelUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ChannelStatusEnum>;
};

export type ChannelUpdateMembersError = Error & {
  __typename?: 'ChannelUpdateMembersError';
  message: Scalars['String']['output'];
};

export type ChannelUpsertUsersError = Error & {
  __typename?: 'ChannelUpsertUsersError';
  message: Scalars['String']['output'];
};

export type Channels = {
  __typename?: 'Channels';
  channels: Array<Channel>;
};

export type ChatChannelAddModeratorsResult = Channel | ChannelAddModeratorsError | ChannelNotFound | NotAuthorized;

export type ChatChannelAddUsersResult = Channel | ChannelAddUsersError | ChannelNotFound | NotAuthorized;

export type ChatChannelCreateResult = Channel | ChannelCreateError | NotAuthorized;

export type ChatChannelResult = Channel | ChannelNotFound | MissingArguments | NotAuthenticated;

export type ChatChannelUpdateMembersResult = Channel | ChannelNotFound | ChannelUpdateMembersError | MissingArguments | NotAuthorized;

export type ChatChannelUpdateResult = Channel | ChannelNotFound | ChannelUpdateError | NotAuthenticated | NotAuthorized;

export type ChatChannelsResult = Channels | NotAuthenticated;

export type ChatToken = {
  __typename?: 'ChatToken';
  accessToken: Scalars['String']['output'];
};

export type ChatTokenResult = ChatToken | NotAuthenticated;

export type ChatUnreadMessagesResult = NotAuthenticated | UnreadCount | UnreadCountError;

export type Check = {
  __typename?: 'Check';
  amount: Scalars['Decimal']['output'];
  campaignId: Scalars['Int']['output'];
  checkNumber: Scalars['String']['output'];
  created: Scalars['Timestamp']['output'];
  destination: Account;
  effective: Scalars['Timestamp']['output'];
  expiration: Scalars['Timestamp']['output'];
  payee: Payee;
  status: CheckStatus;
  transactionId: Scalars['UUID']['output'];
};

export enum CheckStatus {
  Deposited = 'DEPOSITED',
  /** TODO(SHR): update this once we get actual statuses from bill.com */
  Processing = 'PROCESSING',
  Sent = 'SENT'
}

export type Cheer = {
  __typename?: 'Cheer';
  anonymous?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  donorMessage?: Maybe<Scalars['String']['output']>;
  donorName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lastName?: Maybe<Scalars['String']['output']>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
};

/** @deprecated */
export type CheerWallData = {
  __typename?: 'CheerWallData';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  donorMessage?: Maybe<Scalars['String']['output']>;
  donorName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  participant?: Maybe<ParticipantData>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
};

export enum Color {
  Black = 'BLACK',
  Blue = 'BLUE',
  Brown = 'BROWN',
  Gray = 'GRAY',
  Green = 'GREEN',
  LightBlue = 'LIGHT_BLUE',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  White = 'WHITE',
  Yellow = 'YELLOW'
}

export type CombinedPermissions = {
  __typename?: 'CombinedPermissions';
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CommonCalendar = {
  __typename?: 'CommonCalendar';
  activity?: Maybe<Scalars['String']['output']>;
  bus_count?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  driver_name?: Maybe<Scalars['String']['output']>;
  driver_phone?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  facility?: Maybe<Scalars['String']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  home_field?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  levels?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  season_id?: Maybe<Scalars['Int']['output']>;
  sports_code?: Maybe<Scalars['String']['output']>;
  sports_description?: Maybe<Scalars['String']['output']>;
  sports_group?: Maybe<Scalars['String']['output']>;
  sports_name?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  vehicle_id?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
};

export type CommonCalendarFiltersInput = {
  activity?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gender?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  homeAway?: InputMaybe<Scalars['String']['input']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleType?: InputMaybe<Scalars['String']['input']>;
};

export type CommonGrid = {
  __typename?: 'CommonGrid';
  activity?: Maybe<Scalars['String']['output']>;
  bus_count?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  driver_name?: Maybe<Scalars['String']['output']>;
  driver_phone?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  facility?: Maybe<Scalars['String']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  home_field?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  levels?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  season_id?: Maybe<Scalars['Int']['output']>;
  sports_code?: Maybe<Scalars['String']['output']>;
  sports_description?: Maybe<Scalars['String']['output']>;
  sports_group?: Maybe<Scalars['String']['output']>;
  sports_name?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  vehicle_id?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
};

export type CommonGridFiltersInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gender?: InputMaybe<Scalars['String']['input']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CommonSchedules = {
  __typename?: 'CommonSchedules';
  activity?: Maybe<Scalars['String']['output']>;
  bus_count?: Maybe<Scalars['Int']['output']>;
  bus_departure_location?: Maybe<Scalars['String']['output']>;
  bus_early_dismissal_time?: Maybe<Scalars['String']['output']>;
  bus_estimated_return_time?: Maybe<Scalars['String']['output']>;
  bus_time?: Maybe<Scalars['String']['output']>;
  cancellation_status?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  driver_name?: Maybe<Scalars['String']['output']>;
  driver_phone?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  facility?: Maybe<Scalars['String']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  home_field?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  levels?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  season_id?: Maybe<Scalars['Int']['output']>;
  sports_code?: Maybe<Scalars['String']['output']>;
  sports_description?: Maybe<Scalars['String']['output']>;
  sports_group?: Maybe<Scalars['String']['output']>;
  sports_name?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  vehicle_id?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
};

export type CommonSchedulesFiltersInput = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activityName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gender?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  homeAway?: InputMaybe<Scalars['String']['input']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  vehicleType?: InputMaybe<Scalars['String']['input']>;
};

export type CommonSheet = {
  __typename?: 'CommonSheet';
  activity?: Maybe<Scalars['String']['output']>;
  bus_count?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  driver_name?: Maybe<Scalars['String']['output']>;
  driver_phone?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  facility?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  home_field?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  levels?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  season_id?: Maybe<Scalars['Int']['output']>;
  sports_code?: Maybe<Scalars['String']['output']>;
  sports_description?: Maybe<Scalars['String']['output']>;
  sports_group?: Maybe<Scalars['String']['output']>;
  sports_name?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  vehicle_id?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
};

export type CommonSheetFiltersInput = {
  activity?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gender?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  homeAway?: InputMaybe<Scalars['String']['input']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleType?: InputMaybe<Scalars['String']['input']>;
};

export type CommsContactResponse = {
  __typename?: 'CommsContactResponse';
  contact?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RequestStatus>;
};

export type CommsHtmlResponse = {
  __typename?: 'CommsHtmlResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  html?: Maybe<Scalars['String']['output']>;
  mjml?: Maybe<Scalars['String']['output']>;
};

export type CommsMessageResponse = {
  __typename?: 'CommsMessageResponse';
  consumerTemplateId: Scalars['String']['output'];
  contacts: Array<MessageContactResponse>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  sendAt: Scalars['Date']['output'];
};

export type CommsRegisterResponse = {
  __typename?: 'CommsRegisterResponse';
  attributes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  emailProvider?: Maybe<SupportedEmailProviders>;
  from?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mjml?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  service: SnapService;
  smsProvider?: Maybe<SupportedSmsProviders>;
  subject?: Maybe<Scalars['String']['output']>;
  testData: Scalars['JSON']['output'];
  text?: Maybe<Scalars['String']['output']>;
  transport: MessageTransportType;
  type: MessageType;
};

export type CommsSendResponse = {
  __typename?: 'CommsSendResponse';
  details?: Maybe<Array<CommsContactResponse>>;
  id: Scalars['ID']['output'];
  rejectedContacts: Array<Scalars['String']['output']>;
};

export type CommsTemplateResponse = {
  __typename?: 'CommsTemplateResponse';
  attributes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  emailProvider?: Maybe<SupportedEmailProviders>;
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  from?: Maybe<Scalars['String']['output']>;
  fromName?: Maybe<Scalars['String']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mjml?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  service: SnapService;
  smsProvider?: Maybe<SupportedSmsProviders>;
  subject?: Maybe<Scalars['String']['output']>;
  testData: Scalars['JSON']['output'];
  text?: Maybe<Scalars['String']['output']>;
  transport: MessageTransportType;
  type: MessageType;
  unsubscribeGroupId?: Maybe<Scalars['Int']['output']>;
  unsubscribeGroups?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type CommsTestResponse = {
  __typename?: 'CommsTestResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type CommsUnsubscribeGroup = {
  __typename?: 'CommsUnsubscribeGroup';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  unsubscribes?: Maybe<Scalars['Int']['output']>;
};

export type CommsUnsubscribeGroupsResponse = {
  __typename?: 'CommsUnsubscribeGroupsResponse';
  groups?: Maybe<Array<CommsUnsubscribeGroup>>;
};

export type Conference = {
  __typename?: 'Conference';
  active?: Maybe<Scalars['Boolean']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  conferenceSports?: Maybe<Array<ConferenceSport>>;
  documents?: Maybe<Array<LeagueKeeperDocument>>;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagramUrl?: Maybe<Scalars['String']['output']>;
  levels?: Maybe<Array<LeagueKeeperLevel>>;
  link1Label?: Maybe<Scalars['String']['output']>;
  link1Url?: Maybe<Scalars['String']['output']>;
  link2Label?: Maybe<Scalars['String']['output']>;
  link2Url?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  schools?: Maybe<Array<School>>;
  shortName?: Maybe<Scalars['String']['output']>;
  twitterUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  welcomeMessage?: Maybe<Scalars['String']['output']>;
  youtubeUrl?: Maybe<Scalars['String']['output']>;
};


export type ConferenceConferenceSportsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type ConferenceDocumentsArgs = {
  docType?: InputMaybe<Scalars['String']['input']>;
};

export type ConferenceSport = {
  __typename?: 'ConferenceSport';
  awards?: Maybe<Array<Award>>;
  champions?: Maybe<Array<Champion>>;
  document?: Maybe<LeagueKeeperDocument>;
  events?: Maybe<Array<LeagueKeeperEvent>>;
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Sport>;
  standings?: Maybe<Array<Standing>>;
  summary?: Maybe<Array<Summary>>;
};


export type ConferenceSportChampionsArgs = {
  school?: InputMaybe<Scalars['String']['input']>;
  years?: InputMaybe<Scalars['String']['input']>;
};


export type ConferenceSportEventsArgs = {
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  levelId?: InputMaybe<Scalars['ID']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  years?: InputMaybe<Scalars['String']['input']>;
};


export type ConferenceSportStandingsArgs = {
  levelId: Scalars['ID']['input'];
  years: Scalars['String']['input'];
};


export type ConferenceSportSummaryArgs = {
  levelId: Scalars['ID']['input'];
  years: Scalars['String']['input'];
};

export type ConfirmProfileChallengeResponse = {
  __typename?: 'ConfirmProfileChallengeResponse';
  auth: Auth;
  challenge: UserChallenge;
  success: Scalars['Boolean']['output'];
};

export type Consumer = {
  __typename?: 'Consumer';
  accessBy?: Maybe<Scalars['String']['output']>;
  color: Scalars['String']['output'];
  description: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inApps: Scalars['Boolean']['output'];
  logo: Scalars['String']['output'];
  mask: Scalars['String']['output'];
  modal?: Maybe<ConsumerModal>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ConsumerModal = {
  __typename?: 'ConsumerModal';
  descriptions: Array<Maybe<Scalars['String']['output']>>;
  header: Scalars['String']['output'];
};

export enum ContactMethodEnum {
  Email = 'EMAIL',
  PhoneNumber = 'PHONE_NUMBER'
}

export enum ContactSupportInquiryType {
  GeneralInquiry = 'GENERAL_INQUIRY',
  Insights = 'INSIGHTS',
  Leagues = 'LEAGUES',
  ManageRegistration = 'MANAGE_REGISTRATION',
  ManageSchedule = 'MANAGE_SCHEDULE',
  ManageSites = 'MANAGE_SITES',
  Messaging = 'MESSAGING',
  RaiseFundraising = 'RAISE_FUNDRAISING',
  SnapMobileApp = 'SNAP_MOBILE_APP',
  Spend = 'SPEND',
  Store = 'STORE'
}

export enum ContactTemplateMedium {
  Email = 'EMAIL',
  TextMessage = 'TEXT_MESSAGE'
}

export enum ContactTemplateType {
  GroupLeader = 'GROUP_LEADER',
  Participant = 'PARTICIPANT'
}

export type ContractItem = {
  __typename?: 'ContractItem';
  event_contract?: Maybe<EventContract>;
  event_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ContractSignees = {
  __typename?: 'ContractSignees';
  auth_hash?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  email_id?: Maybe<Scalars['String']['output']>;
  event_contract_number?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  original_contract?: Maybe<Scalars['String']['output']>;
  sent_date?: Maybe<Scalars['Date']['output']>;
  sign_contract?: Maybe<Scalars['String']['output']>;
  sign_date?: Maybe<Scalars['Date']['output']>;
  sign_status?: Maybe<Scalars['String']['output']>;
};

export type ControlPanelEventResult = {
  __typename?: 'ControlPanelEventResult';
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  school: ControlPanelSchool;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ControlPanelSchool = {
  __typename?: 'ControlPanelSchool';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  fax: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgsId: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  webfolder: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

/** Owned by Vault Only used in legacy createCard */
export type CoreCardFields = {
  __typename?: 'CoreCardFields';
  cardId: Scalars['String']['output'];
  expirationMonth: Scalars['Int']['output'];
  expirationYear: Scalars['Int']['output'];
  gatewayId?: Maybe<Scalars['String']['output']>;
  last4: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Autogenerated input type of CpDeleteEventResult */
export type CpDeleteEventResultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  schedResultId: Scalars['ID']['input'];
};

/** Autogenerated return type of CpDeleteEventResult. */
export type CpDeleteEventResultPayload = {
  __typename?: 'CpDeleteEventResultPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CpEventResultInput = {
  author?: InputMaybe<Scalars['String']['input']>;
  eventComplete?: InputMaybe<Scalars['Boolean']['input']>;
  eventStory?: InputMaybe<Scalars['String']['input']>;
  eventTitle?: InputMaybe<Scalars['String']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  onFrontPage?: InputMaybe<Scalars['Boolean']['input']>;
  opponentScore?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<Scalars['String']['input']>;
  schedId: Scalars['ID']['input'];
  score?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CpUpdateEventResult */
export type CpUpdateEventResultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventResultData: CpEventResultInput;
  orgsId: Scalars['String']['input'];
  schedResultId: Scalars['ID']['input'];
};

/** Autogenerated return type of CpUpdateEventResult. */
export type CpUpdateEventResultPayload = {
  __typename?: 'CpUpdateEventResultPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  eventResult?: Maybe<ControlPanelEventResult>;
};

export type CreateChildFundraiserParticipantInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  fundraiserId: Scalars['ID']['input'];
  groupId?: InputMaybe<Scalars['ID']['input']>;
  joinType?: InputMaybe<JoinType>;
  lastName: Scalars['String']['input'];
  udid: Scalars['String']['input'];
};

export type CreateEventInput = {
  activity?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  bus_fee?: InputMaybe<Scalars['Float']['input']>;
  bus_time?: InputMaybe<Scalars['String']['input']>;
  cancellation_status?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  conference?: InputMaybe<Scalars['String']['input']>;
  conference_event_id?: InputMaybe<Scalars['Int']['input']>;
  conference_id?: InputMaybe<Scalars['Int']['input']>;
  confirmed?: InputMaybe<Scalars['String']['input']>;
  contract?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['Date']['input']>;
  departure_location?: InputMaybe<Scalars['String']['input']>;
  directions?: InputMaybe<Scalars['String']['input']>;
  early_dismissal_required?: InputMaybe<Scalars['String']['input']>;
  early_dismissal_time?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['String']['input']>;
  estimated_return_time?: InputMaybe<Scalars['String']['input']>;
  event_date?: InputMaybe<Scalars['Date']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  exists_in_mls?: InputMaybe<Scalars['Int']['input']>;
  fee?: InputMaybe<Scalars['Float']['input']>;
  g_s?: InputMaybe<Scalars['String']['input']>;
  gate_revenue?: InputMaybe<Scalars['Float']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  impact_event?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  loss_points?: InputMaybe<Scalars['Int']['input']>;
  num_buses?: InputMaybe<Scalars['Int']['input']>;
  opponent?: InputMaybe<Scalars['String']['input']>;
  opponent_code?: InputMaybe<Scalars['String']['input']>;
  opponent_score?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  prep_setup?: InputMaybe<Scalars['String']['input']>;
  promote?: InputMaybe<Scalars['String']['input']>;
  results?: InputMaybe<Scalars['String']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
  rollover?: InputMaybe<Scalars['String']['input']>;
  season_team?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['String']['input']>;
  team_score?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  tournament?: InputMaybe<Scalars['String']['input']>;
  trans_id?: InputMaybe<Scalars['Int']['input']>;
  transport_comments?: InputMaybe<Scalars['String']['input']>;
  transportation?: InputMaybe<Scalars['String']['input']>;
  update_at?: InputMaybe<Scalars['Date']['input']>;
  web_dir?: InputMaybe<Scalars['String']['input']>;
  win_points?: InputMaybe<Scalars['Int']['input']>;
  years?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEventParticipantsInput = {
  contract_received?: InputMaybe<Scalars['String']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['String']['input']>;
  school_code?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEventPreparationInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  prep?: InputMaybe<Scalars['String']['input']>;
  qty?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEventTransportDetailsInput = {
  driver_name?: InputMaybe<Scalars['String']['input']>;
  driver_phone?: InputMaybe<Scalars['String']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  vehicle_id?: InputMaybe<Scalars['String']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFacilityInput = {
  Address1: Scalars['String']['input'];
  Address2?: InputMaybe<Scalars['String']['input']>;
  City: Scalars['String']['input'];
  State: Scalars['String']['input'];
  ZipCode: Scalars['String']['input'];
  directions?: InputMaybe<Scalars['String']['input']>;
  indoor?: InputMaybe<Scalars['String']['input']>;
  ml_site_id?: InputMaybe<Scalars['String']['input']>;
  ml_space?: InputMaybe<Scalars['String']['input']>;
  ml_space_id?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  place_name: Scalars['String']['input'];
  show?: InputMaybe<Scalars['String']['input']>;
  web?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLevelInput = {
  Level?: InputMaybe<Scalars['String']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateOfficialDutyInput = {
  duty?: InputMaybe<Scalars['String']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateOfficialInput = {
  duty?: InputMaybe<Scalars['String']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  offic_id?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['String']['input']>;
  pay_code?: InputMaybe<Scalars['String']['input']>;
  received?: InputMaybe<Scalars['String']['input']>;
  salary?: InputMaybe<Scalars['Float']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  voucher_number?: InputMaybe<Scalars['String']['input']>;
  worker_name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOfficialPoolByIdInput = {
  Address?: InputMaybe<Scalars['String']['input']>;
  City?: InputMaybe<Scalars['String']['input']>;
  First: Scalars['String']['input'];
  Home_Phone?: InputMaybe<Scalars['String']['input']>;
  ID: Scalars['String']['input'];
  Last: Scalars['String']['input'];
  SSN?: InputMaybe<Scalars['String']['input']>;
  State?: InputMaybe<Scalars['String']['input']>;
  Work_Phone?: InputMaybe<Scalars['String']['input']>;
  Zip?: InputMaybe<Scalars['String']['input']>;
  cell_phone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  vendor_number?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOpponentInput = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Phone?: InputMaybe<Scalars['String']['input']>;
  SchoolCode: Scalars['String']['input'];
  SchoolName?: InputMaybe<Scalars['String']['input']>;
  State?: InputMaybe<Scalars['String']['input']>;
  Zip?: InputMaybe<Scalars['String']['input']>;
  ad_name?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  nces_id?: InputMaybe<Scalars['String']['input']>;
  non_school?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreatePreparationInput = {
  duty?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  qty?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSchoolInfoInput = {
  Schoolname?: InputMaybe<Scalars['String']['input']>;
  ad?: InputMaybe<Scalars['String']['input']>;
  ad_contract_signee?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  ccemail?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  conf_text_type?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_reminder?: InputMaybe<Scalars['String']['input']>;
  email_reminder_officials?: InputMaybe<Scalars['String']['input']>;
  email_reminder_workers?: InputMaybe<Scalars['String']['input']>;
  enable_cc_email_as_origin?: InputMaybe<Scalars['String']['input']>;
  enable_ml_updates?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  mascot?: InputMaybe<Scalars['String']['input']>;
  message_board_read_at?: InputMaybe<Scalars['Date']['input']>;
  ml_key?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  principal?: InputMaybe<Scalars['String']['input']>;
  school_timezone?: InputMaybe<Scalars['String']['input']>;
  secondary_ad_email?: InputMaybe<Scalars['String']['input']>;
  secondary_ad_name?: InputMaybe<Scalars['String']['input']>;
  signed_contract_notification?: InputMaybe<Scalars['String']['input']>;
  ssn_on_file?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_org?: InputMaybe<Scalars['String']['input']>;
  state_org_abbreviation?: InputMaybe<Scalars['String']['input']>;
  use_security?: InputMaybe<Scalars['String']['input']>;
  web_password?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVehicleInput = {
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkerInput = {
  duty?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  organization?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['String']['input']>;
  pay_code?: InputMaybe<Scalars['String']['input']>;
  pay_rate?: InputMaybe<Scalars['Float']['input']>;
  salary?: InputMaybe<Scalars['Float']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  woker_name?: InputMaybe<Scalars['String']['input']>;
  worker_end_time?: InputMaybe<Scalars['String']['input']>;
  worker_start_time?: InputMaybe<Scalars['String']['input']>;
  worker_type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkerPool = {
  Address?: InputMaybe<Scalars['String']['input']>;
  City?: InputMaybe<Scalars['String']['input']>;
  First: Scalars['String']['input'];
  Home_Phone?: InputMaybe<Scalars['String']['input']>;
  Last: Scalars['String']['input'];
  SSN?: InputMaybe<Scalars['String']['input']>;
  State?: InputMaybe<Scalars['String']['input']>;
  Work_Phone?: InputMaybe<Scalars['String']['input']>;
  Zip?: InputMaybe<Scalars['String']['input']>;
  cell_phone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  pay_rate?: InputMaybe<Scalars['Float']['input']>;
  worker_type?: InputMaybe<Scalars['String']['input']>;
};

export type CreatedFundraiserUser = {
  __typename?: 'CreatedFundraiserUser';
  id: Scalars['Int']['output'];
};

export type CreatedOtkEntry = {
  __typename?: 'CreatedOtkEntry';
  id: Scalars['Int']['output'];
};

export type CreatedParticipantRewards = {
  __typename?: 'CreatedParticipantRewards';
  id: Scalars['Int']['output'];
};

export type CurrentCustomersDeals = {
  __typename?: 'CurrentCustomersDeals';
  activity?: Maybe<Scalars['String']['output']>;
  dealName?: Maybe<Scalars['String']['output']>;
  dealStage?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  fundraiserStatus?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastLaunch?: Maybe<Scalars['String']['output']>;
  leaderFirstName?: Maybe<Scalars['String']['output']>;
  leaderLastName?: Maybe<Scalars['String']['output']>;
  previousDealId?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  snapFundraiserId?: Maybe<Scalars['String']['output']>;
  totalRaised?: Maybe<Scalars['Int']['output']>;
};

/** Information of custom email templates */
export type CustomContactTemplates = {
  __typename?: 'CustomContactTemplates';
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarBusScheduleEvents = {
  __typename?: 'DailyCalendarBusScheduleEvents';
  activity?: Maybe<Scalars['String']['output']>;
  activityType?: Maybe<Scalars['String']['output']>;
  bus_time?: Maybe<Scalars['String']['output']>;
  calendarDate?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  departure_location?: Maybe<Scalars['String']['output']>;
  early_dismissal_required?: Maybe<Scalars['String']['output']>;
  early_dismissal_time?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  estimated_return_time?: Maybe<Scalars['String']['output']>;
  event_comments?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  num_buses?: Maybe<Scalars['Int']['output']>;
  num_buses_text?: Maybe<Scalars['Int']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  sportGender?: Maybe<Scalars['String']['output']>;
  sportLevel?: Maybe<Scalars['String']['output']>;
  sport_name?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarBusScheduleExportData = {
  __typename?: 'DailyCalendarBusScheduleExportData';
  activity?: Maybe<Scalars['String']['output']>;
  bus_time?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  departure_location?: Maybe<Scalars['String']['output']>;
  driver_name?: Maybe<Scalars['String']['output']>;
  driver_phone?: Maybe<Scalars['String']['output']>;
  early_dismissal_required?: Maybe<Scalars['String']['output']>;
  early_dismissal_time?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  estimated_return_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  groupval?: Maybe<Scalars['String']['output']>;
  level1?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  num_buses_text?: Maybe<Scalars['Int']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  sport_description?: Maybe<Scalars['String']['output']>;
  sport_name?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  transport_comments?: Maybe<Scalars['String']['output']>;
  vehicle_id?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarEvent = {
  __typename?: 'DailyCalendarEvent';
  activity?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  departure_location?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  early_dismissal_time?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  estimated_return_time?: Maybe<Scalars['String']['output']>;
  eventId?: Maybe<Scalars['Int']['output']>;
  eventTransportDetails?: Maybe<Array<Maybe<EventTransportDetails>>>;
  event_date?: Maybe<Scalars['Date']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  num_buses?: Maybe<Scalars['Int']['output']>;
  officials?: Maybe<Array<Maybe<Official>>>;
  opponent?: Maybe<Scalars['String']['output']>;
  preparations?: Maybe<Array<Maybe<Preparation>>>;
  schoolInfo?: Maybe<SchoolInfo>;
  season_team?: Maybe<Scalars['Int']['output']>;
  season_years?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  workers?: Maybe<Array<Maybe<Worker>>>;
};

export type DailyCalendarEventTransportDetails = {
  __typename?: 'DailyCalendarEventTransportDetails';
  driver_name?: Maybe<Scalars['String']['output']>;
  driver_phone?: Maybe<Scalars['String']['output']>;
  event_transport_details_id?: Maybe<Scalars['Int']['output']>;
  vehicle_id?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarOfficialDuties = {
  __typename?: 'DailyCalendarOfficialDuties';
  Address?: Maybe<Scalars['String']['output']>;
  City?: Maybe<Scalars['String']['output']>;
  Home_Phone?: Maybe<Scalars['String']['output']>;
  SSN?: Maybe<Scalars['String']['output']>;
  State?: Maybe<Scalars['String']['output']>;
  Work_Phone?: Maybe<Scalars['String']['output']>;
  Zip?: Maybe<Scalars['String']['output']>;
  cell_phone?: Maybe<Scalars['String']['output']>;
  duty?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offic_id?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['String']['output']>;
  salary?: Maybe<Scalars['String']['output']>;
  vendor_number?: Maybe<Scalars['String']['output']>;
  voucher_field?: Maybe<Scalars['Int']['output']>;
  voucher_number?: Maybe<Scalars['String']['output']>;
  woker_name?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarOfficialExport = {
  __typename?: 'DailyCalendarOfficialExport';
  address?: Maybe<Scalars['String']['output']>;
  cell_phone?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  duty?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  groupVal?: Maybe<Scalars['String']['output']>;
  home_phone?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  offic_id?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  salary?: Maybe<Scalars['String']['output']>;
  sport_description?: Maybe<Scalars['String']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  vendor_number?: Maybe<Scalars['String']['output']>;
  voucher_number?: Maybe<Scalars['String']['output']>;
  woker_name?: Maybe<Scalars['String']['output']>;
  work_phone?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarOfficialMessage = {
  __typename?: 'DailyCalendarOfficialMessage';
  comments?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  official_duties?: Maybe<Array<Maybe<DailyCalendarOfficialDuties>>>;
  opponent?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarPreparation = {
  __typename?: 'DailyCalendarPreparation';
  comments?: Maybe<Scalars['String']['output']>;
  prep?: Maybe<Scalars['String']['output']>;
  qty?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarPreparationExportData = {
  __typename?: 'DailyCalendarPreparationExportData';
  comments?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  groupval?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  prep?: Maybe<Scalars['String']['output']>;
  qty?: Maybe<Scalars['String']['output']>;
  sport_description?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarPreparationMessage = {
  __typename?: 'DailyCalendarPreparationMessage';
  comments?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  groupval?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  preparations?: Maybe<Array<Maybe<DailyCalendarPreparation>>>;
  sport?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarWorkerDuties = {
  __typename?: 'DailyCalendarWorkerDuties';
  SSN?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  duty?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  groupval?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['String']['output']>;
  pay_code?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Scalars['String']['output']>;
  salary?: Maybe<Scalars['Float']['output']>;
  sport_description?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  woker_name?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarWorkerExportData = {
  __typename?: 'DailyCalendarWorkerExportData';
  comments?: Maybe<Scalars['String']['output']>;
  duty?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  groupval?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  pay_code?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Scalars['String']['output']>;
  sport_description?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  woker_name?: Maybe<Scalars['String']['output']>;
};

export type DailyCalendarWorkerMessage = {
  __typename?: 'DailyCalendarWorkerMessage';
  comments?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  groupval?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  worker_duties?: Maybe<Array<Maybe<DailyCalendarWorkerDuties>>>;
};

export type DateRange = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

/** Filtering options for nullable DateTime type */
export type DateTimeNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars['String']['input']>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars['String']['input']>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars['String']['input']>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars['String']['input']>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars['String']['input']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['String']['input']>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DeleteCount = {
  __typename?: 'DeleteCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export type DeleteEventInput = {
  event_id: Scalars['Int']['input'];
};

export type DeleteEventParticipantsInput = {
  id: Scalars['Int']['input'];
};

export type DeleteEventPreparationInput = {
  id: Scalars['Int']['input'];
};

export type DeleteEventPreparationsInput = {
  id: Scalars['Int']['input'];
};

export type DeleteEventTransportDetailsInput = {
  id: Scalars['Int']['input'];
};

export type DeleteFacilityInput = {
  place_name: Scalars['String']['input'];
};

export type DeleteLevelInput = {
  ID: Scalars['Int']['input'];
};

export type DeleteManyEventParticipantsInput = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type DeleteManyEventPreparationsInput = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type DeleteManyEventTransportDetailsInput = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type DeleteManyWorkerInput = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type DeleteOfficialDutiesInput = {
  ids: Array<Scalars['Int']['input']>;
};

export type DeleteOfficialInput = {
  id: Scalars['Int']['input'];
};

export type DeleteOfficialPoolInput = {
  ids: Array<InputMaybe<Scalars['String']['input']>>;
};

export type DeleteOpponentInput = {
  SchoolCode: Scalars['String']['input'];
};

export type DeletePreparationInput = {
  id: Scalars['Int']['input'];
};

export type DeleteSchoolInfoInput = {
  id: Scalars['String']['input'];
};

export type DeleteVehicleInput = {
  id: Scalars['Int']['input'];
};

export type DeleteWorkerInput = {
  id: Scalars['Int']['input'];
};

export type DeleteWorkerPoolInput = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type DeletedIncentiveRecord = {
  __typename?: 'DeletedIncentiveRecord';
  id: Scalars['ID']['output'];
};

export type Deposit = {
  __typename?: 'Deposit';
  amount: Scalars['Decimal']['output'];
  campaignId: Scalars['Int']['output'];
  created: Scalars['Timestamp']['output'];
  description: Scalars['String']['output'];
  destination: Account;
  effective: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  source: Account;
  transactionId: Scalars['UUID']['output'];
};

export type Division = {
  __typename?: 'Division';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Documents uploaded for validation purposes */
export type Document = {
  __typename?: 'Document';
  documentBack?: Maybe<Scalars['String']['output']>;
  documentFront?: Maybe<Scalars['String']['output']>;
  gateway: VaultFinancialProvider;
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
  vaultKybId?: Maybe<Scalars['ID']['output']>;
  vaultKycId?: Maybe<Scalars['ID']['output']>;
};

export type DonateIntent = {
  __typename?: 'DonateIntent';
  /** Donation amount (without coverage fee) in cents */
  amount?: Maybe<Scalars['Int']['output']>;
  anonymous?: Maybe<Scalars['Boolean']['output']>;
  /** Flag to indicate if the Credit card fee is covered by the donor */
  ccFeeCovered?: Maybe<Scalars['Boolean']['output']>;
  /** Credit card fee percentage, If leave null/undefined, it will be calculated at 0.03 (3%) */
  ccFeePercentage?: Maybe<Scalars['Float']['output']>;
  /** Fee amount coverage by donor (without coverage fee) in cents */
  coverageAmount?: Maybe<Scalars['Int']['output']>;
  /** Linked donation if existed */
  donation?: Maybe<DonorDonation>;
  donationId?: Maybe<Scalars['String']['output']>;
  donorEmail?: Maybe<Scalars['String']['output']>;
  donorFirstName?: Maybe<Scalars['String']['output']>;
  donorLastName?: Maybe<Scalars['String']['output']>;
  fundraiser: DonorFundraiser;
  history?: Maybe<Array<Maybe<DonateIntent>>>;
  id: Scalars['ID']['output'];
  latestCartPurchaseIntent?: Maybe<GiftShopPurchaseIntentData>;
  matchDon?: Maybe<Scalars['String']['output']>;
  participantId?: Maybe<Scalars['Int']['output']>;
  participantUserDirectoryId?: Maybe<Scalars['String']['output']>;
  /** Payment Method used for Strip or Paypal payment */
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  paymentProvider?: Maybe<PaymentProvider>;
  previousStatus?: Maybe<DonateIntentStatus>;
  /** Share Type of donation */
  shareType?: Maybe<Scalars['String']['output']>;
  /** The subtotal amount in cents which will be send to Snap's Stripe account snapAmount = (amount + coverageAmount - allocationAmount + tipAmount), where allocationAmount is the subtotalAmount will be sent to team's Stripe connected account, calculated through donation-allocation-amount helper. */
  snapAmount?: Maybe<Scalars['Int']['output']>;
  status: DonateIntentStatus;
  tipAmount?: Maybe<Scalars['Int']['output']>;
  /** Total amounts will be charged to donor's credit card. Equals amount + coverageAmount */
  totalAmount?: Maybe<Scalars['Int']['output']>;
};

export enum DonateIntentStatus {
  Cancelled = 'CANCELLED',
  ChargedBack = 'CHARGED_BACK',
  Failed = 'FAILED',
  Initiated = 'INITIATED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Refunded = 'REFUNDED',
  Succeeded = 'SUCCEEDED'
}

/** Not used yet. Will be removed soon. */
export type DonationInvite = {
  __typename?: 'DonationInvite';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastEmailSentAt?: Maybe<Scalars['String']['output']>;
  senderName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type DonationInviteReleaseStatus = {
  __typename?: 'DonationInviteReleaseStatus';
  areDonationInviteEmailsReleased: Scalars['Boolean']['output'];
};

export type DonationInviteRemoveData = {
  __typename?: 'DonationInviteRemoveData';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

export type DonationInviteSmsData = {
  __typename?: 'DonationInviteSMSData';
  id: Scalars['Int']['output'];
  phoneNumber: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export enum DonationInviteStatus {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Donated = 'DONATED',
  Loaded = 'LOADED',
  Preloaded = 'PRELOADED',
  Released = 'RELEASED'
}

export type DonationLevel = {
  __typename?: 'DonationLevel';
  amount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  level: DonationLevels;
  rewardDescription?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export enum DonationLevels {
  Five = 'FIVE',
  Four = 'FOUR',
  One = 'ONE',
  Six = 'SIX',
  Three = 'THREE',
  Two = 'TWO'
}

export type DonationTopEarner = {
  __typename?: 'DonationTopEarner';
  participantID?: Maybe<Scalars['Int']['output']>;
};

/** Donation Statistics. Will change name to CampaignDonationStats later. */
export type Donations = {
  __typename?: 'Donations';
  /** Total of distinct donors in campaigns */
  AskedToShare?: Maybe<Scalars['Int']['output']>;
  /** Total count of donations in campaign */
  count?: Maybe<Scalars['Int']['output']>;
  /** Total donation amount in cents raised through email */
  emailTotal?: Maybe<Scalars['Int']['output']>;
  /** Total donation amount in cents raised other methods outside text, email and social */
  extraTotal?: Maybe<Scalars['Int']['output']>;
  /** Total of donation amount in cents raised through forward to friends. */
  fowardTotal?: Maybe<Scalars['Int']['output']>;
  /** Total quantity of gear items purchased in campaign. */
  gearAdded?: Maybe<Scalars['Int']['output']>;
  /** Total count of distinct donors that purchased gears. */
  gearPurchased?: Maybe<Scalars['Int']['output']>;
  /** Total of purchase amount in cents of gear (OTK) items */
  gearTotal?: Maybe<Scalars['Int']['output']>;
  /** Total donation amounts in cents through retaining supporters from previous closed campaign. Share Type is 'reisgn_a' or 'resign_b' */
  legacySupporters?: Maybe<Scalars['Int']['output']>;
  /** Total count of distinct donors who are new and donated campaigns through share-type 'supporter_referral' */
  newSupporters?: Maybe<Scalars['Int']['output']>;
  otherTotal?: Maybe<Scalars['Int']['output']>;
  /** Total count of donors who are retaining supporters from previous closed campaign. Share Type is 'reisgn_a' or 'resign_b' */
  pastSupporters?: Maybe<Scalars['Int']['output']>;
  /** Total donation amount in cents raised through social media */
  socialTotal?: Maybe<Scalars['Int']['output']>;
  /** Total donation amount in cents raised through sms/text */
  textTotal?: Maybe<Scalars['Int']['output']>;
};

export enum DonationsAndPurchasesEnum {
  Donation = 'donation',
  Purchase = 'purchase'
}

export type DonationsResponse = {
  __typename?: 'DonationsResponse';
  fundraiserTopDonation?: Maybe<TopDonation>;
  fundraiserTotalDonationsRaised?: Maybe<TotalDonationsRaised>;
  participantTopDonation?: Maybe<TopDonation>;
  participantTotalDonationsRaised?: Maybe<TotalDonationsRaised>;
};

export type DonorData = {
  __typename?: 'DonorData';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type DonorDonateIntentCreateInput = {
  amount: Scalars['Int']['input'];
  /** This is preference of the donors to be anonymous */
  anonymous?: InputMaybe<Scalars['Boolean']['input']>;
  /** This is a session browser secret */
  browserSecret?: InputMaybe<Scalars['String']['input']>;
  /** Flag to indicate if the Credit card fee is covered by the donor */
  ccFeeCovered?: InputMaybe<Scalars['Boolean']['input']>;
  ccFeePercentage?: InputMaybe<Scalars['Float']['input']>;
  /** This is the Donation Level ID that will be passed thru the URL on Checkout. */
  donationLevelId?: InputMaybe<Scalars['String']['input']>;
  donorEmail?: InputMaybe<Scalars['String']['input']>;
  donorFirstName?: InputMaybe<Scalars['String']['input']>;
  donorLastName?: InputMaybe<Scalars['String']['input']>;
  errorType?: InputMaybe<Scalars['String']['input']>;
  fundraiserId: Scalars['String']['input'];
  matchDon?: InputMaybe<Scalars['String']['input']>;
  /** This is the IP address of the donor */
  originIp?: InputMaybe<Scalars['String']['input']>;
  participantId?: InputMaybe<Scalars['Int']['input']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']['input']>;
  /** Share Type of donation will be passed as a search query to the URL on Checkout. Valid values can be found at https://www.notion.so/snap-mobile/508f9d741b434cef85cb2c5cbdf15eb7?v=804cce5f998a415b97d672037112b4ae&pvs=4 under the New Stack Share Types column */
  shareType?: InputMaybe<Scalars['String']['input']>;
  tipAmount?: InputMaybe<Scalars['Int']['input']>;
};

export type DonorDonateIntentUpdateInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  /** This is preference of the donors to be anonymous */
  anonymous?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flag to indicate if the Credit card fee is covered by the donor */
  ccFeeCovered?: InputMaybe<Scalars['Boolean']['input']>;
  ccFeePercentage?: InputMaybe<Scalars['Float']['input']>;
  cheerwallName?: InputMaybe<Scalars['String']['input']>;
  /** This is the Donation Level ID that will be passed thru the URL on Checkout. */
  donationLevelId?: InputMaybe<Scalars['String']['input']>;
  donorEmail?: InputMaybe<Scalars['String']['input']>;
  donorFirstName?: InputMaybe<Scalars['String']['input']>;
  donorLastName?: InputMaybe<Scalars['String']['input']>;
  donorMessage?: InputMaybe<Scalars['String']['input']>;
  errorType?: InputMaybe<Scalars['String']['input']>;
  matchDon?: InputMaybe<Scalars['String']['input']>;
  participantId?: InputMaybe<Scalars['Int']['input']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  paymentProvider?: InputMaybe<PaymentProvider>;
  tipAmount?: InputMaybe<Scalars['Int']['input']>;
};

/** A donation made by a donor to a fundraiser. */
export type DonorDonation = {
  __typename?: 'DonorDonation';
  anonymous?: Maybe<Scalars['Boolean']['output']>;
  appFeeCents?: Maybe<Scalars['Int']['output']>;
  authorizationCode?: Maybe<Scalars['String']['output']>;
  averageDonationCents?: Maybe<Scalars['Int']['output']>;
  ccFeePercentage?: Maybe<Scalars['Float']['output']>;
  chargedBackAt?: Maybe<Scalars['DateTime']['output']>;
  checkoutId?: Maybe<Scalars['String']['output']>;
  checkoutState?: Maybe<DonorDonationCheckoutState>;
  cheerwallName?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  coverageAmountCents?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creditCardId?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  disputeWonId?: Maybe<Scalars['String']['output']>;
  donateIntent?: Maybe<DonateIntent>;
  donationLevel?: Maybe<DonorDonationLevel>;
  donationLevelId?: Maybe<Scalars['String']['output']>;
  donor?: Maybe<DonorUser>;
  donorId?: Maybe<Scalars['String']['output']>;
  donorMessage?: Maybe<Scalars['String']['output']>;
  donorName?: Maybe<Scalars['String']['output']>;
  extCcFeeCents?: Maybe<Scalars['Int']['output']>;
  fbPostId?: Maybe<Scalars['String']['output']>;
  fundraiser?: Maybe<DonorFundraiser>;
  fundraiserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intCcFeeCents?: Maybe<Scalars['Int']['output']>;
  matchDon?: Maybe<Scalars['String']['output']>;
  newsletterOptIn?: Maybe<Scalars['Boolean']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  originIp?: Maybe<Scalars['String']['output']>;
  participant?: Maybe<DonorParticipantData>;
  participantId?: Maybe<Scalars['Int']['output']>;
  participantUserDirectoryId?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  paymentProvider?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  raiseId?: Maybe<Scalars['Int']['output']>;
  refundReason?: Maybe<Scalars['String']['output']>;
  serviceFeeCents?: Maybe<Scalars['Int']['output']>;
  /** Share Type of donation */
  shareType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<DonorDonationStatus>;
  subtotal?: Maybe<Scalars['String']['output']>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
  supporterId?: Maybe<Scalars['Int']['output']>;
  tipAmountCents?: Maybe<Scalars['Int']['output']>;
  totalPriceCents?: Maybe<Scalars['Int']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum DonorDonationCheckoutState {
  Authorized = 'AUTHORIZED',
  Cancelled = 'CANCELLED',
  Captured = 'CAPTURED',
  ChargedBack = 'CHARGED_BACK',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  New = 'NEW',
  Refunded = 'REFUNDED',
  Released = 'RELEASED'
}

/** Donation levels that are displayed to donors on the donation form, suggesting donation specific amounts and detailing why a donor might select them. */
export type DonorDonationLevel = {
  __typename?: 'DonorDonationLevel';
  amount: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fundraiserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  impact?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  rewardDescription?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DonorDonationOfflineInput = {
  /** The amount of the donation in cents */
  amount: Scalars['Int']['input'];
  anonymous?: InputMaybe<Scalars['Boolean']['input']>;
  donorName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fundraiserId: Scalars['String']['input'];
  participantId?: InputMaybe<Scalars['Int']['input']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']['input']>;
};

export type DonorDonationRecordUpdate = {
  __typename?: 'DonorDonationRecordUpdate';
  donateIntentData?: Maybe<DonateIntent>;
  donationId?: Maybe<Scalars['String']['output']>;
};

export enum DonorDonationStatus {
  Anonymous = 'ANONYMOUS',
  ChargedBack = 'CHARGED_BACK',
  Complete = 'COMPLETE',
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  Paid = 'PAID',
  Refunded = 'REFUNDED',
  Refunding = 'REFUNDING',
  Released = 'RELEASED',
  Unpaid = 'UNPAID',
  Updated1 = 'UPDATED1',
  Updated2 = 'UPDATED2'
}

export type DonorDonationUpdateInput = {
  anonymous?: InputMaybe<Scalars['Boolean']['input']>;
  cheerwallName?: InputMaybe<Scalars['String']['input']>;
  donorId?: InputMaybe<Scalars['ID']['input']>;
  donorMessage?: InputMaybe<Scalars['String']['input']>;
  donorName?: InputMaybe<Scalars['String']['input']>;
  participantId?: InputMaybe<Scalars['Int']['input']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DonorDonationStatus>;
  subtotalCents?: InputMaybe<Scalars['Int']['input']>;
};

export type DonorEmailData = {
  __typename?: 'DonorEmailData';
  deliveryStatus?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  followUpNumber?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  personListEntryID?: Maybe<Scalars['Int']['output']>;
};

/** A copy of the pertinent fields from the Fundraiser model in raise. */
export type DonorFundraiser = {
  __typename?: 'DonorFundraiser';
  ccFeeCovered?: Maybe<Scalars['Boolean']['output']>;
  ccFeePayer?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  donationMinimumCents?: Maybe<Scalars['Int']['output']>;
  donations?: Maybe<Array<Maybe<DonorDonation>>>;
  endDate: Scalars['DateTime']['output'];
  feeType?: Maybe<Scalars['String']['output']>;
  /** The amount of money to raise in dollars. */
  goal?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** Old ID field from raise */
  raiseId: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  status?: Maybe<Scalars['String']['output']>;
  whyDonations?: Maybe<Scalars['String']['output']>;
};

export type DonorFundraiserData = {
  __typename?: 'DonorFundraiserData';
  ccFeeCovered?: Maybe<Scalars['Boolean']['output']>;
  ccFeePayer: SnapFeePayer;
  description?: Maybe<Scalars['String']['output']>;
  donationMinimumCents?: Maybe<Scalars['Int']['output']>;
  endDate: Scalars['DateTime']['output'];
  feeType?: Maybe<Scalars['String']['output']>;
  giftShop: Scalars['Boolean']['output'];
  goal?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  isRaiseClassic?: Maybe<Scalars['Boolean']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  participantGoal?: Maybe<Scalars['Int']['output']>;
  personalMessage?: Maybe<Scalars['String']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  programLeader?: Maybe<Scalars['String']['output']>;
  programType?: Maybe<Scalars['String']['output']>;
  raiseEntityId?: Maybe<Scalars['Int']['output']>;
  raiseId: Scalars['Int']['output'];
  redirect?: Maybe<Scalars['Boolean']['output']>;
  /** If the redirectPath is provided it means you should actually be accessing this data via the redirectPath */
  redirectPath?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  status: FundraiserStatus;
  stripeConnectId?: Maybe<Scalars['String']['output']>;
  subtotalDollarsSum?: Maybe<Scalars['Float']['output']>;
  tippingEnabled?: Maybe<Scalars['Boolean']['output']>;
  waCampaignSetup?: Maybe<Scalars['Boolean']['output']>;
  whyDonations?: Maybe<Scalars['String']['output']>;
};

export type DonorFundraiserPayable = DonorDonation | DonorFundraiserPurchase | DonorOfflineDonation;

export type DonorFundraiserPayableRefundInput = {
  /** The id of the donation or purchase */
  id: Scalars['ID']['input'];
  /** Optional: The type of fundraiser payable. If not provided, the type will be determined by the id. */
  type?: InputMaybe<DonorFundraiserPayableTypeEnum>;
};

export enum DonorFundraiserPayableTypeEnum {
  Donation = 'donation',
  Purchase = 'purchase'
}

export type DonorFundraiserPayablesInput = {
  fundraiserId?: InputMaybe<Scalars['String']['input']>;
  fundraiserRaiseId?: InputMaybe<Scalars['Int']['input']>;
};

export type DonorFundraiserPurchase = {
  __typename?: 'DonorFundraiserPurchase';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  donationId?: Maybe<Scalars['String']['output']>;
  donor?: Maybe<DonorUser>;
  fundraiserUserId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lineItems?: Maybe<Array<Maybe<DonorGiftShopItem>>>;
  participant?: Maybe<DonorParticipantData>;
  participantUserDirectoryId?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  paymentProvider?: Maybe<Scalars['String']['output']>;
  purchaseIntentId?: Maybe<Scalars['String']['output']>;
  raiseId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<DonationsAndPurchasesEnum>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DonorGiftShopItem = {
  __typename?: 'DonorGiftShopItem';
  costCents?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priceCents?: Maybe<Scalars['Int']['output']>;
  purchasableId?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DonorOfflineDonation = {
  __typename?: 'DonorOfflineDonation';
  anonymous?: Maybe<Scalars['Boolean']['output']>;
  cheerwallName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** This type is meant for admin only */
  donor?: Maybe<DonorUser>;
  donorId?: Maybe<Scalars['String']['output']>;
  donorMessage?: Maybe<Scalars['String']['output']>;
  donorName?: Maybe<Scalars['String']['output']>;
  fundraiser?: Maybe<DonorFundraiser>;
  fundraiserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  origin?: Maybe<Scalars['String']['output']>;
  participant?: Maybe<DonorParticipantData>;
  participantId?: Maybe<Scalars['Int']['output']>;
  participantUserDirectoryId?: Maybe<Scalars['String']['output']>;
  raiseId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<DonorDonationStatus>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DonorParticipantData = {
  __typename?: 'DonorParticipantData';
  raiseId?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
};

export type DonorPayableRefundResponse = {
  __typename?: 'DonorPayableRefundResponse';
  payable?: Maybe<DonorFundraiserPayable>;
  refundId?: Maybe<Scalars['String']['output']>;
};

export type DonorPersonListEntry = {
  __typename?: 'DonorPersonListEntry';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

/** This type is meant for admin only */
export type DonorUser = {
  __typename?: 'DonorUser';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  /** Donor Id */
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  raiseUserId?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
};

export type DonorsCheerwall = {
  __typename?: 'DonorsCheerwall';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  donorMessage?: Maybe<Scalars['String']['output']>;
  donorName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  participant?: Maybe<DonorsParticipantPublic>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DonorsCheerwallsResult = {
  __typename?: 'DonorsCheerwallsResult';
  cheers?: Maybe<Array<Maybe<DonorsCheerwall>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type DonorsDonationLevel = {
  __typename?: 'DonorsDonationLevel';
  amount: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  impact?: Maybe<Scalars['String']['output']>;
  rewardDescription?: Maybe<Scalars['String']['output']>;
};

export type DonorsDonationStatsResponse = {
  __typename?: 'DonorsDonationStatsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  donorsCount?: Maybe<Scalars['Int']['output']>;
  otkPurchaseTotal?: Maybe<Scalars['Int']['output']>;
  participantsWithDonationCount?: Maybe<Scalars['Int']['output']>;
  totalAmountCents?: Maybe<Scalars['Int']['output']>;
};

export type DonorsDonationStatsWhereInput = {
  fundraiserId?: InputMaybe<Scalars['ID']['input']>;
  fundraiserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  participantId?: InputMaybe<Scalars['Int']['input']>;
  shareType?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** @deprecated */
export type DonorsFundraiserCheerWall = {
  __typename?: 'DonorsFundraiserCheerWall';
  cheers?: Maybe<Array<Maybe<CheerWallData>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type DonorsOrg = {
  __typename?: 'DonorsOrg';
  city?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type DonorsOrgsResult = {
  __typename?: 'DonorsOrgsResult';
  data: Array<DonorsOrg>;
  hasMore?: Maybe<Scalars['Boolean']['output']>;
};

export type DonorsOrgsSearchInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  payableId: Scalars['String']['input'];
  stateCode: Scalars['String']['input'];
};

export type DonorsOrgsSearchPagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DonorsParticipantOtkIncentive = {
  __typename?: 'DonorsParticipantOTKIncentive';
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priceCents?: Maybe<Scalars['Int']['output']>;
  purchaseLimit?: Maybe<Scalars['Int']['output']>;
  purchased?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['String']['output']>;
};

export type DonorsParticipantOtkIncentives = {
  __typename?: 'DonorsParticipantOTKIncentives';
  incentives?: Maybe<Array<Maybe<DonorsParticipantOtkIncentive>>>;
};

export type DonorsParticipantPublic = {
  __typename?: 'DonorsParticipantPublic';
  data?: Maybe<DonorsParticipantPublicData>;
  raiseId?: Maybe<Scalars['Int']['output']>;
};

export type DonorsParticipantPublicData = {
  __typename?: 'DonorsParticipantPublicData';
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
};

export type DonorsParticipantsGiftShop = {
  __typename?: 'DonorsParticipantsGiftShop';
  fundraiserUserId?: Maybe<Scalars['Int']['output']>;
  giftShop?: Maybe<Array<Maybe<DonorsParticipantsGiftShopData>>>;
  totalPurchased?: Maybe<Scalars['Int']['output']>;
};

export type DonorsParticipantsGiftShopData = {
  __typename?: 'DonorsParticipantsGiftShopData';
  costCents?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  netEarnedPercentage?: Maybe<Scalars['Float']['output']>;
  priceCents?: Maybe<Scalars['Int']['output']>;
  promoLabel?: Maybe<Scalars['String']['output']>;
  purchaseLimit?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['String']['output']>;
};

/** Account User is a user that is registered in Raise's database. Deprecated: Use UserDirectory 's User instead instead */
export type DriveAccountUser = DriveUser & {
  __typename?: 'DriveAccountUser';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  snapRaiseId?: Maybe<Scalars['Int']['output']>;
};

/** MDM Actor. e.g. Account Manager/Salereps/Sales Manager */
export type DriveAmSalesReps = {
  __typename?: 'DriveAmSalesReps';
  /** Primary contact email of salesrep */
  email?: Maybe<Scalars['String']['output']>;
  /** End time of salesrep */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** First Name of Salesrep */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Last Name of Salesrep */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Primary phone number of salesrep */
  phone?: Maybe<Scalars['String']['output']>;
  /** The sales manager of this salesrep */
  salesManager?: Maybe<DriveAmSalesReps>;
  /** Salesrep Id. This is Raise's account user id. */
  salesrepId?: Maybe<Scalars['Int']['output']>;
  /** Start time of salesrep */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** actor type. e.g. 'salesrep', 'salesmanager', 'am' */
  type?: Maybe<Scalars['String']['output']>;
};

export enum DriveAttendanceStatus {
  Attending = 'ATTENDING',
  NotAttending = 'NOT_ATTENDING'
}

/** Information of a single drive campaign */
export type DriveCampaign = {
  __typename?: 'DriveCampaign';
  /** The account manager of the campaign */
  accountManager?: Maybe<AccountManager>;
  /** @deprecated: not used, in favor of totalRaisedCents */
  donationRaisedCents?: Maybe<Scalars['Int']['output']>;
  /** The date the campaign ends */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** The date the campaign has been finalized/closed */
  finalizedDate?: Maybe<Scalars['DateTime']['output']>;
  /** The mapping id in fundraisers service */
  fundraiserId?: Maybe<Scalars['ID']['output']>;
  /** Indicate whether the campaign has any incentives, such as OTK */
  hasIncentive?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  /** Name of campaign */
  name?: Maybe<Scalars['String']['output']>;
  /** Notes for a campaign */
  notes?: Maybe<Scalars['String']['output']>;
  /** The origin of the campaign. Values: RAISE_CLASSIC, FUNDRAISERS_SERVICE */
  origin?: Maybe<Scalars['String']['output']>;
  /** The date where the campaign has settle its payments from snap */
  settlementDate?: Maybe<Scalars['DateTime']['output']>;
  /** Url-slug of campaign name which is a unique identifier */
  slug?: Maybe<Scalars['String']['output']>;
  /** The date campaign starts */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** Current status of campaign */
  status?: Maybe<CampaignStatus>;
  /** The size of the team/group attached to campaign */
  teamSize?: Maybe<Scalars['Int']['output']>;
  /** @deprecated: not used, in favor of `totalRaisedCents` */
  totalRaised?: Maybe<Scalars['BigInt']['output']>;
  /** Total amount of donation of campaign in cents. */
  totalRaisedCents?: Maybe<Scalars['Int']['output']>;
};

/** Campaign/Fundraiser details information. NOTE: In the future, we might merged with DriveCampaign or Campaign */
export type DriveCampaignDetails = {
  __typename?: 'DriveCampaignDetails';
  /** Account Balance of campaign. */
  accountBalance?: Maybe<AccountBalanceReturn>;
  /** The activity/sport of this campaign, e.g. 'football', 'wrestling', etc. */
  activityType?: Maybe<Scalars['String']['output']>;
  /** The amount in cents that campaigns was advanced in Wallet. */
  advanceAmount?: Maybe<Scalars['Int']['output']>;
  /** Bill type when settled/finalized. Values are 'invoice', 'regular' and 'default' */
  billType?: Maybe<Scalars['String']['output']>;
  /** Credit Card Fee Payer. Values are 'customer' or 'snap'. */
  ccFeePayer?: Maybe<Scalars['String']['output']>;
  /** Charge back fee in cents. The charge from payment processor when a donor cancels their donation through their bank instead of getting a refund from us directly. (usually $15 per) */
  chargebackFee?: Maybe<Scalars['Int']['output']>;
  /** The statistic of a donation of campaigns */
  donationStats?: Maybe<DriveCampaignDonationStats>;
  /** The statistics of emails delivered/sent */
  emailDelivery?: Maybe<EmailsInfo>;
  /** The type of snap fee being collected. e.g. 'standard', '1+1', '20%/15%', etc. */
  feeType?: Maybe<Scalars['String']['output']>;
  /** Total incentives price in cents. */
  gearCost?: Maybe<Scalars['Int']['output']>;
  /** Group leader of a campaign */
  groupLeader?: Maybe<DriveGroupLeader>;
  id: Scalars['Int']['output'];
  /** Cover Image for Campaign */
  image?: Maybe<Scalars['String']['output']>;
  /** Goal of how much a fundraiser wants to raise */
  initialGoalCents?: Maybe<Scalars['Int']['output']>;
  /** kyc processor. Values are 'wepay' or 'stripe' */
  kycProcessor?: Maybe<Scalars['String']['output']>;
  /** kyc status of a campaign. Values are 'pending', 'active', 'inactive', etc. */
  kycStatus?: Maybe<Scalars['String']['output']>;
  /** The address to ship the check for this campaign */
  mailingAddress?: Maybe<Scalars['String']['output']>;
  /** The amount in cents that campaigns was manual-advanced in Raise. */
  manualAdvanceAmount?: Maybe<Scalars['Int']['output']>;
  /** Net raised amount in cents. Equals totalRaised minus snapFee and adds the otkBonus. It is the amount client received in their check/deposit. */
  netRaisedTotal?: Maybe<Scalars['Int']['output']>;
  /** Notes for settlement for this campaign */
  notes?: Maybe<Scalars['String']['output']>;
  /** The legal name of a fundraisers organization */
  organizationLegalName?: Maybe<Scalars['String']['output']>;
  /** The tin number of the organization */
  organizationTin?: Maybe<Scalars['String']['output']>;
  /** Total bonus amount of otk in cents */
  otkBonus?: Maybe<Scalars['Int']['output']>;
  /** The statistics of participants's activity of campaign */
  participantStats?: Maybe<DriveParticipantStats>;
  /** Processing Fee when settled/finalized. */
  processingFee?: Maybe<Scalars['Int']['output']>;
  /** The date where the campaign has settle its payments from snap */
  settlementDate?: Maybe<Scalars['DateTime']['output']>;
  /** How the funds has delivered to the organization. Values are 'check_fundraiser', 'check_salesrep', 'check_office' and 'direct_deposit' */
  settlementMethod?: Maybe<Scalars['String']['output']>;
  /** The amount in cents collected as fee when a campaign ends based of the fee type */
  snapFee?: Maybe<Scalars['Float']['output']>;
  /** Location of this fundraiser */
  territory?: Maybe<Scalars['String']['output']>;
  /** The amount of purchase in cents. */
  totalPurchaseCents?: Maybe<Scalars['Int']['output']>;
  /** Total raised of a fundraiser */
  totalRaisedCents?: Maybe<Scalars['Int']['output']>;
  /** The total raised combined this organization has previously ran campaigns */
  totalRaisedHistory?: Maybe<Scalars['Int']['output']>;
};

/** Pulls data on a campaigns donation broken down */
export type DriveCampaignDonationStats = {
  __typename?: 'DriveCampaignDonationStats';
  ccCoverageAmountCents?: Maybe<Scalars['Int']['output']>;
  donationAppFeeCents?: Maybe<Scalars['Int']['output']>;
  /** The amount that been raised */
  donationRaisedCents?: Maybe<Scalars['Int']['output']>;
  /** The total count of donations */
  donationsCount?: Maybe<Scalars['Int']['output']>;
  offlineTotalCents?: Maybe<Scalars['Int']['output']>;
  onlineTotalCents?: Maybe<Scalars['Int']['output']>;
  /** The count of participants */
  participantCount?: Maybe<Scalars['Int']['output']>;
  /** The donated amount in cents that been raised by emails delivered */
  totalEmailDonations?: Maybe<Scalars['Int']['output']>;
  /** The amount that been raised by miscellaneous forms */
  totalExtraDonations?: Maybe<Scalars['Int']['output']>;
  totalOtherDonations?: Maybe<Scalars['Int']['output']>;
  /** The donated amount that been raised by sharing through social media */
  totalSocialDonations?: Maybe<Scalars['Int']['output']>;
  /** The donated amount in cents that been raised by text message */
  totalTextDonations?: Maybe<Scalars['Int']['output']>;
};

/** Paginated list of campaigns */
export type DriveCampaignList = {
  __typename?: 'DriveCampaignList';
  /** Total campaigns */
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<DriveCampaign>>>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/** Statistics for a set of campaigns in campaign-search. */
export type DriveCampaignSearchStatistics = {
  __typename?: 'DriveCampaignSearchStatistics';
  avgDonationDollars?: Maybe<Scalars['Float']['output']>;
  avgEmailsDelivered?: Maybe<Scalars['Float']['output']>;
  avgEmailsPerParticipant?: Maybe<Scalars['Float']['output']>;
  avgRaisedDollarsPerCampaign?: Maybe<Scalars['Float']['output']>;
  avgRaisedDollarsPerEmail?: Maybe<Scalars['Float']['output']>;
  avgTeamSize?: Maybe<Scalars['Float']['output']>;
  totalCampaign?: Maybe<Scalars['BigInt']['output']>;
  totalCountDelivered?: Maybe<Scalars['BigInt']['output']>;
  totalCountParticpants?: Maybe<Scalars['BigInt']['output']>;
  totalDonations?: Maybe<Scalars['BigInt']['output']>;
  totalRaisedByEmail?: Maybe<Scalars['Float']['output']>;
  totalRaisedDollars?: Maybe<Scalars['Float']['output']>;
};

export type DriveCampaignUpdateInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type DriveDateCompareInput = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a Event object and its attributes */
export type DriveEvent = {
  __typename?: 'DriveEvent';
  /** List of messages in the activity feed. Order by posted ascending by default. */
  activityFeed?: Maybe<Array<Maybe<DriveEventActivityFeed>>>;
  /** Activity types for an event. e.g. `football`, 'basketball', etc. */
  activityTypes?: Maybe<Array<Scalars['String']['output']>>;
  /** Event Agenda which include start-time/end-time of event dates. Should be empty is **agendaConfig.isSameTime** is true */
  agenda?: Maybe<Array<DriveEventAgendaItem>>;
  /** The configuration for agenda. Format as follows ```JSON { isSameTime: true, startTime: "09:00AM", endTime: "07:00PM" } ``` where **isSameTime** means all event dates will be occured at same **startTime** and **endTime**. If **isSameTime**, **startTime** and **endTime** should be filled-in */
  agendaConfig?: Maybe<Scalars['JSON']['output']>;
  /** List of attendees. */
  attendees?: Maybe<Array<DriveEventAttendee>>;
  /** Booth information for an event. */
  booth?: Maybe<DriveEventBooth>;
  /** The user who is the clinic leader. */
  clinicLeader?: Maybe<User>;
  /** The user who created the event. */
  createdBy?: Maybe<User>;
  /** The description of an event. */
  description?: Maybe<Scalars['String']['output']>;
  /** Indicator that event requires a door prize. */
  doorPrizedRequired?: Maybe<Scalars['Boolean']['output']>;
  /** The end date of an event. */
  endDate: Scalars['String']['output'];
  /** The hotel address for the attendees */
  hotelAddress?: Maybe<Scalars['String']['output']>;
  /** The hotel name for the attendees */
  hotelName?: Maybe<Scalars['String']['output']>;
  /** Url for hubspot link. */
  hubspotTrackingLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Notes associated for the event. */
  notes?: Maybe<Scalars['String']['output']>;
  /** A indicator whether the event had been completed planning phase. */
  planningCompleted?: Maybe<Scalars['Boolean']['output']>;
  /** The time zone of an event */
  preferedTimezone?: Maybe<Scalars['String']['output']>;
  /** Description for the sponsorship */
  sponsorDescription?: Maybe<Scalars['String']['output']>;
  /** Indicator whether the door prize has been confirmed. */
  sponsorDoorPrized?: Maybe<Scalars['Boolean']['output']>;
  /** The start date of an event. */
  startDate: Scalars['String']['output'];
  /** The events status. */
  status: DriveEventStatus;
  /** The tier level for this event. Valid values is `1`, `2` and `3` */
  tier?: Maybe<Scalars['Int']['output']>;
  /** The name of the event. */
  title: Scalars['String']['output'];
  /** Venue location information for an event. */
  venue?: Maybe<DriveVenue>;
  /** Website link for an event. */
  websiteLink?: Maybe<Scalars['String']['output']>;
};

/** Information of a activity feed message */
export type DriveEventActivityFeed = {
  __typename?: 'DriveEventActivityFeed';
  /** A list of replies the message */
  children?: Maybe<Array<Maybe<DriveEventActivityFeed>>>;
  /** The date this post was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The event id */
  eventId: Scalars['ID']['output'];
  /** If the message is pinned to the top */
  featured?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the message */
  id: Scalars['String']['output'];
  /** The message posted */
  message?: Maybe<Scalars['String']['output']>;
  /** The date when this post has been updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** A users first name and last and link to profile photo */
  user?: Maybe<User>;
  /** The id of the user who posted the message */
  userId: Scalars['ID']['output'];
};

export type DriveEventActivityInput = {
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type DriveEventAgendaInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes for an events agenda */
export type DriveEventAgendaItem = {
  __typename?: 'DriveEventAgendaItem';
  description?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Represents an events attendee attributes */
export type DriveEventAttendee = {
  __typename?: 'DriveEventAttendee';
  /** The department the attendee is in. */
  department?: Maybe<Scalars['String']['output']>;
  /** The event ID */
  eventId: Scalars['ID']['output'];
  /** The status of an attendee flight request. Default is `NO_REQUEST`. */
  flightRequest?: Maybe<EventAttendeeRequestStatus>;
  /** The status of an attendee hotel request. Default is `NO_REQUEST`. */
  hotelRequest?: Maybe<EventAttendeeRequestStatus>;
  /** @deprecated Use userId instead */
  id: Scalars['ID']['output'];
  /** Indication whether this attendee a clinic leader. */
  isClinicLeader?: Maybe<Scalars['Boolean']['output']>;
  /** The status of an attendee attendence. */
  status: DriveAttendanceStatus;
  /** User Information of attendee, includes `firstName`, `lastName`, `email`, etc. defined in UserDirectory. */
  user?: Maybe<User>;
  /** The udId of an attendee. e.g. `ud_abc123` */
  userId: Scalars['ID']['output'];
};

export type DriveEventAttendeeInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  flightRequest?: InputMaybe<EventAttendeeRequestStatus>;
  hotelRequest?: InputMaybe<EventAttendeeRequestStatus>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Booth attributes */
export type DriveEventBooth = {
  __typename?: 'DriveEventBooth';
  /** Notes for the booth e.g. Electricity is paid for */
  boothNotes?: Maybe<Scalars['String']['output']>;
  /** The time to end breakdown or clean up event equipment in Venue. */
  breakdownEndTime?: Maybe<Scalars['DateTime']['output']>;
  /** The time to start breakdown or clean up event equipment in Venue. */
  breakdownStartTime?: Maybe<Scalars['DateTime']['output']>;
  /** Indication whether electricity is provided. */
  electricityProvided?: Maybe<Scalars['Boolean']['output']>;
  /** Indication whether flooring is provided. */
  flooringProvided?: Maybe<Scalars['Boolean']['output']>;
  /** Indication whether internet is provided. */
  internetProvided?: Maybe<Scalars['Boolean']['output']>;
  /** The time to end setup for event */
  setupEndTime?: Maybe<Scalars['DateTime']['output']>;
  /** The time to begin setup for event */
  setupStartTime?: Maybe<Scalars['DateTime']['output']>;
};

export type DriveEventBoothInput = {
  boothNotes?: InputMaybe<Scalars['String']['input']>;
  breakdownEndTime?: InputMaybe<Scalars['String']['input']>;
  breakdownStartTime?: InputMaybe<Scalars['String']['input']>;
  electricityProvided?: InputMaybe<Scalars['Boolean']['input']>;
  flooringProvided?: InputMaybe<Scalars['Boolean']['input']>;
  internetProvided?: InputMaybe<Scalars['Boolean']['input']>;
  setupEndTime?: InputMaybe<Scalars['String']['input']>;
  setupStartTime?: InputMaybe<Scalars['String']['input']>;
};

export type DriveEventInput = {
  activityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  agenda?: InputMaybe<Array<DriveEventAgendaInput>>;
  agendaConfig?: InputMaybe<Scalars['JSON']['input']>;
  attendees?: InputMaybe<Array<DriveEventAttendeeInput>>;
  booth?: InputMaybe<DriveEventBoothInput>;
  clinicLeaderId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  hotelAddress?: InputMaybe<Scalars['String']['input']>;
  hotelName?: InputMaybe<Scalars['String']['input']>;
  hubspotTrackingLink?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  planningCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  preferedTimezone?: InputMaybe<Scalars['String']['input']>;
  sponsor?: InputMaybe<DriveEventSponsorInput>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DriveEventStatus>;
  tier?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  venue?: InputMaybe<DriveEventVenueInput>;
  websiteLink?: InputMaybe<Scalars['String']['input']>;
};

/** Paginated list of drive events */
export type DriveEventResults = {
  __typename?: 'DriveEventResults';
  events?: Maybe<Array<DriveEvent>>;
  offset?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DriveEventSearchInput = {
  activityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  attendees?: InputMaybe<Array<Scalars['String']['input']>>;
  boothOptions?: InputMaybe<DriveEventBoothInput>;
  dateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate?: InputMaybe<DriveDateCompareInput>;
  eventStatus?: InputMaybe<EventDateStatus>;
  id?: InputMaybe<Scalars['ID']['input']>;
  sponsor?: InputMaybe<DriveEventSponsorInput>;
  startDate?: InputMaybe<DriveDateCompareInput>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<DriveEventStatus>>;
  tiers?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type DriveEventSponsorInput = {
  doorPrized?: InputMaybe<Scalars['Boolean']['input']>;
  doorPrizedRequired?: InputMaybe<Scalars['Boolean']['input']>;
  sponsorDescription?: InputMaybe<Scalars['String']['input']>;
};

export enum DriveEventStatus {
  Closed = 'CLOSED',
  EmailSent = 'EMAIL_SENT',
  NotAttending = 'NOT_ATTENDING',
  Pending = 'PENDING',
  Registered = 'REGISTERED'
}

export type DriveEventVenueInput = {
  buildingOrRoom?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DriveFieldInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  linkText?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type: DriveFieldType;
  value: Scalars['String']['input'];
};

export enum DriveFieldType {
  Html = 'HTML',
  Link = 'LINK',
  Text = 'TEXT'
}

export type DriveFileInput = {
  content: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Organization Summary information used by Drive's Get List Application */
export type DriveGetListOrganizationSummaryReturn = {
  __typename?: 'DriveGetListOrganizationSummaryReturn';
  /**
   * Entity ID of the organization. Its purpose is for caching in Apollo Client.
   * @deprecated use id
   */
  _id?: Maybe<Scalars['Int']['output']>;
  activitiesWithoutCampaigns?: Maybe<Scalars['Int']['output']>;
  averageRaisedPerCampaign?: Maybe<Scalars['Float']['output']>;
  campaignCompleted?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['Int']['output']>;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** Number of campaigns that completed of this Organization */
  name?: Maybe<Scalars['String']['output']>;
  /** Total students of the organization. Inherited from NCES data and studentCount from orgs-api. */
  population?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use potentialRaisedDollars */
  potentialRaised?: Maybe<Scalars['Float']['output']>;
  /** Total potential raised in dollars for this organization. Equals NCES total students multiply with National RPK. */
  potentialRaisedDollars?: Maybe<Scalars['Float']['output']>;
  /** @deprecated use saturationPercentage */
  saturationPercent?: Maybe<Scalars['Float']['output']>;
  /** Saturation percentage of organization. It is calculated by divide total logged-in students by NCES total students of this organization. */
  saturationPercentage?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  /** Lifetime total raised in dollars of organization. Computed from all non-upcoming campaigns. */
  totalRaised?: Maybe<Scalars['Float']['output']>;
  /** Lifetime total raised in cents of organization. */
  totalRaisedCents?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DriveGetlistProspectsReturn = {
  __typename?: 'DriveGetlistProspectsReturn';
  offset?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<Maybe<HubspotProspectsDeals>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DriveGetlistWinbackReturn = {
  __typename?: 'DriveGetlistWinbackReturn';
  offset?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<Maybe<WinbackDeals>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** Group Leader information in Drive */
export type DriveGroupLeader = {
  __typename?: 'DriveGroupLeader';
  /** The full name of the group leader */
  name?: Maybe<Scalars['String']['output']>;
};

export type DriveOrgListResults = {
  __typename?: 'DriveOrgListResults';
  offset?: Maybe<Scalars['Int']['output']>;
  orgs?: Maybe<Array<Maybe<DriveGetListOrganizationSummaryReturn>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DriveOrgUserTrackingSearchInput = {
  trackingIdStartsWith?: InputMaybe<Scalars['String']['input']>;
  trackingType?: InputMaybe<Scalars['String']['input']>;
};

/** Organization used by Drive */
export type DriveOrganization = {
  __typename?: 'DriveOrganization';
  activity?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** List of organizations used by Drive */
export type DriveOrganizationList = {
  __typename?: 'DriveOrganizationList';
  list: Array<Maybe<DriveOrganization>>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/** Statistics information related to Participants in one campaign */
export type DriveParticipantStats = {
  __typename?: 'DriveParticipantStats';
  /** Count of participants who had logged in */
  loggedInCount?: Maybe<Scalars['Int']['output']>;
  /** Count of participants with 20 emails sent */
  participantsWithAtLeastTwentyEmails?: Maybe<Scalars['Int']['output']>;
  /** Percentage of active participants of campaign. */
  participationPercent?: Maybe<Scalars['Int']['output']>;
  /** Count of participants with at least one donation */
  withAtLeastOneDonation?: Maybe<Scalars['Int']['output']>;
};

/** Information of a sms scheduled for release */
export type DrivePreloadSmsSchedule = {
  __typename?: 'DrivePreloadSmsSchedule';
  /** The date for release. Valid format is YYYY-MM-DD */
  date?: Maybe<Scalars['String']['output']>;
  /** The campaign id */
  fundraiserId?: Maybe<Scalars['Int']['output']>;
  /** The timeframe for release. */
  timeframe?: Maybe<DriveScheduleTimeFrame>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars['String']['output']>;
};

/** Input use for create schedule for preload email */
export type DriveScheduleInput = {
  /** Date to send out. Valid format is YYYY-MM-DD */
  date: Scalars['String']['input'];
  /** Timeframe of schedule release. */
  timeframe: DriveScheduleTimeFrame;
  /** Timezone of datetime to send out. e.g. American/New_York */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export enum DriveScheduleTimeFrame {
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING',
  Morning = 'MORNING'
}

export type DriveScheduleUpdateInput = {
  /** Date to send out. Valid format is YYYY-MM-DD */
  date?: InputMaybe<Scalars['String']['input']>;
  /** Timeframe of schedule release. */
  timeframe?: InputMaybe<DriveScheduleTimeFrame>;
  /** Timezone of datetime to send out. e.g. American/New_York */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type DriveTrackingInput = {
  trackingId: Scalars['String']['input'];
  trackingType?: InputMaybe<Scalars['String']['input']>;
  trackingValue: Scalars['String']['input'];
  trackingValueType?: InputMaybe<Scalars['String']['input']>;
};

export type DriveUser = {
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type DriveUserSearchInput = {
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type DriveUsersResult = {
  __typename?: 'DriveUsersResult';
  nextPage?: Maybe<Scalars['Boolean']['output']>;
  users?: Maybe<Array<Maybe<DriveAccountUser>>>;
};

/** Venue or location for Event used by Event Clinic */
export type DriveVenue = {
  __typename?: 'DriveVenue';
  /** Building name or Room number. e.g. `Room 330` */
  buildingOrRoom?: Maybe<Scalars['String']['output']>;
  /** City of Venue location, e.g. `Dallas` */
  city?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Venue Name. e.g. `Hillton Convention Center`, e.g. */
  name: Scalars['String']['output'];
  /** The short-code for US States. e.g. `TX`, 'CA' */
  state?: Maybe<Scalars['String']['output']>;
};

export type DriveVenueSearchInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type EditSubmissionDocumentResultObject = {
  __typename?: 'EditSubmissionDocumentResultObject';
  s3FileName: Scalars['String']['output'];
};

export enum EmailDeliveryStatus {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Preloaded = 'PRELOADED'
}

export type EmailLog = {
  __typename?: 'EmailLog';
  body?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  messageId?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['Date']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

/** Information of a emails for a camapgin */
export type EmailsInfo = {
  __typename?: 'EmailsInfo';
  /** Total emails bounced back due to failed delivery */
  bounced?: Maybe<Scalars['Int']['output']>;
  /** Total emails loaded by particpants */
  loaded?: Maybe<Scalars['Int']['output']>;
  /** The schedule (timezone-sensitive) thatthe emails are scheduled to release */
  scheduledReleaseDate?: Maybe<ScheduledReleaseInfo>;
  /** Total emails sent */
  sent?: Maybe<Scalars['Int']['output']>;
  /** The datetime the emails were sent out */
  sentAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Error = {
  message: Scalars['String']['output'];
};

export type Event = {
  __typename?: 'Event';
  activity?: Maybe<Scalars['String']['output']>;
  activityLevel?: Maybe<Scalars['String']['output']>;
  activityType?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  bus_fee?: Maybe<Scalars['Float']['output']>;
  bus_time?: Maybe<Scalars['String']['output']>;
  cancellation_status?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  conference?: Maybe<Scalars['String']['output']>;
  conference_event_id?: Maybe<Scalars['Int']['output']>;
  conference_id?: Maybe<Scalars['Int']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  confirmedStatusBoolean?: Maybe<Scalars['Boolean']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Date']['output']>;
  departure_location?: Maybe<Scalars['String']['output']>;
  directions?: Maybe<Scalars['String']['output']>;
  early_dismissal_required?: Maybe<Scalars['String']['output']>;
  early_dismissal_time?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  estimated_return_time?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  eventOfficials?: Maybe<Array<Maybe<Official>>>;
  eventTiming?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['Date']['output']>;
  event_id: Scalars['Int']['output'];
  exists_in_mls?: Maybe<Scalars['Int']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  formattedEventDate?: Maybe<Scalars['String']['output']>;
  formattedEventDateSystem?: Maybe<Scalars['String']['output']>;
  formattedEventDay?: Maybe<Scalars['String']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  gate_revenue?: Maybe<Scalars['Float']['output']>;
  groupval?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  impact_event?: Maybe<Scalars['String']['output']>;
  isDuplicate?: Maybe<Scalars['Boolean']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  loss_points?: Maybe<Scalars['Int']['output']>;
  num_buses?: Maybe<Scalars['Int']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  opponent_code?: Maybe<Scalars['String']['output']>;
  opponent_score?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  prep_setup?: Maybe<Scalars['String']['output']>;
  promote?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Scalars['String']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  rollover?: Maybe<Scalars['String']['output']>;
  rolloverStatusBoolean?: Maybe<Scalars['Boolean']['output']>;
  seasonInfo?: Maybe<Scalars['String']['output']>;
  seasonSportCode?: Maybe<Scalars['String']['output']>;
  season_team?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  sportDescription?: Maybe<Scalars['String']['output']>;
  sportGender?: Maybe<Scalars['String']['output']>;
  sportLevel?: Maybe<Scalars['String']['output']>;
  sportName?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  team_score?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tournament?: Maybe<Scalars['String']['output']>;
  trans_id?: Maybe<Scalars['Int']['output']>;
  transportDetails?: Maybe<Scalars['String']['output']>;
  transport_comments?: Maybe<Scalars['String']['output']>;
  transportation?: Maybe<Scalars['String']['output']>;
  update_at?: Maybe<Scalars['Date']['output']>;
  vehicle_count?: Maybe<Scalars['Int']['output']>;
  web_dir?: Maybe<Scalars['String']['output']>;
  weekdayname?: Maybe<Scalars['String']['output']>;
  win_points?: Maybe<Scalars['Int']['output']>;
  years?: Maybe<Scalars['String']['output']>;
};

export type EventArchiveResult = {
  __typename?: 'EventArchiveResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  event?: Maybe<ScheduleEvent>;
};

export enum EventAttendeeRequestStatus {
  NoRequest = 'NO_REQUEST',
  Processed = 'PROCESSED',
  Request = 'REQUEST'
}

export type EventCalendarFiltersInput = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activityName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facilities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gender?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  homeAway?: InputMaybe<Scalars['String']['input']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleType?: InputMaybe<Scalars['String']['input']>;
};

export type EventContract = {
  __typename?: 'EventContract';
  comments?: Maybe<Scalars['String']['output']>;
  event_contract_number?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  main_event_id?: Maybe<Scalars['Int']['output']>;
  participant?: Maybe<Scalars['String']['output']>;
  signee_name?: Maybe<Scalars['String']['output']>;
  signees?: Maybe<Array<Maybe<ContractSignees>>>;
  years?: Maybe<Scalars['String']['output']>;
};

export type EventDataInput = {
  equals?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum EventDateStatus {
  Active = 'Active',
  Closed = 'Closed',
  Upcoming = 'Upcoming'
}

export type EventFilter = {
  dataFilter?: InputMaybe<Array<InputMaybe<EventDataInput>>>;
  endDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  eventType?: InputMaybe<EventType>;
  location?: InputMaybe<Scalars['String']['input']>;
  startDatetime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EventOfficialReport = {
  __typename?: 'EventOfficialReport';
  comments?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['String']['output']>;
  facility?: Maybe<Scalars['String']['output']>;
  officials?: Maybe<Array<Maybe<EventOfficials>>>;
  opponent?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
};

export type EventOfficialReportFilter = {
  event_date: Scalars['Date']['input'];
};

export type EventOfficials = {
  __typename?: 'EventOfficials';
  address?: Maybe<Scalars['String']['output']>;
  cell_phone?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  duty?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  home_phone?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  official_id?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['String']['output']>;
  pay_code?: Maybe<Scalars['String']['output']>;
  received?: Maybe<Scalars['String']['output']>;
  salary?: Maybe<Scalars['Float']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  work_phone?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type EventParticipants = {
  __typename?: 'EventParticipants';
  SchoolName?: Maybe<Scalars['String']['output']>;
  contract_received?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['String']['output']>;
  school_code?: Maybe<Scalars['String']['output']>;
};

export type EventParticipantsInput = {
  contract_received?: InputMaybe<Scalars['String']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['String']['input']>;
  school_code?: InputMaybe<Scalars['String']['input']>;
};

export type EventPreparations = {
  __typename?: 'EventPreparations';
  comments?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  prep?: Maybe<Scalars['String']['output']>;
  qty?: Maybe<Scalars['String']['output']>;
};

export type EventPreparationsInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  prep?: InputMaybe<Scalars['String']['input']>;
  qty?: InputMaybe<Scalars['String']['input']>;
};

export type EventResult = {
  __typename?: 'EventResult';
  data?: Maybe<Scalars['JSON']['output']>;
  event: ScheduleEvent;
  id: Scalars['ID']['output'];
};

export type EventResultArchiveResult = {
  __typename?: 'EventResultArchiveResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  eventResult?: Maybe<EventResult>;
};

export type EventResultCreateResult = {
  __typename?: 'EventResultCreateResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  eventResult?: Maybe<EventResult>;
};

export type EventResultInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
};

export type EventResultUpdateResult = {
  __typename?: 'EventResultUpdateResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  eventResult?: Maybe<EventResult>;
};

export type EventResultsUpdateResult = {
  __typename?: 'EventResultsUpdateResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  eventResults?: Maybe<Array<Maybe<EventResult>>>;
};

export type EventTransportDetails = {
  __typename?: 'EventTransportDetails';
  driver_name?: Maybe<Scalars['String']['output']>;
  driver_phone?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['Date']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  vehicle_id?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
};

export type EventTransportDetailsDeleteManyCount = {
  __typename?: 'EventTransportDetailsDeleteManyCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export type EventTransportDetailsInput = {
  driver_name?: InputMaybe<Scalars['String']['input']>;
  driver_phone?: InputMaybe<Scalars['String']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  vehicle_id?: InputMaybe<Scalars['String']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
};

export enum EventType {
  Fundraiser = 'FUNDRAISER',
  Game = 'GAME',
  Other = 'OTHER'
}

export type EventsOpponent = {
  __typename?: 'EventsOpponent';
  activity?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  eventId?: Maybe<Scalars['Int']['output']>;
  event_date?: Maybe<Scalars['Date']['output']>;
  eventsOpponentId?: Maybe<Scalars['Int']['output']>;
  season_years?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
};

export type ExportFile = {
  __typename?: 'ExportFile';
  content?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
};

export type ExternalBankAccount = {
  __typename?: 'ExternalBankAccount';
  accountId: Scalars['String']['output'];
  accountType?: Maybe<AccountType>;
  bankName: Scalars['String']['output'];
  campaignId: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export enum ExternalTransferDirectionEnum {
  Credit = 'Credit',
  Debit = 'Debit'
}

/** Owned by Vault Only used in legacy createCard */
export enum Financial_Gateway {
  Stripe = 'stripe'
}

export type Facility = {
  __typename?: 'Facility';
  Address1?: Maybe<Scalars['String']['output']>;
  Address2?: Maybe<Scalars['String']['output']>;
  City?: Maybe<Scalars['String']['output']>;
  Date?: Maybe<Scalars['Date']['output']>;
  Facility_id?: Maybe<Scalars['Int']['output']>;
  State?: Maybe<Scalars['String']['output']>;
  ZipCode?: Maybe<Scalars['String']['output']>;
  directions?: Maybe<Scalars['String']['output']>;
  indoor?: Maybe<Scalars['String']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  ml_site_id?: Maybe<Scalars['Int']['output']>;
  ml_space?: Maybe<Scalars['String']['output']>;
  ml_space_id?: Maybe<Scalars['Int']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  place_name: Scalars['String']['output'];
  show?: Maybe<Scalars['String']['output']>;
  specialDirections?: Maybe<Scalars['String']['output']>;
  web?: Maybe<Scalars['String']['output']>;
};

export type FacilityLocation = {
  __typename?: 'FacilityLocation';
  Address1?: Maybe<Scalars['String']['output']>;
  Address2?: Maybe<Scalars['String']['output']>;
  City?: Maybe<Scalars['String']['output']>;
  State?: Maybe<Scalars['String']['output']>;
  ZipCode?: Maybe<Scalars['String']['output']>;
  directions?: Maybe<Scalars['String']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  place_name?: Maybe<Scalars['String']['output']>;
};

export type Family = {
  __typename?: 'Family';
  children?: Maybe<Array<Maybe<User>>>;
  parents?: Maybe<Array<Maybe<User>>>;
};

export enum FinAcctStatus {
  ActionRequired = 'ACTION_REQUIRED',
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  AwaitingReapproval = 'AWAITING_REAPPROVAL',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Terminated = 'TERMINATED',
  UnderReview = 'UNDER_REVIEW'
}

export enum FinAcctStripeEnv {
  Raise = 'RAISE',
  Sponsor = 'SPONSOR'
}

export type FinancialAccount = {
  __typename?: 'FinancialAccount';
  acctId?: Maybe<Scalars['ID']['output']>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  kybId: Scalars['ID']['output'];
  paymentMethodId?: Maybe<Scalars['ID']['output']>;
  processor: Processor;
  recipientName?: Maybe<Scalars['String']['output']>;
  settlementMethod?: Maybe<SettlementMethod>;
  stateCode?: Maybe<StateCode>;
  status: FinAcctStatus;
  street?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
  stripeEnv: FinAcctStripeEnv;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type FinancialAcctOrg = {
  __typename?: 'FinancialAcctOrg';
  acctId?: Maybe<Scalars['ID']['output']>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['ID']['output']>;
  finAcctId?: Maybe<Scalars['ID']['output']>;
  kybId?: Maybe<Scalars['ID']['output']>;
  orgEin?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['ID']['output']>;
  orgName?: Maybe<Scalars['String']['output']>;
  orgType?: Maybe<OrgTypeWithCampaign>;
  paymentMethodId?: Maybe<Scalars['ID']['output']>;
  processor?: Maybe<Processor>;
  recipientName?: Maybe<Scalars['String']['output']>;
  settlementMethod?: Maybe<SettlementMethod>;
  stateCode?: Maybe<StateCode>;
  status?: Maybe<FinAcctStatus>;
  street?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
  stripeEnv?: Maybe<FinAcctStripeEnv>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type FinancialAcctUser = {
  __typename?: 'FinancialAcctUser';
  acctId?: Maybe<Scalars['ID']['output']>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  authorizationCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['ID']['output']>;
  finAcctId?: Maybe<Scalars['ID']['output']>;
  isBeneficialOwner?: Maybe<Scalars['Boolean']['output']>;
  isContact?: Maybe<Scalars['Boolean']['output']>;
  isPrincipal?: Maybe<Scalars['Boolean']['output']>;
  isRepresentative?: Maybe<Scalars['Boolean']['output']>;
  kybId?: Maybe<Scalars['ID']['output']>;
  kycId?: Maybe<Scalars['ID']['output']>;
  orgId?: Maybe<Scalars['ID']['output']>;
  personId?: Maybe<Scalars['ID']['output']>;
  processor?: Maybe<Processor>;
  recipientName?: Maybe<Scalars['String']['output']>;
  settlementMethod?: Maybe<SettlementMethod>;
  stateCode?: Maybe<StateCode>;
  status?: Maybe<FinAcctStatus>;
  street?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Owned by Vault Duplicated by Wallet Used in legacy createCard */
export type FinancialAddress = {
  __typename?: 'FinancialAddress';
  city?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

/** Owned by Vault Only used in legacy createCard */
export type FinancialAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** Filtering options for nullable Float type */
export type FloatNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars['Float']['input']>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars['Float']['input']>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars['Float']['input']>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars['Float']['input']>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars['Float']['input']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['Float']['input']>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type Fundraiser = {
  __typename?: 'Fundraiser';
  blockedDonationInviteDomains?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  donationReceiptCustomText?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  goal?: Maybe<Scalars['Int']['output']>;
  groupLeaders?: Maybe<Array<FundraiserGroupLeader>>;
  id: Scalars['ID']['output'];
  links?: Maybe<Scalars['JSON']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<FundraiserOrigin>;
  raiseId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<FundraiserStatus>;
};


export type FundraiserGroupLeadersArgs = {
  where?: InputMaybe<FundraisersGroupLeadersWhereInput>;
};

export enum FundraiserAcknowledgementStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Signed = 'SIGNED'
}

export type FundraiserActivationEligibilityResponse = {
  __typename?: 'FundraiserActivationEligibilityResponse';
  fundraiserActivationEligibility: Scalars['Boolean']['output'];
  fundraiserActivationEligibilityFailedReasons?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
};

export enum FundraiserAddressType {
  Check = 'CHECK',
  Mailing = 'MAILING',
  Shipping = 'SHIPPING'
}

export type FundraiserApprovalSubmission = {
  __typename?: 'FundraiserApprovalSubmission';
  additionalNotes?: Maybe<Scalars['String']['output']>;
  campaignRaiseId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customerSupportInfo?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  estimatedTotalRaisedCents?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  formId: Scalars['Int']['output'];
  formType?: Maybe<FundraiserApprovalSubmissionFormType>;
  fundraiserApprovalSubmissionDocs: Array<FundraiserApprovalSubmissionDocument>;
  fundraiserApprovers: Array<FundraiserApprover>;
  fundraiserName: Scalars['String']['output'];
  fundraiserServiceWebsite?: Maybe<Scalars['String']['output']>;
  groupSize?: Maybe<Scalars['Int']['output']>;
  howThisWorks?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  latestRevision: Scalars['Boolean']['output'];
  newFundraiser: Scalars['Boolean']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  organizationName: Scalars['String']['output'];
  previousRevision?: Maybe<Scalars['Int']['output']>;
  pricingAndFees?: Maybe<Scalars['String']['output']>;
  processorId: Scalars['String']['output'];
  programId?: Maybe<Scalars['String']['output']>;
  proposedStartDate?: Maybe<Scalars['DateTime']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  revisionRequest?: Maybe<Scalars['String']['output']>;
  safety?: Maybe<Scalars['String']['output']>;
  status: FundraiserApprovalSubmissionStatus;
  submittedAt: Scalars['DateTime']['output'];
  submitterId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FundraiserApprovalSubmissionDocDataInput = {
  filename: Scalars['NonEmptyString']['input'];
  filetype: Scalars['NonEmptyString']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  location: Scalars['NonEmptyString']['input'];
};

export type FundraiserApprovalSubmissionDocument = {
  __typename?: 'FundraiserApprovalSubmissionDocument';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  filename?: Maybe<Scalars['NonEmptyString']['output']>;
  filetype?: Maybe<Scalars['NonEmptyString']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Scalars['NonEmptyString']['output']>;
  s3Filename?: Maybe<Scalars['NonEmptyString']['output']>;
  status?: Maybe<Scalars['NonEmptyString']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FundraiserApprovalSubmissionDocumentInput = {
  action: FundraiserApprovalSubmissionDocumentInputAction;
  document: FundraiserApprovalSubmissionDocDataInput;
};

export enum FundraiserApprovalSubmissionDocumentInputAction {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export enum FundraiserApprovalSubmissionFormType {
  Athon = 'ATHON',
  Events = 'EVENTS',
  Online = 'ONLINE',
  Other = 'OTHER',
  Product = 'PRODUCT',
  Snap = 'SNAP'
}

export type FundraiserApprovalSubmissionResult = {
  __typename?: 'FundraiserApprovalSubmissionResult';
  errors: Array<Maybe<Scalars['String']['output']>>;
  fundraiserApprovalSubmission?: Maybe<FundraiserApprovalSubmission>;
  status: Scalars['String']['output'];
};

export enum FundraiserApprovalSubmissionStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  RevisionRequested = 'REVISION_REQUESTED',
  Submitted = 'SUBMITTED'
}

export type FundraiserApprovalSubmissionsOrgNames = {
  __typename?: 'FundraiserApprovalSubmissionsOrgNames';
  fundraiserName: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  programName: Scalars['String']['output'];
};

export type FundraiserApprovalSubmissionsOrgNamesResult = FundraiserApprovalSubmissionsOrgNames | InvalidToken;

export type FundraiserApprover = {
  __typename?: 'FundraiserApprover';
  approverType?: Maybe<FundraiserApproverType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type FundraiserApproverDataInput = {
  approverType?: InputMaybe<FundraiserApproverType>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type FundraiserApproverInput = {
  action: FundraiserApproverInputAction;
  approver: FundraiserApproverDataInput;
};

export enum FundraiserApproverInputAction {
  Add = 'ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

export enum FundraiserApproverType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export enum FundraiserBasicStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Upcoming = 'UPCOMING'
}

export type FundraiserBlockedDonationInviteDomains = {
  __typename?: 'FundraiserBlockedDonationInviteDomains';
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type FundraiserCheerwall = {
  __typename?: 'FundraiserCheerwall';
  cheers?: Maybe<Array<Maybe<Cheer>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export enum FundraiserCreationOrigin {
  FundraisersService = 'FUNDRAISERS_SERVICE',
  RaiseClassic = 'RAISE_CLASSIC'
}

export type FundraiserCustomRewardsByLevel = {
  __typename?: 'FundraiserCustomRewardsByLevel';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sizes?: Maybe<Scalars['String']['output']>;
};

export type FundraiserCustomRewardsData = {
  __typename?: 'FundraiserCustomRewardsData';
  rewards?: Maybe<Array<Maybe<FundraiserCustomRewardsByLevel>>>;
  rewardsLevel: Scalars['Int']['output'];
};

export type FundraiserDataByCoachJoinCode = {
  __typename?: 'FundraiserDataByCoachJoinCode';
  coachJoinCode?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  joinCode?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type FundraiserEntityResource = {
  __typename?: 'FundraiserEntityResource';
  entityId?: Maybe<Scalars['Int']['output']>;
};

export type FundraiserFinalizationEligibilityResponse = {
  __typename?: 'FundraiserFinalizationEligibilityResponse';
  fundraiserFinalizationEligibility: Scalars['Boolean']['output'];
  fundraiserFinalizationEligibilityFailedReasons?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
};

export type FundraiserGroup = {
  __typename?: 'FundraiserGroup';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type FundraiserGroupLeader = {
  __typename?: 'FundraiserGroupLeader';
  id: Scalars['ID']['output'];
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  raiseUserId?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
  userDirectoryId?: Maybe<Scalars['String']['output']>;
};

export enum FundraiserGroupLeaderRoles {
  GroupLeader = 'GROUP_LEADER',
  Participant = 'PARTICIPANT',
  PrimaryGroupLeader = 'PRIMARY_GROUP_LEADER'
}

export enum FundraiserKycStatus {
  Resubmit = 'RESUBMIT',
  Submitted = 'SUBMITTED',
  Unsubmitted = 'UNSUBMITTED'
}

export type FundraiserManagerAlert = {
  __typename?: 'FundraiserManagerAlert';
  createdAt: Scalars['Date']['output'];
  fundraiserManagerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  readAt?: Maybe<Scalars['Date']['output']>;
  type: FundraiserManagerAlertType;
  updatedAt: Scalars['Date']['output'];
};

export enum FundraiserManagerAlertType {
  FirstLoadFundraiserActive = 'FIRST_LOAD_FUNDRAISER_ACTIVE',
  FirstLoadFundraiserClosed = 'FIRST_LOAD_FUNDRAISER_CLOSED'
}

export enum FundraiserManagersRole {
  GroupLeader = 'GROUP_LEADER',
  PrimaryGroupLeader = 'PRIMARY_GROUP_LEADER'
}

export enum FundraiserMediaType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export enum FundraiserOrigin {
  FundraisersService = 'FUNDRAISERS_SERVICE',
  RaiseClassic = 'RAISE_CLASSIC'
}

export type FundraiserParticipantPublic = {
  __typename?: 'FundraiserParticipantPublic';
  /** Raise Participant Id */
  id: Scalars['Int']['output'];
  participant?: Maybe<ParticipantPublic>;
};

export type FundraiserParticipantSmsDonationInvitesAddResponse = {
  __typename?: 'FundraiserParticipantSmsDonationInvitesAddResponse';
  errors?: Maybe<Scalars['JSON']['output']>;
  smsInvites?: Maybe<Array<ParticipantSmsDonationInvite>>;
};

export type FundraiserParticipantUpdateInput = {
  lastLoginAt?: InputMaybe<Scalars['Date']['input']>;
};

/** @deprecated(reason: "Use participantsPublic instead") */
export type FundraiserParticipants = {
  __typename?: 'FundraiserParticipants';
  id: Scalars['Int']['output'];
  participant?: Maybe<User>;
};

export type FundraiserRaisedAmount = {
  __typename?: 'FundraiserRaisedAmount';
  subtotalCents?: Maybe<Scalars['Int']['output']>;
};

export type FundraiserRewardLevelsCount = {
  __typename?: 'FundraiserRewardLevelsCount';
  levelsCount?: Maybe<Scalars['Int']['output']>;
};

export type FundraiserRewardsProduct = {
  __typename?: 'FundraiserRewardsProduct';
  default?: Maybe<Scalars['Boolean']['output']>;
  fitting?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sizes?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
};

export enum FundraiserSettlementMethod {
  CheckFundraiser = 'CHECK_FUNDRAISER',
  /** deprecated value */
  CheckOffice = 'CHECK_OFFICE',
  CheckSalesrep = 'CHECK_SALESREP',
  /** deprecated value */
  Default = 'DEFAULT',
  DirectDeposit = 'DIRECT_DEPOSIT'
}

export type FundraiserSetupProgress = {
  __typename?: 'FundraiserSetupProgress';
  nextIncompletedSection?: Maybe<Scalars['String']['output']>;
  sections: Array<FundraiserSetupProgressSection>;
};

export type FundraiserSetupProgressInput = {
  currentStep: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  section?: InputMaybe<Scalars['String']['input']>;
  status: FundraiserSetupProgressStatus;
};

export type FundraiserSetupProgressSection = {
  __typename?: 'FundraiserSetupProgressSection';
  currentStep: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  section: Scalars['String']['output'];
  status: FundraiserSetupProgressStatus;
  totalSteps: Scalars['Int']['output'];
};

export enum FundraiserSetupProgressStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotApplicable = 'NOT_APPLICABLE',
  NotStarted = 'NOT_STARTED'
}

export enum FundraiserStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Archive = 'ARCHIVE',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Final = 'FINAL',
  PendingApproval = 'PENDING_APPROVAL',
  Settled = 'SETTLED'
}

export type FundraiserTopDonation = {
  __typename?: 'FundraiserTopDonation';
  donorName?: Maybe<Scalars['String']['output']>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
};

export enum FundraiserUserImportType {
  CsvImport = 'csv_import',
  Rollover = 'rollover'
}

export type FundraiserUserIncentiveId = {
  __typename?: 'FundraiserUserIncentiveID';
  id?: Maybe<Scalars['Int']['output']>;
};

export type FundraiserUserRole = {
  __typename?: 'FundraiserUserRole';
  isGroupLeader?: Maybe<Scalars['Boolean']['output']>;
  isParticipant?: Maybe<Scalars['Boolean']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum FundraiserVaultKycStatus {
  AccountNotFound = 'ACCOUNT_NOT_FOUND',
  AdditionalInfoRequired = 'ADDITIONAL_INFO_REQUIRED',
  Verified = 'VERIFIED'
}

export type FundraisersDonationInvite = FundraisersGroupLeaderDonationInvite | FundraisersParticipantDonationInvite;

export type FundraisersDonationInviteUpdateInput = {
  status?: InputMaybe<DonationInviteStatus>;
};

export type FundraisersGroupLeaderDonationInvite = IFundraisersDonationInvite & {
  __typename?: 'FundraisersGroupLeaderDonationInvite';
  emailAddress?: Maybe<Scalars['String']['output']>;
  fundraiserId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lastEmailNumber?: Maybe<Scalars['Int']['output']>;
  lastEmailSentAt?: Maybe<Scalars['String']['output']>;
  participantId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<DonationInviteStatus>;
};

export type FundraisersGroupLeadersWhereInput = {
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FundraisersMediaGallery = {
  __typename?: 'FundraisersMediaGallery';
  fundraiserId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  mediaType: FundraiserMediaType;
  position?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FundraisersParticipantDonationInvite = IFundraisersDonationInvite & {
  __typename?: 'FundraisersParticipantDonationInvite';
  emailAddress?: Maybe<Scalars['String']['output']>;
  fundraiserId: Scalars['ID']['output'];
  groupLeaderId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  lastEmailNumber?: Maybe<Scalars['Int']['output']>;
  lastEmailSentAt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<DonationInviteStatus>;
};

export type GalleryItems = {
  __typename?: 'GalleryItems';
  campaignLogo?: Maybe<Scalars['String']['output']>;
  campaignLogoThumb?: Maybe<Scalars['String']['output']>;
  carouselItems?: Maybe<Array<Maybe<CarouselItem>>>;
};

export type Gender = {
  __typename?: 'Gender';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type GetCompetitionListInput = {
  from_date?: InputMaybe<Scalars['String']['input']>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  to_date?: InputMaybe<Scalars['String']['input']>;
};

export type GetDailyCalendarBusScheduleByDateInput = {
  from_date?: InputMaybe<Scalars['String']['input']>;
  to_date?: InputMaybe<Scalars['String']['input']>;
};

export type GetDailyCalendarEventsByDateInput = {
  from_date?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  to_date?: InputMaybe<Scalars['String']['input']>;
};

export type GetDailyCalendarOfficialsByDateInput = {
  from_date?: InputMaybe<Scalars['String']['input']>;
  to_date?: InputMaybe<Scalars['String']['input']>;
};

export type GetDailyCalendarPreparationsByDateInput = {
  from_date?: InputMaybe<Scalars['String']['input']>;
  to_date?: InputMaybe<Scalars['String']['input']>;
};

export type GetDailyCalendarWorkersByDateInput = {
  from_date?: InputMaybe<Scalars['String']['input']>;
  to_date?: InputMaybe<Scalars['String']['input']>;
};

export type GetEventByIdInput = {
  event_id: Scalars['Int']['input'];
};

export type GetEventParticipantsByIdInput = {
  id: Scalars['Int']['input'];
};

export type GetEventPreparationsByEventIdInput = {
  event_id: Scalars['Int']['input'];
};

export type GetEventPreparationsByIdInput = {
  id: Scalars['Int']['input'];
};

export type GetEventTransportDetailsByEventOrDatesInput = {
  event_date_end?: InputMaybe<Scalars['Date']['input']>;
  event_date_start?: InputMaybe<Scalars['Date']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
};

export type GetEventsFilteredByOpponentStartAndEndDate = {
  endDate: Scalars['String']['input'];
  opponent: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type GetFacilityByPlaceNameInput = {
  place_name: Scalars['String']['input'];
};

export type GetLevelByIdInput = {
  ID: Scalars['Int']['input'];
};

export type GetOfficialAssignmentsInput = {
  from_date?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  official_name?: InputMaybe<Scalars['String']['input']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  to_date?: InputMaybe<Scalars['String']['input']>;
};

export type GetOfficialByIdInput = {
  id: Scalars['Int']['input'];
};

export type GetOfficialDutyByIdInput = {
  id: Scalars['Int']['input'];
};

export type GetOfficialPoolByIdInput = {
  ID?: InputMaybe<Scalars['String']['input']>;
};

export type GetOpponentBySchoolCodeInput = {
  SchoolCode: Scalars['String']['input'];
};

export type GetPreparationByIdInput = {
  id: Scalars['Int']['input'];
};

export type GetSchoolInfoByIdInput = {
  id: Scalars['String']['input'];
};

export type GetVehicleByIdInput = {
  id: Scalars['Int']['input'];
};

export type GetWorkerByIdInput = {
  id: Scalars['Int']['input'];
};

export type GetWorkerPoolByIdInput = {
  ID: Scalars['Int']['input'];
};

export type GetWorkersByEventIdInput = {
  event_id: Scalars['Int']['input'];
};

export type GiftShopIntentInput = {
  donateIntentId: Scalars['String']['input'];
  fundraiserId: Scalars['Int']['input'];
  fundraiserUserId: Scalars['Int']['input'];
  participantUserDirectoryId: Scalars['String']['input'];
  purchaseIntentId?: InputMaybe<Scalars['String']['input']>;
  status: PurchaseIntentStatus;
  userId: Scalars['Int']['input'];
};

export type GiftShopIntentUpdateInput = {
  donorEmail?: InputMaybe<Scalars['String']['input']>;
  donorFirstName?: InputMaybe<Scalars['String']['input']>;
  donorLastName?: InputMaybe<Scalars['String']['input']>;
  fundraiserUserId?: InputMaybe<Scalars['Int']['input']>;
  participantUserDirectoryId?: InputMaybe<Scalars['String']['input']>;
  paymentProvider?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type GiftShopPurchaseIntentData = {
  __typename?: 'GiftShopPurchaseIntentData';
  donateIntentId?: Maybe<Scalars['String']['output']>;
  donorEmail?: Maybe<Scalars['String']['output']>;
  donorFirstName?: Maybe<Scalars['String']['output']>;
  donorId?: Maybe<Scalars['String']['output']>;
  donorLastName?: Maybe<Scalars['String']['output']>;
  fundraiserUserId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  lineItems: Array<DonorGiftShopItem>;
  participantUserDirectoryId?: Maybe<Scalars['String']['output']>;
  purchaseIntentId?: Maybe<Scalars['String']['output']>;
  raiseFundraiserId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type GiftShopPurchaseIntentId = {
  __typename?: 'GiftShopPurchaseIntentId';
  id: Scalars['String']['output'];
};

export type GiftShopPurchaseItemUpdate = {
  id: Scalars['String']['input'];
  size: Scalars['String']['input'];
};

export type GroupLeader = {
  __typename?: 'GroupLeader';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type GroupLeaderBlockedDomainsUpdateInput = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  remove?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** See CampaignMembership for details */
export type GroupLeaderCampaign = {
  __typename?: 'GroupLeaderCampaign';
  basicStatus: BasicCampaignStatus;
  donationLink: Scalars['String']['output'];
  entityId?: Maybe<Scalars['Int']['output']>;
  goalInDollars?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isCocoach: Scalars['Boolean']['output'];
  joinCode?: Maybe<Scalars['String']['output']>;
  /** Getting Kyc status adds time to your query **expensive** */
  kyc?: Maybe<CampaignKyc>;
  name: Scalars['String']['output'];
  /** Primary group leader */
  primary?: Maybe<PrimaryGroupLeader>;
  raiseUserJoinedAt?: Maybe<Scalars['String']['output']>;
  /** Getting roster adds time to your query */
  roster?: Maybe<Array<Maybe<Roster>>>;
  status: CampaignStatus;
  teamSize?: Maybe<Scalars['Int']['output']>;
  totalCentsRaised?: Maybe<Scalars['Int']['output']>;
  whyDonateText?: Maybe<Scalars['String']['output']>;
};

export type GroupLeaderDonationLevels = {
  __typename?: 'GroupLeaderDonationLevels';
  amount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  impact?: Maybe<Scalars['String']['output']>;
  rewardDescription?: Maybe<Scalars['String']['output']>;
};

export type GroupLeaderDonationLevelsInput = {
  id: Scalars['ID']['input'];
  impact?: InputMaybe<Scalars['String']['input']>;
  rewardDescription?: InputMaybe<Scalars['String']['input']>;
};

export type GroupLeaderDonationStats = {
  __typename?: 'GroupLeaderDonationStats';
  _id?: Maybe<Scalars['String']['output']>;
  count: Scalars['Int']['output'];
  donorsCount?: Maybe<Scalars['Int']['output']>;
  hasResignLineage?: Maybe<Scalars['Boolean']['output']>;
  otkPurchaseTotal?: Maybe<Scalars['Int']['output']>;
  participantsWithDonationCount?: Maybe<Scalars['Int']['output']>;
  totalCents: Scalars['Int']['output'];
};

export type GroupLeaderEditFundraiserFinancialsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  kycSubmitted?: InputMaybe<Scalars['Boolean']['input']>;
  payoutSubmitted?: InputMaybe<Scalars['Boolean']['input']>;
  settlementMethod?: InputMaybe<FundraiserSettlementMethod>;
  setupCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupLeaderEditFundraiserInput = {
  earlyStartDateRequested?: InputMaybe<Scalars['Boolean']['input']>;
  howDonationsAreUsed?: InputMaybe<Scalars['String']['input']>;
  /** will always be logo.${extension}, the full url will be constructed in the resolver */
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  personalMessage?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
  /** pass when group leader completes a setup step */
  setupCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  whyDonations?: InputMaybe<Scalars['String']['input']>;
};

export type GroupLeaderEmailInput = {
  emailBody: Scalars['String']['input'];
  emailSubject: Scalars['String']['input'];
  emailType: GroupLeaderEmailType;
};

export enum GroupLeaderEmailType {
  DonorThankYou = 'DONOR_THANK_YOU',
  ParticipantCongratulation = 'PARTICIPANT_CONGRATULATION'
}

export type GroupLeaderEmailsSent = {
  __typename?: 'GroupLeaderEmailsSent';
  donorThankYouEmailSent?: Maybe<Scalars['Boolean']['output']>;
  participantCongratulationEmailsSent?: Maybe<Scalars['Boolean']['output']>;
};

export type GroupLeaderFundraiser = {
  __typename?: 'GroupLeaderFundraiser';
  BlockedFundraiserDonationInviteDomains?: Maybe<Array<FundraiserBlockedDonationInviteDomains>>;
  DonationLevels?: Maybe<Array<GroupLeaderDonationLevels>>;
  FundraiserAcknowledgement?: Maybe<GroupLeaderFundraiserAcknowledgement>;
  FundraiserDonations?: Maybe<GroupLeaderFundraiserDonations>;
  FundraiserEmailInvites?: Maybe<GroupLeaderFundraiserDonationEmailInvites>;
  FundraiserFinancials?: Maybe<GroupLeaderFundraiserFinancials>;
  FundraiserParticipants?: Maybe<GroupLeaderFundraiserParticipantsResponse>;
  FundraiserSmsInvites?: Maybe<GroupLeaderFundraiserDonationSmsInvites>;
  GearLogoConfiguration?: Maybe<GroupLeaderGearLogoConfiguration>;
  MediaGallery?: Maybe<Array<GroupLeaderMediaGallery>>;
  SetupProgress?: Maybe<FundraiserSetupProgress>;
  ShippingAddress?: Maybe<GroupLeaderFundraiserAddress>;
  activity?: Maybe<Scalars['String']['output']>;
  basicStatus?: Maybe<FundraiserBasicStatus>;
  /** @deprecated This field is only used for redirecting to legacy raise. It will be removed in the future. */
  deprecatedRaiseEntityId?: Maybe<Scalars['Int']['output']>;
  earlyStartDateRequested?: Maybe<Scalars['Boolean']['output']>;
  emailInvitesReleasedAt?: Maybe<Scalars['Date']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  fundraiserFeatures?: Maybe<GroupLeaderFundraiserFeatures>;
  fundraiserManagers?: Maybe<Array<GroupLeaderFundraiserManager>>;
  goal?: Maybe<Scalars['Float']['output']>;
  groupLeadersEmailSent?: Maybe<GroupLeaderEmailsSent>;
  howDonationsAreUsed?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  joinCode?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orgsId?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<FundraiserCreationOrigin>;
  participantGroups?: Maybe<Array<GroupLeaderParticipantGroup>>;
  participantJoinData?: Maybe<ParticipantJoinData>;
  personalMessage?: Maybe<Scalars['String']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  raiseId?: Maybe<Scalars['Int']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  smsInvitesReleasedAt?: Maybe<Scalars['Date']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<FundraiserStatus>;
  storeOrder?: Maybe<GroupLeaderStoreOrderResult>;
  stripeConnectAccountId?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['String']['output']>;
  whyDonations?: Maybe<Scalars['String']['output']>;
};

export type GroupLeaderFundraiserAcknowledgement = {
  __typename?: 'GroupLeaderFundraiserAcknowledgement';
  agreedToTerms?: Maybe<Scalars['Boolean']['output']>;
  documentContent: Scalars['String']['output'];
  fundraiserId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  signedAt?: Maybe<Scalars['Date']['output']>;
  status: FundraiserAcknowledgementStatus;
  typedSignature?: Maybe<Scalars['String']['output']>;
};

export type GroupLeaderFundraiserAcknowledgementSignInput = {
  agreedToTerms: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  setupCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  signedAt: Scalars['Date']['input'];
  typedSignature: Scalars['String']['input'];
};

export type GroupLeaderFundraiserAddress = {
  __typename?: 'GroupLeaderFundraiserAddress';
  addressType?: Maybe<FundraiserAddressType>;
  attentionTo?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  editable?: Maybe<Scalars['Boolean']['output']>;
  fundraiserId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  streetLine1?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type GroupLeaderFundraiserAddressInput = {
  attentionTo?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  region: Scalars['String']['input'];
  setupCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  streetLine1: Scalars['String']['input'];
  streetLine2?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type GroupLeaderFundraiserDonationEmailInvites = {
  __typename?: 'GroupLeaderFundraiserDonationEmailInvites';
  fundraiserId: Scalars['String']['output'];
  legacyDonorsInvitesCount: Scalars['Int']['output'];
  myInviteCount: Scalars['Int']['output'];
  reachedParticipantsInviteGoalCount: Scalars['Int']['output'];
};

export type GroupLeaderFundraiserDonationSmsInvites = {
  __typename?: 'GroupLeaderFundraiserDonationSmsInvites';
  fundraiserId: Scalars['String']['output'];
  reachedParticipantsInviteGoalCount: Scalars['Int']['output'];
};

export type GroupLeaderFundraiserDonations = {
  __typename?: 'GroupLeaderFundraiserDonations';
  byLegacyDonors?: Maybe<GroupLeaderDonationStats>;
  byResignedFundraisers?: Maybe<GroupLeaderDonationStats>;
  fundraiserId: Scalars['ID']['output'];
  total?: Maybe<GroupLeaderDonationStats>;
};

export type GroupLeaderFundraiserFeatures = {
  __typename?: 'GroupLeaderFundraiserFeatures';
  hasCustomRewards?: Maybe<Scalars['Boolean']['output']>;
  hasGuardianCampaign?: Maybe<Scalars['Boolean']['output']>;
  hasOtk?: Maybe<Scalars['Boolean']['output']>;
  hasRewards?: Maybe<Scalars['Boolean']['output']>;
  hasTippingEnabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  storeUrl?: Maybe<Scalars['String']['output']>;
  waCampaignSetup?: Maybe<Scalars['Boolean']['output']>;
};

export type GroupLeaderFundraiserFinancials = {
  __typename?: 'GroupLeaderFundraiserFinancials';
  externalOrderId?: Maybe<Scalars['String']['output']>;
  fundraiserId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  kycStatus: FundraiserKycStatus;
  settlementCheckAddress?: Maybe<GroupLeaderFundraiserAddress>;
  settlementDate?: Maybe<Scalars['Date']['output']>;
  settlementMethod: FundraiserSettlementMethod;
  settlementMethodEditable?: Maybe<Scalars['Boolean']['output']>;
  vaultKycStatus?: Maybe<FundraiserVaultKycStatus>;
};

export type GroupLeaderFundraiserGear = {
  __typename?: 'GroupLeaderFundraiserGear';
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GroupLeaderFundraiserManager = {
  __typename?: 'GroupLeaderFundraiserManager';
  fundraiserId: Scalars['ID']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  role?: Maybe<FundraiserManagersRole>;
};

export type GroupLeaderFundraiserManagerInput = {
  lastLoginAt?: InputMaybe<Scalars['Date']['input']>;
  role?: InputMaybe<FundraiserManagersRole>;
};

export type GroupLeaderFundraiserManagerUpdateInput = {
  id: Scalars['ID']['input'];
  update?: InputMaybe<GroupLeaderFundraiserManagerInput>;
};

export type GroupLeaderFundraiserMediaGalleryUpdateInput = {
  add?: InputMaybe<Array<GroupLeaderMediaGalleryInput>>;
  remove?: InputMaybe<Array<Scalars['ID']['input']>>;
  update?: InputMaybe<GroupLeaderMediaGalleryUpdateInput>;
};

export type GroupLeaderFundraiserParticipant = {
  __typename?: 'GroupLeaderFundraiserParticipant';
  deletedAt?: Maybe<Scalars['Date']['output']>;
  donationsRaised?: Maybe<GroupLeaderParticipantDonationRaisedResponse>;
  email?: Maybe<Scalars['String']['output']>;
  emailsLoadedCount?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  guardianFullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  missingUser: Scalars['Boolean']['output'];
  participantGroupId?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  textsLoadedCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupLeaderFundraiserParticipantUpdate = {
  __typename?: 'GroupLeaderFundraiserParticipantUpdate';
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  participantGroupId?: Maybe<Scalars['String']['output']>;
};

export type GroupLeaderFundraiserParticipantUpdateInput = {
  id: Scalars['ID']['input'];
  participantGroupId?: InputMaybe<Scalars['String']['input']>;
};

export type GroupLeaderFundraiserParticipantsResponse = {
  __typename?: 'GroupLeaderFundraiserParticipantsResponse';
  fundraiserId: Scalars['String']['output'];
  participantsCount: Scalars['Int']['output'];
  participantsJoinedCount: Scalars['Int']['output'];
  profilePhotoUploadedCount: Scalars['Int']['output'];
  signedInCount: Scalars['Int']['output'];
};

export enum GroupLeaderFundraiserPdfType {
  ParticipantJoinFundraiser = 'PARTICIPANT_JOIN_FUNDRAISER'
}

export type GroupLeaderFundraisersPaginated = {
  __typename?: 'GroupLeaderFundraisersPaginated';
  fundraisers: Array<Maybe<GroupLeaderFundraiser>>;
  pagination?: Maybe<GroupLeadersPagination>;
};

export type GroupLeaderGearLogoConfiguration = {
  __typename?: 'GroupLeaderGearLogoConfiguration';
  approvedAt?: Maybe<Scalars['Date']['output']>;
  changeRequestDescription?: Maybe<Scalars['String']['output']>;
  changesRequestedAt?: Maybe<Scalars['Date']['output']>;
  fundraiserId: Scalars['ID']['output'];
  gearLogoImageDigital?: Maybe<Scalars['String']['output']>;
  gearSample?: Maybe<Array<GroupLeaderFundraiserGear>>;
  id: Scalars['ID']['output'];
};

export type GroupLeaderGearLogoUpdateInput = {
  approvedAt?: InputMaybe<Scalars['Date']['input']>;
  changeRequestDescription?: InputMaybe<Scalars['String']['input']>;
  changesRequestedAt?: InputMaybe<Scalars['Date']['input']>;
  setupCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupLeaderList = {
  __typename?: 'GroupLeaderList';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['String']['output']>;
  list: Array<Maybe<GroupLeader>>;
};

export type GroupLeaderMediaGallery = {
  __typename?: 'GroupLeaderMediaGallery';
  fundraiserId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  mediaType?: Maybe<FundraiserMediaType>;
  position?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type GroupLeaderMediaGalleryInput = {
  fundraiserId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  mediaType: FundraiserMediaType;
  position?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GroupLeaderMediaGalleryUpdateInput = {
  items?: InputMaybe<Array<GroupLeaderMediaGalleryInput>>;
  setupCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupLeaderParticipantDonationRaisedResponse = {
  __typename?: 'GroupLeaderParticipantDonationRaisedResponse';
  count: Scalars['Int']['output'];
  totalDollars: Scalars['Int']['output'];
};

export type GroupLeaderParticipantGroup = {
  __typename?: 'GroupLeaderParticipantGroup';
  fundraiserId: Scalars['ID']['output'];
  goal?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GroupLeaderParticipantsUpdateInput = {
  delete?: InputMaybe<Array<Scalars['ID']['input']>>;
  update?: InputMaybe<Array<GroupLeaderFundraiserParticipantUpdateInput>>;
};

export type GroupLeaderRoster = {
  __typename?: 'GroupLeaderRoster';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  userDirectoryMembers?: Maybe<Array<GroupLeaderRosterMembers>>;
};

export type GroupLeaderRosterMembers = {
  __typename?: 'GroupLeaderRosterMembers';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  initials?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
};

export type GroupLeaderStoreOrderResult = {
  __typename?: 'GroupLeaderStoreOrderResult';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  netsuiteId?: Maybe<Scalars['String']['output']>;
  shippingReceivedAt?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GroupLeadersPagination = {
  __typename?: 'GroupLeadersPagination';
  currentPage?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export enum GroupRosterFilterEnum {
  GroupId = 'groupId',
  Id = 'id',
  MemberId = 'memberId',
  RosterId = 'rosterId',
  SeasonId = 'seasonId'
}

export type GroupsOverviewDashboardResponse = {
  __typename?: 'GroupsOverviewDashboardResponse';
  count: Scalars['Int']['output'];
  overview?: Maybe<Array<Maybe<SpendGroupsOverview>>>;
};

export type HelpDocumentDeleteInput = {
  key: Scalars['String']['input'];
};

export type HelpDocumentUploadInput = {
  base64File: Scalars['String']['input'];
};

export type HmAccountRole = {
  __typename?: 'HmAccountRole';
  account_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  role_id?: Maybe<Scalars['Int']['output']>;
};

export type HmGraphSales = {
  __typename?: 'HmGraphSales';
  soldDuringWeek?: Maybe<Scalars['Int']['output']>;
  weekName?: Maybe<Scalars['String']['output']>;
};

export type HmSalesRep = {
  __typename?: 'HmSalesRep';
  rep_email?: Maybe<Scalars['String']['output']>;
  sales_rep?: Maybe<Scalars['String']['output']>;
};

export type HmSpendAccount = {
  __typename?: 'HmSpendAccount';
  id?: Maybe<Scalars['Int']['output']>;
  orgTeamMembers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type HmSpendData = {
  __typename?: 'HmSpendData';
  credited?: Maybe<Scalars['Int']['output']>;
  inactiveCards?: Maybe<Scalars['Int']['output']>;
  paid?: Maybe<Scalars['Int']['output']>;
  pastDue?: Maybe<Scalars['Int']['output']>;
  pending?: Maybe<Scalars['Int']['output']>;
  upcoming?: Maybe<Scalars['Int']['output']>;
};

export type HmSponsorData = {
  __typename?: 'HmSponsorData';
  activeSponsorships?: Maybe<Scalars['Int']['output']>;
  assetCount?: Maybe<Scalars['Int']['output']>;
  available?: Maybe<Scalars['Int']['output']>;
  sponsorShipValue?: Maybe<Scalars['Int']['output']>;
  totalAssets?: Maybe<Scalars['Int']['output']>;
};

export type HmStore = {
  __typename?: 'HmStore';
  active_stores?: Maybe<Scalars['String']['output']>;
  monthly_sales?: Maybe<Array<Maybe<HmGraphSales>>>;
  total_orders?: Maybe<Scalars['String']['output']>;
  total_points?: Maybe<Scalars['String']['output']>;
  total_sales?: Maybe<Scalars['String']['output']>;
};

export type HmUser = {
  __typename?: 'HmUser';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Hubspot Engagement entity */
export type HubspotCallEngagement = {
  __typename?: 'HubspotCallEngagement';
  contactIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  dealIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  engagementId?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type HubspotProspectsDeals = {
  __typename?: 'HubspotProspectsDeals';
  activity?: Maybe<Scalars['String']['output']>;
  dealName?: Maybe<Scalars['String']['output']>;
  dealStage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isProspectPinned?: Maybe<Scalars['Boolean']['output']>;
  lastActivityDate?: Maybe<Scalars['String']['output']>;
  leaderFirstName?: Maybe<Scalars['String']['output']>;
  leaderLastName?: Maybe<Scalars['String']['output']>;
  projectedLaunchDate?: Maybe<Scalars['String']['output']>;
};

export enum HubspotSortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type IFundraisersDonationInvite = {
  emailAddress?: Maybe<Scalars['String']['output']>;
  fundraiserId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lastEmailNumber?: Maybe<Scalars['Int']['output']>;
  lastEmailSentAt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<DonationInviteStatus>;
};

/** Common payload for mutations. */
export type IMutationResult = {
  __typename?: 'IMutationResult';
  data?: Maybe<Scalars['JSON']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type IosNotificationInput = {
  badge?: InputMaybe<Scalars['Int']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  sound?: InputMaybe<Scalars['String']['input']>;
  threadId?: InputMaybe<Scalars['String']['input']>;
};

export type ImageInput = {
  url: Scalars['String']['input'];
};

export type InitiateProfileChallengeResponse = {
  __typename?: 'InitiateProfileChallengeResponse';
  challenge: UserChallenge;
  success: Scalars['Boolean']['output'];
};

export type InsAddPreApprovedContactApproverInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type InsAddPreApprovedContactOrgInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  modId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  raiseId?: InputMaybe<Scalars['Int']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type InsAddPreApprovedContactsResult = {
  __typename?: 'InsAddPreApprovedContactsResult';
  contactsResult?: Maybe<Array<Maybe<InsPreApprovedContactResult>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status: Scalars['String']['output'];
};

export type InsAnalyticsSummaryStat = {
  __typename?: 'InsAnalyticsSummaryStat';
  amount_raised_cents: Scalars['Int']['output'];
  campaign_id: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type InsCampaignDonation = {
  __typename?: 'InsCampaignDonation';
  amount_cents?: Maybe<Scalars['Int']['output']>;
  campaign_id: Scalars['Int']['output'];
  campaign_name?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  donor_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  participant_name?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
};

export type InsCampaignRaiseEntityInfo = {
  __typename?: 'InsCampaignRaiseEntityInfo';
  campaign_id: Scalars['Int']['output'];
  entity_id?: Maybe<Scalars['Int']['output']>;
};

export type InsCampaignSettlement = {
  __typename?: 'InsCampaignSettlement';
  amount_cents?: Maybe<Scalars['Int']['output']>;
  campaign_id: Scalars['Int']['output'];
  campaign_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_updated?: Maybe<Scalars['DateTime']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export type InsCampaignStat = {
  __typename?: 'InsCampaignStat';
  donations_count?: Maybe<Scalars['Int']['output']>;
  end_date?: Maybe<Scalars['DateTime']['output']>;
  forecasted_amount_cents?: Maybe<Scalars['Int']['output']>;
  group_leader_email?: Maybe<Scalars['String']['output']>;
  group_leader_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  insights_status?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  participants?: Maybe<Scalars['Int']['output']>;
  participation?: Maybe<Scalars['Float']['output']>;
  preloading?: Maybe<Scalars['Float']['output']>;
  slug: Scalars['String']['output'];
  start_date?: Maybe<Scalars['DateTime']['output']>;
  total_raised_cents?: Maybe<Scalars['Int']['output']>;
};

export type InsCampaignsData = {
  __typename?: 'InsCampaignsData';
  analyticsSummaryStats?: Maybe<Array<Maybe<InsAnalyticsSummaryStat>>>;
  campaignStats?: Maybe<Array<Maybe<InsCampaignStat>>>;
  donationMapStats?: Maybe<Array<Maybe<InsDonationMapStat>>>;
  inviteStats?: Maybe<Array<Maybe<InsInviteStat>>>;
  lTRChart?: Maybe<InsLtrChartData>;
  monthlyCampaignStats?: Maybe<Array<Maybe<InsMonthlyCampaignStat>>>;
};

export type InsDeletePreApprovedContactResult = {
  __typename?: 'InsDeletePreApprovedContactResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status: Scalars['String']['output'];
};

export type InsDonationMapStat = {
  __typename?: 'InsDonationMapStat';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  campaign_ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  donations_amount_cents?: Maybe<Scalars['Int']['output']>;
  donations_count?: Maybe<Scalars['Int']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  long?: Maybe<Scalars['Float']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
};

export type InsDonorParticipantContact = {
  __typename?: 'InsDonorParticipantContact';
  campaign_id: Scalars['Int']['output'];
  donor_email?: Maybe<Scalars['String']['output']>;
  donor_id?: Maybe<Scalars['Int']['output']>;
  donor_name?: Maybe<Scalars['String']['output']>;
  participant_email?: Maybe<Scalars['String']['output']>;
  participant_id?: Maybe<Scalars['Int']['output']>;
  participant_name?: Maybe<Scalars['String']['output']>;
};

export type InsEditPreApprovedContactInput = {
  activity?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type InsEditPreApprovedContactResult = {
  __typename?: 'InsEditPreApprovedContactResult';
  error?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type InsEditPreApprovedInvite = {
  __typename?: 'InsEditPreApprovedInvite';
  activity: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  inviteStatus: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type InsEditPreApprovedInviteResult = {
  __typename?: 'InsEditPreApprovedInviteResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status: Scalars['String']['output'];
  updatedInvite?: Maybe<InsEditPreApprovedInvite>;
};

export type InsEmailTestPreApprovedContactResult = {
  __typename?: 'InsEmailTestPreApprovedContactResult';
  email: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type InsInviteStat = {
  __typename?: 'InsInviteStat';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  campaign_ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  invite_count: Scalars['Int']['output'];
  invite_type: Scalars['String']['output'];
  total_amount_cents: Scalars['Int']['output'];
};

export type InsLtrChartData = {
  __typename?: 'InsLTRChartData';
  activeCampaignsCount?: Maybe<Scalars['Int']['output']>;
  activeCampaignsParticipation?: Maybe<Scalars['Float']['output']>;
  activeCampaignsTotalCents?: Maybe<Scalars['Int']['output']>;
  avgDonationCents?: Maybe<Scalars['Int']['output']>;
  closedCampaignsCount?: Maybe<Scalars['Int']['output']>;
  closedCampaignsParticipation?: Maybe<Scalars['Float']['output']>;
  closedCampaignsTotalCents?: Maybe<Scalars['Int']['output']>;
  ltr?: Maybe<Scalars['Int']['output']>;
  upcomingCampaignsCount?: Maybe<Scalars['Int']['output']>;
  upcomingCampaignsForecastedAmountCents?: Maybe<Scalars['Int']['output']>;
  upcomingCampaignsParticipantSignIn?: Maybe<Scalars['Float']['output']>;
};

export type InsMonthlyCampaignStat = {
  __typename?: 'InsMonthlyCampaignStat';
  campaign_ids: Array<Maybe<Scalars['Int']['output']>>;
  month: Scalars['Int']['output'];
  participation?: Maybe<Scalars['Float']['output']>;
  preloading?: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type InsOrg = {
  __typename?: 'InsOrg';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type InsOrgCampaignSummary = {
  __typename?: 'InsOrgCampaignSummary';
  campaignsCount: Scalars['Int']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  totalRaisedCents: Scalars['Int']['output'];
};

export type InsOrgCampaignSummaryInput = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};

export type InsPreApprovedContact = {
  __typename?: 'InsPreApprovedContact';
  activity?: Maybe<Scalars['String']['output']>;
  associated_org_id?: Maybe<Scalars['String']['output']>;
  associated_org_name?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  invite_phone_number?: Maybe<Scalars['String']['output']>;
  invite_sent_at?: Maybe<Scalars['DateTime']['output']>;
  invite_status?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
};

export type InsPreApprovedContactInput = {
  activity: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type InsPreApprovedContactResult = {
  __typename?: 'InsPreApprovedContactResult';
  email: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type InsResendPreApprovedContactResult = {
  __typename?: 'InsResendPreApprovedContactResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status: Scalars['String']['output'];
};

export type InsSalesRepInfo = {
  __typename?: 'InsSalesRepInfo';
  email: Scalars['String']['output'];
  featured_image?: Maybe<Scalars['String']['output']>;
  hubspot_calendar_link?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  post_title?: Maybe<Scalars['String']['output']>;
};

export enum InsSetNotificationPreferenceFlag {
  Off = 'OFF',
  On = 'ON'
}

export type InsSetNotificationPreferences = {
  notificationIds?: InputMaybe<Array<InputMaybe<InsUserNotificationType>>>;
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  setTo: InsSetNotificationPreferenceFlag;
  type: InsSetNotificationType;
};

export type InsSetNotificationPreferencesResult = {
  __typename?: 'InsSetNotificationPreferencesResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  messages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status: Scalars['String']['output'];
};

export enum InsSetNotificationType {
  Organization = 'ORGANIZATION',
  User = 'USER'
}

export type InsSetUserPreferencesResult = {
  __typename?: 'InsSetUserPreferencesResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  messages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status: Scalars['String']['output'];
};

export type InsUser = {
  __typename?: 'InsUser';
  id: Scalars['Int']['output'];
};

export enum InsUserNotificationType {
  CheckSent = 'CHECK_SENT',
  DepositMade = 'DEPOSIT_MADE',
  FundraiserCreated = 'FUNDRAISER_CREATED',
  FundraiserFinalized = 'FUNDRAISER_FINALIZED'
}

export type InsightsGetUserPreference = {
  __typename?: 'InsightsGetUserPreference';
  campaign_raise_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  org_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** Filtering options for Int type */
export type IntFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars['Int']['input']>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars['Int']['input']>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars['Int']['input']>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars['Int']['input']>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars['Int']['input']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['Int']['input']>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Filtering options for nullable Int type */
export type IntNullableFilter = {
  /** Equals operation */
  equals?: InputMaybe<Scalars['Int']['input']>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars['Int']['input']>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars['Int']['input']>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars['Int']['input']>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars['Int']['input']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['Int']['input']>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type InvalidCampaignPersonListData = {
  __typename?: 'InvalidCampaignPersonListData';
  email: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type InvalidCampaignSmsInviteData = {
  __typename?: 'InvalidCampaignSmsInviteData';
  errorMessage: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type InvalidToken = Error & {
  __typename?: 'InvalidToken';
  message: Scalars['String']['output'];
};

export type InviteError = Error & {
  __typename?: 'InviteError';
  message: Scalars['String']['output'];
};

export type InviteFilter = {
  objectId: Scalars['String']['input'];
  type: InviteType;
};

export enum InviteFilterEnum {
  Group = 'group',
  Program = 'program',
  Status = 'status',
  Type = 'type'
}

export type InviteFundraiserResponse = {
  __typename?: 'InviteFundraiserResponse';
  errors?: Maybe<Scalars['JSON']['output']>;
  invitesSent?: Maybe<Array<Maybe<InviteFundraiserSentResponse>>>;
};

export type InviteFundraiserSentResponse = {
  __typename?: 'InviteFundraiserSentResponse';
  id: Scalars['String']['output'];
  invite?: Maybe<Scalars['String']['output']>;
  status: JoinFundraiserInviteStatus;
};

export type InviteInfo = {
  __typename?: 'InviteInfo';
  email?: Maybe<Scalars['String']['output']>;
  flow?: Maybe<Scalars['String']['output']>;
  flowParams?: Maybe<Scalars['JSONObject']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  joincode?: Maybe<Scalars['String']['output']>;
  params?: Maybe<UserInviteParams>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  requester?: Maybe<User>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<InviteType>;
};

export type InviteList = {
  __typename?: 'InviteList';
  acceptedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  joincode?: Maybe<Scalars['String']['output']>;
  params?: Maybe<UserInviteParams>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  requester?: Maybe<User>;
  status?: Maybe<UserInviteStatus>;
  type?: Maybe<InviteType>;
};

export type InviteMemberContactInput = {
  contact: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  method: ContactMethodEnum;
  role: ChannelRolesEnum;
};

export type InviteMemberUsersInput = {
  id: Scalars['ID']['input'];
  role: ChannelRolesEnum;
};

export type InviteNotFound = Error & {
  __typename?: 'InviteNotFound';
  message: Scalars['String']['output'];
};

export type InviteParentArguments = {
  joincode?: InputMaybe<Scalars['String']['input']>;
};

export type InviteSendResult = InviteError | InviteInfo;

export enum InviteType {
  Channel = 'channel',
  Org = 'org',
  Parent = 'parent'
}

export type InviteUserArguments = {
  apps?: InputMaybe<Array<InputMaybe<UserApps>>>;
  consumer?: InputMaybe<UserApps>;
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
  occupation?: InputMaybe<UserOccupation>;
  orgsAffiliation?: InputMaybe<Array<UserOrgAffiliationPayload>>;
  permissionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type InviteUserOrgsArguments = {
  orgId?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<UserApps>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

export type InvitesListResponse = {
  __typename?: 'InvitesListResponse';
  invites?: Maybe<Array<InviteList>>;
  pagination: Pagination;
};

export type InvitesResponse = {
  __typename?: 'InvitesResponse';
  invites: Array<InviteInfo>;
};

export enum InvoiceFilterEnum {
  Date = 'date',
  GroupId = 'groupId',
  GroupName = 'groupName',
  GroupRosterId = 'groupRosterId',
  InviteId = 'inviteId',
  PlayerName = 'playerName',
  SeasonId = 'seasonId',
  Status = 'status',
  Unreconciled = 'unreconciled'
}

export enum InvoiceGuardianFilterEnum {
  GroupId = 'groupId',
  RosterId = 'rosterId'
}

export enum InvoiceReportFilterEnum {
  DateAfter = 'dateAfter',
  DateBefore = 'dateBefore',
  ExcludeArchived = 'excludeArchived',
  GroupId = 'groupId',
  GroupRosterId = 'groupRosterId',
  InviteId = 'inviteId',
  OrgId = 'orgId',
  PastDueRange = 'pastDueRange',
  PlayerName = 'playerName',
  Status = 'status'
}

/** Owned by Vault Only used in legacy createCard */
export type IssueCardInput = {
  altName?: InputMaybe<Scalars['String']['input']>;
  cardType: Card_Type;
  metadata?: InputMaybe<VaultCardMetadataInput>;
  recipientName?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<FinancialAddressInput>;
  shippingService?: InputMaybe<Shipping_Service>;
  spendingLimitAmount: Scalars['Int']['input'];
  spendingLimitInterval: Spending_Limit_Interval;
};

export enum JoinCodeType {
  GroupLeader = 'GROUP_LEADER',
  Invalid = 'INVALID',
  Participant = 'PARTICIPANT'
}

export enum JoinFundraiserInviteStatus {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export enum JoinFundraiserInviteType {
  Email = 'EMAIL',
  ExistingUser = 'EXISTING_USER',
  Sms = 'SMS'
}

export type JoinFundraiserUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum JoinType {
  CopyLink = 'COPY_LINK',
  CopyMessage = 'COPY_MESSAGE',
  CsvImport = 'CSV_IMPORT',
  Fanx = 'FANX',
  QrCode = 'QR_CODE',
  Rollover = 'ROLLOVER',
  Sma = 'SMA',
  SnapEmail = 'SNAP_EMAIL',
  SnapText = 'SNAP_TEXT'
}

export type JoinedFundraiserId = {
  __typename?: 'JoinedFundraiserID';
  /** Returns the ID of the fundraiser */
  id?: Maybe<Scalars['Int']['output']>;
};

export enum Kyb_Structure {
  FreeZoneEstablishment = 'free_zone_establishment',
  FreeZoneLlc = 'free_zone_llc',
  GovernmentInstrumentality = 'government_instrumentality',
  GovernmentalUnit = 'governmental_unit',
  IncorporatedNonProfit = 'incorporated_non_profit',
  LimitedLiabilityPartnership = 'limited_liability_partnership',
  Llc = 'llc',
  MultiMemberLlc = 'multi_member_llc',
  PrivateCompany = 'private_company',
  PrivateCorporation = 'private_corporation',
  PrivatePartnership = 'private_partnership',
  PublicCompany = 'public_company',
  PublicCorporation = 'public_corporation',
  PublicPartnership = 'public_partnership',
  SingleMemberLlc = 'single_member_llc',
  SoleEstablishment = 'sole_establishment',
  SoleProprietorship = 'sole_proprietorship',
  TaxExemptGovernmentInstrumentality = 'tax_exempt_government_instrumentality',
  UnincorporatedAssociation = 'unincorporated_association',
  UnincorporatedNonProfit = 'unincorporated_non_profit'
}

export enum Kyb_Type {
  Company = 'company',
  GovernmentEntity = 'government_entity',
  Individual = 'individual',
  NonProfit = 'non_profit'
}

export type Leader = {
  __typename?: 'Leader';
  createdAt: Scalars['DateTime']['output'];
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  title: TeamTitle;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LeagueKeeperDocument = {
  __typename?: 'LeagueKeeperDocument';
  description?: Maybe<Scalars['String']['output']>;
  docType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  sportName?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LeagueKeeperEvent = {
  __typename?: 'LeagueKeeperEvent';
  activity?: Maybe<Scalars['String']['output']>;
  conferenceEvent?: Maybe<Scalars['Boolean']['output']>;
  date?: Maybe<Scalars['ISO8601DateTime']['output']>;
  eventType?: Maybe<Scalars['String']['output']>;
  gameComplete?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  level?: Maybe<LeagueKeeperLevel>;
  location?: Maybe<Scalars['String']['output']>;
  loser?: Maybe<Scalars['Int']['output']>;
  scorePlus?: Maybe<Scalars['Boolean']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  staticSite?: Maybe<Scalars['Boolean']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  team1?: Maybe<School>;
  team1Name?: Maybe<Scalars['String']['output']>;
  team1Score?: Maybe<Scalars['String']['output']>;
  team2?: Maybe<School>;
  team2Name?: Maybe<Scalars['String']['output']>;
  team2Score?: Maybe<Scalars['String']['output']>;
  team3?: Maybe<School>;
  team3Name?: Maybe<Scalars['String']['output']>;
  tie?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  winner?: Maybe<Scalars['Int']['output']>;
  years?: Maybe<Scalars['String']['output']>;
};

export type LeagueKeeperLevel = {
  __typename?: 'LeagueKeeperLevel';
  id: Scalars['ID']['output'];
  level?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type LegacyTransactionsInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type LegacyTransactionsOutput = {
  __typename?: 'LegacyTransactionsOutput';
  count: Scalars['Int']['output'];
  transactions: Array<Maybe<Transaction>>;
};

export type Level = {
  __typename?: 'Level';
  ID: Scalars['Int']['output'];
  Level?: Maybe<Scalars['String']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
};

export enum LogoPosition {
  FrontCenter = 'FRONT_CENTER',
  FrontLeftArm = 'FRONT_LEFT_ARM',
  FrontLeftChest = 'FRONT_LEFT_CHEST',
  FrontLeftLeg = 'FRONT_LEFT_LEG',
  FrontRightArm = 'FRONT_RIGHT_ARM',
  FrontRightChest = 'FRONT_RIGHT_CHEST',
  FrontRightLeg = 'FRONT_RIGHT_LEG'
}

export type MdmDetails = DriveUser & {
  __typename?: 'MDMDetails';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export enum MacroServiceRequestWorkflowState {
  Approved = 'approved',
  Canceled = 'canceled',
  Closed = 'closed',
  Completed = 'completed',
  Open = 'open',
  Rejected = 'rejected'
}

export type MagentoConsumerPointsLiabilityInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerBalanceId?: InputMaybe<Scalars['Int']['input']>;
  customerId: Scalars['Int']['input'];
  customerLiability?: InputMaybe<Scalars['Float']['input']>;
  entityId?: InputMaybe<Scalars['Int']['input']>;
  eventCode?: InputMaybe<Scalars['String']['input']>;
  eventData?: InputMaybe<Scalars['JSON']['input']>;
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  expirationPeriod?: InputMaybe<Scalars['Int']['input']>;
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
  isNeedSendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  isNotificationSent?: InputMaybe<Scalars['Boolean']['input']>;
  netsuiteExternalId?: InputMaybe<Scalars['String']['input']>;
  netsuiteInternalId?: InputMaybe<Scalars['Int']['input']>;
  orderId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  parentNetsuiteExternalId?: InputMaybe<Scalars['String']['input']>;
  parentNetsuiteInternalId?: InputMaybe<Scalars['Int']['input']>;
  parentTransactionId?: InputMaybe<Scalars['Int']['input']>;
  parentTransactionType?: InputMaybe<Scalars['String']['input']>;
  paymentEntityId?: InputMaybe<Scalars['Int']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  pointsBalance?: InputMaybe<Scalars['Float']['input']>;
  pointsDelta: Scalars['Float']['input'];
  pointsToUse?: InputMaybe<Scalars['Float']['input']>;
  receiverId?: InputMaybe<Scalars['Int']['input']>;
  ruleId?: InputMaybe<Scalars['Int']['input']>;
  senderId?: InputMaybe<Scalars['Int']['input']>;
  storeId?: InputMaybe<Scalars['Int']['input']>;
  transactionId: Scalars['Int']['input'];
  transactionParentGroup?: InputMaybe<Scalars['String']['input']>;
  transactionType: Scalars['String']['input'];
  websiteId?: InputMaybe<Scalars['Int']['input']>;
};

export type MagentoOrder = {
  __typename?: 'MagentoOrder';
  baseDiscountAmount?: Maybe<Scalars['Float']['output']>;
  baseGrandTotal?: Maybe<Scalars['Float']['output']>;
  baseShippingAmount?: Maybe<Scalars['Float']['output']>;
  baseSubtotal?: Maybe<Scalars['Float']['output']>;
  baseTaxAmount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['ID']['output']>;
  customerEmail?: Maybe<Scalars['String']['output']>;
  customerFirstName?: Maybe<Scalars['String']['output']>;
  customerLastName?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  grandTotal?: Maybe<Scalars['Float']['output']>;
  incrementId?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<OrderItem>>>;
  mageworxRewardPointsAmount?: Maybe<Scalars['Float']['output']>;
  orderId?: Maybe<Scalars['BigInt']['output']>;
  scopeId?: Maybe<Scalars['BigInt']['output']>;
  shippingAmount?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  storeDashbordStatus?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
  totalPaid?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type MagentoOrderUnionType = MagentoOrders | OnException;

export type MagentoOrders = {
  __typename?: 'MagentoOrders';
  orders?: Maybe<Array<Maybe<MagentoOrder>>>;
};

export type MagentoPointActivities = {
  __typename?: 'MagentoPointActivities';
  activities?: Maybe<Array<Maybe<MagentoPointActivity>>>;
};

export type MagentoPointActivity = {
  __typename?: 'MagentoPointActivity';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['BigInt']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  orderIncrementId?: Maybe<Scalars['String']['output']>;
  pointsBalance?: Maybe<Scalars['Float']['output']>;
  pointsDelta?: Maybe<Scalars['Float']['output']>;
  receiver?: Maybe<MagentoPointReceiver>;
  scopeId?: Maybe<Scalars['BigInt']['output']>;
  sender?: Maybe<MagentoPointSender>;
  status?: Maybe<Scalars['String']['output']>;
  storeCode?: Maybe<Scalars['String']['output']>;
  storeName?: Maybe<Scalars['String']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['BigInt']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
};

export type MagentoPointActivityUnionType = MagentoPointActivities | OnException;

export type MagentoPointReceiver = {
  __typename?: 'MagentoPointReceiver';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  receiverId?: Maybe<Scalars['Int']['output']>;
};

export type MagentoPointSender = {
  __typename?: 'MagentoPointSender';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['Int']['output']>;
};

export type MagentoRefundInput = {
  amount: Scalars['Float']['input'];
  netsuiteOrderId: Scalars['Int']['input'];
  orderId: Scalars['Int']['input'];
  status: Scalars['String']['input'];
};

export type MagentoStore = {
  __typename?: 'MagentoStore';
  accountManagerId?: Maybe<Scalars['String']['output']>;
  activityType?: Maybe<Scalars['String']['output']>;
  baseTotalSales?: Maybe<Scalars['Float']['output']>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  campaignId?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  digitalLogo?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  earnedPoints?: Maybe<Scalars['Float']['output']>;
  earnedPointsBalance?: Maybe<Scalars['Float']['output']>;
  earnedSharedPoints?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  embroideryLogo?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Int']['output']>;
  entityId?: Maybe<Scalars['BigInt']['output']>;
  expiringPoints?: Maybe<Scalars['Float']['output']>;
  favicon?: Maybe<Scalars['String']['output']>;
  givenPoints?: Maybe<Scalars['Float']['output']>;
  givenSharedPoints?: Maybe<Scalars['Float']['output']>;
  groupLeaderEmail?: Maybe<Scalars['String']['output']>;
  hatLogo?: Maybe<Scalars['String']['output']>;
  headerLogo?: Maybe<Scalars['String']['output']>;
  heroSliderVariableId?: Maybe<Scalars['BigInt']['output']>;
  homepagePageId?: Maybe<Scalars['String']['output']>;
  managerId?: Maybe<Scalars['BigInt']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ncesId?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['Int']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  parentStoreId?: Maybe<Scalars['Int']['output']>;
  pointsPercentage?: Maybe<Scalars['Int']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  productColors?: Maybe<Array<Maybe<ScopeProductColor>>>;
  productPricePercentage?: Maybe<Scalars['Int']['output']>;
  programId?: Maybe<Scalars['Int']['output']>;
  raiseId?: Maybe<Scalars['Int']['output']>;
  salesRepId?: Maybe<Scalars['String']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  storeId?: Maybe<Scalars['BigInt']['output']>;
  storevariablesId?: Maybe<Scalars['BigInt']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
  totalDiscountAmount?: Maybe<Scalars['Float']['output']>;
  totalOrders?: Maybe<Scalars['BigInt']['output']>;
  totalPoints?: Maybe<Scalars['Float']['output']>;
  totalSales?: Maybe<Scalars['Float']['output']>;
  zipCode?: Maybe<Scalars['Int']['output']>;
};

export type MagentoStoreManager = {
  __typename?: 'MagentoStoreManager';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  managerId?: Maybe<Scalars['BigInt']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  scopeId?: Maybe<Scalars['BigInt']['output']>;
};

export type MagentoStorePointsEarned = {
  __typename?: 'MagentoStorePointsEarned';
  endDate?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  totalPoints?: Maybe<Scalars['Float']['output']>;
};

export type MagentoStorePointsEarnedUnionType = MagentoStoresPointsEarned | OnException;

export type MagentoStoreUnionType = MagentoStore | OnException;

export type MagentoStoresPointsEarned = {
  __typename?: 'MagentoStoresPointsEarned';
  points?: Maybe<Array<Maybe<MagentoStorePointsEarned>>>;
};

export type MagentoTransactionWebhookResponse = {
  __typename?: 'MagentoTransactionWebhookResponse';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum ManageAnnouncementDistinctOld {
  Announcement = 'announcement',
  SportName = 'sportName'
}

export type ManageAnnouncementFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageAnnouncementDistinctOld>>>;
  orderBy?: InputMaybe<ManageAnnouncementOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManageAnnouncementWhereOld>;
};

export type ManageAnnouncementListOld = {
  __typename?: 'ManageAnnouncementListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManageAnnouncementOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

/** The object which contains all the input data for the ManageResource announcement query */
export type ManageAnnouncementOld = {
  __typename?: 'ManageAnnouncementOld';
  active?: Maybe<Scalars['String']['output']>;
  announcement?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  emailFilter?: Maybe<Scalars['String']['output']>;
  emailRequest?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imageId?: Maybe<Scalars['Int']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  onFront?: Maybe<Scalars['Int']['output']>;
  recordStatus?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  showUrgentFrom?: Maybe<Scalars['Date']['output']>;
  showUrgentUntil?: Maybe<Scalars['Date']['output']>;
  sortVal?: Maybe<Scalars['Int']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  sportName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
  urgent?: Maybe<Scalars['Boolean']['output']>;
};

export type ManageAnnouncementOrderByOld = {
  endDate?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
  sortVal?: InputMaybe<SortOrderEnum>;
  startDate?: InputMaybe<SortOrderEnum>;
  title?: InputMaybe<SortOrderEnum>;
};

export type ManageAnnouncementWhereOld = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  onFront?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ManageCoachDistinctOld {
  AdId = 'adId',
  FirstName = 'firstName',
  LastName = 'lastName',
  SchoolId = 'schoolId',
  SeasonId = 'seasonId',
  Summary = 'summary',
  Title = 'title'
}

export type ManageCoachListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageCoachDistinctOld>>>;
  /** Defines how the coach list should be ordered. */
  orderBy?: InputMaybe<ManageCoachListOrderByOld>;
  /** Number of coaches to skip before starting to fetch the result set. */
  skip?: InputMaybe<Scalars['Int']['input']>;
  /** Limits the number of coaches to fetch from the result set. */
  take?: InputMaybe<Scalars['Int']['input']>;
  /** Conditions to filter the coach list. */
  where?: InputMaybe<ManageCoachListWhereOld>;
};

export type ManageCoachListOld = {
  __typename?: 'ManageCoachListOld';
  /** Total count of coaches that match the criteria. */
  count?: Maybe<Scalars['Int']['output']>;
  /** The list of coaches. */
  list?: Maybe<Array<Maybe<ManageCoachOld>>>;
  /** Number of coaches skipped. */
  skip?: Maybe<Scalars['Int']['output']>;
  /** Number of coaches fetched. */
  take?: Maybe<Scalars['Int']['output']>;
};

export type ManageCoachListOrderByOld = {
  /** Orders coaches based on their address. */
  address?: InputMaybe<SortOrderEnum>;
  /** Orders coaches based on their city. */
  city?: InputMaybe<SortOrderEnum>;
  /** Orders coaches based on their first name. */
  firstName?: InputMaybe<SortOrderEnum>;
  /** Orders coaches based on their last name. */
  lastName?: InputMaybe<SortOrderEnum>;
  /** Orders coaches based on their postal code. */
  postalCode?: InputMaybe<SortOrderEnum>;
};

export type ManageCoachListWhereOld = {
  /** Associated Ad ID for the coach. */
  adId?: InputMaybe<Scalars['Int']['input']>;
  /** Unique ID of the coach. */
  coachId?: InputMaybe<Scalars['Int']['input']>;
  /** Timestamp when the coach was created. */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  /** ID of the user who created the coach entry. */
  createdBy?: InputMaybe<Scalars['Int']['input']>;
  /** First name of the coach. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if the coach is a head coach. */
  headCoach?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates if the coach is approved. */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Last name of the coach. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** ID for the coach's photo. */
  photoId?: InputMaybe<Scalars['Int']['input']>;
  /** School's unique ID associated with the coach. */
  schoolId?: InputMaybe<Scalars['Int']['input']>;
  /** ID of the coaching season. */
  seasonId?: InputMaybe<Scalars['Int']['input']>;
  /** Brief description or profile of the coach. */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** Title or position of the coach. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Timestamp when the coach was last updated. */
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  /** ID of the user who last updated the coach entry. */
  updatedBy?: InputMaybe<Scalars['Int']['input']>;
};

export type ManageCoachOld = {
  __typename?: 'ManageCoachOld';
  /** Associated Ad ID for the coach. */
  adId?: Maybe<Scalars['Int']['output']>;
  /** Unique ID of the coach. */
  coachId?: Maybe<Scalars['Int']['output']>;
  /** Timestamp when the coach was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** ID of the user who created the coach entry. */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** First name of the coach. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Indicates if the coach is a head coach. */
  headCoach?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the coach is approved. */
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  /** Last name of the coach. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** ID for the coach's photo. */
  photoId?: Maybe<Scalars['Int']['output']>;
  /** School's unique ID associated with the coach. */
  schoolId?: Maybe<Scalars['Int']['output']>;
  /** ID of the coaching season. */
  seasonId?: Maybe<Scalars['Int']['output']>;
  /** Brief description or profile of the coach. */
  summary?: Maybe<Scalars['String']['output']>;
  /** Title or position of the coach. */
  title?: Maybe<Scalars['String']['output']>;
  /** Timestamp when the coach was last updated. */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** ID of the user who last updated the coach entry. */
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export enum ManageEventListDistinctOld {
  Gs = 'gs',
  Opponent = 'opponent',
  Place = 'place'
}

/** Criteria to filter and sort the list of events. */
export type ManageEventListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageEventListDistinctOld>>>;
  /** Defines how the list should be ordered. */
  orderBy?: InputMaybe<ManageEventListOrderByOld>;
  /** Number of items to skip before starting to fetch the result set. */
  skip?: InputMaybe<Scalars['Int']['input']>;
  /** Limits the number of items to fetch from the result set. */
  take?: InputMaybe<Scalars['Int']['input']>;
  /** Conditions to filter the list. */
  where?: InputMaybe<ManageEventListWhereOld>;
  /** Conditions to filter not the list. */
  whereNot?: InputMaybe<ManageEventListWhereNotOld>;
};

/** Represents a paginated list of events. */
export type ManageEventListOld = {
  __typename?: 'ManageEventListOld';
  /** Total count of events that match the criteria. */
  count?: Maybe<Scalars['Int']['output']>;
  /** The list of events. */
  list?: Maybe<Array<Maybe<ManageEventOld>>>;
  /** Number of items skipped. */
  skip?: Maybe<Scalars['Int']['output']>;
  /** Number of items fetched. */
  take?: Maybe<Scalars['Int']['output']>;
};

/** Specifies the order in which the event list should be sorted. */
export type ManageEventListOrderByOld = {
  /** Orders events based on their confirmation status. */
  confirmed?: InputMaybe<SortOrderEnum>;
  event_date?: InputMaybe<SortOrderEnum>;
  /** Orders events based on the opponent's name. */
  opponent?: InputMaybe<SortOrderEnum>;
};

/** Conditions to filter the event list. */
export type ManageEventListWhereNotOld = {
  /** Filters events based on their activity or sport type. */
  activity?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their author or writer. */
  author?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their cancellation status. */
  cancellationStatus?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their conference details or name. */
  conference?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their related conference event ID. */
  conferenceEventId?: InputMaybe<Scalars['Int']['input']>;
  /** Filters events based on their confirmation status. */
  confirmed?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on the timestamp when they were created. */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  /** Filters events based on their location for departure. */
  departureLocation?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their direction or map link. */
  directionLink?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their estimated time of return. */
  estimatedReturnTime?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their date. */
  eventDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filters events based on their title or main heading. */
  eventTitle?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on whether they exist in the MLS system. */
  existsInMls?: InputMaybe<Scalars['Int']['input']>;
  /** Filters events based on their gender specification. */
  gender?: InputMaybe<Scalars['String']['input']>;
  /** Specifies the list of gs in which the scores list should be filter. */
  gs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filters events based on their level. */
  level?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on the opponent's name. */
  opponent?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on the score achieved by the opponent team. */
  opponentScore?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their location or venue. */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their associated team for the season. */
  seasonTeam?: InputMaybe<Scalars['Int']['input']>;
  /** Filters events based on their start time. */
  startTime?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on the score achieved by the home team. */
  teamScore?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their transport comments. */
  transportComments?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their transportation details. */
  transportation?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on the last update timestamp. */
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  years?: InputMaybe<Scalars['String']['input']>;
};

/** Conditions to filter the event list. */
export type ManageEventListWhereOld = {
  /** Filters events based on their activity or sport type. */
  activity?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their author or writer. */
  author?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their cancellation status. */
  cancellationStatus?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their conference details or name. */
  conference?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their related conference event ID. */
  conferenceEventId?: InputMaybe<Scalars['Int']['input']>;
  /** Filters events based on their confirmation status. */
  confirmed?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on the timestamp when they were created. */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  /** Filters events based on their location for departure. */
  departureLocation?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their direction or map link. */
  directionLink?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on the end date */
  endDate?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their estimated time of return. */
  estimatedReturnTime?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their date. */
  eventDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filters events based on their title or main heading. */
  eventTitle?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on whether they exist in the MLS system. */
  existsInMls?: InputMaybe<Scalars['Int']['input']>;
  /** Filters events based on their gender specification. */
  gender?: InputMaybe<Scalars['String']['input']>;
  /** Specifies the list of gs in which the scores list should be filter. */
  gs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filters events based on their level. */
  level?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on the opponent's name. */
  opponent?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on the score achieved by the opponent team. */
  opponentScore?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their location or venue. */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their associated team for the season. */
  seasonTeam?: InputMaybe<Scalars['Int']['input']>;
  /** Filters events based on the start date */
  startDate?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their start time. */
  startTime?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on the score achieved by the home team. */
  teamScore?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their transport comments. */
  transportComments?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on their transportation details. */
  transportation?: InputMaybe<Scalars['String']['input']>;
  /** Filters events based on the last update timestamp. */
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  years?: InputMaybe<Scalars['String']['input']>;
};

export type ManageEventOld = {
  __typename?: 'ManageEventOld';
  /** Type of activity or sport. */
  activity?: Maybe<Scalars['String']['output']>;
  /** Author or writer of the event story. */
  author?: Maybe<Scalars['String']['output']>;
  /** Fee associated with transportation or bus. */
  busFee?: Maybe<Scalars['Int']['output']>;
  /** Scheduled time for the bus or transportation. */
  busTime?: Maybe<Scalars['String']['output']>;
  /** Status indicating event's cancellation. */
  cancellationStatus?: Maybe<Scalars['String']['output']>;
  /** Additional comments or notes. */
  comments?: Maybe<Scalars['String']['output']>;
  /** Conference details or name. */
  conference?: Maybe<Scalars['String']['output']>;
  /** Event ID related to the conference. */
  conferenceEventId?: Maybe<Scalars['Int']['output']>;
  /** ID associated with the conference. */
  conferenceId?: Maybe<Scalars['Int']['output']>;
  /** Confirmation status of the event. */
  confirmed?: Maybe<Scalars['String']['output']>;
  /** Contract details or identifier. */
  contract?: Maybe<Scalars['String']['output']>;
  /** Timestamp when the event was created. */
  createdAt?: Maybe<Scalars['Date']['output']>;
  /** Timestamp when the event result was created. */
  createdResultAt?: Maybe<Scalars['String']['output']>;
  /** User ID of the creator of the event result. */
  createdResultBy?: Maybe<Scalars['Int']['output']>;
  /** Location for event departure. */
  departureLocation?: Maybe<Scalars['String']['output']>;
  /** Link for directions or map. */
  directionLink?: Maybe<Scalars['String']['output']>;
  /** Directions or map details for the event. */
  directions?: Maybe<Scalars['String']['output']>;
  /** Indication if early dismissal is required. */
  earlyDismissalRequired?: Maybe<Scalars['String']['output']>;
  /** Scheduled time for early dismissal. */
  earlyDismissalTime?: Maybe<Scalars['String']['output']>;
  /** End time of the event. */
  endTime?: Maybe<Scalars['String']['output']>;
  /** Estimated time of return post event. */
  estimatedReturnTime?: Maybe<Scalars['String']['output']>;
  /** Indication if the event is complete. */
  eventComplete?: Maybe<Scalars['Int']['output']>;
  /** Date of the event. */
  eventDate?: Maybe<Scalars['Date']['output']>;
  /** Date and Time for start of event. */
  eventDateTime?: Maybe<Scalars['String']['output']>;
  /** Calculated field from event date indicating day of the week. */
  eventDay?: Maybe<Scalars['String']['output']>;
  /** Calculated field from event ID for event details. */
  eventDetailsPath?: Maybe<Scalars['String']['output']>;
  /** The unique ID of the event. */
  eventId?: Maybe<Scalars['Int']['output']>;
  /** Lead or main summary of the event. */
  eventLead?: Maybe<Scalars['String']['output']>;
  /** Unique ID for the event result. */
  eventResultID?: Maybe<Scalars['Int']['output']>;
  /** Detailed story or description of the event. */
  eventStory?: Maybe<Scalars['String']['output']>;
  /** Title or main heading of the event story. */
  eventTitle?: Maybe<Scalars['String']['output']>;
  /** Type or category of the event. */
  eventType?: Maybe<Scalars['String']['output']>;
  /** Indication if event exists in MLS system. */
  existsInMls?: Maybe<Scalars['Int']['output']>;
  /** Fee or cost associated with the event. */
  fee?: Maybe<Scalars['Int']['output']>;
  /** Revenue generated from gate or entry fee. */
  gateRevenue?: Maybe<Scalars['Int']['output']>;
  /** Gender specification for the event. */
  gender?: Maybe<Scalars['String']['output']>;
  /** Value grouping multiple events. */
  groupVal?: Maybe<Scalars['String']['output']>;
  /** General status of the event. */
  gs?: Maybe<Scalars['String']['output']>;
  /** Main headline or caption for the event. */
  headline?: Maybe<Scalars['String']['output']>;
  /** Indication if the event has major impact. */
  impactEvent?: Maybe<Scalars['String']['output']>;
  /** Indication if event details are approved. */
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  /** Lead information or summary. */
  lead?: Maybe<Scalars['String']['output']>;
  /** Level of the event. */
  level?: Maybe<Scalars['String']['output']>;
  /** Location description or address. */
  location?: Maybe<Scalars['String']['output']>;
  /** Points deducted for a loss. */
  lossPoints?: Maybe<Scalars['Int']['output']>;
  /** Number of buses or transportation units. */
  numBuses?: Maybe<Scalars['Int']['output']>;
  /** Name or description of the opponent. */
  opponent?: Maybe<Scalars['String']['output']>;
  /** Code identifier for the opponent. */
  opponentCode?: Maybe<Scalars['String']['output']>;
  /** Opponent details or description. */
  opponentForEvent?: Maybe<ManageOpponentOld>;
  /** Score achieved by the opponent team. */
  opponentScore?: Maybe<Scalars['String']['output']>;
  /** Outcome or result of the event. */
  outcome?: Maybe<Scalars['String']['output']>;
  /** URL or path to event picture. */
  picture?: Maybe<Scalars['String']['output']>;
  /** Location or venue of the event. */
  place?: Maybe<Scalars['String']['output']>;
  /** Details for event setup or preparation. */
  prepSetup?: Maybe<Scalars['String']['output']>;
  /** Program associated with the event. */
  programForEvent?: Maybe<ProgramForEventOld>;
  /** Indication if event should be promoted. */
  promote?: Maybe<Scalars['String']['output']>;
  /** Indication if score should be reported. */
  reportScore?: Maybe<Scalars['Int']['output']>;
  /** Indication if story related to event should be reported. */
  reportStory?: Maybe<Scalars['Int']['output']>;
  /** Results or outcomes from the event. */
  results?: Maybe<Scalars['String']['output']>;
  /** Total revenue generated from the event. */
  revenue?: Maybe<Scalars['Int']['output']>;
  /** Indication if event rolls over to next season. */
  rollover?: Maybe<Scalars['String']['output']>;
  /** Season details or year. */
  season?: Maybe<Scalars['String']['output']>;
  /** Associated team for the season. */
  seasonTeam?: Maybe<Scalars['Int']['output']>;
  /** Indication if event should be showcased on front page. */
  showFrontPage?: Maybe<Scalars['Int']['output']>;
  /** Start time used for sorting. */
  sortStartTime?: Maybe<Scalars['String']['output']>;
  /** Start time of the event. */
  startTime?: Maybe<Scalars['String']['output']>;
  /** Name of the associated team. */
  teamName?: Maybe<Scalars['String']['output']>;
  /** Score achieved by the home team. */
  teamScore?: Maybe<Scalars['String']['output']>;
  /** Title or heading of the event. */
  title?: Maybe<Scalars['String']['output']>;
  /** Indication if the event is part of a tournament. */
  tournament?: Maybe<Scalars['String']['output']>;
  /** Unique ID for transportation details. */
  transId?: Maybe<Scalars['Int']['output']>;
  /** Comments related to transportation. */
  transportComments?: Maybe<Scalars['String']['output']>;
  /** Transportation details for the event. */
  transportation?: Maybe<Scalars['String']['output']>;
  /** Date when event result was last updated. */
  updateResultDate?: Maybe<Scalars['String']['output']>;
  /** Timestamp of the last update to the event. */
  updatedAt?: Maybe<Scalars['Date']['output']>;
  /** Timestamp of the last update to the event result. */
  updatedResultAt?: Maybe<Scalars['String']['output']>;
  /** User ID of the last updater of the event result. */
  updatedResultBy?: Maybe<Scalars['Int']['output']>;
  /** Web directory or path for the event. */
  webDir?: Maybe<Scalars['String']['output']>;
  /** Points awarded for a win. */
  winPoints?: Maybe<Scalars['Int']['output']>;
  /** Year(s) associated with the event. */
  years?: Maybe<Scalars['String']['output']>;
};

export enum ManageFacilityDistinctOld {
  Directions = 'directions',
  PlaceName = 'placeName'
}

export type ManageFacilityFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageFacilityDistinctOld>>>;
  orderBy?: InputMaybe<ManageFacilityOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManageFacilityWhereOld>;
  whereNot?: InputMaybe<ManageFacilityWhereNotOld>;
};

export type ManageFacilityListOld = {
  __typename?: 'ManageFacilityListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManageFacilityOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

/** The object which contains all the input data for the ManageFacility query */
export type ManageFacilityOld = {
  __typename?: 'ManageFacilityOld';
  directions?: Maybe<Scalars['String']['output']>;
  indoor?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  locationId?: Maybe<Scalars['Int']['output']>;
  mlSiteId?: Maybe<Scalars['Int']['output']>;
  mlSpace?: Maybe<Scalars['String']['output']>;
  mlSpaceId?: Maybe<Scalars['Int']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  placeName?: Maybe<Scalars['String']['output']>;
  show?: Maybe<Scalars['String']['output']>;
  web?: Maybe<Scalars['String']['output']>;
};

export type ManageFacilityOrderByOld = {
  directions?: InputMaybe<SortOrderEnum>;
  indoor?: InputMaybe<SortOrderEnum>;
  isDeleted?: InputMaybe<SortOrderEnum>;
  locationId?: InputMaybe<SortOrderEnum>;
  mlSiteId?: InputMaybe<SortOrderEnum>;
  mlSpace?: InputMaybe<SortOrderEnum>;
  mlSpaceId?: InputMaybe<SortOrderEnum>;
  picture?: InputMaybe<SortOrderEnum>;
  placeName?: InputMaybe<SortOrderEnum>;
  show?: InputMaybe<SortOrderEnum>;
  web?: InputMaybe<SortOrderEnum>;
};

export type ManageFacilityWhereNotOld = {
  directions?: InputMaybe<Scalars['String']['input']>;
  indoor?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  mlSiteId?: InputMaybe<Scalars['Int']['input']>;
  mlSpace?: InputMaybe<Scalars['String']['input']>;
  mlSpaceId?: InputMaybe<Scalars['Int']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  show?: InputMaybe<Scalars['String']['input']>;
  web?: InputMaybe<Scalars['String']['input']>;
};

export type ManageFacilityWhereOld = {
  directions?: InputMaybe<Scalars['String']['input']>;
  indoor?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  mlSiteId?: InputMaybe<Scalars['Int']['input']>;
  mlSpace?: InputMaybe<Scalars['String']['input']>;
  mlSpaceId?: InputMaybe<Scalars['Int']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  placeName?: InputMaybe<Scalars['String']['input']>;
  show?: InputMaybe<Scalars['String']['input']>;
  web?: InputMaybe<Scalars['String']['input']>;
};

export type ManageHeadlineFilterOld = {
  orderBy?: InputMaybe<ManageHeadlineOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManageHeadlineWhereOld>;
};

export type ManageHeadlineListOld = {
  __typename?: 'ManageHeadlineListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManageHeadlineOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

/** The object which contains all the input data for the ManageHeadline query */
export type ManageHeadlineOld = {
  __typename?: 'ManageHeadlineOld';
  eventResultId?: Maybe<Scalars['Int']['output']>;
  eventStory?: Maybe<Scalars['String']['output']>;
  eventTitle?: Maybe<Scalars['String']['output']>;
  refId?: Maybe<Scalars['Int']['output']>;
};

export type ManageHeadlineOrderByOld = {
  EventResultId?: InputMaybe<SortOrderEnum>;
  EventStory?: InputMaybe<SortOrderEnum>;
  EventTitle?: InputMaybe<SortOrderEnum>;
  RefId?: InputMaybe<SortOrderEnum>;
};

export type ManageHeadlineWhereOld = {
  EventResultId?: InputMaybe<Scalars['Int']['input']>;
  EventStory?: InputMaybe<Scalars['String']['input']>;
  EventTitle?: InputMaybe<Scalars['String']['input']>;
  RefId?: InputMaybe<Scalars['Int']['input']>;
};

/** A type representing details about an opponent in the context of school management. */
export type ManageOpponentOld = {
  __typename?: 'ManageOpponentOld';
  /** The name of the athletic director (AD) associated with the school/opponent. */
  adName?: Maybe<Scalars['String']['output']>;
  /** The physical address of the school. */
  address?: Maybe<Scalars['String']['output']>;
  /** The city where the school is located. */
  city?: Maybe<Scalars['String']['output']>;
  /** The primary contact email address for the school. */
  email?: Maybe<Scalars['String']['output']>;
  /** The fax number for the school, if available. */
  fax?: Maybe<Scalars['String']['output']>;
  /** A flag indicating if the opponent's record has been marked as deleted. */
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  /** The National Center for Education Statistics (NCES) identifier for the school. */
  ncesId?: Maybe<Scalars['String']['output']>;
  /** A flag indicating if the entity is not an actual school. */
  nonSchool?: Maybe<Scalars['Boolean']['output']>;
  /** The primary contact phone number for the school. */
  phone?: Maybe<Scalars['String']['output']>;
  /** The unique identifier for the school. This is mandatory. */
  schoolCode?: Maybe<Scalars['String']['output']>;
  /** The official name of the school. */
  schoolName?: Maybe<Scalars['String']['output']>;
  /** The state or province where the school is located. */
  state?: Maybe<Scalars['String']['output']>;
  /** The webFolder of the opponent */
  webFolder?: Maybe<Scalars['String']['output']>;
  /** The postal code for the school's address. */
  zip?: Maybe<Scalars['String']['output']>;
};

export type ManageOrganizationListOld = {
  __typename?: 'ManageOrganizationListOld';
  /** Total count of organizations that match the criteria. */
  count?: Maybe<Scalars['Int']['output']>;
  /** The list of organizations. */
  list?: Maybe<Array<Maybe<ManageOrganizationOld>>>;
  /** Number of organizations skipped. */
  skip?: Maybe<Scalars['Int']['output']>;
  /** Number of organizations fetched. */
  take?: Maybe<Scalars['Int']['output']>;
};

/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOld = {
  __typename?: 'ManageOrganizationOld';
  /** The ad of the school */
  ad?: Maybe<Scalars['String']['output']>;
  /** AD Program ID */
  adProgramID?: Maybe<Scalars['Int']['output']>;
  /** Ad report */
  adReport?: Maybe<Scalars['Int']['output']>;
  /** The ad school of the school */
  adSchool?: Maybe<Scalars['Int']['output']>;
  /** The address of the school */
  address?: Maybe<Scalars['String']['output']>;
  /** Announcements for the organization */
  announcementsForOrganizationOld?: Maybe<ManageAnnouncementListOld>;
  /** AOTM Spotlight */
  aotmSpotlight?: Maybe<Scalars['Int']['output']>;
  /** Auto Approve Score Only */
  autoApproveScoreOnly?: Maybe<Scalars['Int']['output']>;
  /** Camp Registration Confirmation Text */
  campRegConfirmationTxt?: Maybe<Scalars['String']['output']>;
  /** The city of the school */
  city?: Maybe<Scalars['String']['output']>;
  /** The list of coaches for this organization */
  coachForOrganizationOld?: Maybe<ManageCoachListOld>;
  /** The First Color of the school */
  color1?: Maybe<Scalars['String']['output']>;
  /** The Second Color of the school */
  color2?: Maybe<Scalars['String']['output']>;
  /** Conference Name */
  conferenceName?: Maybe<Scalars['String']['output']>;
  /** Conference URL */
  conferenceURL?: Maybe<Scalars['String']['output']>;
  /** Custom Label */
  customLabel?: Maybe<Scalars['String']['output']>;
  /** Custom Label 2 */
  customLabel2?: Maybe<Scalars['String']['output']>;
  /** Custom Link */
  customLink?: Maybe<Scalars['String']['output']>;
  /** Custom Link 2 */
  customLink2?: Maybe<Scalars['String']['output']>;
  /** The Database folder of the school */
  dbFolder?: Maybe<Scalars['String']['output']>;
  /** Email Blast Enabled */
  emailBlastEnabled?: Maybe<Scalars['Int']['output']>;
  /** Email Spotlight */
  emailSpotlight?: Maybe<Scalars['Int']['output']>;
  /** Equipment Enabled */
  equipmentEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Event Locations for the organization */
  eventLocationsForOrganizationOld?: Maybe<ManageResourceLinkListOld>;
  eventsForOrganization?: Maybe<ManageEventListOld>;
  /** Facebook Url */
  facebookUrl?: Maybe<Scalars['String']['output']>;
  /** Facilities for the Organization */
  facilitiesForOrganizationOld?: Maybe<ManageFacilityListOld>;
  /** The fax number of the school */
  fax?: Maybe<Scalars['String']['output']>;
  /** The featured of the school */
  featured?: Maybe<Scalars['Int']['output']>;
  /** Feeders Towns for the school */
  feederTowns?: Maybe<Scalars['String']['output']>;
  /** School FuelMyClubLink */
  fuelMyClubLink?: Maybe<Scalars['String']['output']>;
  /** Gallery Spotlight */
  gallerySpotlight?: Maybe<Scalars['Int']['output']>;
  /** Has Activities */
  hasActivities?: Maybe<Scalars['Boolean']['output']>;
  /** Has Access OTM */
  hasAotm?: Maybe<Scalars['Boolean']['output']>;
  /** Has Athletics */
  hasAthletics?: Maybe<Scalars['Boolean']['output']>;
  /** Has Email Blast */
  hasEmailBlast?: Maybe<Scalars['String']['output']>;
  /** Has Facebook Url */
  hasFacebookUrl?: Maybe<Scalars['Boolean']['output']>;
  /** Has Instagram Url */
  hasInstagramUrl?: Maybe<Scalars['Boolean']['output']>;
  /** Has Migrated From Access To Sql Server */
  hasMigratedFromAccessToSqlServer?: Maybe<Scalars['Boolean']['output']>;
  /** Has Registration */
  hasRegistration?: Maybe<Scalars['Boolean']['output']>;
  /** Has Twitter Url */
  hasTwitterUrl?: Maybe<Scalars['Boolean']['output']>;
  /** Headlines for the Organization */
  headlinesForOrganizationOld?: Maybe<ManageHeadlineListOld>;
  /** The icon of the school */
  iCon?: Maybe<Scalars['String']['output']>;
  /** Instagram Url */
  instagramUrl?: Maybe<Scalars['String']['output']>;
  /** League Keeper school id */
  lKschoolId?: Maybe<Scalars['Int']['output']>;
  /** The latitude of the school */
  lat?: Maybe<Scalars['Float']['output']>;
  /** The id of the link to school database */
  linkSchoolId?: Maybe<Scalars['Int']['output']>;
  /** The logo of the school */
  logo?: Maybe<Scalars['String']['output']>;
  /** The longitude of the school */
  long?: Maybe<Scalars['Float']['output']>;
  /** The mascot of the school */
  mascot?: Maybe<Scalars['String']['output']>;
  /** Does the school have email updates */
  massUpdate?: Maybe<Scalars['Int']['output']>;
  /** Master School Id */
  masterSchoolId?: Maybe<Scalars['Int']['output']>;
  /** The message of the school */
  message?: Maybe<Scalars['String']['output']>;
  /** Motto */
  motto?: Maybe<Scalars['String']['output']>;
  /** NCES ID */
  ncesId?: Maybe<Scalars['String']['output']>;
  /** Old School Id */
  oldSchoolId?: Maybe<Scalars['Int']['output']>;
  /** Pages for the organization */
  pagesForOrganizationOld?: Maybe<ManagePagesListOld>;
  /** The url path for the school */
  path?: Maybe<Scalars['String']['output']>;
  /** The phone number of the school */
  phone?: Maybe<Scalars['String']['output']>;
  /** Photos for the Organization */
  photosForOrganizationOld?: Maybe<ManagePhotosListOld>;
  /** The list of players for this organization */
  playersForOrganizationOld?: Maybe<ManagePlayerListOld>;
  /** The principal of the school */
  principal?: Maybe<Scalars['String']['output']>;
  programsForOrganizationOld?: Maybe<ManageProgramListOld>;
  /** PS School Id */
  psSchoolId?: Maybe<Scalars['String']['output']>;
  /** PS Store Active */
  psStoreActive?: Maybe<Scalars['Int']['output']>;
  /** The Registration Email of the school */
  registrationEmail?: Maybe<Scalars['String']['output']>;
  /** The Registration Enabled of the school */
  registrationEnabled?: Maybe<Scalars['Int']['output']>;
  /** The Registration Policy of the school */
  registrationPolicy?: Maybe<Scalars['String']['output']>;
  /** The Registration Type of the school */
  registrationType?: Maybe<Scalars['Int']['output']>;
  /** Registration Url */
  registrationUrl?: Maybe<Scalars['String']['output']>;
  /** The id of the school */
  schoolId?: Maybe<Scalars['Int']['output']>;
  /** The name of the school */
  schoolName?: Maybe<Scalars['String']['output']>;
  /** School Website Url */
  schoolWebsiteUrl?: Maybe<Scalars['String']['output']>;
  scoresForOrganization?: Maybe<ManageEventListOld>;
  /** The show of the school */
  show?: Maybe<Scalars['Int']['output']>;
  /** Show Ad */
  showAd?: Maybe<Scalars['Boolean']['output']>;
  /** Show School Name And Motto */
  showSchoolNameAndMotto?: Maybe<Scalars['Boolean']['output']>;
  /** Do you want to show the teams pages first on the site */
  showTeamPagesFirst?: Maybe<Scalars['Int']['output']>;
  /** A Sign up code for the school */
  signUpCode?: Maybe<Scalars['String']['output']>;
  /** The site type of the school */
  siteType?: Maybe<Scalars['String']['output']>;
  /** Sport Registration Confirmation Text */
  sportRegConfirmationTxt?: Maybe<Scalars['String']['output']>;
  /** The id of the sql database */
  sqlBase?: Maybe<Scalars['Int']['output']>;
  /** Staff Calendar Enabled */
  staffCalendarEnabled?: Maybe<Scalars['Int']['output']>;
  /** The state of the school (abbreviation) */
  state?: Maybe<Scalars['String']['output']>;
  /** The state organization of the school */
  stateOrg?: Maybe<Scalars['String']['output']>;
  /** Time Zone */
  timeZone?: Maybe<Scalars['String']['output']>;
  /** Twitter Url */
  twitterUrl?: Maybe<Scalars['String']['output']>;
  /** Urgent Announcements for the organization */
  urgentAnnouncementsForOrganizationOld?: Maybe<ManageAnnouncementListOld>;
  /** The web folder of the school that you have chosen */
  webFolder?: Maybe<Scalars['String']['output']>;
  /** Web Password */
  webPassword?: Maybe<Scalars['String']['output']>;
  /** The website of the school */
  webSite?: Maybe<Scalars['String']['output']>;
  /** The zip code of the school */
  zip?: Maybe<Scalars['String']['output']>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldAnnouncementsForOrganizationOldArgs = {
  filter?: InputMaybe<ManageAnnouncementFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldCoachForOrganizationOldArgs = {
  filter?: InputMaybe<ManageCoachListFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldEventLocationsForOrganizationOldArgs = {
  filter?: InputMaybe<ManageResourceLinkFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldEventsForOrganizationArgs = {
  filter?: InputMaybe<ManageEventListFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldFacilitiesForOrganizationOldArgs = {
  filter?: InputMaybe<ManageFacilityFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldHeadlinesForOrganizationOldArgs = {
  filter?: InputMaybe<ManageHeadlineFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldPagesForOrganizationOldArgs = {
  filter?: InputMaybe<ManagePagesFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldPhotosForOrganizationOldArgs = {
  filter?: InputMaybe<ManagePhotosFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldPlayersForOrganizationOldArgs = {
  filter?: InputMaybe<ManagePlayerListFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldProgramsForOrganizationOldArgs = {
  filter?: InputMaybe<ManageProgramListFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldScoresForOrganizationArgs = {
  filter?: InputMaybe<ScoreForOrganizationFilterOld>;
};


/** The object which contains all the input data for the manage organization query */
export type ManageOrganizationOldUrgentAnnouncementsForOrganizationOldArgs = {
  filter?: InputMaybe<UrgentAnnouncementFilterOld>;
};

export enum ManageOrganizationOldDistinct {
  Address = 'address',
  City = 'city',
  Name = 'name',
  State = 'state'
}

export type ManageOrganizationOldFilter = {
  distinct?: InputMaybe<Array<InputMaybe<ManageOrganizationOldDistinct>>>;
  /** Defines how the organization list should be ordered. */
  orderBy?: InputMaybe<ManageOrganizationOldOrderBy>;
  /** Number of organizations to skip before starting to fetch the result set. */
  skip?: InputMaybe<Scalars['Int']['input']>;
  /** Limits the number of organizations to fetch from the result set. */
  take?: InputMaybe<Scalars['Int']['input']>;
  /** Conditions to filter the organization list. */
  where?: InputMaybe<ManageOrganizationOldWhere>;
};

/** The object which contains all the input data for the manage organization query without extends */
export type ManageOrganizationOldInterface = {
  /** The ad of the school */
  ad?: Maybe<Scalars['String']['output']>;
  /** AD Program ID */
  adProgramID?: Maybe<Scalars['Int']['output']>;
  /** Ad report */
  adReport?: Maybe<Scalars['Int']['output']>;
  /** The ad school of the school */
  adSchool?: Maybe<Scalars['Int']['output']>;
  /** The address of the school */
  address?: Maybe<Scalars['String']['output']>;
  /** AOTM Spotlight */
  aotmSpotlight?: Maybe<Scalars['Int']['output']>;
  /** Auto Approve Score Only */
  autoApproveScoreOnly?: Maybe<Scalars['Int']['output']>;
  /** Camp Registration Confirmation Text */
  campRegConfirmationTxt?: Maybe<Scalars['String']['output']>;
  /** The city of the school */
  city?: Maybe<Scalars['String']['output']>;
  /** The First Color of the school */
  color1?: Maybe<Scalars['String']['output']>;
  /** The Second Color of the school */
  color2?: Maybe<Scalars['String']['output']>;
  /** Conference Name */
  conferenceName?: Maybe<Scalars['String']['output']>;
  /** Conference URL */
  conferenceURL?: Maybe<Scalars['String']['output']>;
  /** Custom Label */
  customLabel?: Maybe<Scalars['String']['output']>;
  /** Custom Label 2 */
  customLabel2?: Maybe<Scalars['String']['output']>;
  /** Custom Link */
  customLink?: Maybe<Scalars['String']['output']>;
  /** Custom Link 2 */
  customLink2?: Maybe<Scalars['String']['output']>;
  /** The Database folder of the school */
  dbFolder?: Maybe<Scalars['String']['output']>;
  /** Email Blast Enabled */
  emailBlastEnabled?: Maybe<Scalars['Int']['output']>;
  /** Email Spotlight */
  emailSpotlight?: Maybe<Scalars['Int']['output']>;
  /** Equipment Enabled */
  equipmentEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Facebook Url */
  facebookUrl?: Maybe<Scalars['String']['output']>;
  /** The fax number of the school */
  fax?: Maybe<Scalars['String']['output']>;
  /** The featured of the school */
  featured?: Maybe<Scalars['Int']['output']>;
  /** Feeders Towns for the school */
  feederTowns?: Maybe<Scalars['String']['output']>;
  /** School FuelMyClubLink */
  fuelMyClubLink?: Maybe<Scalars['String']['output']>;
  /** Gallery Spotlight */
  gallerySpotlight?: Maybe<Scalars['Int']['output']>;
  /** Has Activities */
  hasActivities?: Maybe<Scalars['Boolean']['output']>;
  /** Has Access OTM */
  hasAotm?: Maybe<Scalars['Boolean']['output']>;
  /** Has Athletics */
  hasAthletics?: Maybe<Scalars['Boolean']['output']>;
  /** Has Email Blast */
  hasEmailBlast?: Maybe<Scalars['String']['output']>;
  /** Has Facebook Url */
  hasFacebookUrl?: Maybe<Scalars['Boolean']['output']>;
  /** Has Instagram Url */
  hasInstagramUrl?: Maybe<Scalars['Boolean']['output']>;
  /** Has Migrated From Access To Sql Server */
  hasMigratedFromAccessToSqlServer?: Maybe<Scalars['Boolean']['output']>;
  /** Has Registration */
  hasRegistration?: Maybe<Scalars['Boolean']['output']>;
  /** Has Twitter Url */
  hasTwitterUrl?: Maybe<Scalars['Boolean']['output']>;
  /** The icon of the school */
  iCon?: Maybe<Scalars['String']['output']>;
  /** Instagram Url */
  instagramUrl?: Maybe<Scalars['String']['output']>;
  /** League Keeper school id */
  lKschoolId?: Maybe<Scalars['Int']['output']>;
  /** The latitude of the school */
  lat?: Maybe<Scalars['Float']['output']>;
  /** The id of the link to school database */
  linkSchoolId?: Maybe<Scalars['Int']['output']>;
  /** The logo of the school */
  logo?: Maybe<Scalars['String']['output']>;
  /** The longitude of the school */
  long?: Maybe<Scalars['Float']['output']>;
  /** The mascot of the school */
  mascot?: Maybe<Scalars['String']['output']>;
  /** Does the school have email updates */
  massUpdate?: Maybe<Scalars['Int']['output']>;
  /** Master School Id */
  masterSchoolId?: Maybe<Scalars['Int']['output']>;
  /** The message of the school */
  message?: Maybe<Scalars['String']['output']>;
  /** Motto */
  motto?: Maybe<Scalars['String']['output']>;
  /** NCES ID */
  ncesId?: Maybe<Scalars['String']['output']>;
  /** Old School Id */
  oldSchoolId?: Maybe<Scalars['Int']['output']>;
  /** The url path for the school */
  path?: Maybe<Scalars['String']['output']>;
  /** The phone number of the school */
  phone?: Maybe<Scalars['String']['output']>;
  /** The principal of the school */
  principal?: Maybe<Scalars['String']['output']>;
  /** PS School Id */
  psSchoolId?: Maybe<Scalars['String']['output']>;
  /** PS Store Active */
  psStoreActive?: Maybe<Scalars['Int']['output']>;
  /** The Registration Email of the school */
  registrationEmail?: Maybe<Scalars['String']['output']>;
  /** The Registration Enabled of the school */
  registrationEnabled?: Maybe<Scalars['Int']['output']>;
  /** The Registration Policy of the school */
  registrationPolicy?: Maybe<Scalars['String']['output']>;
  /** The Registration Type of the school */
  registrationType?: Maybe<Scalars['Int']['output']>;
  /** Registration Url */
  registrationUrl?: Maybe<Scalars['String']['output']>;
  /** The id of the school */
  schoolId?: Maybe<Scalars['Int']['output']>;
  /** The name of the school */
  schoolName?: Maybe<Scalars['String']['output']>;
  /** School Website Url */
  schoolWebsiteUrl?: Maybe<Scalars['String']['output']>;
  /** The show of the school */
  show?: Maybe<Scalars['Int']['output']>;
  /** Show Ad */
  showAd?: Maybe<Scalars['Boolean']['output']>;
  /** Show School Name And Motto */
  showSchoolNameAndMotto?: Maybe<Scalars['Boolean']['output']>;
  /** Do you want to show the teams pages first on the site */
  showTeamPagesFirst?: Maybe<Scalars['Int']['output']>;
  /** A Sign up code for the school */
  signUpCode?: Maybe<Scalars['String']['output']>;
  /** The site type of the school */
  siteType?: Maybe<Scalars['String']['output']>;
  /** Sport Registration Confirmation Text */
  sportRegConfirmationTxt?: Maybe<Scalars['String']['output']>;
  /** The id of the sql database */
  sqlBase?: Maybe<Scalars['Int']['output']>;
  /** Staff Calendar Enabled */
  staffCalendarEnabled?: Maybe<Scalars['Int']['output']>;
  /** The state of the school (abbreviation) */
  state?: Maybe<Scalars['String']['output']>;
  /** The state organization of the school */
  stateOrg?: Maybe<Scalars['String']['output']>;
  /** Time Zone */
  timeZone?: Maybe<Scalars['String']['output']>;
  /** Twitter Url */
  twitterUrl?: Maybe<Scalars['String']['output']>;
  /** The web folder of the school that you have chosen */
  webFolder?: Maybe<Scalars['String']['output']>;
  /** Web Password */
  webPassword?: Maybe<Scalars['String']['output']>;
  /** The website of the school */
  webSite?: Maybe<Scalars['String']['output']>;
  /** The zip code of the school */
  zip?: Maybe<Scalars['String']['output']>;
};

export type ManageOrganizationOldOrderBy = {
  /** Orders organizations based on their name. */
  name?: InputMaybe<SortOrderEnum>;
  /** Orders organizations based on their state. */
  state?: InputMaybe<SortOrderEnum>;
};

export type ManageOrganizationOldWhere = {
  /** The name of the organization. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Condition to determine visibility of the organization (e.g., might represent a specific view mode). */
  show?: InputMaybe<Scalars['Int']['input']>;
  /** The state or province of the organization. */
  state?: InputMaybe<Scalars['String']['input']>;
};

export type ManagePagesFilterOld = {
  orderBy?: InputMaybe<ManagePagesOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManagePagesWhereOld>;
};

export type ManagePagesListOld = {
  __typename?: 'ManagePagesListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManagePagesOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

/** The object which contains all the input data for the ManageResource Pages query */
export type ManagePagesOld = {
  __typename?: 'ManagePagesOld';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pageContent?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type ManagePagesOrderByOld = {
  name?: InputMaybe<SortOrderEnum>;
  pageTitle?: InputMaybe<SortOrderEnum>;
};

export type ManagePagesWhereOld = {
  name?: InputMaybe<PagesNameEnum>;
};

export enum ManagePhotosDistinctOld {
  Group = 'group',
  Sport = 'sport',
  Src = 'src'
}

export type ManagePhotosFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManagePhotosDistinctOld>>>;
  orderBy?: InputMaybe<ManagePhotosOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManagePhotosWhereOld>;
  whereNot?: InputMaybe<ManagePhotosWhereOld>;
};

export type ManagePhotosListOld = {
  __typename?: 'ManagePhotosListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManagePhotosOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

/** The object which contains all the input data for the ManageResource photos query */
export type ManagePhotosOld = {
  __typename?: 'ManagePhotosOld';
  batch?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['Buffer']['output']>;
  layout?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  needsApproval?: Maybe<Scalars['Int']['output']>;
  projectId?: Maybe<Scalars['Int']['output']>;
  recordStatus?: Maybe<Scalars['Int']['output']>;
  rphoto?: Maybe<Scalars['Int']['output']>;
  school?: Maybe<Scalars['Int']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  src?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type ManagePhotosOrderByOld = {
  caption?: InputMaybe<SortOrderEnum>;
  filename?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
  title?: InputMaybe<SortOrderEnum>;
};

export type ManagePhotosWhereOld = {
  group?: InputMaybe<Scalars['String']['input']>;
  image1?: InputMaybe<Scalars['Buffer']['input']>;
  needsApproval?: InputMaybe<Scalars['Int']['input']>;
  rphoto?: InputMaybe<Scalars['Int']['input']>;
  sport?: InputMaybe<Scalars['String']['input']>;
};

export enum ManagePlayerDistinctOld {
  City = 'city',
  FName = 'fName',
  Gender = 'gender',
  GradYear = 'gradYear',
  Height = 'height',
  Jersey = 'jersey',
  LName = 'lName',
  Position = 'position',
  SchoolId = 'schoolId',
  SeasonId = 'seasonId',
  State = 'state',
  Status = 'status',
  Weight = 'weight',
  Zip = 'zip'
}

export type ManagePlayerListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManagePlayerDistinctOld>>>;
  orderBy?: InputMaybe<ManagePlayerListOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManagePlayerListWhereOld>;
};

export type ManagePlayerListOld = {
  __typename?: 'ManagePlayerListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManagePlayerOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

export type ManagePlayerListOrderByOld = {
  city?: InputMaybe<SortOrderEnum>;
  dob?: InputMaybe<SortOrderEnum>;
  fName?: InputMaybe<SortOrderEnum>;
  gender?: InputMaybe<SortOrderEnum>;
  gradYear?: InputMaybe<SortOrderEnum>;
  height?: InputMaybe<SortOrderEnum>;
  jersey?: InputMaybe<SortOrderEnum>;
  lName?: InputMaybe<SortOrderEnum>;
  participantId?: InputMaybe<SortOrderEnum>;
  permission?: InputMaybe<SortOrderEnum>;
  position?: InputMaybe<SortOrderEnum>;
  rosterId?: InputMaybe<SortOrderEnum>;
  schoolId?: InputMaybe<SortOrderEnum>;
  seasonId?: InputMaybe<SortOrderEnum>;
  state?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  weight?: InputMaybe<SortOrderEnum>;
  zip?: InputMaybe<SortOrderEnum>;
};

export type ManagePlayerListWhereOld = {
  ch1?: InputMaybe<Scalars['Int']['input']>;
  ch2?: InputMaybe<Scalars['Int']['input']>;
  ch3?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  custodyCode?: InputMaybe<Scalars['Int']['input']>;
  dayPhone?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['Date']['input']>;
  fName?: InputMaybe<Scalars['String']['input']>;
  feePaid?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  gradYear?: InputMaybe<Scalars['String']['input']>;
  hatsize?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['String']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  hospitalPhone?: InputMaybe<Scalars['String']['input']>;
  insuranceCompany?: InputMaybe<Scalars['String']['input']>;
  insurancePolicyNum?: InputMaybe<Scalars['String']['input']>;
  jersey?: InputMaybe<Scalars['String']['input']>;
  lName?: InputMaybe<Scalars['String']['input']>;
  livesWithCode?: InputMaybe<Scalars['Int']['input']>;
  noPress?: InputMaybe<Scalars['Int']['input']>;
  participantId?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<Scalars['Int']['input']>;
  physical?: InputMaybe<Scalars['Int']['input']>;
  physicalDate?: InputMaybe<Scalars['Date']['input']>;
  physician?: InputMaybe<Scalars['String']['input']>;
  physicianTelephone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  preferredHospital?: InputMaybe<Scalars['String']['input']>;
  rosterId?: InputMaybe<Scalars['Int']['input']>;
  schoolId?: InputMaybe<Scalars['Int']['input']>;
  seasonId?: InputMaybe<Scalars['Int']['input']>;
  shirtsize?: InputMaybe<Scalars['String']['input']>;
  shortsize?: InputMaybe<Scalars['String']['input']>;
  stAddress?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** The object which contains all the input data for each player */
export type ManagePlayerOld = {
  __typename?: 'ManagePlayerOld';
  ch1?: Maybe<Scalars['Int']['output']>;
  ch2?: Maybe<Scalars['Int']['output']>;
  ch3?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  custodyCode?: Maybe<Scalars['Int']['output']>;
  dayPhone?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['Date']['output']>;
  fName?: Maybe<Scalars['String']['output']>;
  feePaid?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  gradYear?: Maybe<Scalars['String']['output']>;
  hatsize?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  homePhone?: Maybe<Scalars['String']['output']>;
  hospitalPhone?: Maybe<Scalars['String']['output']>;
  insuranceCompany?: Maybe<Scalars['String']['output']>;
  insurancePolicyNum?: Maybe<Scalars['String']['output']>;
  jersey?: Maybe<Scalars['String']['output']>;
  lName?: Maybe<Scalars['String']['output']>;
  livesWithCode?: Maybe<Scalars['Int']['output']>;
  noPress?: Maybe<Scalars['Int']['output']>;
  participantId?: Maybe<Scalars['Int']['output']>;
  permission?: Maybe<Scalars['Int']['output']>;
  physical?: Maybe<Scalars['Int']['output']>;
  physicalDate?: Maybe<Scalars['Date']['output']>;
  physician?: Maybe<Scalars['String']['output']>;
  physicianTelephone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  preferredHospital?: Maybe<Scalars['String']['output']>;
  rosterId?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  seasonId?: Maybe<Scalars['Int']['output']>;
  shirtsize?: Maybe<Scalars['String']['output']>;
  shortsize?: Maybe<Scalars['String']['output']>;
  sortJersey?: Maybe<Scalars['Int']['output']>;
  stAddress?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  studentId?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum ManageProgramListDistinctOld {
  Gender = 'gender',
  GroupVal = 'groupVal',
  HomeField = 'homeField',
  Id = 'id',
  Level1 = 'level1',
  SportCode = 'sportCode',
  SportDescription = 'sportDescription',
  SportName = 'sportName'
}

export type ManageProgramListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageProgramListDistinctOld>>>;
  orderBy?: InputMaybe<ManageProgramListOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManageProgramListWhereOld>;
  whereNot?: InputMaybe<ManageProgramListWhereNotOld>;
};

export type ManageProgramListOld = {
  __typename?: 'ManageProgramListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManageProgramOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

export type ManageProgramListOrderByOld = {
  gender?: InputMaybe<SortOrderEnum>;
  level1?: InputMaybe<SortOrderEnum>;
  sportName?: InputMaybe<SortOrderEnum>;
};

export type ManageProgramListWhereNotOld = {
  gender?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groupVal?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  level1?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sportName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ManageProgramListWhereOld = {
  gender?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groupVal?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  level1?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sportName?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ManageProgramOld = {
  __typename?: 'ManageProgramOld';
  /** Announcements for the program */
  announcementsForProgramsOld?: Maybe<ManageAnnouncementListOld>;
  gender?: Maybe<Scalars['String']['output']>;
  genderSegment?: Maybe<Scalars['String']['output']>;
  groupVal?: Maybe<Scalars['String']['output']>;
  homeField?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  level1?: Maybe<Scalars['String']['output']>;
  levelName?: Maybe<Scalars['String']['output']>;
  levelSegment?: Maybe<Scalars['String']['output']>;
  /** Photos for the program */
  photosForProgramsOld?: Maybe<ManagePhotosListOld>;
  /** Links for the program */
  resourceLinksForProgramsOld?: Maybe<ManageResourceLinkListOld>;
  seasonsForProgramOld?: Maybe<ManageSeasonListOld>;
  sportCode?: Maybe<Scalars['String']['output']>;
  sportDescription?: Maybe<Scalars['String']['output']>;
  sportDetailsForProgramOld?: Maybe<ManageSportDetailsListOld>;
  sportName?: Maybe<Scalars['String']['output']>;
  sportSegment?: Maybe<Scalars['String']['output']>;
  /** Team Stats for the program */
  teamStatsForProgramsOld?: Maybe<ManageTeamStatsListOld>;
  yearsForProgramOld?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};


export type ManageProgramOldAnnouncementsForProgramsOldArgs = {
  filter?: InputMaybe<ManageAnnouncementFilterOld>;
};


export type ManageProgramOldPhotosForProgramsOldArgs = {
  filter?: InputMaybe<ManagePhotosFilterOld>;
};


export type ManageProgramOldResourceLinksForProgramsOldArgs = {
  filter?: InputMaybe<ManageResourceLinkFilterOld>;
};


export type ManageProgramOldSeasonsForProgramOldArgs = {
  filter?: InputMaybe<ManageSeasonListFilterOld>;
};


export type ManageProgramOldSportDetailsForProgramOldArgs = {
  filter?: InputMaybe<ManageSportDetailsListFilterOld>;
};


export type ManageProgramOldTeamStatsForProgramsOldArgs = {
  filter?: InputMaybe<ManageTeamStatsFilterOld>;
};

export type ManageResourceLinkFilterOld = {
  orderBy?: InputMaybe<ManageResourceLinkOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManageResourceLinkWhereOld>;
  whereNot?: InputMaybe<ManageResourceLinkWhereOld>;
};

export type ManageResourceLinkListOld = {
  __typename?: 'ManageResourceLinkListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManageResourceLinkOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

/** The object which contains all the input data for the ManageResource event location query */
export type ManageResourceLinkOld = {
  __typename?: 'ManageResourceLinkOld';
  /** The fileName for the event location */
  fileName?: Maybe<Scalars['String']['output']>;
  /** The filename link for the event location */
  fileNameLink?: Maybe<Scalars['String']['output']>;
  /** The sport gender and level for the event location */
  gVal?: Maybe<Scalars['String']['output']>;
  /** The id for the event location */
  id?: Maybe<Scalars['Int']['output']>;
  /** The isApproved for the event location */
  isApproved?: Maybe<Scalars['Int']['output']>;
  /** The type for the event location */
  lType?: Maybe<Scalars['String']['output']>;
  /** The link for the event location */
  link?: Maybe<Scalars['String']['output']>;
  /** The name for the event location */
  name?: Maybe<Scalars['String']['output']>;
  /** The onFornt for the event location */
  onFront?: Maybe<Scalars['Int']['output']>;
  /** The recordStatus for the event location */
  recordStatus?: Maybe<Scalars['Int']['output']>;
  /** The school id for the event location */
  schoolNum?: Maybe<Scalars['Int']['output']>;
  /** The sortOrder for the event location */
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type ManageResourceLinkOrderByOld = {
  fileName?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
};

export type ManageResourceLinkWhereOld = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  isApproved?: InputMaybe<Scalars['Int']['input']>;
  lType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onFront?: InputMaybe<Scalars['Int']['input']>;
};

export enum ManageRosterDistinctOld {
  City = 'city',
  FName = 'fName',
  Gender = 'gender',
  GradYear = 'gradYear',
  Height = 'height',
  Jersey = 'jersey',
  LName = 'lName',
  Position = 'position',
  SchoolId = 'schoolId',
  SeasonId = 'seasonId',
  State = 'state',
  Status = 'status',
  Weight = 'weight',
  Zip = 'zip'
}

export type ManageRosterListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageRosterDistinctOld>>>;
  orderBy?: InputMaybe<ManageRosterListOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManageRosterListWhereOld>;
};

export type ManageRosterListOld = {
  __typename?: 'ManageRosterListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManageRosterOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

export type ManageRosterListOrderByOld = {
  city?: InputMaybe<SortOrderEnum>;
  dob?: InputMaybe<SortOrderEnum>;
  fName?: InputMaybe<SortOrderEnum>;
  gender?: InputMaybe<SortOrderEnum>;
  gradYear?: InputMaybe<SortOrderEnum>;
  height?: InputMaybe<SortOrderEnum>;
  jersey?: InputMaybe<SortOrderEnum>;
  lName?: InputMaybe<SortOrderEnum>;
  participantId?: InputMaybe<SortOrderEnum>;
  permission?: InputMaybe<SortOrderEnum>;
  position?: InputMaybe<SortOrderEnum>;
  rosterId?: InputMaybe<SortOrderEnum>;
  schoolId?: InputMaybe<SortOrderEnum>;
  seasonId?: InputMaybe<SortOrderEnum>;
  state?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  weight?: InputMaybe<SortOrderEnum>;
  zip?: InputMaybe<SortOrderEnum>;
};

export type ManageRosterListWhereOld = {
  ch1?: InputMaybe<Scalars['Int']['input']>;
  ch2?: InputMaybe<Scalars['Int']['input']>;
  ch3?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  custodyCode?: InputMaybe<Scalars['Int']['input']>;
  dayPhone?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['Date']['input']>;
  fName?: InputMaybe<Scalars['String']['input']>;
  feePaid?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  gradYear?: InputMaybe<Scalars['String']['input']>;
  hatsize?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['String']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  hospitalPhone?: InputMaybe<Scalars['String']['input']>;
  insuranceCompany?: InputMaybe<Scalars['String']['input']>;
  insurancePolicyNum?: InputMaybe<Scalars['String']['input']>;
  jersey?: InputMaybe<Scalars['String']['input']>;
  lName?: InputMaybe<Scalars['String']['input']>;
  livesWithCode?: InputMaybe<Scalars['Int']['input']>;
  noPress?: InputMaybe<Scalars['Int']['input']>;
  participantId?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<Scalars['Int']['input']>;
  physical?: InputMaybe<Scalars['Int']['input']>;
  physicalDate?: InputMaybe<Scalars['Date']['input']>;
  physician?: InputMaybe<Scalars['String']['input']>;
  physicianTelephone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  preferredHospital?: InputMaybe<Scalars['String']['input']>;
  rosterId?: InputMaybe<Scalars['Int']['input']>;
  schoolId?: InputMaybe<Scalars['Int']['input']>;
  seasonId?: InputMaybe<Scalars['Int']['input']>;
  shirtsize?: InputMaybe<Scalars['String']['input']>;
  shortsize?: InputMaybe<Scalars['String']['input']>;
  stAddress?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** The object which contains all the input data for each player */
export type ManageRosterOld = {
  __typename?: 'ManageRosterOld';
  ch1?: Maybe<Scalars['Int']['output']>;
  ch2?: Maybe<Scalars['Int']['output']>;
  ch3?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  custodyCode?: Maybe<Scalars['Int']['output']>;
  dayPhone?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['Date']['output']>;
  fName?: Maybe<Scalars['String']['output']>;
  feePaid?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  gradYear?: Maybe<Scalars['String']['output']>;
  hatsize?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  homePhone?: Maybe<Scalars['String']['output']>;
  hospitalPhone?: Maybe<Scalars['String']['output']>;
  insuranceCompany?: Maybe<Scalars['String']['output']>;
  insurancePolicyNum?: Maybe<Scalars['String']['output']>;
  jersey?: Maybe<Scalars['String']['output']>;
  lName?: Maybe<Scalars['String']['output']>;
  livesWithCode?: Maybe<Scalars['Int']['output']>;
  noPress?: Maybe<Scalars['Int']['output']>;
  participantId?: Maybe<Scalars['Int']['output']>;
  permission?: Maybe<Scalars['Int']['output']>;
  physical?: Maybe<Scalars['Int']['output']>;
  physicalDate?: Maybe<Scalars['Date']['output']>;
  physician?: Maybe<Scalars['String']['output']>;
  physicianTelephone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  preferredHospital?: Maybe<Scalars['String']['output']>;
  rosterId?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  seasonId?: Maybe<Scalars['Int']['output']>;
  shirtsize?: Maybe<Scalars['String']['output']>;
  shortsize?: Maybe<Scalars['String']['output']>;
  sortJersey?: Maybe<Scalars['Int']['output']>;
  stAddress?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  studentId?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum ManageSeasonListDistinctOld {
  Budget = 'budget',
  DefaultTimeForEvent = 'defaultTimeForEvent',
  DefaultTimeForPractice = 'defaultTimeForPractice',
  HomeField = 'homeField',
  IsDeleted = 'isDeleted',
  Preview = 'preview',
  Season = 'season',
  SeasonId = 'seasonId',
  SportCode = 'sportCode',
  WebPassword = 'webPassword',
  Year = 'year'
}

export type ManageSeasonListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageSeasonListDistinctOld>>>;
  orderBy?: InputMaybe<ManageSeasonListOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManageSeasonListWhereOld>;
};

export type ManageSeasonListOld = {
  __typename?: 'ManageSeasonListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManageSeasonOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

export type ManageSeasonListOrderByOld = {
  sport_code?: InputMaybe<SortOrderEnum>;
  year?: InputMaybe<SortOrderEnum>;
};

export type ManageSeasonListWhereOld = {
  sport_code?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type ManageSeasonOld = {
  __typename?: 'ManageSeasonOld';
  budget?: Maybe<Scalars['Float']['output']>;
  coachForProgramSeasonOld?: Maybe<ManageCoachListOld>;
  defaultTimeForEvent?: Maybe<Scalars['String']['output']>;
  defaultTimeForPractice?: Maybe<Scalars['String']['output']>;
  /** The events for the season. */
  eventsForSeason?: Maybe<ManageEventListOld>;
  homeField?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  playersForProgramSeasonOld?: Maybe<ManagePlayerListOld>;
  preview?: Maybe<Scalars['String']['output']>;
  rosterForProgramSeasonOld?: Maybe<ManageRosterListOld>;
  season?: Maybe<Scalars['String']['output']>;
  seasonId?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  webPassword?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};


export type ManageSeasonOldCoachForProgramSeasonOldArgs = {
  filter?: InputMaybe<ManageCoachListFilterOld>;
};


export type ManageSeasonOldEventsForSeasonArgs = {
  filter?: InputMaybe<ManageEventListFilterOld>;
};


export type ManageSeasonOldPlayersForProgramSeasonOldArgs = {
  filter?: InputMaybe<ManagePlayerListFilterOld>;
};

export enum ManageSportDetailsListDistinctOld {
  DefaultToSchedulePage = 'defaultToSchedulePage',
  Gender = 'gender',
  GroupVal = 'groupVal',
  HomeField = 'homeField',
  IntroHtml = 'introHtml',
  IntroImageId = 'introImageID',
  Level1 = 'level1',
  NeedsApproval = 'needsApproval',
  RecordStatus = 'recordStatus',
  SchoolId = 'schoolId',
  SiteSchoolSport = 'siteSchoolSport',
  SportCode = 'sportCode',
  SportDescription = 'sportDescription',
  SportName = 'sportName',
  SportStatus = 'sportStatus'
}

export type ManageSportDetailsListFilterOld = {
  distinct?: InputMaybe<Array<InputMaybe<ManageSportDetailsListDistinctOld>>>;
  orderBy?: InputMaybe<ManageSportDetailsListOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManageSportDetailsListWhereOld>;
};

export type ManageSportDetailsListOld = {
  __typename?: 'ManageSportDetailsListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManageSportDetailsOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

export type ManageSportDetailsListOrderByOld = {
  createdAt?: InputMaybe<SortOrderEnum>;
  createdBy?: InputMaybe<SortOrderEnum>;
  defaultToSchedulePage?: InputMaybe<SortOrderEnum>;
  gender?: InputMaybe<SortOrderEnum>;
  groupVal?: InputMaybe<SortOrderEnum>;
  homeField?: InputMaybe<SortOrderEnum>;
  introHtml?: InputMaybe<SortOrderEnum>;
  introImageID?: InputMaybe<SortOrderEnum>;
  level1?: InputMaybe<SortOrderEnum>;
  needsApproval?: InputMaybe<SortOrderEnum>;
  recordStatus?: InputMaybe<SortOrderEnum>;
  schoolId?: InputMaybe<SortOrderEnum>;
  siteSchoolSport?: InputMaybe<SortOrderEnum>;
  sportCode?: InputMaybe<SortOrderEnum>;
  sportDescription?: InputMaybe<SortOrderEnum>;
  sportName?: InputMaybe<SortOrderEnum>;
  sportStatus?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
  updatedBy?: InputMaybe<SortOrderEnum>;
};

export type ManageSportDetailsListWhereOld = {
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  createdBy?: InputMaybe<Scalars['Int']['input']>;
  defaultToSchedulePage?: InputMaybe<Scalars['Int']['input']>;
  introHtml?: InputMaybe<Scalars['String']['input']>;
  introImageID?: InputMaybe<Scalars['Int']['input']>;
  needsApproval?: InputMaybe<Scalars['Int']['input']>;
  recordStatus?: InputMaybe<Scalars['Int']['input']>;
  siteSchoolSport?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  updatedBy?: InputMaybe<Scalars['Int']['input']>;
};

export type ManageSportDetailsOld = {
  __typename?: 'ManageSportDetailsOld';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  defaultToSchedulePage?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  groupVal?: Maybe<Scalars['String']['output']>;
  homeField?: Maybe<Scalars['String']['output']>;
  introHtml?: Maybe<Scalars['String']['output']>;
  introImageID?: Maybe<Scalars['Int']['output']>;
  level1?: Maybe<Scalars['String']['output']>;
  needsApproval?: Maybe<Scalars['Int']['output']>;
  recordStatus?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  siteSchoolSport?: Maybe<Scalars['Int']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  sportDescription?: Maybe<Scalars['String']['output']>;
  sportName?: Maybe<Scalars['String']['output']>;
  sportStatus?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type ManageTeamStatsFilterOld = {
  orderBy?: InputMaybe<ManageTeamStatsOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManageTeamStatsWhereOld>;
};

export type ManageTeamStatsListOld = {
  __typename?: 'ManageTeamStatsListOld';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<ManageTeamStatsOld>>>;
  skip?: Maybe<Scalars['Int']['output']>;
  take?: Maybe<Scalars['Int']['output']>;
};

/** The object which contains all the input data for the ManageTeamStats query */
export type ManageTeamStatsOld = {
  __typename?: 'ManageTeamStatsOld';
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isApproved?: Maybe<Scalars['String']['output']>;
  pageHtml?: Maybe<Scalars['String']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type ManageTeamStatsOrderByOld = {
  pageHtml?: InputMaybe<SortOrderEnum>;
  sport?: InputMaybe<SortOrderEnum>;
};

export type ManageTeamStatsWhereOld = {
  pageHtml?: InputMaybe<Scalars['String']['input']>;
  sport?: InputMaybe<Scalars['String']['input']>;
};

export type ManageUser = {
  __typename?: 'ManageUser';
  contracts?: Maybe<Scalars['String']['output']>;
  events?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id?: Maybe<Scalars['Int']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  is_super_user?: Maybe<Scalars['String']['output']>;
  maintenance?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  seasons?: Maybe<Scalars['String']['output']>;
  teams?: Maybe<Scalars['String']['output']>;
  user_email?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  user_level?: Maybe<Scalars['String']['output']>;
};

export type ManageUserCreateInput = {
  contracts: Scalars['String']['input'];
  events: Scalars['String']['input'];
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  maintenance: Scalars['String']['input'];
  password: Scalars['String']['input'];
  seasons: Scalars['String']['input'];
  teams: Scalars['String']['input'];
  user_email: Scalars['String']['input'];
  user_id: Scalars['String']['input'];
  user_level: Scalars['String']['input'];
};

export type ManageUserDeleteInput = {
  id: Scalars['Int']['input'];
};

export type ManageUserInput = {
  id: Scalars['Int']['input'];
};

export type ManageUserUpdateInput = {
  contracts?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  maintenance?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  seasons?: InputMaybe<Scalars['String']['input']>;
  teams?: InputMaybe<Scalars['String']['input']>;
  user_email?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  user_level?: InputMaybe<Scalars['String']['input']>;
};

export type ManageUsersList = {
  __typename?: 'ManageUsersList';
  items?: Maybe<Array<Maybe<ManageUser>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  totalFilteredItems?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
  totalRows?: Maybe<Scalars['Int']['output']>;
};

export type ManageUsersListOptions = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MarcoEventInput = {
  address?: InputMaybe<MarcoShippingAddress>;
  created_at?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  purchase_order: Scalars['String']['input'];
  received_at?: InputMaybe<Scalars['String']['input']>;
  shipments?: InputMaybe<Array<InputMaybe<MarcoShipment>>>;
  shipped_at?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MarcoOrderStatus>;
};

export enum MarcoItemWorkflowState {
  Canceled = 'canceled',
  Completed = 'completed',
  IntakeHold = 'intake_hold',
  Open = 'open',
  ProductionHold = 'production_hold',
  Shipped = 'shipped'
}

export type MarcoOrderItem = {
  client_item_num?: InputMaybe<Scalars['String']['input']>;
  customer_sku?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  sku_id?: InputMaybe<Scalars['Int']['input']>;
  workflow_state?: InputMaybe<MarcoItemWorkflowState>;
};

export enum MarcoOrderStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  IntakeHold = 'intake_hold',
  Open = 'open',
  ProductionHold = 'production_hold',
  Received = 'received',
  Shipped = 'shipped'
}

export type MarcoOrderStatusNote = {
  created_at: Scalars['String']['input'];
  note: Scalars['String']['input'];
};

export type MarcoServiceRequest = {
  created_at?: InputMaybe<Scalars['String']['input']>;
  items_count?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  workflow_state?: InputMaybe<MacroServiceRequestWorkflowState>;
};

export type MarcoShipment = {
  delivered?: InputMaybe<Scalars['Boolean']['input']>;
  search_link?: InputMaybe<Scalars['String']['input']>;
  shipping_carrier?: InputMaybe<ShippingProvider>;
  shipping_method?: InputMaybe<Scalars['String']['input']>;
  tracking_number?: InputMaybe<Scalars['String']['input']>;
};

export type MarcoShippingAddress = {
  ship_to_address?: InputMaybe<Scalars['String']['input']>;
  ship_to_address_2?: InputMaybe<Scalars['String']['input']>;
  ship_to_city?: InputMaybe<Scalars['String']['input']>;
  ship_to_company_name?: InputMaybe<Scalars['String']['input']>;
  ship_to_country?: InputMaybe<Scalars['String']['input']>;
  ship_to_email?: InputMaybe<Scalars['String']['input']>;
  ship_to_first_name?: InputMaybe<Scalars['String']['input']>;
  ship_to_last_name?: InputMaybe<Scalars['String']['input']>;
  ship_to_state?: InputMaybe<Scalars['String']['input']>;
  ship_to_telephone?: InputMaybe<Scalars['String']['input']>;
  ship_to_zip_code?: InputMaybe<Scalars['String']['input']>;
};

export type MarcoWebhookResponse = {
  __typename?: 'MarcoWebhookResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum MatchStyle {
  Exact = 'EXACT',
  Fuzzy = 'FUZZY',
  Predictive = 'PREDICTIVE'
}

export type MessageContact = {
  attributes?: InputMaybe<Scalars['JSON']['input']>;
  to: Scalars['String']['input'];
};

export type MessageContactDetailResponse = {
  __typename?: 'MessageContactDetailResponse';
  createdAt: Scalars['Date']['output'];
  status: ActivityStatus;
};

export type MessageContactResponse = {
  __typename?: 'MessageContactResponse';
  activity: Array<MessageContactDetailResponse>;
  attributes: Scalars['JSON']['output'];
  id: Scalars['String']['output'];
  to: Scalars['String']['output'];
};

export enum MessageTransportType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export enum MessageType {
  Promotional = 'PROMOTIONAL',
  Transactional = 'TRANSACTIONAL'
}

export type MessagesChannelInviteAcceptResult = Channel | ChannelInviteAcceptError | InviteNotFound | NotAuthenticated;

export type MessagesChannelInviteMembersResult = ChannelInviteMembers | ChannelInviteMembersError | ChannelNotFound | NotAuthenticated | NotAuthorized;

export type MessagesChannelInviteResendResult = ChannelInvite | ChannelInviteResendError | ChannelNotFound | NotAuthenticated | NotAuthorized;

export type MessagesChannelInvitesResult = ChannelInvites | ChannelNotFound | NotAuthenticated | NotAuthorized;

export type MessagesChannelMembersResult = ChannelMembers | ChannelNotFound | NotAuthenticated;

export type MessagesChannelOrgResult = ChannelNotFound | ChannelOrg | ChannelOrgError;

export type MessagesChannelRemoveMembersResult = ChannelMembers | ChannelNotFound | ChannelRemoveMembersError | NotAuthenticated | NotAuthorized;

export type MessagesChannelsFilterInput = {
  orgId: Scalars['String']['input'];
};

export type MessagesMyChannelsResult = MyChannels | NotAuthenticated;

export type MessagesMyModeratedChannelsResult = MyModeratedChannels | NotAuthenticated;

export type MessagesUpsertUsersResult = ChannelUpsertUsersError | MessagesUsers | NotAuthorized;

export type MessagesUser = {
  __typename?: 'MessagesUser';
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MessagesUsers = {
  __typename?: 'MessagesUsers';
  users: Array<MessagesUser>;
};

export type MissingArguments = Error & {
  __typename?: 'MissingArguments';
  message: Scalars['String']['output'];
};

export enum ModType {
  Affiliate = 'AFFILIATE',
  Business = 'BUSINESS',
  Club = 'CLUB',
  District = 'DISTRICT',
  Individual = 'INDIVIDUAL',
  Other = 'OTHER',
  School = 'SCHOOL',
  Team = 'TEAM'
}

export type ModifyDeleteCount = {
  __typename?: 'ModifyDeleteCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export type ModifyEventInput = {
  activity?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['String']['input']>;
  bus_fee?: InputMaybe<Scalars['Float']['input']>;
  bus_time?: InputMaybe<Scalars['String']['input']>;
  cancellation_status?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  conference?: InputMaybe<Scalars['String']['input']>;
  conference_event_id?: InputMaybe<Scalars['Int']['input']>;
  conference_id?: InputMaybe<Scalars['Int']['input']>;
  confirmed?: InputMaybe<Scalars['String']['input']>;
  contract?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['Date']['input']>;
  departure_location?: InputMaybe<Scalars['String']['input']>;
  directions?: InputMaybe<Scalars['String']['input']>;
  early_dismissal_required?: InputMaybe<Scalars['String']['input']>;
  early_dismissal_time?: InputMaybe<Scalars['String']['input']>;
  end_time?: InputMaybe<Scalars['String']['input']>;
  estimated_return_time?: InputMaybe<Scalars['String']['input']>;
  event_date?: InputMaybe<Scalars['Date']['input']>;
  event_id: Scalars['Int']['input'];
  exists_in_mls?: InputMaybe<Scalars['Int']['input']>;
  fee?: InputMaybe<Scalars['Float']['input']>;
  g_s?: InputMaybe<Scalars['String']['input']>;
  gate_revenue?: InputMaybe<Scalars['Float']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  impact_event?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  loss_points?: InputMaybe<Scalars['Int']['input']>;
  num_buses?: InputMaybe<Scalars['Int']['input']>;
  opponent?: InputMaybe<Scalars['String']['input']>;
  opponent_code?: InputMaybe<Scalars['String']['input']>;
  opponent_score?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  prep_setup?: InputMaybe<Scalars['String']['input']>;
  promote?: InputMaybe<Scalars['String']['input']>;
  results?: InputMaybe<Scalars['String']['input']>;
  revenue?: InputMaybe<Scalars['Float']['input']>;
  rollover?: InputMaybe<Scalars['String']['input']>;
  season_team?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['String']['input']>;
  team_score?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  tournament?: InputMaybe<Scalars['String']['input']>;
  trans_id?: InputMaybe<Scalars['Int']['input']>;
  transport_comments?: InputMaybe<Scalars['String']['input']>;
  transportation?: InputMaybe<Scalars['String']['input']>;
  update_at?: InputMaybe<Scalars['Date']['input']>;
  web_dir?: InputMaybe<Scalars['String']['input']>;
  win_points?: InputMaybe<Scalars['Int']['input']>;
  years?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyEventParticipantsInput = {
  contract_received?: InputMaybe<Scalars['String']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['String']['input']>;
  school_code?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyEventPreparationInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  prep?: InputMaybe<Scalars['String']['input']>;
  qty?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyEventPreparationsInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  prep?: InputMaybe<Scalars['String']['input']>;
  qty?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyEventTransportDetailsInput = {
  driver_name?: InputMaybe<Scalars['String']['input']>;
  driver_phone?: InputMaybe<Scalars['String']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  vehicle_id?: InputMaybe<Scalars['String']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyFacilityInput = {
  Address1?: InputMaybe<Scalars['String']['input']>;
  Address2?: InputMaybe<Scalars['String']['input']>;
  City?: InputMaybe<Scalars['String']['input']>;
  State?: InputMaybe<Scalars['String']['input']>;
  ZipCode?: InputMaybe<Scalars['String']['input']>;
  directions?: InputMaybe<Scalars['String']['input']>;
  indoor?: InputMaybe<Scalars['String']['input']>;
  ml_site_id?: InputMaybe<Scalars['String']['input']>;
  ml_space?: InputMaybe<Scalars['String']['input']>;
  ml_space_id?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  place_name: Scalars['String']['input'];
  place_name_old: Scalars['String']['input'];
  show?: InputMaybe<Scalars['String']['input']>;
  web?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyLevelInput = {
  ID: Scalars['Int']['input'];
  Level?: InputMaybe<Scalars['String']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModifyManyEventPreparationsByEventInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  event: Scalars['Int']['input'];
  prep?: InputMaybe<Scalars['String']['input']>;
  qty?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyOfficialDutyInput = {
  duty?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModifyOfficialInput = {
  duty?: InputMaybe<Scalars['String']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  offic_id?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['String']['input']>;
  pay_code?: InputMaybe<Scalars['String']['input']>;
  received?: InputMaybe<Scalars['String']['input']>;
  salary?: InputMaybe<Scalars['Float']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  voucher_number?: InputMaybe<Scalars['String']['input']>;
  worker_name?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyOfficialPoolByIdInput = {
  Address?: InputMaybe<Scalars['String']['input']>;
  City?: InputMaybe<Scalars['String']['input']>;
  First?: InputMaybe<Scalars['String']['input']>;
  Home_Phone?: InputMaybe<Scalars['String']['input']>;
  ID: Scalars['String']['input'];
  ID_old: Scalars['String']['input'];
  Last?: InputMaybe<Scalars['String']['input']>;
  SSN?: InputMaybe<Scalars['String']['input']>;
  State?: InputMaybe<Scalars['String']['input']>;
  Work_Phone?: InputMaybe<Scalars['String']['input']>;
  Zip?: InputMaybe<Scalars['String']['input']>;
  cell_phone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  vendor_number?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyOpponentInput = {
  Address?: InputMaybe<Scalars['String']['input']>;
  Phone?: InputMaybe<Scalars['String']['input']>;
  SchoolCode: Scalars['String']['input'];
  SchoolName?: InputMaybe<Scalars['String']['input']>;
  State?: InputMaybe<Scalars['String']['input']>;
  Zip?: InputMaybe<Scalars['String']['input']>;
  ad_name?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  nces_id?: InputMaybe<Scalars['String']['input']>;
  non_school?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModifyPreparationInput = {
  duty?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  qty?: InputMaybe<Scalars['String']['input']>;
};

export type ModifySchoolInfoInput = {
  Schoolname?: InputMaybe<Scalars['String']['input']>;
  ad?: InputMaybe<Scalars['String']['input']>;
  ad_contract_signee?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  ccemail?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  conf_text_type?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_reminder?: InputMaybe<Scalars['String']['input']>;
  email_reminder_officials?: InputMaybe<Scalars['String']['input']>;
  email_reminder_workers?: InputMaybe<Scalars['String']['input']>;
  enable_cc_email_as_origin?: InputMaybe<Scalars['String']['input']>;
  enable_ml_updates?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  mascot?: InputMaybe<Scalars['String']['input']>;
  message_board_read_at?: InputMaybe<Scalars['Date']['input']>;
  ml_key?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  principal?: InputMaybe<Scalars['String']['input']>;
  school_timezone?: InputMaybe<Scalars['String']['input']>;
  secondary_ad_email?: InputMaybe<Scalars['String']['input']>;
  secondary_ad_name?: InputMaybe<Scalars['String']['input']>;
  signed_contract_notification?: InputMaybe<Scalars['String']['input']>;
  ssn_on_file?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_org?: InputMaybe<Scalars['String']['input']>;
  state_org_abbreviation?: InputMaybe<Scalars['String']['input']>;
  use_security?: InputMaybe<Scalars['String']['input']>;
  web_password?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyVehicleInput = {
  id: Scalars['Int']['input'];
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  vehicle_type?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyWorkerInput = {
  duty?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  organization?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['String']['input']>;
  pay_code?: InputMaybe<Scalars['String']['input']>;
  pay_rate?: InputMaybe<Scalars['Float']['input']>;
  salary?: InputMaybe<Scalars['Float']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  woker_name?: InputMaybe<Scalars['String']['input']>;
  worker_end_time?: InputMaybe<Scalars['String']['input']>;
  worker_start_time?: InputMaybe<Scalars['String']['input']>;
  worker_type?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyWorkerPoolInput = {
  Address?: InputMaybe<Scalars['String']['input']>;
  City?: InputMaybe<Scalars['String']['input']>;
  First?: InputMaybe<Scalars['String']['input']>;
  Home_Phone?: InputMaybe<Scalars['String']['input']>;
  ID: Scalars['Int']['input'];
  Last?: InputMaybe<Scalars['String']['input']>;
  SSN?: InputMaybe<Scalars['String']['input']>;
  State?: InputMaybe<Scalars['String']['input']>;
  Work_Phone?: InputMaybe<Scalars['String']['input']>;
  Zip?: InputMaybe<Scalars['String']['input']>;
  cell_phone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  pay_rate?: InputMaybe<Scalars['Float']['input']>;
  worker_type?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addParticipantGuardianDonationInviteEmailsV2: Array<ParticipantGuardianReturn>;
  /** Add revision request. New submission will be created. Email will be sent to submitter. */
  addRevisionFundraiserApprovalSubmission: RevisionFundraiserApprovalSubmissionResult;
  /** Approve submission using approver's token. Email will be sent to submitter. */
  approveFundraiserApprovalSubmission: ApproveFundraiserApprovalSubmissionResult;
  campaignPersonListBulkCreate?: Maybe<CampaignPersonListEntries>;
  /** @deprecated Will be using campaignPersonListBulkCreate instead. */
  campaignPersonListCreate?: Maybe<CampaignPersonList>;
  campaignPersonListEntryDelete?: Maybe<CampaignPersonListEntryDelete>;
  /** Create saved search filters for campaigns for current user. */
  campaignSearchFilterCreate?: Maybe<CampaignSearchFilter>;
  /** Remove saved search filters for campaigns for current user. */
  campaignSearchFilterDelete?: Maybe<CampaignSearchFilter>;
  campaignSmsInviteAdd?: Maybe<CampaignSmsDataResponse>;
  campaignSmsInviteDelete: CampaignSmsInviteDeleteResponse;
  /** Create and update content */
  commsRegister: CommsRegisterResponse;
  /** Send message */
  commsSend: CommsSendResponse;
  /** Send Test Message */
  commsTest: CommsTestResponse;
  /** Edit document Status associated to a Fundraiser Approval Submission Form */
  completeFundraiserApprovalSubmissionDocumentUpload: CompleteFundraiserApprovalSubmissionDocumentUploadResult;
  confirmPassword?: Maybe<Scalars['String']['output']>;
  /** Deletes a custom template based off ID */
  contactTemplateDelete?: Maybe<CustomContactTemplates>;
  /** Saves custom contact-template for current user */
  contactTemplateSave?: Maybe<CustomContactTemplates>;
  cpDeleteEventResult?: Maybe<CpDeleteEventResultPayload>;
  cpUpdateEventResult?: Maybe<CpUpdateEventResultPayload>;
  /** @deprecated This will be replaced with latestCreateCard after data has been migrated from Spend and Raise */
  createCard: CoreCardFields;
  /** @deprecated(reason: "Use createChildFundraiserParticipantV2 instead") */
  createChildFundraiserParticipant: JoinedFundraiserId;
  createChildFundraiserParticipantV2: JoinedFundraiserId;
  createFundraiserApprovalSubmission: FundraiserApprovalSubmissionResult;
  createFundraiserUser?: Maybe<CreatedFundraiserUser>;
  createOtkParticipantEntry?: Maybe<CreatedOtkEntry>;
  createProduct?: Maybe<ProductResult>;
  createStore: StoreResult;
  createStoreOrder?: Maybe<OrderResult>;
  /** Create the log record with type DEBUG */
  debug?: Maybe<Scalars['Boolean']['output']>;
  /** Delete documents from fundraiser approval submission. If document is not linked to previous submission (history) it will be removed from S3 as well. */
  deleteFundraiserApprovalSubmissionDocument: Scalars['String']['output'];
  /** Create new donate intent. The status with created always INITIATED */
  donorDonateIntentCreate?: Maybe<DonateIntent>;
  /** Update the status of donate intent to be PROCESSING. This is called when the donation was successfully sent to Stripe, but we are waiting for an charge.succeeded event from Stripe to finalize the payment. */
  donorDonateIntentMarkProcessing?: Maybe<DonateIntent>;
  /** Update donate intent as user update the payment form. The status with created always become PENDING. */
  donorDonateIntentUpdate?: Maybe<DonateIntent>;
  donorsAdhocGiftShopIntentAdd: GiftShopPurchaseIntentId;
  donorsAlumni?: Maybe<AlumniDonorSchoolDetails>;
  /** create an offline donation */
  donorsDonationCreateOffline?: Maybe<DonorOfflineDonation>;
  donorsDonationDelete?: Maybe<DonorDonation>;
  donorsDonationUpdate?: Maybe<DonorDonation>;
  donorsFundraiserPayableRefund?: Maybe<DonorPayableRefundResponse>;
  donorsGiftShopIntentAdd: GiftShopPurchaseIntentId;
  donorsGiftShopIntentAddMany: GiftShopPurchaseIntentId;
  donorsGiftShopIntentMarkProcessing: GiftShopPurchaseIntentData;
  donorsGiftShopIntentRemove: GiftShopPurchaseIntentId;
  donorsGiftShopIntentUpdate: GiftShopPurchaseIntentId;
  donorsGiftShopIntentUpdateIntent: GiftShopPurchaseIntentData;
  donorsGiftShopPurchaseUpdate?: Maybe<DonorFundraiserPurchase>;
  donorsReceiptsResend: ResendReceiptsResult;
  driveAddHubspotProspectPin?: Maybe<Scalars['String']['output']>;
  driveAddWinbackPin?: Maybe<Scalars['String']['output']>;
  /** Finalize the (closed) fundraiser after campaign settled. */
  driveCampaignFinalize?: Maybe<IMutationResult>;
  /** Settle the fundraiser when campaigns start to be closed campaigns. */
  driveCampaignSettle?: Maybe<IMutationResult>;
  /** Update the fundraiser. Right now only fundraiser notes can be updated. */
  driveCampaignUpdate?: Maybe<IMutationResult>;
  /** @deprecated use driveDisableOrgActivity */
  driveDisableActivity?: Maybe<Scalars['String']['output']>;
  driveDisableOrgActivity?: Maybe<Scalars['String']['output']>;
  /** @deprecated use driveEnableOrgActivity */
  driveEnableActivity?: Maybe<Scalars['String']['output']>;
  driveEnableOrgActivity?: Maybe<Scalars['String']['output']>;
  /** Posts a message in the activity feed */
  driveEventActivityCreate?: Maybe<DriveEventActivityFeed>;
  /** Removeds an activity feed message */
  driveEventActivityRemove?: Maybe<Scalars['String']['output']>;
  /** Updates the message and feature toggle in a activity message */
  driveEventActivityUpdate?: Maybe<DriveEventActivityFeed>;
  /** Takes in 2 arguments to post the agendas */
  driveEventAgendaCreate?: Maybe<DriveEventAgendaItem>;
  /** Adds an attendee to event. */
  driveEventAttendeeAdd?: Maybe<DriveEventAttendee>;
  /** Updates attendance information of attendee. */
  driveEventAttendeeUpdate?: Maybe<DriveEventAttendee>;
  /** Removes an attendee from event. */
  driveEventAttendeesRemove?: Maybe<Scalars['Int']['output']>;
  /** Create Event (Drive Event-Tracker) */
  driveEventCreate?: Maybe<DriveEvent>;
  /** Delete Event (Drive Event-Tracker) */
  driveEventRemove?: Maybe<Scalars['String']['output']>;
  /** Update Event (Drive Event-Tracker) */
  driveEventUpdate?: Maybe<DriveEvent>;
  /** Set tracking by specific organization current user. */
  driveOrgUserTrackingSet?: Maybe<DriveOrgUserTrackingReturn>;
  /** Cancel the scheduled preload sms */
  drivePreloadSmsScheduleCancel?: Maybe<Scalars['String']['output']>;
  /** Send out preload sms to all participants */
  drivePreloadSmsSend?: Maybe<Scalars['String']['output']>;
  drivePreloadSmsSendScheduleUpdate?: Maybe<Scalars['String']['output']>;
  driveRemoveHubspotProspectPin?: Maybe<Scalars['String']['output']>;
  driveRemoveWinbackPin?: Maybe<Scalars['String']['output']>;
  /** Resizes the uploaded attachment in S3 in Drive. */
  driveS3ImageResize?: Maybe<Scalars['String']['output']>;
  driveShareProjectedRaisedResults?: Maybe<IMutationResult>;
  /** Generate S3 generate pre-signed url for Drive to upload. This is used for uploading big attachments to the support ticket */
  driveUploadUrlCreate: Scalars['String']['output'];
  editFundraiserApprovalSubmission: FundraiserApprovalSubmissionResult;
  /** Create the log record with type ERROR */
  error?: Maybe<Scalars['Boolean']['output']>;
  eventCreate?: Maybe<Event>;
  eventDelete?: Maybe<Event>;
  eventModify?: Maybe<Event>;
  eventParticipantsDeleteMany?: Maybe<DeleteCount>;
  eventParticipantsUpsert?: Maybe<UpsertEventParticipantsCount>;
  eventPreparationCreate?: Maybe<DailyCalendarPreparation>;
  eventPreparationDelete?: Maybe<DailyCalendarPreparation>;
  eventPreparationModify?: Maybe<DailyCalendarPreparation>;
  eventPreparationsCreateMany?: Maybe<Array<Maybe<EventPreparations>>>;
  eventPreparationsDeleteMany?: Maybe<ModifyDeleteCount>;
  eventPreparationsModifyMany?: Maybe<ModifyDeleteCount>;
  eventPreparationsUpsert?: Maybe<UpsertEventPreparationsCount>;
  eventResultArchive?: Maybe<EventResultArchiveResult>;
  eventResultCreate?: Maybe<EventResultCreateResult>;
  eventResultUpdate?: Maybe<EventResultUpdateResult>;
  eventResultsUpdate?: Maybe<EventResultsUpdateResult>;
  eventTransportDetailsDeleteMany?: Maybe<EventTransportDetailsDeleteManyCount>;
  eventTransportDetailsUpsertMany?: Maybe<UpsertEventTransportDetailsCount>;
  facilitiesUpsert?: Maybe<Array<Maybe<FacilityLocation>>>;
  facilityCreate?: Maybe<Facility>;
  facilityDelete?: Maybe<Facility>;
  facilityModify?: Maybe<Facility>;
  /** Associate a User with a FinancialAccount. This will also create a User Node only if they exist in User Directory but not Orgs. */
  finAcctUserAssociationCreate: MutationResponse;
  /** Add a deleted_at property to a User's FinancialAccount association. This will NOT delete the relationship. */
  finAcctUserAssociationDelete: MutationResponse;
  /** Update a User's FinancialAccount association properties. This mutation will re-activate a user that has been deleted by default. **Note** There is a known bug preventing status Booleans from updating from True to False. */
  finAcctUserAssociationUpdate: MutationResponse;
  /** Create a processor specific FinancialAccount Node that is associated with an Org. This requires a valid Org id and a valid KYB id */
  financialAcctCreate: MutationResponse;
  /** Change a FinancialAccount status to "TERMINATED". This will NOT delete the node. */
  financialAcctDelete: MutationResponse;
  /** Update a FinancialAccount properties that is already associated with an Org. */
  financialAcctUpdate: MutationResponse;
  fundraiserParticipantUpdate: ParticipantFundraiserParticipant;
  fundraiserStoreUrlUpdate?: Maybe<UpdatedFundraiserStoreUrl>;
  fundraisersDonationInviteDelete?: Maybe<Scalars['Boolean']['output']>;
  fundraisersDonationInviteUpdate?: Maybe<FundraisersDonationInvite>;
  /** update the list of blocked email domains for a fundraiser for donation invites */
  groupLeaderBlockedFundraiserDonationInviteDomainsUpdate?: Maybe<Array<FundraiserBlockedDonationInviteDomains>>;
  /** Send out email to group-leader. Support CC emails */
  groupLeaderEmailSend?: Maybe<IMutationResult>;
  groupLeaderFundraiserAcknowledgementSign?: Maybe<GroupLeaderFundraiserAcknowledgement>;
  groupLeaderFundraiserAddressUpdate?: Maybe<GroupLeaderFundraiserAddress>;
  groupLeaderFundraiserDonationLevelsUpdate?: Maybe<Array<GroupLeaderDonationLevels>>;
  /** edit a fundraiser financials information associated with the group leader */
  groupLeaderFundraiserFinancialsUpdate?: Maybe<GroupLeaderFundraiserFinancials>;
  groupLeaderFundraiserManagerUpdate?: Maybe<GroupLeaderFundraiserManager>;
  groupLeaderFundraiserMediaGalleryUpdate?: Maybe<Array<Maybe<GroupLeaderMediaGallery>>>;
  groupLeaderFundraiserParticipantsUpdate?: Maybe<Array<GroupLeaderFundraiserParticipantUpdate>>;
  /** edit a fundraiser associated with the group leader */
  groupLeaderFundraiserUpdate?: Maybe<GroupLeaderFundraiser>;
  /** update the gear logo configuration to approve the logo or request changes */
  groupLeaderGearLogoConfigurationUpdate?: Maybe<GroupLeaderGearLogoConfiguration>;
  groupLeaderMarkFundraiserManagerAlertAsRead: FundraiserManagerAlert;
  groupLeaderParticipantJoinFundraiserInvite: InviteFundraiserResponse;
  groupLeaderSendEmails?: Maybe<GroupLeaderEmailsSent>;
  helpDocumentDelete?: Maybe<Scalars['String']['output']>;
  helpDocumentUpload?: Maybe<Scalars['String']['output']>;
  /** Update hubspot call engagement for fundraiser */
  hubspotCallEngagementToDealAdd?: Maybe<HubspotCallEngagement>;
  /** Update hubspot note engagement for fundraiser */
  hubspotNoteEngagementToDealAdd?: Maybe<HubspotCallEngagement>;
  /** Create the log record with type INFO */
  info?: Maybe<Scalars['Boolean']['output']>;
  insightsAddPreApprovedContacts: InsAddPreApprovedContactsResult;
  insightsDeletePreApprovedContact: InsDeletePreApprovedContactResult;
  insightsEditPreApprovedContact: InsEditPreApprovedContactResult;
  insightsEditPreApprovedContactInvite: InsEditPreApprovedInviteResult;
  insightsResendInvitePreApprovedContact: InsResendPreApprovedContactResult;
  insightsSetNotificationPreferences: InsSetNotificationPreferencesResult;
  insightsSetUserPreferences: InsSetUserPreferencesResult;
  inviteAccept?: Maybe<UserInviteResponse>;
  inviteCreate: Scalars['String']['output'];
  inviteDelete?: Maybe<Scalars['String']['output']>;
  inviteResend?: Maybe<InviteInfo>;
  joinFundraiserUser: JoinedFundraiserId;
  joinFundraiserUserById: JoinedFundraiserId;
  latestVaultCardCreate: Scalars['ID']['output'];
  levelCreate?: Maybe<Level>;
  levelDelete?: Maybe<Level>;
  levelModify?: Maybe<Level>;
  /** Create the log record. The most universal and agnostic request. */
  log?: Maybe<Scalars['Boolean']['output']>;
  login?: Maybe<AuthChallenge>;
  logout?: Maybe<Scalars['Boolean']['output']>;
  magentoRefundWebhook?: Maybe<MagentoTransactionWebhookResponse>;
  magentoTransactionWebhook?: Maybe<MagentoTransactionWebhookResponse>;
  manageUserCreate?: Maybe<ManageUser>;
  manageUserDelete?: Maybe<ManageUser>;
  manageUserUpdate?: Maybe<ManageUser>;
  marcoWebhook: MarcoWebhookResponse;
  messagesChannelAddModerators?: Maybe<ChatChannelAddModeratorsResult>;
  messagesChannelAddUsers?: Maybe<ChatChannelAddUsersResult>;
  messagesChannelCreate?: Maybe<ChatChannelCreateResult>;
  messagesChannelInviteAccept?: Maybe<MessagesChannelInviteAcceptResult>;
  messagesChannelInviteMembers?: Maybe<MessagesChannelInviteMembersResult>;
  messagesChannelInviteResend?: Maybe<MessagesChannelInviteResendResult>;
  messagesChannelRemoveMembers?: Maybe<MessagesChannelRemoveMembersResult>;
  messagesChannelUpdate?: Maybe<ChatChannelUpdateResult>;
  messagesChannelUpdateMembers?: Maybe<ChatChannelUpdateMembersResult>;
  messagesToken?: Maybe<ChatTokenResult>;
  messagesUpsertUsers?: Maybe<MessagesUpsertUsersResult>;
  netsuiteWebhook?: Maybe<NetsuiteWebhookResponse>;
  noticeCreate?: Maybe<Notice>;
  noticeDelete?: Maybe<Array<Maybe<Notice>>>;
  noticeModify?: Maybe<Notice>;
  oauthCallback?: Maybe<OAuthCallbackResponse>;
  officialCreate?: Maybe<Official>;
  officialDelete?: Maybe<Official>;
  officialDutiesDelete?: Maybe<Array<Maybe<OfficialDuty>>>;
  officialDutiesUpsert?: Maybe<Array<Maybe<OfficialDuty>>>;
  officialDutyCreate?: Maybe<OfficialDuty>;
  officialDutyModify?: Maybe<OfficialDuty>;
  officialModify?: Maybe<Official>;
  officialPoolCreate?: Maybe<OfficialPool>;
  officialPoolDelete?: Maybe<Array<Maybe<OfficialPool>>>;
  officialPoolModify?: Maybe<OfficialPool>;
  officialsUpsert?: Maybe<Array<Maybe<OfficialPool>>>;
  opponentCreate?: Maybe<Opponent>;
  opponentDelete?: Maybe<Opponent>;
  opponentModify?: Maybe<Opponent>;
  opponentsUpsert?: Maybe<Array<Maybe<Opponent>>>;
  /** Create a new Affiliate. Affiliates have a parent Org, and this mutation will connect the two. */
  orgAffiliateCreate: MutationResponse;
  /** Create a new Club. Clubs do not have a parent Org. */
  orgClubCreate: MutationResponse;
  /** Create a new District. Districts do not have a parent Org. */
  orgDistrictCreate: MutationResponse;
  /** Update an Org Node's label in parallel with changes being made in MOD. This mutation is intended to preserve User Associations and Financial Account Associations. */
  orgLabelUpdate: MutationResponse;
  /** Create a new Program named after a given activity and connect it to a given Org. */
  orgProgramCreate: MutationResponse;
  /** Create a new School. Schools usually have a parent District, and this mutation will optionally connect the two. */
  orgSchoolCreate: MutationResponse;
  /** Create a new Team for a given activity and connect it to a Program of the same activity under a given Org. */
  orgTeamCreate: MutationResponse;
  participantDonationInviteEdit?: Maybe<DonorEmailData>;
  participantDonationInviteEmailAdd?: Maybe<DonorEmailData>;
  participantDonationInviteRemove?: Maybe<Array<Maybe<DonationInviteRemoveData>>>;
  participantDonationInviteSMSAdd?: Maybe<DonationInviteSmsData>;
  participantDonationInvitesUpdate?: Maybe<ParticipantDonationInviteResponse>;
  /** This will update the emails of a Participant's Guardian Email field */
  participantGuardianEmailUpdate: ParticipantGuardianEmailUpdate;
  participantIncentiveSelectionDelete?: Maybe<DeletedIncentiveRecord>;
  participantRewardsBulkCreate?: Maybe<Array<Maybe<BulkRewards>>>;
  participantRewardsCreate?: Maybe<CreatedParticipantRewards>;
  participantSignup: Array<TransportType>;
  participantSmsDonationInvitesAdd?: Maybe<FundraiserParticipantSmsDonationInvitesAddResponse>;
  participantSmsDonationInvitesRemove?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Send out emails to particpants */
  participantsEmailSend?: Maybe<IMutationResult>;
  /** Sends out texts to particpants */
  participantsTextSend?: Maybe<IMutationResult>;
  paymentsApiCustomerGetOrCreate: PaymentsApiCustomerResponse;
  paymentsApiDetachPaymentMethod?: Maybe<PaymentsApiDetachPaymentMethodResponse>;
  paymentsApiPayment?: Maybe<PaymentsApiPaymentResponse>;
  paymentsApiRefund?: Maybe<PaymentsApiCreateRefundResponse>;
  /** Reschedule time (timezone-sensitive) to send out preload email to all participants */
  preloadEmailRescheduleSend?: Maybe<PreloadEmailSchedule>;
  preloadEmailScheduleCancel?: Maybe<Scalars['Boolean']['output']>;
  /** Schedule time (timezone-sensitive) to send out preload email to all participants */
  preloadEmailScheduleSend?: Maybe<PreloadEmailSchedule>;
  /** Send out preload email to all participants */
  preloadEmailSend?: Maybe<Scalars['String']['output']>;
  preparationCreate?: Maybe<Preparation>;
  preparationDelete?: Maybe<Preparation>;
  preparationModify?: Maybe<Preparation>;
  /** Send push notification to user */
  pushNotificationSend: PushNotificationResponse;
  /** Create multiple fundraiser users and return total number of fundraiser users created */
  raiseAdminFundraiserUsersCreateMany: Scalars['Int']['output'];
  roleCreate?: Maybe<Role>;
  roleDelete?: Maybe<Role>;
  rolePermissionsUpdate?: Maybe<Role>;
  roleUpdate?: Maybe<Role>;
  /** Clone an existing Roster and attach it to a new Org. This Roster will NOT be synced or subscribed to changes to the original roster. This will also clone the list of Members. */
  rosterClone: MutationResponse;
  /** Create a new Roster for a given Org and attach a list of Members to it. */
  rosterCreate: MutationResponse;
  /** Update a Roster's properties including adding to the list of Members OR removing members from the list. */
  rosterUpdate: MutationResponse;
  /** Request one or more S3 pre-signed urls to upload files for approval submissions. */
  s3PresignedInsightsApprovalsUrl: Array<S3PresignedInsightsApprovalsUrlResult>;
  samlCallback?: Maybe<Auth>;
  scheduleEventArchive?: Maybe<EventArchiveResult>;
  scheduleEventCreate?: Maybe<ScheduleEventCreateResult>;
  scheduleEventUpdate?: Maybe<ScheduleEventUpdateResult>;
  schoolInfoCreate?: Maybe<SchoolInfo>;
  schoolInfoDelete?: Maybe<SchoolInfo>;
  schoolInfoModify?: Maybe<SchoolInfo>;
  seasonCreate?: Maybe<Season>;
  seasonDelete?: Maybe<Array<Maybe<Season>>>;
  seasonModify?: Maybe<Season>;
  seasonPostponeModify?: Maybe<PostponeUnpostponeResponse>;
  seasonScheduleTeamsCreate?: Maybe<Array<Maybe<Season>>>;
  seasonUnpostponeModify?: Maybe<PostponeUnpostponeResponse>;
  /** Add, update or remove Insights configurations for current user Allowed configurations: - VAULT_WELCOME_DISMISSED (YES | default=NO) - VAULT_ORG_APPROVED_DISMISS_BANNER (value should be org_id list) - APPROVALS_WELCOME_DISMISSED (YES | default=NO) Note: value=null to unset */
  setUserInsightsConfigurations: UserInsightsConfigResult;
  /** Update settlement note for a fundraiser */
  settlementIssuesRecord?: Maybe<Scalars['String']['output']>;
  /** Update settlement status for a fundraiser */
  settlementStatusUpdate?: Maybe<IMutationResult>;
  snapMobileOneAccessRequest: MutationResponse;
  snapMobileOneContactSupport: MutationResponse;
  snapMobileOneCreate: MutationResponse;
  snapMobileOneProductActivate: MutationResponse;
  snapMobileOneProductDelete: MutationResponse;
  snapMobileOneProductRequest: MutationResponse;
  snapMobileOneUpdate: MutationResponse;
  spendAdminAllowCheckId?: Maybe<SpendCheckImageAllowanceResponse>;
  spendAdminApprovalUpdate?: Maybe<SpendAdminApprovalUpdateResponse>;
  spendAdminBulkAddUnit?: Maybe<AuthorizedUsersAdded>;
  spendAdminCloseCustomer?: Maybe<SpendAdminCustomerCloseResponse>;
  spendAdminUnarchiveGroup?: Maybe<SpendGroupId>;
  spendArchiveSeasonMembers?: Maybe<SpendArchiveSeasonMembersResponse>;
  spendAuthorizeAutoPay?: Maybe<SpendAuthorizeAutoPayResponse>;
  spendBudgetCreate?: Maybe<SpendBudgetId>;
  spendBudgetDelete?: Maybe<SpendBudgetId>;
  spendBudgetUpdate?: Maybe<SpendBudgetId>;
  spendCategoryCreate?: Maybe<SpendCategoryId>;
  spendCategoryUpdate?: Maybe<SpendCategoryId>;
  spendCategoryUpsertBulk?: Maybe<Array<Maybe<SpendCategoryId>>>;
  spendCreditMemoCreate?: Maybe<SpendCreditMemoCreateResponse>;
  spendCreditMemoUpdate?: Maybe<SpendCreditMemoUpdateResponse>;
  spendGroupACHCredit?: Maybe<SpendAchCreditResponse>;
  spendGroupArchive?: Maybe<SpendGroupArchiveResponse>;
  spendGroupBankAccessTokenCreate?: Maybe<SpendBankAccessCreateResponse>;
  spendGroupBankAccessTokenDelete?: Maybe<SpendBankAccessDeleteResponse>;
  spendGroupCheckCancel?: Maybe<SpendCancelCheckId>;
  spendGroupCheckDepositPatch?: Maybe<SpendCheckDepositTagsResponse>;
  spendGroupCheckSend?: Maybe<SpendCheckSendResponse>;
  spendGroupCounterpartyCreate?: Maybe<SpendCounterpartyCreateResponse>;
  spendGroupCounterpartyDelete?: Maybe<SpendDeleteCounterpartyResponse>;
  spendGroupCreate?: Maybe<SpendGroupId>;
  spendGroupDebitCardCreate?: Maybe<SpendDebitCardId>;
  spendGroupDuplicate?: Maybe<SpendGroupId>;
  spendGroupExternalTransfer?: Maybe<SpendExternalTransferResponse>;
  spendGroupPayeeCreate?: Maybe<SpendPayeeCreateResponse>;
  spendGroupPayeeUpdate?: Maybe<SpendPayeeCreateResponse>;
  spendGroupRosterCreate?: Maybe<SpendGroupRosterId>;
  spendGroupRosterUpdate?: Maybe<SpendRosterUpdate>;
  spendGroupRostersCreate?: Maybe<Array<Maybe<SpendGroupRosterId>>>;
  spendGroupUnArchive?: Maybe<SpendGroupArchiveResponse>;
  spendGroupUpdate?: Maybe<SpendGroupId>;
  spendGuardianSignup?: Maybe<SpendUserId>;
  spendInvoiceArchive?: Maybe<SpendInvoiceId>;
  spendInvoiceChangeRequest?: Maybe<SpendInvoiceId>;
  spendInvoiceCreate?: Maybe<SpendInvoiceId>;
  spendInvoiceOptInOrOut?: Maybe<SpendInvoiceId>;
  spendInvoicePaymentDeauthorize?: Maybe<SpendDeauthorizeResponse>;
  spendInvoiceRefund?: Maybe<SpendInvoiceRefundResponse>;
  spendInvoiceReminderCreate?: Maybe<SpendNotificationId>;
  spendInvoiceUpdate?: Maybe<SpendInvoiceId>;
  spendInvoiceUpdateMany?: Maybe<SpendInvoiceUpdateResponse>;
  spendInvoiceUpdatePaymentMethod?: Maybe<SpendInvoiceIDs>;
  spendNotificationCreate?: Maybe<SpendNotificationStatus>;
  spendOrganizationACHCredit?: Maybe<SpendAchCreditResponse>;
  spendOrganizationAccountTransfer: SpendAccountId;
  spendOrganizationAdminUpdate?: Maybe<SpendOrgAdminUpdateResponse>;
  spendOrganizationBankAccessTokenCreate?: Maybe<SpendBankAccessCreateResponse>;
  spendOrganizationBankAccessTokenDelete?: Maybe<SpendBankAccessDeleteResponse>;
  spendOrganizationCheckCancel?: Maybe<SpendCancelCheckId>;
  spendOrganizationCheckDepositPatch?: Maybe<SpendCheckDepositTagsResponse>;
  spendOrganizationCheckSend?: Maybe<SpendCheckSendResponse>;
  spendOrganizationCounterpartyCreate?: Maybe<SpendCounterpartyCreateResponse>;
  spendOrganizationCounterpartyDelete?: Maybe<SpendDeleteCounterpartyResponse>;
  spendOrganizationCreate?: Maybe<SpendOrganizationId>;
  spendOrganizationDebitCardCreate?: Maybe<SpendDebitCardId>;
  spendOrganizationExternalTransfer?: Maybe<SpendExternalTransferResponse>;
  spendOrganizationPayeeCreate?: Maybe<SpendPayeeCreateResponse>;
  spendOrganizationPayeeUpdate?: Maybe<SpendPayeeCreateResponse>;
  spendOrganizationSignup?: Maybe<SpendSignupFormResponse>;
  spendOrganizationUpdate?: Maybe<SpendOrganization>;
  spendPaymentMethodCreate?: Maybe<SpendPaymentMethodResponse>;
  spendPaymentScheduleCreate?: Maybe<SpendPaymentScheduleId>;
  spendPaymentScheduleRevert?: Maybe<SpendPaymentScheduleId>;
  spendPaymentScheduleRevertMany?: Maybe<Array<Maybe<SpendPaymentScheduleId>>>;
  spendPaymentScheduleUpdate?: Maybe<SpendPaymentScheduleId>;
  spendPaymentScheduleUpdateBySeason?: Maybe<Array<Maybe<SpendPaymentScheduleId>>>;
  spendRemoveParticipants?: Maybe<SpendRemoveSeasonMemberResponse>;
  spendRoleChange?: Maybe<SpendRoleId>;
  spendSeasonCreate?: Maybe<SpendSeasonId>;
  spendSeasonUpdate?: Maybe<SpendSeasonId>;
  spendSessionCreate?: Maybe<SpendSession>;
  spendSessionDelete?: Maybe<SpendSessionId>;
  spendSessionRefresh?: Maybe<SpendSessionId>;
  spendSettingsUpdate?: Maybe<SpendOrganizationId>;
  spendSettingsUpdateAll?: Maybe<SpendUpdateResponse>;
  spendSignupAgreementCreate?: Maybe<SpendAgreementId>;
  spendStopCheckPayment?: Maybe<SpendStopCheckPaymentResponse>;
  spendSystemNotificationCreate?: Maybe<SpendSystemNotificationIdResponse>;
  spendSystemNotificationUpdate?: Maybe<SpendSystemNotificationIdResponse>;
  spendTransactionAttachmentCreate?: Maybe<SpendTransactionAttachmentId>;
  spendTransactionAttachmentDelete?: Maybe<SpendTransactionAttachmentId>;
  spendTransactionAttachmentDeleteByKey?: Maybe<Array<SpendTransactionAttachmentId>>;
  spendTransactionBudgetUnreconcile?: Maybe<SpendReconcileTransactionId>;
  spendTransactionInvoiceUnreconcile?: Maybe<SpendReconcileTransactionId>;
  spendTransactionNoteCreate?: Maybe<SpendTransactionNoteId>;
  spendTransactionNoteUpdate?: Maybe<SpendTransactionNoteId>;
  /** @deprecated Use spendTransactionReconcileV2 */
  spendTransactionReconcile?: Maybe<SpendReconcileTransactionId>;
  spendTransactionReconcileV2?: Maybe<SpendReconcileTransactionId>;
  spendTransactionsCreate?: Maybe<SpendTransactionsIdList>;
  spendUnitApplication?: Maybe<SpendUnitApplicationResponse>;
  spendUnitFileUpload?: Maybe<SpendTransactionAttachmentId>;
  spendUpdateApproval?: Maybe<SpendApprovalUpdateResponse>;
  spendUserAccountUpdate?: Maybe<SpendUserAccountUpdateResponse>;
  spendUserAchPayment?: Maybe<SpendAchPaymentResponse>;
  spendUserAchPaymentCancel?: Maybe<SpendAchPaymentId>;
  spendUserAcknowledgeNotification?: Maybe<SpendUserAcknowledgeNotificationResponse>;
  spendUserBankAccessTokenCreate?: Maybe<SpendBankAccessCreateResponse>;
  spendUserBankAccessTokenDelete?: Maybe<SpendBankAccessDeleteResponse>;
  spendUserEmailUpdate?: Maybe<SpendUserEmailUpdateResponse>;
  spendUserInviteArchive?: Maybe<SpendInviteId>;
  spendUserInviteCreate?: Maybe<SpendInviteId>;
  spendUserInviteDelete?: Maybe<SpendInviteId>;
  spendUserInviteDismiss?: Maybe<SpendDismissInviteResponse>;
  spendUserInviteResend?: Maybe<SpendInviteId>;
  spendUserInviteResendAll?: Maybe<SpendAllInviteIds>;
  spendUserInviteStatusUpdate?: Maybe<SpendInviteId>;
  spendUserInviteUpdate?: Maybe<SpendInviteId>;
  spendUserLeaveGroup?: Maybe<SpendUpdateResponse>;
  spendUserLoginAudit?: Maybe<SpendUserLoginAuditResponse>;
  spendUserNotificationSettingUpdate?: Maybe<SpendUserNotificationSettingId>;
  spendUserPaymentsCardDetach?: Maybe<SpendPaymentMethodDetachResponse>;
  spendUserRoleArchive?: Maybe<SpendUserId>;
  spendUserRoleSet?: Maybe<SpendUserRoleId>;
  spendUserRoleUpdate?: Maybe<SpendUserRoleId>;
  spendUserRoleUpdateIsApprover?: Maybe<SpendUserRoleId>;
  spendUserSignUp?: Maybe<SpendSignUpResponse>;
  spendUserSignupUpdate?: Maybe<SpendUserRoleId>;
  storeBuildRequest?: Maybe<StoreBuildRequestUnionType>;
  storeEditInfo?: Maybe<StoreEditInfoUnionType>;
  storeManagerPointsUpdate?: Maybe<StoreManagerUpdatePointsUnionType>;
  storePaymentIntentCreate?: Maybe<StorePaymantIntentUnionType>;
  storePointsWithdrawalRequest?: Maybe<StorePointsWithdrawalRequestUnionType>;
  storeProfitPercentageUpdate?: Maybe<StoreProfitPercentageUpdated>;
  storeTicketCreate?: Maybe<StoreTicketsUnionType>;
  storeTransactionSave?: Maybe<StoreTransactionUnionType>;
  storeTransferToCustomer?: Maybe<StoreTransferPointsUnionType>;
  storeTransferToGL?: Maybe<StoreTransferPointsUnionType>;
  storeTransferToParticipant?: Maybe<StoreTransferPointsUnionType>;
  storeUpdatePayable?: Maybe<StorePayableInfoUnionType>;
  storeUpdateStatus?: Maybe<MagentoStoreUnionType>;
  storeUserPreferenceUpsert?: Maybe<UserPreference>;
  /** Submits submission draft to be reviewed by approver. Email will be sent to approver. */
  submitFundraiserApprovalSubmission: FundraiserApprovalSubmission;
  /** Send support-ticket email to the support team. */
  supportTicketSend?: Maybe<IMutationResult>;
  teamCreate?: Maybe<Team>;
  teamDelete?: Maybe<Team>;
  teamModify?: Maybe<Team>;
  teamOfficialCreate?: Maybe<TemplateOfficial>;
  teamOfficialDelete?: Maybe<TemplateOfficial>;
  teamOfficialModify?: Maybe<TemplateOfficial>;
  teamPreparationCreate?: Maybe<TemplatePreparation>;
  teamPreparationDelete?: Maybe<TemplatePreparation>;
  teamPreparationModify?: Maybe<TemplatePreparation>;
  teamWorkerCreate?: Maybe<TemplateWorker>;
  teamWorkerDelete?: Maybe<TemplateWorker>;
  teamWorkerModify?: Maybe<TemplateWorker>;
  /** An example field added by the generator */
  testField: Scalars['String']['output'];
  toggleCardActivation: Scalars['String']['output'];
  updateFundraiserUserTypeConfig?: Maybe<Scalars['Boolean']['output']>;
  updateParticipantCampaignConfiguration: ParticipantCampaignConfiguration;
  updateParticipantFundraiserConfiguration?: Maybe<ParticipantFundraiserConfiguration>;
  updateParticipantGroup?: Maybe<UpdatedParticipantGroupCount>;
  /** This will update the size of a Participant's OTK item */
  updateParticipantOtkSize: FundraiserUserIncentiveId;
  upgradeToManageUser?: Maybe<Scalars['String']['output']>;
  upgradeToWalletUser?: Maybe<User>;
  uploadLogoPNG?: Maybe<OrderResult>;
  /** Create a relationship between a User and a Program with a given activity under a given Organization. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userActivityLeadsCreate: MutationResponse;
  /** Delete a relationship between a User and a Program with a given activity under a given Organization. */
  userActivityLeadsDelete: MutationResponse;
  /** Update a relationship between a User and a Program with a given activity under a given Organization. */
  userActivityLeadsUpdate: MutationResponse;
  userApiKeyCreate?: Maybe<ApiKey>;
  userApiKeyDelete: Scalars['String']['output'];
  userApiKeyUpdate?: Maybe<ApiKey>;
  /** @deprecated Please use userAssociationCreate instead */
  userAssociate: MutationResponse;
  /** Create first_seen and last_seen properties for a User and their product specific Org association. */
  userAssociationAccess: MutationResponse;
  /** Associate a User with an Org and a specific product. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userAssociationCreate: MutationResponse;
  /** Add a deleted_at property to a User and their product specific Org association. This will NOT delete the relationship. */
  userAssociationDelete: MutationResponse;
  userBulkInviteSend: UserOrgInvitationResult;
  userChallengeUpdate?: Maybe<UserChallenge>;
  userChildCreate?: Maybe<User>;
  userChildUnassign: Scalars['String']['output'];
  userChildUpdate?: Maybe<User>;
  userConfirmProfileChallenge?: Maybe<ConfirmProfileChallengeResponse>;
  userCreate?: Maybe<User>;
  userEmailConfirm?: Maybe<Scalars['String']['output']>;
  userFittingAdd?: Maybe<UserFitting>;
  userImpersonate?: Maybe<Auth>;
  userImpersonateLogout?: Maybe<Auth>;
  /** If it is confirmed that a Group Leader is no longer affiliated with their former Org, mark their relationship with a campaign as 'no_longer_affiliated'. */
  userImpliedAffiliationDelete: MutationResponse;
  userInitiateProfileChallenge?: Maybe<InitiateProfileChallengeResponse>;
  /** @deprecated use inviteCreate instead */
  userInvite: Scalars['String']['output'];
  /** @deprecated use inviteAccept instead */
  userInviteAccept?: Maybe<UserInviteResponse>;
  userInviteSend?: Maybe<InviteSendResult>;
  userLoginStep: UserLoginStepResponse;
  /** Connect a User to an Org with a specific title. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userOrgAffiliationCreate: MutationResponse;
  /** Add a deleted_at timestamp to a User's title specific Org affiliation. */
  userOrgAffiliationDelete: MutationResponse;
  /** Update the descripton, or isConfirmed on the affiliation between a User and an Org. If you want to change the title, you will need to delete the existing affiliation and create a new one. */
  userOrgAffiliationUpdate: MutationResponse;
  userPermissionsUpdate?: Maybe<UserPermissionsList>;
  userPhoneNumberChallengeConfirm?: Maybe<PhoneNumberChallengeConfirmation>;
  userPhoneNumberConfirm?: Maybe<Scalars['String']['output']>;
  userRefreshSession?: Maybe<Auth>;
  userResetPassword?: Maybe<UserResetPasswordResponseWithAvailableTransport>;
  userResetPasswordByAdmin?: Maybe<UserResetPasswordByAdminResponse>;
  userSignup?: Maybe<SignUpResponseWithAvailableTransport>;
  userSignupConfirm?: Maybe<Auth>;
  userSignupConfirmationResend: Array<TransportType>;
  /** Create a relationship between a User and a specific Team. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userTeamLeadsCreate: MutationResponse;
  /** Add a deleted_at property to a title specific relationship between a User and Team. */
  userTeamLeadsDelete: MutationResponse;
  /** Update the isConfirmed boolean on a title specific relationship between a User and Team. */
  userTeamLeadsUpdate: MutationResponse;
  userUnassignParent?: Maybe<Scalars['String']['output']>;
  userUpdate?: Maybe<User>;
  userUpdatePassword?: Maybe<User>;
  vaultCreateKybKycSubmit: VaultFormMutationResponse;
  vaultCustomerCreate: Scalars['String']['output'];
  vaultDocumentGatewayUpload?: Maybe<VaultMutationResponse>;
  vaultIncorrectKybSupportReport?: Maybe<VaultMutationResponse>;
  vaultKybCreate: Scalars['ID']['output'];
  vaultKybUpdate: Scalars['ID']['output'];
  vaultKycCreate: Scalars['ID']['output'];
  vaultKycUpdate: Scalars['ID']['output'];
  vaultOrgConfirmationSubmit: VaultFormMutationResponse;
  vaultSetupIntentCreate: Scalars['String']['output'];
  vaultStripeConnectAccountBankAccountDefaultSet: VaultMutationResponse;
  vaultStripeConnectAccountBankAccountDelete: VaultMutationResponse;
  vaultStripeConnectAccountCreate: Scalars['String']['output'];
  vaultStripeConnectAccountOwnersProvided: Scalars['String']['output'];
  vaultStripeConnectAccountPaymentMethodAttach: VaultMutationResponse;
  vaultStripeConnectAccountPaymentMethodDefaultSet: VaultMutationResponse;
  vaultStripeConnectAccountPayoutFrequencySet: VaultMutationResponse;
  vaultStripeConnectAccountRemovePeople?: Maybe<VaultMutationResponse>;
  vaultStripeConnectAccountRemoveRepresentative?: Maybe<VaultMutationResponse>;
  vaultStripeConnectAccountUpdate: Scalars['String']['output'];
  vaultStripePersonCreate: Scalars['String']['output'];
  vaultStripePersonDelete: Scalars['String']['output'];
  vaultStripePersonUpdate: Scalars['String']['output'];
  vaultUpdateVaultKybAutomated?: Maybe<Scalars['String']['output']>;
  vehicleCreate?: Maybe<Vehicle>;
  vehicleDelete?: Maybe<Vehicle>;
  vehicleModify?: Maybe<Vehicle>;
  /** Create the log record with type WARNING */
  warning?: Maybe<Scalars['Boolean']['output']>;
  /** Deprecated: use cron-jobs instead */
  weeklyEventEmailScheduleSend?: Maybe<IMutationResult>;
  workerDeleteMany?: Maybe<WorkerDeleteManyCount>;
  workerPoolCreate?: Maybe<WorkerPool>;
  workerPoolDelete?: Maybe<Array<Maybe<WorkerPool>>>;
  workerPoolModify?: Maybe<WorkerPool>;
  workerUpsertMany?: Maybe<WorkerUpsertManyCount>;
  workersUpsert?: Maybe<Array<Maybe<WorkerPool>>>;
};


export type MutationAddParticipantGuardianDonationInviteEmailsV2Args = {
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationAddRevisionFundraiserApprovalSubmissionArgs = {
  revision: Scalars['NonEmptyString']['input'];
  submissionId?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationApproveFundraiserApprovalSubmissionArgs = {
  submissionId?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCampaignPersonListBulkCreateArgs = {
  emails: Array<Scalars['String']['input']>;
  fundraiserId: Scalars['Int']['input'];
  participantUserId: Scalars['Int']['input'];
};


export type MutationCampaignPersonListCreateArgs = {
  contextableId: Scalars['Int']['input'];
  contextableType: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  participantUserId: Scalars['Int']['input'];
  personListType: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCampaignPersonListEntryDeleteArgs = {
  personListEntryId: Scalars['Int']['input'];
};


export type MutationCampaignSearchFilterCreateArgs = {
  filterCriteria?: InputMaybe<Scalars['JSON']['input']>;
  filterName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCampaignSearchFilterDeleteArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCampaignSmsInviteAddArgs = {
  fundraiserId: Scalars['Int']['input'];
  participantUserId: Scalars['Int']['input'];
  phoneNumbers: Array<Scalars['String']['input']>;
};


export type MutationCampaignSmsInviteDeleteArgs = {
  fundraiserId: Scalars['Int']['input'];
  smsInviteId: Scalars['Int']['input'];
};


export type MutationCommsRegisterArgs = {
  code: Scalars['String']['input'];
  emailProvider?: InputMaybe<SupportedEmailProviders>;
  from?: InputMaybe<Scalars['String']['input']>;
  fromName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mjml?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<SnapService>;
  serviceUserId?: InputMaybe<Scalars['String']['input']>;
  smsProvider?: InputMaybe<SupportedSmsProviders>;
  subject?: InputMaybe<Scalars['String']['input']>;
  testData?: InputMaybe<Scalars['JSON']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  transport?: InputMaybe<MessageTransportType>;
  type?: InputMaybe<MessageType>;
  unsubscribeGroupId?: InputMaybe<Scalars['Int']['input']>;
  unsubscribeGroups?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type MutationCommsSendArgs = {
  contacts: Array<InputMaybe<MessageContact>>;
  sendAt?: InputMaybe<Scalars['Date']['input']>;
  templateAttributes?: InputMaybe<Scalars['JSON']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
  templateName?: InputMaybe<Scalars['String']['input']>;
  workflowId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCommsTestArgs = {
  attributes?: InputMaybe<Scalars['JSON']['input']>;
  challenge?: InputMaybe<Scalars['Boolean']['input']>;
  mjml?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCompleteFundraiserApprovalSubmissionDocumentUploadArgs = {
  approvalSubmissionId: Scalars['Int']['input'];
  s3FileName: Scalars['String']['input'];
};


export type MutationConfirmPasswordArgs = {
  email: Scalars['String']['input'];
  newPassword?: InputMaybe<Scalars['String']['input']>;
  verificationCode: Scalars['String']['input'];
};


export type MutationContactTemplateDeleteArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationContactTemplateSaveArgs = {
  templateMessage?: InputMaybe<Scalars['String']['input']>;
  templateName?: InputMaybe<Scalars['String']['input']>;
  templateSubject?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCpDeleteEventResultArgs = {
  input: CpDeleteEventResultInput;
};


export type MutationCpUpdateEventResultArgs = {
  input: CpUpdateEventResultInput;
};


export type MutationCreateCardArgs = {
  card: IssueCardInput;
  gateway?: InputMaybe<Financial_Gateway>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  raiseOrganizationId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateChildFundraiserParticipantArgs = {
  input: CreateChildFundraiserParticipantInput;
};


export type MutationCreateChildFundraiserParticipantV2Args = {
  input: CreateChildFundraiserParticipantInput;
};


export type MutationCreateFundraiserApprovalSubmissionArgs = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>;
  campaignRaiseId?: InputMaybe<Scalars['Int']['input']>;
  customerSupportInfo?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  estimatedTotalRaisedCents?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  formType?: InputMaybe<FundraiserApprovalSubmissionFormType>;
  fundraiserApprovalSubmissionDocs?: InputMaybe<Array<InputMaybe<FundraiserApprovalSubmissionDocumentInput>>>;
  fundraiserApprovers?: InputMaybe<Array<InputMaybe<FundraiserApproverInput>>>;
  fundraiserServiceWebsite?: InputMaybe<Scalars['String']['input']>;
  groupSize?: InputMaybe<Scalars['Int']['input']>;
  howThisWorks?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  newFundraiser?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  pricingAndFees?: InputMaybe<Scalars['String']['input']>;
  programId?: InputMaybe<Scalars['String']['input']>;
  proposedStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  revisionRequest?: InputMaybe<Scalars['String']['input']>;
  safety?: InputMaybe<Scalars['String']['input']>;
  submitterId: Scalars['NonEmptyString']['input'];
};


export type MutationCreateFundraiserUserArgs = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateOtkParticipantEntryArgs = {
  fundraiserId: Scalars['Int']['input'];
  incentiveId: Scalars['Int']['input'];
  size: Scalars['String']['input'];
};


export type MutationCreateProductArgs = {
  product?: InputMaybe<ProductInput>;
};


export type MutationCreateStoreArgs = {
  overrideSkipLogic?: InputMaybe<Scalars['Boolean']['input']>;
  store: StoreInput;
};


export type MutationCreateStoreOrderArgs = {
  order?: InputMaybe<OrderInput>;
};


export type MutationDebugArgs = {
  payload: Scalars['JSON']['input'];
  service: AuditLogService;
  source: AuditLogSource;
};


export type MutationDeleteFundraiserApprovalSubmissionDocumentArgs = {
  approvalSubmissionId: Scalars['Int']['input'];
  documentKey: Scalars['String']['input'];
};


export type MutationDonorDonateIntentCreateArgs = {
  input: DonorDonateIntentCreateInput;
};


export type MutationDonorDonateIntentMarkProcessingArgs = {
  data?: InputMaybe<DonorDonateIntentUpdateInput>;
  id: Scalars['ID']['input'];
};


export type MutationDonorDonateIntentUpdateArgs = {
  browserSecret?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  input: DonorDonateIntentUpdateInput;
};


export type MutationDonorsAdhocGiftShopIntentAddArgs = {
  intentInput: AdhocGiftShopIntentInput;
  item: PurchaseItemInput;
};


export type MutationDonorsAlumniArgs = {
  input: AlumniDonor;
};


export type MutationDonorsDonationCreateOfflineArgs = {
  offlineDonationData: DonorDonationOfflineInput;
};


export type MutationDonorsDonationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDonorsDonationUpdateArgs = {
  data: DonorDonationUpdateInput;
  id: Scalars['ID']['input'];
};


export type MutationDonorsFundraiserPayableRefundArgs = {
  input: DonorFundraiserPayableRefundInput;
};


export type MutationDonorsGiftShopIntentAddArgs = {
  intentInput: GiftShopIntentInput;
  item: PurchaseItemInput;
};


export type MutationDonorsGiftShopIntentAddManyArgs = {
  intentInput: GiftShopIntentInput;
  items: Array<PurchaseItemInput>;
};


export type MutationDonorsGiftShopIntentMarkProcessingArgs = {
  purchaseIntentId: Scalars['String']['input'];
};


export type MutationDonorsGiftShopIntentRemoveArgs = {
  incentiveId: Scalars['Int']['input'];
  purchaseIntentId: Scalars['String']['input'];
};


export type MutationDonorsGiftShopIntentUpdateArgs = {
  item: PurchaseItemInput;
  purchaseIntentId: Scalars['String']['input'];
  status: PurchaseIntentStatus;
};


export type MutationDonorsGiftShopIntentUpdateIntentArgs = {
  intentInput: GiftShopIntentUpdateInput;
  purchaseIntentId: Scalars['String']['input'];
};


export type MutationDonorsGiftShopPurchaseUpdateArgs = {
  data: PurchaseUpdateInput;
  purchaseId: Scalars['String']['input'];
};


export type MutationDonorsReceiptsResendArgs = {
  transactions: Array<TransactionInput>;
};


export type MutationDriveAddHubspotProspectPinArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveAddWinbackPinArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveCampaignFinalizeArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationDriveCampaignSettleArgs = {
  fundraiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationDriveCampaignUpdateArgs = {
  data: DriveCampaignUpdateInput;
  id: Scalars['Int']['input'];
};


export type MutationDriveDisableActivityArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveDisableOrgActivityArgs = {
  activityId?: InputMaybe<Scalars['String']['input']>;
  activityType?: InputMaybe<Scalars['String']['input']>;
  campaignsCount?: InputMaybe<Scalars['Int']['input']>;
  curricularType?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveEnableActivityArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveEnableOrgActivityArgs = {
  activityId?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveEventActivityCreateArgs = {
  eventId: Scalars['String']['input'];
  input?: InputMaybe<DriveEventActivityInput>;
};


export type MutationDriveEventActivityRemoveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDriveEventActivityUpdateArgs = {
  id: Scalars['ID']['input'];
  input: DriveEventActivityInput;
};


export type MutationDriveEventAgendaCreateArgs = {
  eventId: Scalars['ID']['input'];
  input: DriveEventAgendaInput;
};


export type MutationDriveEventAttendeeAddArgs = {
  eventId: Scalars['ID']['input'];
  input: DriveEventAttendeeInput;
  update?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationDriveEventAttendeeUpdateArgs = {
  eventId: Scalars['ID']['input'];
  input: DriveEventAttendeeInput;
  userId: Scalars['ID']['input'];
};


export type MutationDriveEventAttendeesRemoveArgs = {
  eventId: Scalars['ID']['input'];
  userIds: Array<Scalars['String']['input']>;
};


export type MutationDriveEventCreateArgs = {
  input: DriveEventInput;
};


export type MutationDriveEventRemoveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDriveEventUpdateArgs = {
  id: Scalars['ID']['input'];
  input: DriveEventInput;
};


export type MutationDriveOrgUserTrackingSetArgs = {
  input?: InputMaybe<DriveTrackingInput>;
  orgId: Scalars['String']['input'];
};


export type MutationDrivePreloadSmsScheduleCancelArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationDrivePreloadSmsSendArgs = {
  fundraiserId: Scalars['Int']['input'];
  schedule?: InputMaybe<DriveScheduleInput>;
};


export type MutationDrivePreloadSmsSendScheduleUpdateArgs = {
  fundraiserId: Scalars['Int']['input'];
  schedule: DriveScheduleUpdateInput;
};


export type MutationDriveRemoveHubspotProspectPinArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveRemoveWinbackPinArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveS3ImageResizeArgs = {
  fileUploaded?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveShareProjectedRaisedResultsArgs = {
  activityType: Scalars['String']['input'];
  earlyAccessApprovedFundsMax: Scalars['Int']['input'];
  earlyAccessApprovedFundsMin: Scalars['Int']['input'];
  location: Scalars['String']['input'];
  participantCount: Scalars['Int']['input'];
  projectedRaisedMax: Scalars['Int']['input'];
  projectedRaisedMin: Scalars['Int']['input'];
  to: Scalars['String']['input'];
};


export type MutationDriveUploadUrlCreateArgs = {
  fileName: Scalars['String']['input'];
};


export type MutationEditFundraiserApprovalSubmissionArgs = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>;
  campaignRaiseId?: InputMaybe<Scalars['Int']['input']>;
  customerSupportInfo?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['String']['input']>;
  estimatedTotalRaisedCents?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  formType?: InputMaybe<FundraiserApprovalSubmissionFormType>;
  fundraiserApprovalSubmissionDocs: Array<InputMaybe<FundraiserApprovalSubmissionDocumentInput>>;
  fundraiserApprovers: Array<InputMaybe<FundraiserApproverInput>>;
  fundraiserServiceWebsite?: InputMaybe<Scalars['String']['input']>;
  groupSize?: InputMaybe<Scalars['Int']['input']>;
  howThisWorks?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  newFundraiser?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  pricingAndFees?: InputMaybe<Scalars['String']['input']>;
  programId?: InputMaybe<Scalars['String']['input']>;
  proposedStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  revisionRequest?: InputMaybe<Scalars['String']['input']>;
  safety?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<FundraiserApprovalSubmissionStatus>;
};


export type MutationErrorArgs = {
  payload: Scalars['JSON']['input'];
  service: AuditLogService;
  source: AuditLogSource;
};


export type MutationEventCreateArgs = {
  input?: InputMaybe<CreateEventInput>;
};


export type MutationEventDeleteArgs = {
  input?: InputMaybe<DeleteEventInput>;
};


export type MutationEventModifyArgs = {
  input?: InputMaybe<ModifyEventInput>;
};


export type MutationEventParticipantsDeleteManyArgs = {
  input?: InputMaybe<DeleteManyEventParticipantsInput>;
};


export type MutationEventParticipantsUpsertArgs = {
  input?: InputMaybe<UpsertEventParticipantsInput>;
};


export type MutationEventPreparationCreateArgs = {
  input?: InputMaybe<CreateEventPreparationInput>;
};


export type MutationEventPreparationDeleteArgs = {
  input?: InputMaybe<DeleteEventPreparationInput>;
};


export type MutationEventPreparationModifyArgs = {
  input?: InputMaybe<ModifyEventPreparationInput>;
};


export type MutationEventPreparationsCreateManyArgs = {
  input?: InputMaybe<Array<InputMaybe<EventPreparationsInput>>>;
};


export type MutationEventPreparationsDeleteManyArgs = {
  input?: InputMaybe<DeleteManyEventPreparationsInput>;
};


export type MutationEventPreparationsModifyManyArgs = {
  input?: InputMaybe<ModifyManyEventPreparationsByEventInput>;
};


export type MutationEventPreparationsUpsertArgs = {
  input?: InputMaybe<UpsertEventPreparationsInput>;
};


export type MutationEventResultArchiveArgs = {
  id: Scalars['ID']['input'];
  origin: Scalars['String']['input'];
};


export type MutationEventResultCreateArgs = {
  eventId: Scalars['ID']['input'];
  eventResult: EventResultInput;
  origin: Scalars['String']['input'];
};


export type MutationEventResultUpdateArgs = {
  eventResult: EventResultInput;
  eventResultId: Scalars['ID']['input'];
  origin: Scalars['String']['input'];
};


export type MutationEventResultsUpdateArgs = {
  eventResultIds: Array<InputMaybe<Scalars['ID']['input']>>;
  eventResults: Array<InputMaybe<EventResultInput>>;
  origin: Scalars['String']['input'];
};


export type MutationEventTransportDetailsDeleteManyArgs = {
  input?: InputMaybe<DeleteManyEventTransportDetailsInput>;
};


export type MutationEventTransportDetailsUpsertManyArgs = {
  input?: InputMaybe<UpsertEventTransportDetailsInput>;
};


export type MutationFacilitiesUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertFacilitiesInput>>>;
};


export type MutationFacilityCreateArgs = {
  input?: InputMaybe<CreateFacilityInput>;
};


export type MutationFacilityDeleteArgs = {
  input?: InputMaybe<DeleteFacilityInput>;
};


export type MutationFacilityModifyArgs = {
  input?: InputMaybe<ModifyFacilityInput>;
};


export type MutationFinAcctUserAssociationCreateArgs = {
  finAcctId: Scalars['ID']['input'];
  isBeneficialOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isContact?: InputMaybe<Scalars['Boolean']['input']>;
  isPrincipal?: InputMaybe<Scalars['Boolean']['input']>;
  isRepresentative?: InputMaybe<Scalars['Boolean']['input']>;
  kycId?: InputMaybe<Scalars['ID']['input']>;
  personId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationFinAcctUserAssociationDeleteArgs = {
  finAcctId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationFinAcctUserAssociationUpdateArgs = {
  finAcctId: Scalars['ID']['input'];
  isBeneficialOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isContact?: InputMaybe<Scalars['Boolean']['input']>;
  isPrincipal?: InputMaybe<Scalars['Boolean']['input']>;
  isRepresentative?: InputMaybe<Scalars['Boolean']['input']>;
  kycId?: InputMaybe<Scalars['ID']['input']>;
  personId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationFinancialAcctCreateArgs = {
  acctId?: InputMaybe<Scalars['ID']['input']>;
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  kybId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  processor: Processor;
  status?: FinAcctStatus;
  stripeEnv?: FinAcctStripeEnv;
};


export type MutationFinancialAcctDeleteArgs = {
  finAcctId: Scalars['ID']['input'];
};


export type MutationFinancialAcctUpdateArgs = {
  acctId?: InputMaybe<Scalars['ID']['input']>;
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  finAcctId: Scalars['ID']['input'];
  kybId?: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  recipientName?: InputMaybe<Scalars['String']['input']>;
  settlementMethod?: InputMaybe<SettlementMethod>;
  stateCode?: InputMaybe<StateCode>;
  status?: InputMaybe<FinAcctStatus>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetLine2?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationFundraiserParticipantUpdateArgs = {
  input: FundraiserParticipantUpdateInput;
  raiseFundraiserId: Scalars['Int']['input'];
};


export type MutationFundraiserStoreUrlUpdateArgs = {
  fundraiserId: Scalars['Int']['input'];
  storeUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationFundraisersDonationInviteDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationFundraisersDonationInviteUpdateArgs = {
  id: Scalars['ID']['input'];
  input: FundraisersDonationInviteUpdateInput;
};


export type MutationGroupLeaderBlockedFundraiserDonationInviteDomainsUpdateArgs = {
  fundraiserId: Scalars['ID']['input'];
  input: GroupLeaderBlockedDomainsUpdateInput;
};


export type MutationGroupLeaderEmailSendArgs = {
  ccEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  fundraiserId: Scalars['Int']['input'];
  groupLeaderEmail: Scalars['String']['input'];
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};


export type MutationGroupLeaderFundraiserAcknowledgementSignArgs = {
  fundraiserId: Scalars['ID']['input'];
  input: GroupLeaderFundraiserAcknowledgementSignInput;
};


export type MutationGroupLeaderFundraiserAddressUpdateArgs = {
  addressType: FundraiserAddressType;
  fundraiserId: Scalars['ID']['input'];
  input: GroupLeaderFundraiserAddressInput;
};


export type MutationGroupLeaderFundraiserDonationLevelsUpdateArgs = {
  fundraiserId: Scalars['ID']['input'];
  input: Array<GroupLeaderDonationLevelsInput>;
};


export type MutationGroupLeaderFundraiserFinancialsUpdateArgs = {
  fundraiserId: Scalars['ID']['input'];
  input: GroupLeaderEditFundraiserFinancialsInput;
};


export type MutationGroupLeaderFundraiserManagerUpdateArgs = {
  fundraiserId: Scalars['ID']['input'];
  input: GroupLeaderFundraiserManagerUpdateInput;
};


export type MutationGroupLeaderFundraiserMediaGalleryUpdateArgs = {
  fundraiserId: Scalars['ID']['input'];
  input: GroupLeaderFundraiserMediaGalleryUpdateInput;
};


export type MutationGroupLeaderFundraiserParticipantsUpdateArgs = {
  fundraiserId: Scalars['ID']['input'];
  input: GroupLeaderParticipantsUpdateInput;
};


export type MutationGroupLeaderFundraiserUpdateArgs = {
  fundraiserId: Scalars['ID']['input'];
  input: GroupLeaderEditFundraiserInput;
};


export type MutationGroupLeaderGearLogoConfigurationUpdateArgs = {
  fundraiserId: Scalars['ID']['input'];
  input: GroupLeaderGearLogoUpdateInput;
};


export type MutationGroupLeaderMarkFundraiserManagerAlertAsReadArgs = {
  alertId: Scalars['ID']['input'];
};


export type MutationGroupLeaderParticipantJoinFundraiserInviteArgs = {
  fundraiserId: Scalars['String']['input'];
  inviteType: JoinFundraiserInviteType;
  invites: Array<Scalars['String']['input']>;
};


export type MutationGroupLeaderSendEmailsArgs = {
  fundraiserId: Scalars['ID']['input'];
  input: GroupLeaderEmailInput;
};


export type MutationHelpDocumentDeleteArgs = {
  input: HelpDocumentDeleteInput;
};


export type MutationHelpDocumentUploadArgs = {
  input: HelpDocumentUploadInput;
};


export type MutationHubspotCallEngagementToDealAddArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationHubspotNoteEngagementToDealAddArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationInfoArgs = {
  payload: Scalars['JSON']['input'];
  service: AuditLogService;
  source: AuditLogSource;
};


export type MutationInsightsAddPreApprovedContactsArgs = {
  approver: InsAddPreApprovedContactApproverInput;
  contacts: Array<InputMaybe<InsPreApprovedContactInput>>;
  dashboardUrl: Scalars['String']['input'];
  org: InsAddPreApprovedContactOrgInput;
};


export type MutationInsightsDeletePreApprovedContactArgs = {
  contactId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};


export type MutationInsightsEditPreApprovedContactArgs = {
  contactId: Scalars['Int']['input'];
  orgId: Scalars['String']['input'];
  senderName: Scalars['String']['input'];
  updatedContact: InsEditPreApprovedContactInput;
};


export type MutationInsightsEditPreApprovedContactInviteArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInsightsResendInvitePreApprovedContactArgs = {
  contactId: Scalars['Int']['input'];
  senderName: Scalars['String']['input'];
};


export type MutationInsightsSetNotificationPreferencesArgs = {
  preferences: Array<InputMaybe<InsSetNotificationPreferences>>;
  userId: Scalars['String']['input'];
};


export type MutationInsightsSetUserPreferencesArgs = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  orgIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  setTo: SetUserPreferenceFlag;
  userId: Scalars['String']['input'];
};


export type MutationInviteAcceptArgs = {
  inviteId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInviteCreateArgs = {
  email: Scalars['String']['input'];
  parent?: InputMaybe<InviteParentArguments>;
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<InviteType>;
  user?: InputMaybe<InviteUserArguments>;
};


export type MutationInviteDeleteArgs = {
  inviteId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInviteResendArgs = {
  inviteId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationJoinFundraiserUserArgs = {
  joinCode: Scalars['String']['input'];
  joinType?: InputMaybe<JoinType>;
  role: CampaignMemberAssociation;
};


export type MutationJoinFundraiserUserByIdArgs = {
  fundraiserId: Scalars['Int']['input'];
  importType?: InputMaybe<FundraiserUserImportType>;
  role: CampaignMemberAssociation;
  ssoId: Scalars['String']['input'];
  user: JoinFundraiserUserInput;
};


export type MutationLatestVaultCardCreateArgs = {
  altName?: InputMaybe<Scalars['String']['input']>;
  attachCardToKybBalance: Scalars['Boolean']['input'];
  cardType: VaultCardType;
  kybId: Scalars['ID']['input'];
  metadata?: InputMaybe<VaultCardMetadataInput>;
  recipientName?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<VaultAddressInput>;
  shippingService?: InputMaybe<VaultShippingService>;
  spendingLimitAmount: Scalars['Int']['input'];
  spendingLimitInterval: VaultSpendingLimitInterval;
};


export type MutationLevelCreateArgs = {
  input?: InputMaybe<CreateLevelInput>;
};


export type MutationLevelDeleteArgs = {
  input?: InputMaybe<DeleteLevelInput>;
};


export type MutationLevelModifyArgs = {
  input?: InputMaybe<ModifyLevelInput>;
};


export type MutationLogArgs = {
  payload: Scalars['JSON']['input'];
  service: AuditLogService;
  source: AuditLogSource;
  type: AuditLogType;
};


export type MutationLoginArgs = {
  consumer?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLogoutArgs = {
  consumer?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMagentoRefundWebhookArgs = {
  magentoRefund?: InputMaybe<MagentoRefundInput>;
};


export type MutationMagentoTransactionWebhookArgs = {
  magentoEvent?: InputMaybe<MagentoConsumerPointsLiabilityInput>;
};


export type MutationManageUserCreateArgs = {
  input?: InputMaybe<ManageUserCreateInput>;
};


export type MutationManageUserDeleteArgs = {
  input?: InputMaybe<ManageUserDeleteInput>;
};


export type MutationManageUserUpdateArgs = {
  input?: InputMaybe<ManageUserUpdateInput>;
};


export type MutationMarcoWebhookArgs = {
  status: MarcoEventInput;
};


export type MutationMessagesChannelAddModeratorsArgs = {
  id: Scalars['String']['input'];
  moderatorIds: Array<Scalars['String']['input']>;
};


export type MutationMessagesChannelAddUsersArgs = {
  id: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']>;
};


export type MutationMessagesChannelCreateArgs = {
  assignmentId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  moderatorIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  type: ChannelTypesEnum;
  userIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationMessagesChannelInviteAcceptArgs = {
  inviteId: Scalars['ID']['input'];
  userId: Scalars['String']['input'];
};


export type MutationMessagesChannelInviteMembersArgs = {
  contacts: Array<InviteMemberContactInput>;
  id: Scalars['ID']['input'];
  users: Array<InviteMemberUsersInput>;
};


export type MutationMessagesChannelInviteResendArgs = {
  id: Scalars['String']['input'];
  inviteId: Scalars['ID']['input'];
};


export type MutationMessagesChannelRemoveMembersArgs = {
  id: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']>;
};


export type MutationMessagesChannelUpdateArgs = {
  channel?: InputMaybe<ChannelUpdateInput>;
  id: Scalars['String']['input'];
};


export type MutationMessagesChannelUpdateMembersArgs = {
  assignment?: InputMaybe<AssignmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  moderatorIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationMessagesUpsertUsersArgs = {
  userIds: Array<Scalars['String']['input']>;
};


export type MutationNetsuiteWebhookArgs = {
  netsuiteEvent?: InputMaybe<NetsuiteEventInput>;
};


export type MutationNoticeCreateArgs = {
  input?: InputMaybe<NoticeCreateInput>;
};


export type MutationNoticeDeleteArgs = {
  input?: InputMaybe<NoticeDeleteInput>;
};


export type MutationNoticeModifyArgs = {
  input?: InputMaybe<NoticeModifyInput>;
};


export type MutationOauthCallbackArgs = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  provider: UserProviders;
  providerUserId: Scalars['String']['input'];
};


export type MutationOfficialCreateArgs = {
  input?: InputMaybe<CreateOfficialInput>;
};


export type MutationOfficialDeleteArgs = {
  input?: InputMaybe<DeleteOfficialInput>;
};


export type MutationOfficialDutiesDeleteArgs = {
  input?: InputMaybe<DeleteOfficialDutiesInput>;
};


export type MutationOfficialDutiesUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertOfficialDutiesInput>>>;
};


export type MutationOfficialDutyCreateArgs = {
  input?: InputMaybe<CreateOfficialDutyInput>;
};


export type MutationOfficialDutyModifyArgs = {
  input?: InputMaybe<ModifyOfficialDutyInput>;
};


export type MutationOfficialModifyArgs = {
  input?: InputMaybe<ModifyOfficialInput>;
};


export type MutationOfficialPoolCreateArgs = {
  input?: InputMaybe<CreateOfficialPoolByIdInput>;
};


export type MutationOfficialPoolDeleteArgs = {
  input?: InputMaybe<DeleteOfficialPoolInput>;
};


export type MutationOfficialPoolModifyArgs = {
  input?: InputMaybe<ModifyOfficialPoolByIdInput>;
};


export type MutationOfficialsUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertOfficialsInput>>>;
};


export type MutationOpponentCreateArgs = {
  input?: InputMaybe<CreateOpponentInput>;
};


export type MutationOpponentDeleteArgs = {
  input?: InputMaybe<DeleteOpponentInput>;
};


export type MutationOpponentModifyArgs = {
  input?: InputMaybe<ModifyOpponentInput>;
};


export type MutationOpponentsUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertOpponentsInput>>>;
};


export type MutationOrgAffiliateCreateArgs = {
  input: OrgAffiliateInput;
};


export type MutationOrgClubCreateArgs = {
  input: OrgClubInput;
};


export type MutationOrgDistrictCreateArgs = {
  input: OrgDistrictInput;
};


export type MutationOrgLabelUpdateArgs = {
  auxiliary_type?: InputMaybe<ModType>;
  label: OrgType;
  modId: Scalars['ID']['input'];
  parentModId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationOrgProgramCreateArgs = {
  activity: ProgramActivity;
  orgId: Scalars['ID']['input'];
};


export type MutationOrgSchoolCreateArgs = {
  input: OrgSchoolInput;
};


export type MutationOrgTeamCreateArgs = {
  activity: ProgramActivity;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  teamAge: TeamAge;
  teamGender: TeamGender;
  teamGroupType: TeamGroupType;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  teamTier: TeamTier;
};


export type MutationParticipantDonationInviteEditArgs = {
  campaignID: Scalars['String']['input'];
  donationInviteID: Scalars['String']['input'];
  newEmail: Scalars['String']['input'];
};


export type MutationParticipantDonationInviteEmailAddArgs = {
  campaignID: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type MutationParticipantDonationInviteRemoveArgs = {
  email: Scalars['String']['input'];
  fundraiserId: Scalars['String']['input'];
  personListEntryId: Scalars['String']['input'];
};


export type MutationParticipantDonationInviteSmsAddArgs = {
  campaignID: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationParticipantDonationInvitesUpdateArgs = {
  action: ParticipantDonationInvitesAction;
  raiseFundraiserId: Scalars['Int']['input'];
};


export type MutationParticipantGuardianEmailUpdateArgs = {
  guardianEmail: Scalars['String']['input'];
  secondGuardianEmail?: InputMaybe<Scalars['String']['input']>;
};


export type MutationParticipantIncentiveSelectionDeleteArgs = {
  fundraiserId: Scalars['ID']['input'];
  fundraiserUserIncentiveId: Scalars['ID']['input'];
};


export type MutationParticipantRewardsBulkCreateArgs = {
  fundraiserId: Scalars['Int']['input'];
  rewards: Array<ParticipantRewardsInput>;
};


export type MutationParticipantRewardsCreateArgs = {
  fundraiserId: Scalars['Int']['input'];
  incentiveId: Scalars['Int']['input'];
  size: Scalars['String']['input'];
  tier: Scalars['Int']['input'];
};


export type MutationParticipantSignupArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationParticipantSmsDonationInvitesAddArgs = {
  fundraiserId?: InputMaybe<Scalars['String']['input']>;
  phoneNumbers: Array<Scalars['String']['input']>;
  raiseFundraiserId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationParticipantSmsDonationInvitesRemoveArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationParticipantsEmailSendArgs = {
  fundraiserId: Scalars['Int']['input'];
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};


export type MutationParticipantsTextSendArgs = {
  fundraiserId: Scalars['Int']['input'];
  message: Scalars['String']['input'];
};


export type MutationPaymentsApiCustomerGetOrCreateArgs = {
  stripeEnv?: InputMaybe<StripeEnv>;
};


export type MutationPaymentsApiDetachPaymentMethodArgs = {
  input?: InputMaybe<PaymentsApiDetachPaymentMethodInput>;
};


export type MutationPaymentsApiPaymentArgs = {
  input?: InputMaybe<PaymentsApiPaymentIput>;
};


export type MutationPaymentsApiRefundArgs = {
  input?: InputMaybe<PaymentsApiRefundInput>;
};


export type MutationPreloadEmailRescheduleSendArgs = {
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  fundraiserId: Scalars['Int']['input'];
  offsetInSeconds?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPreloadEmailScheduleCancelArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationPreloadEmailScheduleSendArgs = {
  dateTime: Scalars['DateTime']['input'];
  fundraiserId: Scalars['Int']['input'];
  timezone: Scalars['String']['input'];
};


export type MutationPreloadEmailSendArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationPreparationCreateArgs = {
  input?: InputMaybe<CreatePreparationInput>;
};


export type MutationPreparationDeleteArgs = {
  input?: InputMaybe<DeletePreparationInput>;
};


export type MutationPreparationModifyArgs = {
  input?: InputMaybe<ModifyPreparationInput>;
};


export type MutationPushNotificationSendArgs = {
  input: SendNotificationInput;
};


export type MutationRaiseAdminFundraiserUsersCreateManyArgs = {
  input: RaiseFundraiserUsersCreateManyInput;
};


export type MutationRoleCreateArgs = {
  data: RoleArguments;
};


export type MutationRoleDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationRolePermissionsUpdateArgs = {
  permissionIds: Array<Scalars['String']['input']>;
  roleId: Scalars['String']['input'];
};


export type MutationRoleUpdateArgs = {
  data: RoleArguments;
  id: Scalars['String']['input'];
};


export type MutationRosterCloneArgs = {
  input: RosterCloneInput;
};


export type MutationRosterCreateArgs = {
  input: RosterCreateInput;
};


export type MutationRosterUpdateArgs = {
  input: RosterUpdateInput;
};


export type MutationS3PresignedInsightsApprovalsUrlArgs = {
  approvalsFormId: Scalars['Int']['input'];
  approvalsSubmissionId: Scalars['Int']['input'];
  files: Array<InputMaybe<Scalars['NonEmptyString']['input']>>;
};


export type MutationSamlCallbackArgs = {
  actualEmail?: InputMaybe<Scalars['String']['input']>;
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consumer?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationScheduleEventArchiveArgs = {
  id: Scalars['ID']['input'];
  origin: Scalars['String']['input'];
};


export type MutationScheduleEventCreateArgs = {
  event: ScheduleEventInput;
  origin: Scalars['String']['input'];
};


export type MutationScheduleEventUpdateArgs = {
  event: ScheduleEventInput;
  id: Scalars['ID']['input'];
  origin: Scalars['String']['input'];
};


export type MutationSchoolInfoCreateArgs = {
  input?: InputMaybe<CreateSchoolInfoInput>;
};


export type MutationSchoolInfoDeleteArgs = {
  input?: InputMaybe<DeleteSchoolInfoInput>;
};


export type MutationSchoolInfoModifyArgs = {
  input?: InputMaybe<ModifySchoolInfoInput>;
};


export type MutationSeasonCreateArgs = {
  input?: InputMaybe<SeasonCreateInput>;
};


export type MutationSeasonDeleteArgs = {
  input?: InputMaybe<SeasonDeleteInput>;
};


export type MutationSeasonModifyArgs = {
  input?: InputMaybe<SeasonModifyInput>;
};


export type MutationSeasonPostponeModifyArgs = {
  input?: InputMaybe<SeasonPostponeUnpostponeInput>;
};


export type MutationSeasonScheduleTeamsCreateArgs = {
  input?: InputMaybe<SeasonScheduleTeamsCreateInput>;
};


export type MutationSeasonUnpostponeModifyArgs = {
  input?: InputMaybe<SeasonPostponeUnpostponeInput>;
};


export type MutationSetUserInsightsConfigurationsArgs = {
  config: Array<InputMaybe<UserInsightsConfigInput>>;
};


export type MutationSettlementIssuesRecordArgs = {
  fundraiserId: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSettlementStatusUpdateArgs = {
  fundraiserId: Scalars['Int']['input'];
  settlementStatus?: InputMaybe<SettlementStatus>;
};


export type MutationSnapMobileOneAccessRequestArgs = {
  input: SnapMobileOneAccessInput;
};


export type MutationSnapMobileOneContactSupportArgs = {
  input: SnapMobileOneContactSupportInput;
};


export type MutationSnapMobileOneCreateArgs = {
  input: SnapMobileOneAccessInput;
};


export type MutationSnapMobileOneProductActivateArgs = {
  input: SnapMobileOneProductInput;
};


export type MutationSnapMobileOneProductDeleteArgs = {
  input: SnapMobileOneProductInput;
};


export type MutationSnapMobileOneProductRequestArgs = {
  input: SnapMobileOneProductInput;
};


export type MutationSnapMobileOneUpdateArgs = {
  input: SnapMobileOneAccessInput;
};


export type MutationSpendAdminAllowCheckIdArgs = {
  input: SpendCheckImageAllowance;
};


export type MutationSpendAdminApprovalUpdateArgs = {
  orgId: Scalars['String']['input'];
};


export type MutationSpendAdminBulkAddUnitArgs = {
  input?: InputMaybe<SpendAdminBulkAddUnitInput>;
};


export type MutationSpendAdminCloseCustomerArgs = {
  orgId: Scalars['String']['input'];
};


export type MutationSpendAdminUnarchiveGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationSpendArchiveSeasonMembersArgs = {
  input: SpendArchiveSeasonMembersInput;
};


export type MutationSpendAuthorizeAutoPayArgs = {
  input?: InputMaybe<SpendAutoPayInput>;
};


export type MutationSpendBudgetCreateArgs = {
  input: SpendBudgetInput;
};


export type MutationSpendBudgetDeleteArgs = {
  id: Scalars['String']['input'];
  replace?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSpendBudgetUpdateArgs = {
  id: Scalars['String']['input'];
  input: SpendBudgetInput;
};


export type MutationSpendCategoryCreateArgs = {
  input: SpendCategoryInput;
};


export type MutationSpendCategoryUpdateArgs = {
  id: Scalars['String']['input'];
  input: SpendCategoryInput;
};


export type MutationSpendCategoryUpsertBulkArgs = {
  input?: InputMaybe<Array<InputMaybe<SpendUpsertCategoryInput>>>;
};


export type MutationSpendCreditMemoCreateArgs = {
  input: SpendCreditMemoInput;
};


export type MutationSpendCreditMemoUpdateArgs = {
  input: SpendCreditMemoUpdateInput;
};


export type MutationSpendGroupAchCreditArgs = {
  input?: InputMaybe<SpendGroupAchCredit>;
};


export type MutationSpendGroupArchiveArgs = {
  id: Scalars['String']['input'];
};


export type MutationSpendGroupBankAccessTokenCreateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  publicToken: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSpendGroupBankAccessTokenDeleteArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSpendGroupCheckCancelArgs = {
  checkId: Scalars['String']['input'];
};


export type MutationSpendGroupCheckDepositPatchArgs = {
  input: SpendGroupCheckDepositTagsInput;
};


export type MutationSpendGroupCheckSendArgs = {
  input?: InputMaybe<SpendCheckInput>;
};


export type MutationSpendGroupCounterpartyCreateArgs = {
  input?: InputMaybe<SpendCounterpartyCreateInput>;
};


export type MutationSpendGroupCounterpartyDeleteArgs = {
  counterpartyId: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
};


export type MutationSpendGroupCreateArgs = {
  input: SpendGroupInput;
};


export type MutationSpendGroupDebitCardCreateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  input: SpendDebitCardInput;
};


export type MutationSpendGroupDuplicateArgs = {
  id: Scalars['String']['input'];
};


export type MutationSpendGroupExternalTransferArgs = {
  input: SpendGroupExternalTransferInput;
};


export type MutationSpendGroupPayeeCreateArgs = {
  input: SpendPayeeInput;
};


export type MutationSpendGroupPayeeUpdateArgs = {
  input: SpendPayeeUpdateInput;
};


export type MutationSpendGroupRosterCreateArgs = {
  input: SpendGroupRosterInput;
};


export type MutationSpendGroupRosterUpdateArgs = {
  input: SpendRosterUserUpdate;
};


export type MutationSpendGroupRostersCreateArgs = {
  input: SpendGroupRostersInput;
};


export type MutationSpendGroupUnArchiveArgs = {
  id: Scalars['String']['input'];
};


export type MutationSpendGroupUpdateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  input: SpendGroupInput;
};


export type MutationSpendGuardianSignupArgs = {
  input: SpendGuardianSignupInput;
};


export type MutationSpendInvoiceArchiveArgs = {
  id: Scalars['String']['input'];
};


export type MutationSpendInvoiceChangeRequestArgs = {
  input: SpendInvoiceRequestChangeInput;
};


export type MutationSpendInvoiceCreateArgs = {
  input: SpendInvoiceInput;
};


export type MutationSpendInvoiceOptInOrOutArgs = {
  input: SpendOptInOrOutInput;
};


export type MutationSpendInvoicePaymentDeauthorizeArgs = {
  input: SpendInvoicePaymentDeauthorizeInput;
};


export type MutationSpendInvoiceRefundArgs = {
  input: SpendInvoiceRefundInput;
};


export type MutationSpendInvoiceReminderCreateArgs = {
  input: SpendInvoiceReminderInput;
};


export type MutationSpendInvoiceUpdateArgs = {
  id: Scalars['String']['input'];
  input: SpendInvoiceInput;
};


export type MutationSpendInvoiceUpdateManyArgs = {
  input: SpendUpdateInvoices;
};


export type MutationSpendInvoiceUpdatePaymentMethodArgs = {
  input: SpendInvoicePaymentMethodUpdate;
};


export type MutationSpendNotificationCreateArgs = {
  input: SpendNotificationInput;
};


export type MutationSpendOrganizationAchCreditArgs = {
  input?: InputMaybe<SpendOrgAchCredit>;
};


export type MutationSpendOrganizationAccountTransferArgs = {
  input: SpendAccountTransferInput;
};


export type MutationSpendOrganizationAdminUpdateArgs = {
  input: SpendOrgAdminUpdateInput;
};


export type MutationSpendOrganizationBankAccessTokenCreateArgs = {
  publicToken: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSpendOrganizationCheckCancelArgs = {
  checkId: Scalars['String']['input'];
};


export type MutationSpendOrganizationCheckDepositPatchArgs = {
  input: SpendOrganizationCheckDepositTagsInput;
};


export type MutationSpendOrganizationCheckSendArgs = {
  input?: InputMaybe<SpendCheckInput>;
};


export type MutationSpendOrganizationCounterpartyCreateArgs = {
  input?: InputMaybe<SpendCounterpartyCreateInput>;
};


export type MutationSpendOrganizationCounterpartyDeleteArgs = {
  counterpartyId: Scalars['String']['input'];
};


export type MutationSpendOrganizationCreateArgs = {
  externalId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationSpendOrganizationDebitCardCreateArgs = {
  input: SpendDebitCardInput;
};


export type MutationSpendOrganizationExternalTransferArgs = {
  input: SpendOrganizationExternalTransferInput;
};


export type MutationSpendOrganizationPayeeCreateArgs = {
  input: SpendPayeeInput;
};


export type MutationSpendOrganizationPayeeUpdateArgs = {
  input: SpendPayeeUpdateInput;
};


export type MutationSpendOrganizationUpdateArgs = {
  data: SpendOrganizationInput;
  where: Scalars['ID']['input'];
};


export type MutationSpendPaymentMethodCreateArgs = {
  input: SpendPaymentMethodInput;
};


export type MutationSpendPaymentScheduleCreateArgs = {
  input: SpendPaymentScheduleInput;
};


export type MutationSpendPaymentScheduleRevertArgs = {
  id: Scalars['String']['input'];
};


export type MutationSpendPaymentScheduleRevertManyArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationSpendPaymentScheduleUpdateArgs = {
  id: Scalars['String']['input'];
  input: SpendPaymentScheduleInput;
  rosterIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationSpendPaymentScheduleUpdateBySeasonArgs = {
  input: SpendPaymentScheduleBySeasonInput;
};


export type MutationSpendRemoveParticipantsArgs = {
  input: SpendRemoveSeasonMemberInput;
};


export type MutationSpendRoleChangeArgs = {
  roleId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationSpendSeasonCreateArgs = {
  input: SpendSeasonInput;
};


export type MutationSpendSeasonUpdateArgs = {
  id: Scalars['String']['input'];
  input: SpendSeasonInput;
};


export type MutationSpendSessionCreateArgs = {
  inviteId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSpendSessionRefreshArgs = {
  sessionId: Scalars['String']['input'];
};


export type MutationSpendSettingsUpdateArgs = {
  input?: InputMaybe<SpendSettingsInput>;
};


export type MutationSpendSettingsUpdateAllArgs = {
  input?: InputMaybe<SpendSettingsInput>;
};


export type MutationSpendSignupAgreementCreateArgs = {
  input: SpendSignupAgreementInput;
};


export type MutationSpendStopCheckPaymentArgs = {
  checkId: Scalars['String']['input'];
};


export type MutationSpendSystemNotificationCreateArgs = {
  input?: InputMaybe<SpendSystemNotificationCreateInput>;
};


export type MutationSpendSystemNotificationUpdateArgs = {
  input?: InputMaybe<SpendSystemNotificationUpdateInput>;
};


export type MutationSpendTransactionAttachmentCreateArgs = {
  input: SpendTransactionAttachmentInput;
};


export type MutationSpendTransactionAttachmentDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationSpendTransactionAttachmentDeleteByKeyArgs = {
  attachmentKey: Scalars['String']['input'];
};


export type MutationSpendTransactionBudgetUnreconcileArgs = {
  input: SpendTransactionBudgetUnreconcileInput;
};


export type MutationSpendTransactionInvoiceUnreconcileArgs = {
  input: SpendTransactionInvoiceUnreconcileInput;
};


export type MutationSpendTransactionNoteCreateArgs = {
  input: SpendTranscationNoteInput;
};


export type MutationSpendTransactionNoteUpdateArgs = {
  id: Scalars['String']['input'];
  input: SpendTranscationNoteInput;
};


export type MutationSpendTransactionReconcileArgs = {
  input: SpendTransactionReconcileInput;
};


export type MutationSpendTransactionReconcileV2Args = {
  input: SpendTransactionReconcileV2Input;
};


export type MutationSpendTransactionsCreateArgs = {
  input: SpendTransactionInput;
};


export type MutationSpendUnitFileUploadArgs = {
  fileToUpload: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationSpendUpdateApprovalArgs = {
  input?: InputMaybe<SpendApprovalUpdateInput>;
};


export type MutationSpendUserAccountUpdateArgs = {
  input: SpendUserAccountUpdateInput;
};


export type MutationSpendUserAchPaymentArgs = {
  input: SpendAchPaymentInput;
};


export type MutationSpendUserAchPaymentCancelArgs = {
  input: SpendAchPaymentCancelInput;
};


export type MutationSpendUserAcknowledgeNotificationArgs = {
  input?: InputMaybe<SpendUserAcknowledgeNotificationInput>;
};


export type MutationSpendUserBankAccessTokenCreateArgs = {
  externalAuthId?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['String']['input'];
  publicToken: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSpendUserBankAccessTokenDeleteArgs = {
  counterpartyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSpendUserEmailUpdateArgs = {
  input?: InputMaybe<SpendUserEmailUpdateInput>;
};


export type MutationSpendUserInviteArchiveArgs = {
  id: Scalars['String']['input'];
};


export type MutationSpendUserInviteCreateArgs = {
  input: SpendInviteInput;
};


export type MutationSpendUserInviteDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationSpendUserInviteDismissArgs = {
  inviteId: Scalars['String']['input'];
};


export type MutationSpendUserInviteResendArgs = {
  input: SpendInviteResendInput;
};


export type MutationSpendUserInviteResendAllArgs = {
  input: SpendInviteResendAllInput;
};


export type MutationSpendUserInviteStatusUpdateArgs = {
  id: Scalars['String']['input'];
  status: Scalars['String']['input'];
};


export type MutationSpendUserInviteUpdateArgs = {
  id: Scalars['String']['input'];
};


export type MutationSpendUserLeaveGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationSpendUserLoginAuditArgs = {
  input: SpendUserLoginAuditInputV2;
};


export type MutationSpendUserNotificationSettingUpdateArgs = {
  input?: InputMaybe<SpendUserNotificationSettingInput>;
};


export type MutationSpendUserPaymentsCardDetachArgs = {
  input?: InputMaybe<SpendPaymentMethodDetach>;
};


export type MutationSpendUserRoleArchiveArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type MutationSpendUserRoleSetArgs = {
  roleId: Scalars['String']['input'];
};


export type MutationSpendUserRoleUpdateArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  inviteId: Scalars['String']['input'];
  roleName: SpendRoleNameEnum;
  userId: Scalars['String']['input'];
};


export type MutationSpendUserRoleUpdateIsApproverArgs = {
  input?: InputMaybe<SpendIsApproverUpdateInput>;
};


export type MutationSpendUserSignUpArgs = {
  input: SpendSignUpInput;
};


export type MutationSpendUserSignupUpdateArgs = {
  input?: InputMaybe<SpendUserSignupInput>;
};


export type MutationStoreBuildRequestArgs = {
  input: StoreBuildRequestInput;
};


export type MutationStoreEditInfoArgs = {
  input: StoreEditInfoInput;
};


export type MutationStoreManagerPointsUpdateArgs = {
  input?: InputMaybe<StoreManagerUpdatePoints>;
};


export type MutationStorePaymentIntentCreateArgs = {
  input: StoreCreatePaymentIntent;
};


export type MutationStorePointsWithdrawalRequestArgs = {
  input?: InputMaybe<StorePointsWithdrawalRequestInput>;
};


export type MutationStoreProfitPercentageUpdateArgs = {
  store_id: Scalars['Int']['input'];
  updated_product_price_percentage: Scalars['Int']['input'];
};


export type MutationStoreTicketCreateArgs = {
  input: StoreTicketInput;
};


export type MutationStoreTransactionSaveArgs = {
  input: StoreSaveTransaction;
};


export type MutationStoreTransferToCustomerArgs = {
  input?: InputMaybe<StoreTransferCustomer>;
};


export type MutationStoreTransferToGlArgs = {
  input?: InputMaybe<StoreTransferGl>;
};


export type MutationStoreTransferToParticipantArgs = {
  input?: InputMaybe<StoreTransferParticipant>;
};


export type MutationStoreUpdatePayableArgs = {
  input?: InputMaybe<StoreUpdatePayableInput>;
  scopeId: Scalars['Int']['input'];
};


export type MutationStoreUpdateStatusArgs = {
  input: StoreStatusInput;
};


export type MutationStoreUserPreferenceUpsertArgs = {
  featureTourCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
};


export type MutationSubmitFundraiserApprovalSubmissionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationSupportTicketSendArgs = {
  attachments?: InputMaybe<Array<DriveFileInput>>;
  ccEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  deviceInfo?: InputMaybe<Scalars['String']['input']>;
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  supportTicketInfo?: InputMaybe<Array<DriveFieldInput>>;
  templateId?: InputMaybe<Scalars['String']['input']>;
  to: Support_Ticket_Department;
};


export type MutationTeamCreateArgs = {
  input?: InputMaybe<TeamCreateInput>;
};


export type MutationTeamDeleteArgs = {
  input?: InputMaybe<TeamDeleteInput>;
};


export type MutationTeamModifyArgs = {
  input?: InputMaybe<TeamModifyInput>;
};


export type MutationTeamOfficialCreateArgs = {
  input?: InputMaybe<TeamOfficialCreateInput>;
};


export type MutationTeamOfficialDeleteArgs = {
  input?: InputMaybe<TeamOfficialDeleteInput>;
};


export type MutationTeamOfficialModifyArgs = {
  input?: InputMaybe<TeamOfficialModifyInput>;
};


export type MutationTeamPreparationCreateArgs = {
  input?: InputMaybe<TeamPreparationCreateInput>;
};


export type MutationTeamPreparationDeleteArgs = {
  input?: InputMaybe<TeamPreparationDeleteInput>;
};


export type MutationTeamPreparationModifyArgs = {
  input?: InputMaybe<TeamPreparationModifyInput>;
};


export type MutationTeamWorkerCreateArgs = {
  input?: InputMaybe<TeamWorkerCreateInput>;
};


export type MutationTeamWorkerDeleteArgs = {
  input?: InputMaybe<TeamWorkerDeleteInput>;
};


export type MutationTeamWorkerModifyArgs = {
  input?: InputMaybe<TeamWorkerModifyInput>;
};


export type MutationToggleCardActivationArgs = {
  cardId: Scalars['String']['input'];
  status: Card_Status;
};


export type MutationUpdateFundraiserUserTypeConfigArgs = {
  id: Scalars['Int']['input'];
  userTypeConfig: UserTypeConfiguration;
};


export type MutationUpdateParticipantCampaignConfigurationArgs = {
  field?: InputMaybe<ParticipantCampaignConfigInput>;
  id: Scalars['ID']['input'];
};


export type MutationUpdateParticipantFundraiserConfigurationArgs = {
  input: UpdateParticipantFundraiserConfigurationInput;
};


export type MutationUpdateParticipantGroupArgs = {
  fundraiserId: Scalars['Int']['input'];
  personListsId: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};


export type MutationUpdateParticipantOtkSizeArgs = {
  fundraiserUserIncentiveId: Scalars['Int']['input'];
  size: Scalars['String']['input'];
};


export type MutationUpgradeToWalletUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUploadLogoPngArgs = {
  image?: InputMaybe<ImageInput>;
};


export type MutationUserActivityLeadsCreateArgs = {
  activity: ProgramActivity;
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserActivityLeadsDeleteArgs = {
  activity: ProgramActivity;
  orgId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserActivityLeadsUpdateArgs = {
  activity: ProgramActivity;
  isConfirmed: Scalars['Boolean']['input'];
  orgId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserApiKeyCreateArgs = {
  expiresPeriod?: InputMaybe<Scalars['String']['input']>;
  maxRps?: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  permissionIds: Array<Scalars['String']['input']>;
};


export type MutationUserApiKeyDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationUserApiKeyUpdateArgs = {
  id: Scalars['String']['input'];
  maxRps?: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  permissionIds: Array<Scalars['String']['input']>;
};


export type MutationUserAssociateArgs = {
  orgId: Scalars['ID']['input'];
  product: Product;
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUserAssociationAccessArgs = {
  orgId: Scalars['ID']['input'];
  product: Product;
  userId: Scalars['ID']['input'];
};


export type MutationUserAssociationCreateArgs = {
  orgId: Scalars['ID']['input'];
  product: Product;
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUserAssociationDeleteArgs = {
  orgId: Scalars['ID']['input'];
  product: Product;
  userId: Scalars['ID']['input'];
};


export type MutationUserBulkInviteSendArgs = {
  inviteType: InviteType;
  orgPayload: Array<UserOrgInvitationPayload>;
};


export type MutationUserChallengeUpdateArgs = {
  id: Scalars['String']['input'];
  status: UserChallengeStatus;
};


export type MutationUserChildCreateArgs = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationUserChildUnassignArgs = {
  id: Scalars['String']['input'];
};


export type MutationUserChildUpdateArgs = {
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserConfirmProfileChallengeArgs = {
  challengeId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  data: UserConfirmProfileChallengeData;
};


export type MutationUserCreateArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<UserOccupation>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  snapRaiseId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUserEmailConfirmArgs = {
  code: Scalars['String']['input'];
};


export type MutationUserFittingAddArgs = {
  fitting: UserFittingPreference;
};


export type MutationUserImpersonateArgs = {
  userId: Scalars['String']['input'];
};


export type MutationUserImpliedAffiliationDeleteArgs = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUserInitiateProfileChallengeArgs = {
  challengeId: Scalars['String']['input'];
  data: UserInitiateProfileChallengeData;
};


export type MutationUserInviteArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  joincode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserInviteAcceptArgs = {
  inviteId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserInviteSendArgs = {
  channelInvite?: InputMaybe<ChannelInviteInput>;
  orgInvite?: InputMaybe<OrgInviteInput>;
  parentInvite?: InputMaybe<ParentInviteInput>;
  type: InviteType;
};


export type MutationUserLoginStepArgs = {
  email: Scalars['String']['input'];
};


export type MutationUserOrgAffiliationCreateArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
  title: AffiliationTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserOrgAffiliationDeleteArgs = {
  orgId: Scalars['ID']['input'];
  title: AffiliationTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserOrgAffiliationUpdateArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
  title: AffiliationTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserPermissionsUpdateArgs = {
  negativePermissions: Array<UserPermissionAssignment>;
  permissions: Array<UserPermissionAssignment>;
  roleIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type MutationUserPhoneNumberChallengeConfirmArgs = {
  challengeId: Scalars['String']['input'];
  code: Scalars['String']['input'];
};


export type MutationUserPhoneNumberConfirmArgs = {
  code: Scalars['String']['input'];
};


export type MutationUserRefreshSessionArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserResetPasswordArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  transport?: InputMaybe<TransportEnum>;
  withLink?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUserResetPasswordByAdminArgs = {
  forcePasswordResetChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUserSignupArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consumer?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  inviteId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<UserOccupation>;
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserSignupConfirmArgs = {
  code: Scalars['String']['input'];
  consumer?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUserSignupConfirmationResendArgs = {
  email: Scalars['String']['input'];
  transport?: InputMaybe<TransportEnum>;
};


export type MutationUserTeamLeadsCreateArgs = {
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  teamId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserTeamLeadsDeleteArgs = {
  teamId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserTeamLeadsUpdateArgs = {
  isConfirmed: Scalars['Boolean']['input'];
  teamId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserUnassignParentArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserUpdateArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hsGradYear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<UserOccupation>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserUpdatePasswordArgs = {
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationVaultCreateKybKycSubmitArgs = {
  bankAccountPayableOrgId?: InputMaybe<Scalars['ID']['input']>;
  beneficialOwners?: InputMaybe<Array<VaultKycCreateInput>>;
  gateway: VaultFinancialProvider;
  orgData: VaultKybCreateInput;
  representativeData: VaultRepresentativeCreateInput;
  saveToPayableOrgId?: InputMaybe<Scalars['ID']['input']>;
  stripeData?: InputMaybe<VaultCreateKybKycStripeInput>;
};


export type MutationVaultCustomerCreateArgs = {
  payableOrgName: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultDocumentGatewayUploadArgs = {
  documentBack?: InputMaybe<Scalars['String']['input']>;
  documentFront: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
  gateway: VaultFinancialProvider;
  stripeConnectAccountId?: InputMaybe<Scalars['String']['input']>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  stripePersonId?: InputMaybe<Scalars['String']['input']>;
  type: VaultRequiredDocument;
  unitApplicationId?: InputMaybe<Scalars['String']['input']>;
  vaultId: Scalars['ID']['input'];
};


export type MutationVaultIncorrectKybSupportReportArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  orgAddressCity: Scalars['String']['input'];
  orgAddressState: Scalars['String']['input'];
  orgAddressStreet: Scalars['String']['input'];
  orgAddressZip: Scalars['String']['input'];
  orgEin: Scalars['String']['input'];
  orgLegalName: Scalars['String']['input'];
  raiseCampaignId: Scalars['String']['input'];
  raiseUserId: Scalars['String']['input'];
};


export type MutationVaultKybCreateArgs = {
  address: VaultAddressInput;
  customerFacingName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  legalName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  structure: Kyb_Structure;
  taxId: Scalars['String']['input'];
  type: Kyb_Type;
  url?: InputMaybe<Scalars['String']['input']>;
};


export type MutationVaultKybUpdateArgs = {
  address?: InputMaybe<VaultAddressUpdateInput>;
  customerFacingName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  legalName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  structure?: InputMaybe<Kyb_Structure>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Kyb_Type>;
  url?: InputMaybe<Scalars['String']['input']>;
};


export type MutationVaultKycCreateArgs = {
  address: VaultAddressInput;
  director?: InputMaybe<Scalars['Boolean']['input']>;
  dob: VaultDobInput;
  email: Scalars['String']['input'];
  executive?: InputMaybe<Scalars['Boolean']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  owner?: InputMaybe<Scalars['Boolean']['input']>;
  percentOwnership?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber: Scalars['String']['input'];
  representative?: InputMaybe<Scalars['Boolean']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationVaultKycUpdateArgs = {
  address?: InputMaybe<VaultAddressUpdateInput>;
  director?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<VaultDobInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  executive?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['Boolean']['input']>;
  percentOwnership?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  representative?: InputMaybe<Scalars['Boolean']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationVaultOrgConfirmationSubmitArgs = {
  bankAccountPayableOrgId?: InputMaybe<Scalars['ID']['input']>;
  beneficialOwners?: InputMaybe<Array<VaultBeneficialOwnerCreateInput>>;
  fundraiserId?: InputMaybe<Scalars['String']['input']>;
  fundraiserSlug?: InputMaybe<Scalars['String']['input']>;
  gateway: VaultFinancialProvider;
  orgData: VaultFormKybInput;
  primaryRepresentative: VaultRepresentativeCreateInput;
  productDescription?: InputMaybe<Scalars['String']['input']>;
  representativeToReplace?: InputMaybe<VaultRepresentativeCreateInput>;
  stripeData?: InputMaybe<VaultFormStripeInput>;
  submittedOnYourBehalf: Scalars['Boolean']['input'];
};


export type MutationVaultSetupIntentCreateArgs = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountBankAccountDefaultSetArgs = {
  bankAccountId: Scalars['String']['input'];
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountBankAccountDeleteArgs = {
  bankAccountId: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountCreateArgs = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars['String']['input']>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  vaultKybId: Scalars['ID']['input'];
};


export type MutationVaultStripeConnectAccountOwnersProvidedArgs = {
  ownersProvided: Scalars['Boolean']['input'];
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountPaymentMethodAttachArgs = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId: Scalars['String']['input'];
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountPaymentMethodDefaultSetArgs = {
  customerId: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId: Scalars['String']['input'];
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountPayoutFrequencySetArgs = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
  payoutInterval: VaultPayoutInterval;
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountRemovePeopleArgs = {
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountRemoveRepresentativeArgs = {
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type MutationVaultStripeConnectAccountUpdateArgs = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars['String']['input']>;
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  vaultKybId: Scalars['ID']['input'];
};


export type MutationVaultStripePersonCreateArgs = {
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  vaultKycId: Scalars['ID']['input'];
};


export type MutationVaultStripePersonDeleteArgs = {
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  stripePersonId: Scalars['String']['input'];
};


export type MutationVaultStripePersonUpdateArgs = {
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
  stripePersonId: Scalars['String']['input'];
  vaultKycId: Scalars['ID']['input'];
};


export type MutationVaultUpdateVaultKybAutomatedArgs = {
  payableOrgId?: InputMaybe<Scalars['ID']['input']>;
  raiseFundraiserId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationVehicleCreateArgs = {
  input?: InputMaybe<CreateVehicleInput>;
};


export type MutationVehicleDeleteArgs = {
  input?: InputMaybe<DeleteVehicleInput>;
};


export type MutationVehicleModifyArgs = {
  input?: InputMaybe<ModifyVehicleInput>;
};


export type MutationWarningArgs = {
  payload: Scalars['JSON']['input'];
  service: AuditLogService;
  source: AuditLogSource;
};


export type MutationWorkerDeleteManyArgs = {
  input?: InputMaybe<DeleteManyWorkerInput>;
};


export type MutationWorkerPoolCreateArgs = {
  input?: InputMaybe<CreateWorkerPool>;
};


export type MutationWorkerPoolDeleteArgs = {
  input?: InputMaybe<DeleteWorkerPoolInput>;
};


export type MutationWorkerPoolModifyArgs = {
  input?: InputMaybe<ModifyWorkerPoolInput>;
};


export type MutationWorkerUpsertManyArgs = {
  input?: InputMaybe<UpsertManyWorkersInput>;
};


export type MutationWorkersUpsertArgs = {
  input?: InputMaybe<Array<InputMaybe<UpsertWorkersInput>>>;
};

export type MutationError = {
  __typename?: 'MutationError';
  message: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  errors: Array<Maybe<MutationError>>;
  properties?: Maybe<OrgsProperties>;
  success: Scalars['Boolean']['output'];
};

export type MyChannels = {
  __typename?: 'MyChannels';
  channels: Array<Channel>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type MyModeratedChannels = {
  __typename?: 'MyModeratedChannels';
  channels: Array<Channel>;
  count?: Maybe<Scalars['Int']['output']>;
  orgsCount?: Maybe<Scalars['Int']['output']>;
};

/** NestedBoolFilter is used for nested filtering on boolean fields. */
export type NestedBoolFilter = {
  /** Checks if the boolean field is equal to the specified value. */
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  /** Nested filter for negating a condition. */
  not?: InputMaybe<NestedBoolFilter>;
};

export type NetsuiteEventInput = {
  eventType?: InputMaybe<Scalars['String']['input']>;
  record?: InputMaybe<Scalars['JSON']['input']>;
};

export type NetsuiteWebhookResponse = {
  __typename?: 'NetsuiteWebhookResponse';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type NotAuthenticated = Error & {
  __typename?: 'NotAuthenticated';
  message: Scalars['String']['output'];
};

export type NotAuthorized = Error & {
  __typename?: 'NotAuthorized';
  message: Scalars['String']['output'];
};

export type Notice = {
  __typename?: 'Notice';
  body?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  isUrgent?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NoticeCreateInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  isUrgent?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NoticeDeleteInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type NoticeModifyInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  isUrgent?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type OAuthCallbackResponse = Tokens & {
  __typename?: 'OAuthCallbackResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  challengeId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type Official = {
  __typename?: 'Official';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  duty?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Event>;
  event_id?: Maybe<Scalars['Int']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  home_phone?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  offic_id?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['String']['output']>;
  pay_code?: Maybe<Scalars['String']['output']>;
  received?: Maybe<Scalars['String']['output']>;
  salary?: Maybe<Scalars['Float']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  voucher_number?: Maybe<Scalars['String']['output']>;
  work_phone?: Maybe<Scalars['String']['output']>;
  worker_name?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type OfficialAssignmentsReturn = {
  __typename?: 'OfficialAssignmentsReturn';
  grand_total?: Maybe<Scalars['String']['output']>;
  official_names?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  officials?: Maybe<Array<Maybe<TransformedOfficialAssignment>>>;
  total?: Maybe<Array<Maybe<Total>>>;
};

export type OfficialByIdInput = {
  id: Scalars['Int']['input'];
};

export type OfficialDuty = {
  __typename?: 'OfficialDuty';
  duty?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
};

export type OfficialPool = {
  __typename?: 'OfficialPool';
  Address?: Maybe<Scalars['String']['output']>;
  City?: Maybe<Scalars['String']['output']>;
  First?: Maybe<Scalars['String']['output']>;
  Home_Phone?: Maybe<Scalars['String']['output']>;
  ID: Scalars['String']['output'];
  Last?: Maybe<Scalars['String']['output']>;
  SSN?: Maybe<Scalars['String']['output']>;
  State?: Maybe<Scalars['String']['output']>;
  Work_Phone?: Maybe<Scalars['String']['output']>;
  Zip?: Maybe<Scalars['String']['output']>;
  cell_phone?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  formattedSSN?: Maybe<Scalars['String']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  vendor_number?: Maybe<Scalars['String']['output']>;
};

export type OfficialSchedules = {
  __typename?: 'OfficialSchedules';
  activity?: Maybe<Scalars['String']['output']>;
  bus_count?: Maybe<Scalars['Int']['output']>;
  bus_departure_location?: Maybe<Scalars['String']['output']>;
  bus_early_dismissal_time?: Maybe<Scalars['String']['output']>;
  bus_estimated_return_time?: Maybe<Scalars['String']['output']>;
  bus_time?: Maybe<Scalars['String']['output']>;
  cancellation_status?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  driver_name?: Maybe<Scalars['String']['output']>;
  driver_phone?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  facility?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  home_field?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  levels?: Maybe<Scalars['String']['output']>;
  official_address?: Maybe<Scalars['String']['output']>;
  official_address_city?: Maybe<Scalars['String']['output']>;
  official_address_state?: Maybe<Scalars['String']['output']>;
  official_address_zip?: Maybe<Scalars['String']['output']>;
  official_duty?: Maybe<Scalars['String']['output']>;
  official_email?: Maybe<Scalars['String']['output']>;
  official_first_name?: Maybe<Scalars['String']['output']>;
  official_home_phone?: Maybe<Scalars['String']['output']>;
  official_id?: Maybe<Scalars['String']['output']>;
  official_last_name?: Maybe<Scalars['String']['output']>;
  official_paid?: Maybe<Scalars['String']['output']>;
  official_received?: Maybe<Scalars['String']['output']>;
  official_row_id?: Maybe<Scalars['String']['output']>;
  official_salary?: Maybe<Scalars['String']['output']>;
  official_work_phone?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  season_id?: Maybe<Scalars['Int']['output']>;
  sports_code?: Maybe<Scalars['String']['output']>;
  sports_description?: Maybe<Scalars['String']['output']>;
  sports_group?: Maybe<Scalars['String']['output']>;
  sports_name?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  vehicle_id?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
};

export type OnException = {
  __typename?: 'OnException';
  exceptionCode?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Opponent = {
  __typename?: 'Opponent';
  Address?: Maybe<Scalars['String']['output']>;
  Phone?: Maybe<Scalars['String']['output']>;
  SchoolCode?: Maybe<Scalars['String']['output']>;
  SchoolName?: Maybe<Scalars['String']['output']>;
  State?: Maybe<Scalars['String']['output']>;
  Zip?: Maybe<Scalars['String']['output']>;
  ad_name?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  nces_id?: Maybe<Scalars['String']['output']>;
  non_school?: Maybe<Scalars['Boolean']['output']>;
};

export type OrderFilterInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderBySort?: InputMaybe<Scalars['String']['input']>;
  scopeId: Scalars['Int']['input'];
  searchBy?: InputMaybe<Scalars['String']['input']>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type OrderGraphInput = {
  interval?: InputMaybe<Scalars['Int']['input']>;
  scopeId: Scalars['Int']['input'];
};

export type OrderInput = {
  baseSubtotal?: InputMaybe<Scalars['Float']['input']>;
  billingCity?: InputMaybe<Scalars['String']['input']>;
  billingState?: InputMaybe<Scalars['String']['input']>;
  billingStreet?: InputMaybe<Scalars['String']['input']>;
  billingStreet2?: InputMaybe<Scalars['String']['input']>;
  billingZipCode?: InputMaybe<Scalars['String']['input']>;
  carrier: ShippingProvider;
  city: Scalars['String']['input'];
  confirmationId?: InputMaybe<Scalars['String']['input']>;
  designEps?: InputMaybe<Scalars['String']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  fundraiserId?: InputMaybe<Scalars['String']['input']>;
  giftCardAmount?: InputMaybe<Scalars['Float']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  netsuiteId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  packingSlipId: Scalars['String']['input'];
  packingSlipTitle: Scalars['String']['input'];
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  pointAmount?: InputMaybe<Scalars['Float']['input']>;
  products: Array<OrderProductInput>;
  scheduleAt?: InputMaybe<Scalars['String']['input']>;
  shipTo: Scalars['String']['input'];
  shipToEmail?: InputMaybe<Scalars['String']['input']>;
  shipToPhone?: InputMaybe<Scalars['String']['input']>;
  shippingCost?: InputMaybe<Scalars['Float']['input']>;
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  taxAmount?: InputMaybe<Scalars['Float']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  update?: InputMaybe<Scalars['Boolean']['input']>;
  vendor: Vendor;
  zipCode: Scalars['String']['input'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  itemId?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  parentItemId?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
};

export type OrderProductInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  magentoItemId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  netsuiteId: Scalars['String']['input'];
  printAttributes?: InputMaybe<PrintAttributesInput>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  receiverName: Scalars['String']['input'];
  sku?: InputMaybe<Scalars['String']['input']>;
  taxAmount?: InputMaybe<Scalars['Float']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
};

export type OrderResult = {
  __typename?: 'OrderResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  netsuiteId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OrderStatus>;
};

export enum OrderStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export enum OrderType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrdersFilter = {
  __typename?: 'OrdersFilter';
  orders?: Maybe<Array<Maybe<MagentoOrder>>>;
  pagination?: Maybe<SdPagination>;
};

export type OrdersFilterUnionType = OnException | OrdersFilter;

export type OrdersSummary = {
  __typename?: 'OrdersSummary';
  baseSales?: Maybe<Scalars['Float']['output']>;
  complete?: Maybe<Scalars['BigInt']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  processing?: Maybe<Scalars['BigInt']['output']>;
  sales?: Maybe<Scalars['Float']['output']>;
  scopeId?: Maybe<Scalars['BigInt']['output']>;
  shipped?: Maybe<Scalars['BigInt']['output']>;
};

export type OrdersSummaryUnionType = OnException | OrdersSummary;

export type Org = {
  __typename?: 'Org';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fields?: Maybe<Scalars['JSONObject']['output']>;
  financialAccounts?: Maybe<Array<FinancialAccount>>;
  id: Scalars['ID']['output'];
  lastMigrated?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Please use lastMigrated instead */
  migrated?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Org>;
  /** @deprecated Please use parent.id instead */
  parentId?: Maybe<Scalars['String']['output']>;
  rosters?: Maybe<Array<OrgsRoster>>;
  snapMobileOneAccess?: Maybe<SnapMobileOneAccess>;
  stores?: Maybe<Array<Store>>;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type OrgFieldsArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OrgAffiliateInput = {
  base: BaseOrgInput;
  parentOrgId?: InputMaybe<Scalars['ID']['input']>;
  type: OrgType;
};

export type OrgAffiliation = {
  __typename?: 'OrgAffiliation';
  affiliations: Array<UserAffiliation>;
  org: Org;
};

export type OrgCampaignAffiliation = {
  __typename?: 'OrgCampaignAffiliation';
  campaigns: Array<Org>;
  org: Org;
};

export type OrgCampaignIds = {
  __typename?: 'OrgCampaignIds';
  campaignIds: Array<Scalars['ID']['output']>;
  legacyRaiseIds: Array<Scalars['Int']['output']>;
  role?: Maybe<Scalars['ID']['output']>;
  rootOrgId: Scalars['ID']['output'];
};

export type OrgClubInput = {
  base: BaseOrgInput;
};

export type OrgDistrictInput = {
  base: BaseOrgInput;
  ncesId?: InputMaybe<Scalars['String']['input']>;
};

export type OrgInviteArguments = {
  orgId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type OrgInviteInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type OrgProgramInput = {
  activity: ProgramActivity;
  orgId: Scalars['ID']['input'];
};

export type OrgRelationships = {
  __typename?: 'OrgRelationships';
  governingOrg?: Maybe<Org>;
  org: Org;
  titles: Array<RelationshipTitles>;
};

export type OrgSchoolInput = {
  base: BaseOrgInput;
  ncesId?: InputMaybe<Scalars['String']['input']>;
  parentDistrictId?: InputMaybe<Scalars['ID']['input']>;
};

export type OrgStaffMember = {
  __typename?: 'OrgStaffMember';
  orgAffiliations: Array<UserAffiliation>;
  orgId: Scalars['ID']['output'];
  programsLed: Array<ProgramWithTitle>;
  teamsLed: Array<TeamWithTitle>;
  user: UserNode;
};

export type OrgTeamInput = {
  activity: ProgramActivity;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  teamAge: TeamAge;
  teamGender: TeamGender;
  teamGroupType: TeamGroupType;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  teamTier: TeamTier;
};

export enum OrgType {
  Booster = 'BOOSTER',
  Business = 'BUSINESS',
  Club = 'CLUB',
  District = 'DISTRICT',
  Foundation = 'FOUNDATION',
  Program = 'PROGRAM',
  Pta = 'PTA',
  School = 'SCHOOL',
  Shellschool = 'SHELLSCHOOL',
  Team = 'TEAM'
}

export enum OrgTypeWithCampaign {
  Booster = 'BOOSTER',
  Business = 'BUSINESS',
  Campaign = 'CAMPAIGN',
  Club = 'CLUB',
  District = 'DISTRICT',
  Foundation = 'FOUNDATION',
  Program = 'PROGRAM',
  Pta = 'PTA',
  School = 'SCHOOL',
  Shellschool = 'SHELLSCHOOL',
  Team = 'TEAM'
}

export enum OrganizationFilterEnum {
  Pending = 'pending'
}

export type OrgsProperties = {
  __typename?: 'OrgsProperties';
  acctId?: Maybe<Scalars['ID']['output']>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['ID']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  finAcctId?: Maybe<Scalars['ID']['output']>;
  firstSeen?: Maybe<Scalars['DateTime']['output']>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  isBeneficialOwner?: Maybe<Scalars['Boolean']['output']>;
  isContact?: Maybe<Scalars['Boolean']['output']>;
  isPrincipal?: Maybe<Scalars['Boolean']['output']>;
  isRepresentative?: Maybe<Scalars['Boolean']['output']>;
  kybId?: Maybe<Scalars['ID']['output']>;
  kycId?: Maybe<Scalars['ID']['output']>;
  lastMigrated?: Maybe<Scalars['DateTime']['output']>;
  lastSeen?: Maybe<Scalars['DateTime']['output']>;
  memberCount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Please use lastMigrated instead */
  migrated?: Maybe<Scalars['DateTime']['output']>;
  orgId?: Maybe<Scalars['ID']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  personId?: Maybe<Scalars['ID']['output']>;
  processor?: Maybe<Processor>;
  programId?: Maybe<Scalars['ID']['output']>;
  roleId?: Maybe<Scalars['ID']['output']>;
  rosterId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<FinAcctStatus>;
  type?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type OrgsRoster = {
  __typename?: 'OrgsRoster';
  activity?: Maybe<ProgramActivity>;
  campaign?: Maybe<Org>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<TeamGender>;
  id: Scalars['ID']['output'];
  lastMigrated?: Maybe<Scalars['DateTime']['output']>;
  members: Array<RosterUser>;
  name: Scalars['String']['output'];
  org?: Maybe<Org>;
  product: Array<RosterCreator>;
  startYear: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userDirectoryMembers: Array<UserPublic>;
};

export type OrgsSearchPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrgsSearchResponse = {
  __typename?: 'OrgsSearchResponse';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  orgs?: Maybe<Array<Maybe<Org>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type OrgsSearchWhereInput = {
  /** List of organization ids to filter from. */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The string to search in name of organization. */
  nameIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Organization type. Valid values are "Booster" | "Club" | "District" | "Program" | "School". */
  orgTypes?: InputMaybe<Array<OrgType>>;
  /** Allow to search any properties via JSON query. The key will auto convert to snake_case. */
  properties?: InputMaybe<Scalars['JSON']['input']>;
};

export type OtkFundraiserProduct = {
  __typename?: 'OtkFundraiserProduct';
  /** This otk item image will include the fundraiser logo. */
  dynamicImage?: Maybe<Scalars['String']['output']>;
  incentives?: Maybe<OtkProduct>;
};

export type OtkParticipantData = {
  __typename?: 'OtkParticipantData';
  id: Scalars['ID']['output'];
  incentives?: Maybe<OtkProduct>;
  size?: Maybe<Scalars['String']['output']>;
};

export type OtkProduct = {
  __typename?: 'OtkProduct';
  description?: Maybe<Scalars['String']['output']>;
  fitting?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** This is the base image for the otk item. It will not include the fundraiser logo. */
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priceCents?: Maybe<Scalars['Int']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  sizes?: Maybe<Scalars['String']['output']>;
};

export enum PagesNameEnum {
  AboutUs = 'AboutUs',
  Mission = 'Mission',
  Recruitment = 'Recruitment',
  SchoolDirections = 'SchoolDirections',
  Sponsors = 'Sponsors',
  Staff = 'Staff'
}

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Int']['output'];
  itemCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

export type ParentInviteInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  joincode: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Participant = {
  __typename?: 'Participant';
  campaignId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

/** See CampaignMembership for details */
export type ParticipantCampaign = {
  __typename?: 'ParticipantCampaign';
  areDonationInviteEmailsReleased: Scalars['Boolean']['output'];
  basicStatus: BasicCampaignStatus;
  campaignDates?: Maybe<CampaignDates>;
  campaignGoal?: Maybe<Scalars['Int']['output']>;
  donationsRaised?: Maybe<ParticipantDonationsRaised>;
  /** Getting donor email addresses adds time to your query */
  donorEmailData?: Maybe<Array<Maybe<DonorEmailData>>>;
  donorPersonListEntries?: Maybe<Array<Maybe<DonorPersonListEntry>>>;
  /** Getting donor phone numbers adds time to your query */
  donorPhoneNumbers?: Maybe<Array<Maybe<DonationInviteSmsData>>>;
  fundraiserRewardLevelsCount?: Maybe<FundraiserRewardLevelsCount>;
  galleryItems?: Maybe<GalleryItems>;
  group?: Maybe<ParticipantGroup>;
  hasCustomRewards?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isCoparticipant?: Maybe<Scalars['Boolean']['output']>;
  isGuardianLedCampaign: Scalars['Boolean']['output'];
  isOTKEnabled?: Maybe<Scalars['Boolean']['output']>;
  isRewardsEnabled?: Maybe<Scalars['Boolean']['output']>;
  isTopEarner: Scalars['Boolean']['output'];
  joinCode?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  newStack_participantFundraiserLink?: Maybe<Scalars['String']['output']>;
  otk?: Maybe<Array<Maybe<OtkParticipantData>>>;
  participantGoal?: Maybe<Scalars['Int']['output']>;
  participantGuardianEmails?: Maybe<ParticipantGuardianEmail>;
  participantRewardsData?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  primaryColor: Scalars['String']['output'];
  raiseUserId?: Maybe<Scalars['Int']['output']>;
  raiseUserJoinedAt?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** Datetime-ISO format for sms invites released */
  smsInvitesReleasedAt?: Maybe<Scalars['String']['output']>;
  status: CampaignStatus;
  userFitting?: Maybe<UserFitting>;
};

export type ParticipantCampaignConfigInput = {
  autoImportEmails?: InputMaybe<ParticipantCampaignConfigStates>;
  autoImportTexts?: InputMaybe<ParticipantCampaignConfigStates>;
  giftShop?: InputMaybe<ParticipantCampaignConfigStates>;
  guardianSetup?: InputMaybe<ParticipantCampaignConfigStates>;
  profileSetup?: InputMaybe<ParticipantCampaignConfigStates>;
  rewards?: InputMaybe<ParticipantCampaignConfigStates>;
};

export enum ParticipantCampaignConfigStates {
  Completed = 'COMPLETED',
  NotApplicable = 'NOT_APPLICABLE',
  NotCompleted = 'NOT_COMPLETED',
  Skipped = 'SKIPPED'
}

export type ParticipantCheer = {
  __typename?: 'ParticipantCheer';
  anonymous?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  donorMessage?: Maybe<Scalars['String']['output']>;
  donorName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lastName?: Maybe<Scalars['String']['output']>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantCheerwall = {
  __typename?: 'ParticipantCheerwall';
  cheers?: Maybe<Array<Maybe<ParticipantCheer>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantData = {
  __typename?: 'ParticipantData';
  data?: Maybe<User>;
  raiseId?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantDonationInvalidEmails = {
  __typename?: 'ParticipantDonationInvalidEmails';
  email: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ParticipantDonationInvite = {
  __typename?: 'ParticipantDonationInvite';
  deliveryStatus: EmailDeliveryStatus;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ParticipantDonationInviteResponse = {
  __typename?: 'ParticipantDonationInviteResponse';
  addedInvites?: Maybe<Array<ParticipantDonationInvite>>;
  invalidEmails?: Maybe<Array<ParticipantDonationInvalidEmails>>;
  removed?: Maybe<Array<Scalars['ID']['output']>>;
};

export enum ParticipantDonationInviteStatus {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Donated = 'DONATED',
  Loaded = 'LOADED',
  Preloaded = 'PRELOADED',
  Released = 'RELEASED'
}

export type ParticipantDonationInvitesAction = {
  add?: InputMaybe<Array<Scalars['String']['input']>>;
  remove?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ParticipantDonationsRaised = {
  __typename?: 'ParticipantDonationsRaised';
  numberOfDonations: Scalars['Int']['output'];
  subtotalCents: Scalars['Int']['output'];
};

/** This is just a Fundraiser, but specifically for a participant */
export type ParticipantFundraiser = {
  __typename?: 'ParticipantFundraiser';
  emailInvitesReleasedAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  raiseId?: Maybe<Scalars['Int']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  smsInvitesReleasedAt?: Maybe<Scalars['Date']['output']>;
};

export enum ParticipantFundraiserConfigStates {
  Completed = 'COMPLETED',
  NotApplicable = 'NOT_APPLICABLE',
  NotCompleted = 'NOT_COMPLETED',
  Skipped = 'SKIPPED'
}

export type ParticipantFundraiserConfiguration = {
  __typename?: 'ParticipantFundraiserConfiguration';
  autoImportEmails: ParticipantFundraiserConfigStates;
  autoImportTexts: ParticipantFundraiserConfigStates;
  fundraiserId: Scalars['String']['output'];
  fundraiserParticipantId: Scalars['String']['output'];
  giftShop: ParticipantFundraiserConfigStates;
  guardianSetup: ParticipantFundraiserConfigStates;
  id?: Maybe<Scalars['ID']['output']>;
  profileSetup: ParticipantFundraiserConfigStates;
  raiseFundraiserId?: Maybe<Scalars['Int']['output']>;
  rewards: ParticipantFundraiserConfigStates;
};

export type ParticipantFundraiserParticipant = {
  __typename?: 'ParticipantFundraiserParticipant';
  fundraiserId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
};

export type ParticipantGroup = {
  __typename?: 'ParticipantGroup';
  id?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type ParticipantGuardianDonorEntry = {
  __typename?: 'ParticipantGuardianDonorEntry';
  email?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  fundraiserId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantGuardianEmail = {
  __typename?: 'ParticipantGuardianEmail';
  guardianEmail?: Maybe<Scalars['String']['output']>;
  secondaryGuardianEmail?: Maybe<Scalars['String']['output']>;
};

export type ParticipantGuardianEmailUpdate = {
  __typename?: 'ParticipantGuardianEmailUpdate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantGuardianFundraiser = {
  __typename?: 'ParticipantGuardianFundraiser';
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  participants?: Maybe<Array<Maybe<ParticipantUser>>>;
  slug: Scalars['String']['output'];
  status: BasicCampaignStatus;
};

export type ParticipantGuardianReturn = {
  __typename?: 'ParticipantGuardianReturn';
  entry?: Maybe<Array<ParticipantGuardianDonorEntry>>;
};

export type ParticipantJoinData = {
  __typename?: 'ParticipantJoinData';
  isSmaApp?: Maybe<Scalars['Boolean']['output']>;
  joinLink?: Maybe<Scalars['String']['output']>;
};

export type ParticipantList = {
  __typename?: 'ParticipantList';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['String']['output']>;
  list: Array<Maybe<Participant>>;
};

export type ParticipantPreloadDonationInvitesHistoryResponse = {
  __typename?: 'ParticipantPreloadDonationInvitesHistoryResponse';
  emails?: Maybe<Array<Scalars['String']['output']>>;
  phoneNumbers?: Maybe<Array<Scalars['String']['output']>>;
};

export type ParticipantPublic = {
  __typename?: 'ParticipantPublic';
  firstName?: Maybe<Scalars['String']['output']>;
  /** User directory id */
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
};

/** A 'tremendous' reward. The presence means that a reward has been sent to a participant */
export type ParticipantReward = {
  __typename?: 'ParticipantReward';
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<RewardStatus>;
};

export type ParticipantRewardsData = {
  __typename?: 'ParticipantRewardsData';
  id?: Maybe<Scalars['Int']['output']>;
  incentives?: Maybe<FundraiserRewardsProduct>;
  size?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantRewardsInput = {
  incentiveId: Scalars['Int']['input'];
  size: Scalars['String']['input'];
  tier: Scalars['Int']['input'];
};

export type ParticipantSsoid = {
  __typename?: 'ParticipantSSOID';
  userDirectoryId?: Maybe<Scalars['String']['output']>;
};

export type ParticipantSmsDonationInvite = {
  __typename?: 'ParticipantSmsDonationInvite';
  id: Scalars['ID']['output'];
  participant?: Maybe<ParticipantFundraiserParticipant>;
  phoneNumber: Scalars['String']['output'];
  status: ParticipantDonationInviteStatus;
};

export type ParticipantSmsDonationInvitesWhereInput = {
  fundraiserId?: InputMaybe<Scalars['String']['input']>;
  raiseFundraiserId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ParticipantDonationInviteStatus>;
};

export type ParticipantTextTemplate = {
  __typename?: 'ParticipantTextTemplate';
  guardianTemplate?: Maybe<Scalars['String']['output']>;
  participantTemplate?: Maybe<Scalars['String']['output']>;
};

export type ParticipantTopDonation = {
  __typename?: 'ParticipantTopDonation';
  donorName?: Maybe<Scalars['String']['output']>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantUser = {
  __typename?: 'ParticipantUser';
  fundraiserUserId: Scalars['Int']['output'];
  user: User;
};

export type ParticipantsInfo = {
  __typename?: 'ParticipantsInfo';
  count?: Maybe<Scalars['Int']['output']>;
  loggedInCount?: Maybe<Scalars['Int']['output']>;
  withAtLeastOneDonation?: Maybe<Scalars['Int']['output']>;
  withAtLeastTwentyInvites?: Maybe<Scalars['Int']['output']>;
};

export enum PastDueSortOptions {
  Amount = 'amount',
  DueDate = 'dueDate',
  Groups = 'groups',
  ParentStatus = 'parentStatus',
  ParticipantName = 'participantName'
}

export type PayableInfo = {
  __typename?: 'PayableInfo';
  campaignId?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  ein?: Maybe<Scalars['Int']['output']>;
  fullAddressOne?: Maybe<Scalars['String']['output']>;
  fullAddressTwo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payableName?: Maybe<Scalars['String']['output']>;
  payableType?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  scopeId?: Maybe<Scalars['Int']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['Int']['output']>;
};

export type Payee = {
  __typename?: 'Payee';
  address: PayeeAddress;
  name: Scalars['String']['output'];
};

export type PayeeAddress = {
  __typename?: 'PayeeAddress';
  apartment?: Maybe<Scalars['String']['output']>;
  attention?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export enum PaymentMethod {
  Paypal = 'PAYPAL',
  Stripe = 'STRIPE'
}

export enum PaymentProvider {
  Paypal = 'PAYPAL',
  Stripe = 'STRIPE'
}

export enum PaymentScheduleStatus {
  Draft = 'DRAFT',
  OnHold = 'ON_HOLD',
  Published = 'PUBLISHED'
}

export type PaymentsApiCreateRefundResponse = {
  __typename?: 'PaymentsApiCreateRefundResponse';
  applicationFeeRefundAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  refundApplicationFee?: Maybe<Scalars['Boolean']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type PaymentsApiCustomerInput = {
  email: Scalars['String']['input'];
  stripeEnv?: InputMaybe<StripeEnv>;
};

export type PaymentsApiCustomerPaymentMethod = {
  __typename?: 'PaymentsApiCustomerPaymentMethod';
  billingAddress?: Maybe<PaymentsApiPmAddress>;
  brand?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<PaymentsApiPmExpiration>;
  id: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  type: PaymentsApiCustomerPaymentMethodType;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum PaymentsApiCustomerPaymentMethodType {
  Bank = 'BANK',
  Card = 'CARD',
  Other = 'OTHER'
}

export type PaymentsApiCustomerResponse = {
  __typename?: 'PaymentsApiCustomerResponse';
  customerId?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  paymentMethods: Array<Maybe<PaymentsApiCustomerPaymentMethod>>;
};

export type PaymentsApiDetachPaymentMethodInput = {
  paymentMethodId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<StripeEnv>;
};

export type PaymentsApiDetachPaymentMethodResponse = {
  __typename?: 'PaymentsApiDetachPaymentMethodResponse';
  paymentMethodId: Scalars['String']['output'];
  stripeEnv: StripeEnv;
};

export type PaymentsApiPmAddress = {
  __typename?: 'PaymentsApiPMAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type PaymentsApiPmExpiration = {
  __typename?: 'PaymentsApiPMExpiration';
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type PaymentsApiPaymentIput = {
  amount: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  finalDestination: Scalars['String']['input'];
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  paymentMethodId: Scalars['String']['input'];
  snapAmount?: InputMaybe<Scalars['Int']['input']>;
  snapId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<StripeEnv>;
};

export type PaymentsApiPaymentResponse = {
  __typename?: 'PaymentsApiPaymentResponse';
  amount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  paymentMethodId: Scalars['String']['output'];
  snapAmount: Scalars['Int']['output'];
  snapId: Scalars['String']['output'];
  stripeEnv: StripeEnv;
};

export type PaymentsApiRefundInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  refundApplicationFee?: InputMaybe<Scalars['Boolean']['input']>;
  snapAmount?: InputMaybe<Scalars['Int']['input']>;
  stripeEnv?: InputMaybe<StripeEnv>;
  transactionId: Scalars['String']['input'];
};

export type Payout = Check | Deposit;

export type Permission = {
  __typename?: 'Permission';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PhoneNumberChallengeConfirmation = Tokens & {
  __typename?: 'PhoneNumberChallengeConfirmation';
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type PointActivityFilter = {
  __typename?: 'PointActivityFilter';
  activities?: Maybe<Array<Maybe<MagentoPointActivity>>>;
  pagination?: Maybe<SdPagination>;
};

export type PointActivityFilterUnionType = OnException | PointActivityFilter;

export type PointsActivityFilterInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderBySort?: InputMaybe<Scalars['String']['input']>;
  scopeId: Scalars['Int']['input'];
  searchBy?: InputMaybe<Scalars['String']['input']>;
  searchValue?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type PostponeUnpostponeResponse = {
  __typename?: 'PostponeUnpostponeResponse';
  affected_events?: Maybe<Scalars['Int']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PotentialCustomersDeals = {
  __typename?: 'PotentialCustomersDeals';
  activity?: Maybe<Scalars['String']['output']>;
  campaignsCount?: Maybe<Scalars['Int']['output']>;
  curricularType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** Information of a emails scheduled for release */
export type PreloadEmailSchedule = {
  __typename?: 'PreloadEmailSchedule';
  /** The date and time for release */
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  /** The campaign id */
  fundraiserId?: Maybe<Scalars['Int']['output']>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars['String']['output']>;
};

/** Information of premade email templates */
export type PremadeContactTemplate = {
  __typename?: 'PremadeContactTemplate';
  /** The message of the template. Plain text only */
  message?: Maybe<Scalars['String']['output']>;
  /** The name of the template */
  name?: Maybe<Scalars['String']['output']>;
  /** The subject of the template */
  subject?: Maybe<Scalars['String']['output']>;
  templateMedium?: Maybe<ContactTemplateMedium>;
  /** The type of template */
  templateType?: Maybe<ContactTemplateType>;
};

export type Preparation = {
  __typename?: 'Preparation';
  duty?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  qty?: Maybe<Scalars['String']['output']>;
};

export type PreparationByIdInput = {
  id: Scalars['Int']['input'];
};

export type PreparationReport = {
  __typename?: 'PreparationReport';
  comments?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  prep?: Maybe<Scalars['String']['output']>;
  qty?: Maybe<Scalars['String']['output']>;
};

export type PreparationSheet = {
  __typename?: 'PreparationSheet';
  activity?: Maybe<Scalars['String']['output']>;
  bus_count?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  driver_name?: Maybe<Scalars['String']['output']>;
  driver_phone?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  facility?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  home_field?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  levels?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  preparations?: Maybe<Array<Maybe<PreparationReport>>>;
  season_id?: Maybe<Scalars['Int']['output']>;
  sports_code?: Maybe<Scalars['String']['output']>;
  sports_description?: Maybe<Scalars['String']['output']>;
  sports_group?: Maybe<Scalars['String']['output']>;
  sports_name?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  vehicle_id?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
};

/** Primary Group Leader The primary group leader is the main contact for a fundraiser The association is not direct with a user but made through email */
export type PrimaryGroupLeader = {
  __typename?: 'PrimaryGroupLeader';
  /** Signifies user is primary group leader. Extra query. */
  currentUserIsPrimary: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type PrintAttributesInput = {
  name?: InputMaybe<PrintValueInput>;
  number?: InputMaybe<PrintValueInput>;
};

export type PrintValueInput = {
  color?: InputMaybe<Color>;
  font?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum Processor {
  Stripe = 'STRIPE',
  Unit = 'UNIT'
}

export enum Product {
  AdAssist = 'AD_ASSIST',
  AppPortal = 'APP_PORTAL',
  Connect = 'CONNECT',
  Insights = 'INSIGHTS',
  LeagueKeeper = 'LEAGUE_KEEPER',
  Manage = 'MANAGE',
  Raise = 'RAISE',
  Spend = 'SPEND',
  Sponsor = 'SPONSOR',
  Store = 'STORE'
}

export type ProductInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  logoPosition: LogoPosition;
  magentoSku?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  netsuiteId?: InputMaybe<Scalars['String']['input']>;
  size: ProductSize;
  sku: Scalars['String']['input'];
};

export type ProductResult = {
  __typename?: 'ProductResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id?: Maybe<Scalars['String']['output']>;
};

export enum ProductSize {
  L = 'L',
  M = 'M',
  NoSize = 'NO_SIZE',
  Osfa = 'OSFA',
  S = 'S',
  Xl = 'XL',
  Xs = 'XS',
  Xxl = 'XXL',
  Xxs = 'XXS',
  Xxxl = 'XXXL',
  Xxxxl = 'XXXXL',
  Yl = 'YL',
  Ym = 'YM',
  Ys = 'YS',
  Yxl = 'YXL',
  Yxs = 'YXS',
  Yxxl = 'YXXL',
  Yxxxl = 'YXXXL'
}

export enum ProductStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type ProfitPercentage = {
  __typename?: 'ProfitPercentage';
  points_percentage?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  updated_product_price_percentage?: Maybe<Scalars['String']['output']>;
};

export enum ProgramActivity {
  ActionSports = 'ACTION_SPORTS',
  Archery = 'ARCHERY',
  Art = 'ART',
  Asb = 'ASB',
  Avid = 'AVID',
  Badminton = 'BADMINTON',
  Band = 'BAND',
  Baseball = 'BASEBALL',
  Basketball = 'BASKETBALL',
  Bowling = 'BOWLING',
  Boxing = 'BOXING',
  Business = 'BUSINESS',
  Cheerleading = 'CHEERLEADING',
  Chess = 'CHESS',
  Choir = 'CHOIR',
  Crew = 'CREW',
  CrossCountry = 'CROSS_COUNTRY',
  CultureAndLanguage = 'CULTURE_AND_LANGUAGE',
  Cycling = 'CYCLING',
  Dance = 'DANCE',
  Debate = 'DEBATE',
  Deca = 'DECA',
  Drama = 'DRAMA',
  Equestrian = 'EQUESTRIAN',
  Fashion = 'FASHION',
  Fbla = 'FBLA',
  Fccla = 'FCCLA',
  Fencing = 'FENCING',
  FieldHockey = 'FIELD_HOCKEY',
  FilmAndTvProduction = 'FILM_AND_TV_PRODUCTION',
  Football = 'FOOTBALL',
  FreshmanClass = 'FRESHMAN_CLASS',
  Golf = 'GOLF',
  GsaAndLgbtqi = 'GSA_AND_LGBTQI',
  Gymnastics = 'GYMNASTICS',
  Hockey = 'HOCKEY',
  Hosa = 'HOSA',
  Journalism = 'JOURNALISM',
  JuniorClass = 'JUNIOR_CLASS',
  KeyAndLink = 'KEY_AND_LINK',
  Lacrosse = 'LACROSSE',
  MartialArts = 'MARTIAL_ARTS',
  ModelUnAndTrial = 'MODEL_UN_AND_TRIAL',
  Music = 'MUSIC',
  NationalHonorsSociety = 'NATIONAL_HONORS_SOCIETY',
  NonProfit = 'NON_PROFIT',
  OrchestraAndSymphony = 'ORCHESTRA_AND_SYMPHONY',
  Other = 'OTHER',
  Outdoors = 'OUTDOORS',
  Photography = 'PHOTOGRAPHY',
  Polo = 'POLO',
  Powerlifting = 'POWERLIFTING',
  PromAndHomecoming = 'PROM_AND_HOMECOMING',
  Robotics = 'ROBOTICS',
  Rotc = 'ROTC',
  Rugby = 'RUGBY',
  Sailing = 'SAILING',
  Scholarship = 'SCHOLARSHIP',
  SeniorClass = 'SENIOR_CLASS',
  Shooting = 'SHOOTING',
  Skiing = 'SKIING',
  Soccer = 'SOCCER',
  Softball = 'SOFTBALL',
  SophomoreClass = 'SOPHOMORE_CLASS',
  Stem = 'STEM',
  StudentGovernment = 'STUDENT_GOVERNMENT',
  Surf = 'SURF',
  SwimAndDive = 'SWIM_AND_DIVE',
  TableTennis = 'TABLE_TENNIS',
  Tennis = 'TENNIS',
  TrackAndField = 'TRACK_AND_FIELD',
  UltimateFrisbee = 'ULTIMATE_FRISBEE',
  VideoGame = 'VIDEO_GAME',
  Volleyball = 'VOLLEYBALL',
  WaterPolo = 'WATER_POLO',
  WinterSports = 'WINTER_SPORTS',
  Wrestling = 'WRESTLING',
  Writing = 'WRITING',
  Yearbook = 'YEARBOOK'
}

export type ProgramForEventOld = {
  __typename?: 'ProgramForEventOld';
  /** The gender of the activity or sport. */
  gender?: Maybe<Scalars['String']['output']>;
  /** The sport code associated with the event. */
  groupVal?: Maybe<Scalars['String']['output']>;
  /** The level of the activity or sport. */
  level?: Maybe<Scalars['String']['output']>;
  /** The season ID associated with the event. */
  seasonId?: Maybe<Scalars['Int']['output']>;
  /** The sport code associated with the event. */
  sportCode?: Maybe<Scalars['String']['output']>;
  /** The sport or activity associated with the event. */
  sportName?: Maybe<Scalars['String']['output']>;
  /** The season year associated with the event. */
  year?: Maybe<Scalars['String']['output']>;
};

export type ProgramLeadership = {
  __typename?: 'ProgramLeadership';
  org: Org;
  programs: Array<ProgramWithTitle>;
};

export type ProgramWithTitle = {
  __typename?: 'ProgramWithTitle';
  program: Org;
  titles: Array<Leader>;
};

export type Providers = {
  email?: InputMaybe<SupportedEmailProviders>;
  sms?: InputMaybe<SupportedSmsProviders>;
};

export type PublicFundraiserData = {
  __typename?: 'PublicFundraiserData';
  alternateColor?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  donationMinDollars?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  fanStore?: Maybe<Scalars['Boolean']['output']>;
  goal?: Maybe<Scalars['Int']['output']>;
  hasCustomRewards?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  incStore?: Maybe<Scalars['Boolean']['output']>;
  isGuardianLedCampaign?: Maybe<Scalars['Boolean']['output']>;
  joinCode?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  participantGoal?: Maybe<Scalars['Int']['output']>;
  personalMessage?: Maybe<Scalars['String']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  programLeader?: Maybe<Scalars['String']['output']>;
  programType?: Maybe<Scalars['String']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  storeUrl?: Maybe<Scalars['String']['output']>;
  whyDonations?: Maybe<Scalars['String']['output']>;
};

export type PublicGroup = {
  __typename?: 'PublicGroup';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  seasons?: Maybe<Array<PublicSeason>>;
};

export type PublicGroupResponse = {
  __typename?: 'PublicGroupResponse';
  groups: Array<PublicGroup>;
};

export type PublicSeason = {
  __typename?: 'PublicSeason';
  id: Scalars['String']['output'];
  isLinkEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  paymentScheduleStatus: Scalars['Boolean']['output'];
};

export enum PurchaseIntentStatus {
  Cart = 'CART',
  Purchased = 'PURCHASED',
  Refunded = 'REFUNDED',
  Removed = 'REMOVED',
  Supermarket = 'SUPERMARKET'
}

export type PurchaseItemInput = {
  costCents: Scalars['Int']['input'];
  image: Scalars['String']['input'];
  incentiveId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  netEarnedCents: Scalars['Float']['input'];
  priceCents: Scalars['Int']['input'];
  size: Scalars['String']['input'];
};

export type PurchaseParticipantUpdateData = {
  fundraiserUserId: Scalars['Int']['input'];
  participantUserDirectoryId: Scalars['String']['input'];
};

export enum PurchaseStatus {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Initiated = 'INITIATED',
  Processing = 'PROCESSING',
  Refunded = 'REFUNDED',
  Refunding = 'REFUNDING',
  Succeeded = 'SUCCEEDED'
}

export type PurchaseUpdateInput = {
  /** Update donor full name */
  donorName?: InputMaybe<Scalars['String']['input']>;
  /** Update purchased gift shop item sizes */
  lineItems?: InputMaybe<Array<GiftShopPurchaseItemUpdate>>;
  /** Update with different Participant Data */
  participant?: InputMaybe<PurchaseParticipantUpdateData>;
};

export type PushNotificationResponse = {
  __typename?: 'PushNotificationResponse';
  id: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  accountTransactions: Array<Maybe<Transaction>>;
  auditLogs?: Maybe<AuditLogList>;
  busSchedules?: Maybe<Array<Maybe<BusSchedules>>>;
  /** Return startDateTime and endDateTime as formatted UTC strings */
  campaignDates?: Maybe<CampaignDates>;
  /** Get fundraiser approval submission associated to campaigns. Latest submission only. */
  campaignFundraiserApprovalSubmissions: Array<FundraiserApprovalSubmission>;
  campaignHistoryListGet?: Maybe<Array<Maybe<CampaignHistoryList>>>;
  campaignKyc?: Maybe<CampaignKyc>;
  /** Query uses Users JWT to find their Memberships See CampaignMembership type for details */
  campaignMemberships: Array<CampaignMembership>;
  /** List of saved search filters for campaigns of current user. */
  campaignSearchFiltersGet?: Maybe<Array<Maybe<CampaignSearchFilter>>>;
  /** List campaigns based on logged-in user */
  campaigns?: Maybe<CampaignList>;
  /** Retrieve contact activity */
  commsContact?: Maybe<Array<CommsContactResponse>>;
  /** Get email logs */
  commsGetEmailLogs?: Maybe<Array<Maybe<EmailLog>>>;
  /** Retrieve Content */
  commsHtml?: Maybe<CommsHtmlResponse>;
  /** Retrieve message activity */
  commsMessage: CommsMessageResponse;
  /** Retrieve template details */
  commsTemplate?: Maybe<CommsTemplateResponse>;
  /** Retrieve all templates for a service */
  commsTemplates?: Maybe<Array<CommsRegisterResponse>>;
  /** Retrieve Service Unsubscribe Groups */
  commsUnsubscribeGroups?: Maybe<CommsUnsubscribeGroupsResponse>;
  competitionList?: Maybe<Array<Maybe<TransformedEventReturn>>>;
  conference: Conference;
  conferences: Array<Conference>;
  consumers?: Maybe<Array<Maybe<Consumer>>>;
  /** List of contact templates based on current user */
  customContactTemplatesGet?: Maybe<Array<Maybe<CustomContactTemplates>>>;
  dailyCalendarBusSchedules?: Maybe<TransformedDailyCalendarBusSchedule>;
  dailyCalendarEvents?: Maybe<Array<Maybe<TransformedDailyCalendarEventReturn>>>;
  dailyCalendarOfficials?: Maybe<TransformedDailyCalendarOfficials>;
  dailyCalendarPreparations?: Maybe<TransformedDailyCalendarPreparation>;
  dailyCalendarWorkers?: Maybe<TransformedDailyCalendarWorkers>;
  daySchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  donationLevels: Array<DonorsDonationLevel>;
  donationLevelsByFundraiserId: Array<DonationLevel>;
  /** @deprecated This can be queried for specific personas via CampaignMemberships. */
  donationTopEarner?: Maybe<DonationTopEarner>;
  donationsQuery?: Maybe<DonationsResponse>;
  donorAlumni?: Maybe<AlumniDonorSchoolDetails>;
  donorDonateIntent?: Maybe<DonateIntent>;
  donorFundraiserData: DonorFundraiserData;
  donorPersonListEntries?: Maybe<Array<Maybe<DonorPersonListEntry>>>;
  /** @deprecated Use `donorsCheerwalls` query instead */
  donorsCheerwall?: Maybe<DonorsFundraiserCheerWall>;
  donorsCheerwalls?: Maybe<DonorsCheerwallsResult>;
  donorsDonation?: Maybe<DonorDonation>;
  donorsDonationStats?: Maybe<DonorsDonationStatsResponse>;
  donorsFundraiserPayables?: Maybe<Array<Maybe<DonorFundraiserPayable>>>;
  donorsGeneratePdfFlyer?: Maybe<Scalars['String']['output']>;
  donorsGiftShopIntent: GiftShopPurchaseIntentData;
  donorsGiftShopPurchase?: Maybe<DonorFundraiserPurchase>;
  donorsOrgs: DonorsOrgsResult;
  donorsParticipantOTKIncentives?: Maybe<DonorsParticipantOtkIncentives>;
  donorsParticipantsGiftShop?: Maybe<DonorsParticipantsGiftShop>;
  donorsRedirects: Array<Redirect>;
  /** List all activity types of campaigns. Used by Drive */
  driveActivityTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get details of campaigns. This endpoint need to considered to combined with searchDriveCampaign endpoint */
  driveCampaignDetails?: Maybe<DriveCampaignDetails>;
  /** Get statistics of the set of campaigns returned by the filter. This endpoint are meant to be used with the searchDriveCampaigns endpoint. Arguments should be the same. */
  driveCampaignSearchStats?: Maybe<DriveCampaignSearchStatistics>;
  /** Show details of a specific event */
  driveEvent?: Maybe<DriveEvent>;
  /** List events */
  driveEvents?: Maybe<DriveEventResults>;
  driveGetEarlyAccessFunds?: Maybe<EarlyAccess>;
  driveGetListActivitiesAvailableList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListActivitiesNotAvailableList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListCampaignHistoryList?: Maybe<DriveGetListCurrentCustomersReturn>;
  driveGetListCurrentCustomersList?: Maybe<DriveGetListCurrentCustomersReturn>;
  driveGetListHubspotProspectsList?: Maybe<DriveGetlistProspectsReturn>;
  driveGetListOrgList?: Maybe<DriveOrgListResults>;
  /** Returns Organization Summary information of single organization */
  driveGetListOrganizationSummary?: Maybe<DriveGetListOrganizationSummaryReturn>;
  /** @deprecated changed name to driveGetListActivitiesAvailableList */
  driveGetListPotentialCustomersList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListWinbackList?: Maybe<DriveGetlistWinbackReturn>;
  driveGetProjectedRaised?: Maybe<EarlyAccess>;
  /** Return all tracking of current user to specific organization in Drive's Get List app. */
  driveOrgUserTrackings?: Maybe<Array<Maybe<DriveOrgUserTrackingReturn>>>;
  /** List organization types used by Drive */
  driveOrganizationTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** List all salesreps */
  driveSalesreps?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  /** List all salesreps which had re-assigned any campaigns to current user. This endpoint is applicable for account-manager only. */
  driveSalesrepsByAccountManager?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  /** List all salesreps which managed by current user. This endpoint is applicable for sales manager only. */
  driveSalesrepsByManager?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  driveSmsInviteData?: Maybe<SmsInviteData>;
  /**
   * Search Raise users by UserDirectory 's id
   * @deprecated Use UserDirectory 's users query instead
   */
  driveUsers?: Maybe<DriveUsersResult>;
  /** List Event-Tracker's venues */
  driveVenues?: Maybe<Array<Maybe<DriveVenue>>>;
  event: Event;
  eventCalendar?: Maybe<Array<Maybe<CommonCalendar>>>;
  eventContractItems?: Maybe<Array<Maybe<ContractItem>>>;
  eventOfficial: Official;
  eventOfficials?: Maybe<Array<Maybe<Official>>>;
  eventParticipants: EventParticipants;
  eventPreparations: EventPreparations;
  eventPreparationsByEvent?: Maybe<Array<Maybe<EventPreparations>>>;
  eventResult?: Maybe<EventResult>;
  eventTransportDetailsByEventOrDates?: Maybe<Array<Maybe<EventTransportDetails>>>;
  eventWorker: Worker;
  eventWorkers?: Maybe<Array<Maybe<Worker>>>;
  eventWorkersByEvent?: Maybe<Array<Maybe<Worker>>>;
  events?: Maybe<Array<Maybe<Event>>>;
  eventsParticipants?: Maybe<Array<Maybe<EventParticipants>>>;
  eventsPreparations?: Maybe<Array<Maybe<EventPreparations>>>;
  eventsTransportDetails?: Maybe<Array<Maybe<EventTransportDetails>>>;
  facilities?: Maybe<Array<Maybe<Facility>>>;
  facility: Facility;
  facilityCalendar?: Maybe<Array<Maybe<CommonCalendar>>>;
  facilityGrid?: Maybe<Array<Maybe<CommonGrid>>>;
  facilitySchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  family?: Maybe<Family>;
  feeReportSheets?: Maybe<Array<Maybe<CommonSheet>>>;
  /** Get all Financial Accounts that are associated with a Stripe Connect Account Id If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query. */
  financialAcctAcctId: Array<FinancialAcctOrg>;
  /** Get all Financial Accounts that are associated with an Org using the Org Id. If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query. */
  financialAcctOrg: Array<FinancialAcctOrg>;
  /** Get all Financial Accounts that are associated with an Org using the Kyb Id. If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query. */
  financialAcctOrgByKyb: Array<FinancialAcctOrg>;
  /** Get all Financial Accounts that are associated with a parent Org. This includes parents of parents and all the way up the tree. This will NOT return the Financial Accounts for the target Org, please use the 'financialAcctOrg' query for specific Orgs. */
  financialAcctRoot: Array<FinancialAcctOrg>;
  /** Get all User data associated with a single Financial Account with an Orgs Platform Id. Eg: 'finacct_stripe_abcd1234' */
  financialAcctUsers: Array<FinancialAcctUser>;
  /** Get all User and Financial Account data associated with a single Kyc Id. Eg: 'vkyc_abcd1234' */
  financialAcctUsersByKyc: Array<FinancialAcctUser>;
  fundraiser?: Maybe<Fundraiser>;
  fundraiserApprovalSubmissions: Array<Maybe<FundraiserApprovalSubmission>>;
  /** Get fundraiser approval submissions authenticating request by approver's token */
  fundraiserApprovalSubmissionsHistory: Array<FundraiserApprovalSubmission>;
  /** Get fundraiser approval submission related org names authenticating request by approver's token */
  fundraiserApprovalSubmissionsOrgNames: FundraiserApprovalSubmissionsOrgNamesResult;
  fundraiserCheerwall?: Maybe<FundraiserCheerwall>;
  fundraiserCustomRewardsDetails?: Maybe<Array<Maybe<FundraiserCustomRewardsData>>>;
  fundraiserDataByCoachJoinCode?: Maybe<FundraiserDataByCoachJoinCode>;
  /** resourceId is the fundraiserId */
  fundraiserEntityResources?: Maybe<FundraiserEntityResource>;
  fundraiserGroups?: Maybe<Array<Maybe<FundraiserGroup>>>;
  fundraiserMediaGallery?: Maybe<Array<FundraisersMediaGallery>>;
  fundraiserRaisedAmount?: Maybe<FundraiserRaisedAmount>;
  fundraiserRewardsDetails?: Maybe<Array<Maybe<FundraiserRewardsProduct>>>;
  fundraiserTopDonation?: Maybe<FundraiserTopDonation>;
  fundraiserUserRole?: Maybe<FundraiserUserRole>;
  fundraisersActivationEligibility?: Maybe<FundraiserActivationEligibilityResponse>;
  fundraisersFinalizationEligibility?: Maybe<FundraiserFinalizationEligibilityResponse>;
  fundraisersShippingAddressConfirmed: Scalars['Boolean']['output'];
  galleryItems?: Maybe<GalleryItems>;
  gearLogoConfigurationApproved: Scalars['Boolean']['output'];
  getCampaignRoster?: Maybe<Array<Maybe<Roster>>>;
  getFundraiserUserID?: Maybe<Scalars['Int']['output']>;
  getInsightsUserOrgs: Array<Maybe<InsOrg>>;
  getSalesRepHome: HmSalesRep;
  getUserSavedSalesRep: UserSavedRep;
  /** return the most recently created fundraiser associated with the group leader */
  groupLeaderDefaultFundraiser?: Maybe<GroupLeaderFundraiser>;
  /** return a fundraiser by id */
  groupLeaderFundraiser?: Maybe<GroupLeaderFundraiser>;
  groupLeaderFundraiserManager?: Maybe<GroupLeaderFundraiserManager>;
  groupLeaderFundraiserManagerAlert?: Maybe<Array<FundraiserManagerAlert>>;
  groupLeaderFundraiserParticipants?: Maybe<Array<GroupLeaderFundraiserParticipant>>;
  /** return Fundraiser Pdf based on type */
  groupLeaderFundraiserPdf?: Maybe<Scalars['String']['output']>;
  /** return a list of fundraisers associated with the group leader, paginated, and filtered by role and status */
  groupLeaderFundraisers?: Maybe<GroupLeaderFundraisersPaginated>;
  groupLeaderRoster?: Maybe<GroupLeaderRoster>;
  /** List of group leaders */
  groupLeaders?: Maybe<GroupLeaderList>;
  helpDocumentsList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  infoSheets?: Maybe<Array<Maybe<CommonSheet>>>;
  insightsCampaignDonations: Array<Maybe<InsCampaignDonation>>;
  insightsCampaignRaiseEntityInfo: InsCampaignRaiseEntityInfo;
  insightsCampaignSettlements: Array<Maybe<InsCampaignSettlement>>;
  insightsCampaignsData: InsCampaignsData;
  insightsDonorParticipantContacts: Array<Maybe<InsDonorParticipantContact>>;
  insightsNotificationPreferences: InsightsUserOrgsNotificationPreferencesResponse;
  insightsOrgsCampaignsSummary: Array<Maybe<InsOrgCampaignSummary>>;
  insightsPreApprovedContacts: Array<Maybe<InsPreApprovedContact>>;
  insightsPreApprovedContactsEmailTest: Array<Maybe<InsEmailTestPreApprovedContactResult>>;
  insightsSalesRepInfo?: Maybe<InsSalesRepInfo>;
  insightsUserPreferences?: Maybe<Array<Maybe<InsightsGetUserPreference>>>;
  invite?: Maybe<InviteInfo>;
  invites?: Maybe<Array<Maybe<InviteList>>>;
  invitesList?: Maybe<InvitesListResponse>;
  /** joinCode is a string that is used to join a fundraiser */
  joinCodeType: JoinCodeType;
  legacyTransactions: LegacyTransactionsOutput;
  level: Level;
  levelGrid?: Maybe<Array<Maybe<CommonGrid>>>;
  levels?: Maybe<Array<Maybe<Level>>>;
  /** Retrieves a list of coaches based on the provided filter. */
  manageCoachListOld?: Maybe<ManageCoachListOld>;
  /** Retrieves a specific coach by its ID. */
  manageCoachOld?: Maybe<ManageCoachOld>;
  /** Retrieves a list of events based on the provided filter. */
  manageEventListOld?: Maybe<ManageEventListOld>;
  /** Retrieves a specific event by its ID. */
  manageEventOld?: Maybe<ManageEventOld>;
  /** Get a list of all manage organizations */
  manageOrganizationListOld?: Maybe<ManageOrganizationListOld>;
  /** Get a manage organization by web folder */
  manageOrganizationOld?: Maybe<ManageOrganizationOld>;
  managePlayerListOld?: Maybe<ManagePlayerListOld>;
  manageProgramListOld?: Maybe<ManageProgramListOld>;
  manageProgramOld?: Maybe<ManageProgramOld>;
  manageSeasonListOld?: Maybe<ManageSeasonListOld>;
  manageSeasonOld?: Maybe<ManageSeasonOld>;
  manageSportDetailOld?: Maybe<ManageSportDetailsOld>;
  manageSportDetailsListOld?: Maybe<ManageSportDetailsListOld>;
  manageUser?: Maybe<ManageUser>;
  manageUsersList?: Maybe<ManageUsersList>;
  me?: Maybe<UserWithPermissions>;
  messagesChannel?: Maybe<ChatChannelResult>;
  messagesChannelInvites?: Maybe<MessagesChannelInvitesResult>;
  messagesChannelMembers?: Maybe<MessagesChannelMembersResult>;
  messagesChannelOrg: MessagesChannelOrgResult;
  messagesChannels?: Maybe<ChatChannelsResult>;
  messagesMyChannels?: Maybe<MessagesMyChannelsResult>;
  messagesMyModeratedChannels?: Maybe<MessagesMyModeratedChannelsResult>;
  messagesUnreadCount?: Maybe<ChatUnreadMessagesResult>;
  notices?: Maybe<Array<Maybe<Notice>>>;
  officialAssignments?: Maybe<OfficialAssignmentsReturn>;
  officialDuties?: Maybe<Array<Maybe<OfficialDuty>>>;
  officialDuty?: Maybe<OfficialDuty>;
  officialListsReport?: Maybe<Array<Maybe<EventOfficialReport>>>;
  officialPoolById: OfficialPool;
  officialPools?: Maybe<Array<Maybe<OfficialPool>>>;
  officialSchedules?: Maybe<Array<Maybe<OfficialSchedules>>>;
  opponent: Opponent;
  opponentEventsApplyFilters?: Maybe<Array<Maybe<EventsOpponent>>>;
  opponentSchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  opponents?: Maybe<Array<Maybe<Opponent>>>;
  /** Get Org Node properties by its Org id. If hierarchy is set to true, it will return all the children of the organization, but NOT the target Org. 'fields' returns JSON of all remaining properties. You can optionally limit this to specific fields by passing in an array of keys. Example: "names": [ "country", "zip_code" ] */
  org?: Maybe<Array<Maybe<Org>>>;
  /** Get Org Node properties from the campaign's Org id (eg: "cam_abc123") You can request a specifc Org type by passing in a label. If hierarchy is set to true, this query will return every Org all the way to the root */
  orgCampaignId?: Maybe<Array<Maybe<Org>>>;
  /** Get all Campaigns under a specific Org. This will return an array of all Campaigns in the type of Org, sorted by start_date descending. */
  orgCampaigns?: Maybe<Array<Maybe<Org>>>;
  /** Get fundraiser approval submission associated to orgs. Latest submission only. */
  orgFundraiserApprovalSubmissions: Array<FundraiserApprovalSubmission>;
  /** Get Org Node properties by its Hubspot id. */
  orgHubspotId: Array<Org>;
  /** Get an Organization's Org platform id by its Raise entities.id OR Get a Campaign's Org platform id by its Raise fundraisers.id This ONLY returns an Org id, not the full Org Node. */
  orgId?: Maybe<Scalars['ID']['output']>;
  /** Get the Org paid by a given campaign id. This required an Org Platform Campaign Id (eg: "cam_abc123") If you only have a Raise Fundraiser Id, you can use the 'orgId' query to find it. */
  orgPayable?: Maybe<Array<Maybe<Org>>>;
  /** Fuzzy search for Orgs by their name property, returned in order of match quality. Optionally accepts label, ein, city, two letter state code, or zip code. This search has a default limit of 25 and a maximim limit of 100 responses. */
  orgSearch: Array<Org>;
  /** Get all Users that are directly 'affiliated with' or 'lead' a Program or Team under a specific Org. */
  orgStaffRoster: Array<OrgStaffMember>;
  /** Get all Users that are associated with an Org and a specific product. This includes role and a boolean if the user is internal (snap-raise or snapraise email). */
  orgUserAssociations: Array<Maybe<UserNode>>;
  /**
   * This has been deprecated. Please use orgSearch which has been optimized and has more features. Search for Orgs by their name property, with an option to specify label. This will only return the first 100 orgs.
   * @deprecated Please use orgSearch instead
   */
  orgs: Array<Org>;
  /**
   * This has been deprecated. Please use orgSearch which has been optimized and has more features. This legacy search searches by type or specific properties. Default limit is 25 items.
   * @deprecated Please use orgSearch instead
   */
  orgsSearch?: Maybe<OrgsSearchResponse>;
  /** This query will return OTK Fundraiser Product details */
  otkFundraiserData?: Maybe<Array<Maybe<OtkFundraiserProduct>>>;
  /** This query will return OTK Participant details */
  otkParticipantDetails?: Maybe<Array<Maybe<OtkParticipantData>>>;
  /** This query will return the most recent OTK Participant details */
  otkPastParticipantDetails?: Maybe<Array<Maybe<OtkParticipantData>>>;
  participantCampaignConfigurationData?: Maybe<ParticipantCampaignConfiguration>;
  participantCheerwall?: Maybe<ParticipantCheerwall>;
  participantDonationInvites?: Maybe<Array<ParticipantDonationInvite>>;
  participantDonationsRaised: ParticipantDonationsRaised;
  /** This query will return the incentives a Participant earned for a given fundraiser */
  participantEarnedRewardsDetails?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  participantFundraiser?: Maybe<ParticipantFundraiser>;
  /** Accepts a udid and returns a participantFundraiser object */
  participantFundraiserConfiguration?: Maybe<ParticipantFundraiserConfiguration>;
  participantFundraiserParticipant?: Maybe<ParticipantFundraiserParticipant>;
  participantFundraiserReward?: Maybe<ParticipantReward>;
  participantFundraisers: Array<Maybe<ParticipantFundraiser>>;
  participantGuardianFundraisers?: Maybe<Array<Maybe<ParticipantGuardianFundraiser>>>;
  participantPreloadDonationInvitesHistory?: Maybe<ParticipantPreloadDonationInvitesHistoryResponse>;
  participantPublic?: Maybe<ParticipantPublic>;
  /** This query will return Participant Rewards details */
  participantRewardsDetails?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  participantSSOId?: Maybe<ParticipantSsoid>;
  participantShareMessage: Scalars['String']['output'];
  participantSmsDonationInvites?: Maybe<Array<ParticipantSmsDonationInvite>>;
  participantTextTemplate?: Maybe<ParticipantTextTemplate>;
  participantTopDonation?: Maybe<ParticipantTopDonation>;
  /** List of participants */
  participants?: Maybe<ParticipantList>;
  participantsListV2?: Maybe<Array<Maybe<FundraiserParticipants>>>;
  participantsPublic?: Maybe<Array<Maybe<FundraiserParticipantPublic>>>;
  paymentsApiCustomerByEmail: PaymentsApiCustomerResponse;
  payouts: Array<Maybe<Payout>>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
  /** List of pre-made contact templates */
  premadeContactTemplatesGet?: Maybe<Array<Maybe<PremadeContactTemplate>>>;
  preparation: Preparation;
  preparationReportSheets?: Maybe<Array<Maybe<PreparationSheet>>>;
  preparations?: Maybe<Array<Maybe<Preparation>>>;
  /** This resolver is only for testing purposes at this time */
  primaryGroupLeader?: Maybe<PrimaryGroupLeader>;
  /** Identifier uses fundraiser join_code or slug */
  publicFundraiserData?: Maybe<PublicFundraiserData>;
  /** Get user public data from User Directory by userID */
  publicUserData?: Maybe<User>;
  /** A query to get last year's fundraiser by the id of this year's fundraiser */
  raiseAdminFundraiserPast?: Maybe<RaiseAdminFundraiserPast>;
  registrationRoster: RegistrationRoster;
  role?: Maybe<Role>;
  roles?: Maybe<Array<Maybe<Role>>>;
  /** Get one Roster with it's members by its Roster id. */
  roster?: Maybe<OrgsRoster>;
  /** Get all Rosters and their members that are associated with a specific Org. */
  rosters: Array<OrgsRoster>;
  scheduleEvent?: Maybe<ScheduleEvent>;
  scheduleEvents?: Maybe<Array<Maybe<ScheduleEvent>>>;
  schoolInfo: SchoolInfo;
  schoolInfos?: Maybe<Array<Maybe<SchoolInfo>>>;
  /** Search campaigns */
  searchDriveCampaigns?: Maybe<DriveCampaignList>;
  /** Search Raise's Organization. @Deprecated: should be replaced by Orgs API */
  searchDriveOrganizationsByName?: Maybe<DriveOrganizationList>;
  season: Season;
  seasonByYearAndTeam?: Maybe<Array<Maybe<Season>>>;
  seasonEventWizard?: Maybe<Array<Maybe<Event>>>;
  seasons?: Maybe<Array<Maybe<Season>>>;
  settlementMethodChangeEligibility?: Maybe<SettlementMethodChangeEligibilityResponse>;
  shipments: ShipmentResponse;
  spendAccountExport?: Maybe<ExportFile>;
  spendAdminGetArchivedGroups?: Maybe<SpendGroupResponse>;
  spendBudget?: Maybe<SpendBudgetResponse>;
  spendBudgetExport?: Maybe<ExportFile>;
  spendBudgetTransactions: SpendBudgetTransactionsOutput;
  spendBudgets?: Maybe<SpendBudgetsResponse>;
  spendBudgetsProgramSummary?: Maybe<SpendBudgetSummaryResponse>;
  spendBudgetsSummary?: Maybe<SpendBudgetSummaryResponse>;
  spendBudgetsSummaryV2?: Maybe<SpendBudgetSummaryV2Response>;
  spendCategories?: Maybe<SpendCategoryResponse>;
  spendDataForHome: HmSpendData;
  spendDebitCardInvites?: Maybe<SpendInviteResponse>;
  spendGetApprovalDetails?: Maybe<SpendApprovalDetailResponse>;
  spendGetApprovalsByTab?: Maybe<SpendApprovalResponse>;
  spendGetCounterpartyById?: Maybe<SpendCounterparty>;
  spendGetGroupCounterparties?: Maybe<SpendCounterpartiesResponse>;
  spendGetInstitutionByRoutingNumber?: Maybe<SpendInstitutionResponse>;
  spendGetOrganizationCounterparties?: Maybe<SpendCounterpartiesResponse>;
  spendGetPayeeById?: Maybe<SpendPayees>;
  spendGroup?: Maybe<SpendGroup>;
  spendGroupBankAccounts?: Maybe<SpendBankAccountsResponse>;
  spendGroupBankLinkTokenCreate?: Maybe<SpendBankLinkCreateResponse>;
  spendGroupById?: Maybe<SpendGroup>;
  spendGroupDebitCards?: Maybe<SpendDebitCardResponse>;
  spendGroupPayees?: Maybe<SpendPayeeResponse>;
  spendGroupRosters?: Maybe<SpendGroupRosterResponse>;
  spendGroupRostersBySeason?: Maybe<SpendGroupRosterResponse>;
  spendGroupRostersBySeasonV2?: Maybe<SpendGroupRosterResponse>;
  spendGroupRostersExport?: Maybe<ExportFile>;
  spendGroups?: Maybe<SpendGroupResponse>;
  /** List groups with pagination/filtering and sorting. Valid sort.field: name, seasonStartDate, seasonEndDate, playerCount, paid, upcoming, pastDue, dueToday, processing, paymentScheduleStatus */
  spendGroupsFiltered?: Maybe<SpendGroupsResponse>;
  spendGroupsOverviewDashboard?: Maybe<GroupsOverviewDashboardResponse>;
  spendGroupsPublic?: Maybe<PublicGroupResponse>;
  spendGuardianGroupRosters?: Maybe<SpendGroupRosterResponse>;
  spendGuardianHighlight?: Maybe<SpendGuardianHighlight>;
  spendGuardianInvoices?: Maybe<SpendGuardianInvoiceResponse>;
  spendGuardianInvoicesV2?: Maybe<SpendInvoiceResponse>;
  spendInvite?: Maybe<SpendInviteResponse>;
  spendInvites?: Maybe<SpendInviteResponse>;
  spendInvitesFiltered?: Maybe<SpendInviteResponse>;
  spendInvoiceById?: Maybe<SpendInvoice>;
  spendInvoiceHistory?: Maybe<SendEmailResponse>;
  spendInvoices?: Maybe<SpendInvoiceResponse>;
  spendInvoicesExport?: Maybe<ExportFile>;
  spendInvoicesExportV2?: Maybe<ExportFile>;
  spendInvoicesFiltered?: Maybe<SpendInvoiceResponse>;
  spendOrganization?: Maybe<SpendOrganization>;
  spendOrganizationAccountLimits?: Maybe<SpendAccountLimitsResponse>;
  spendOrganizationAccounts?: Maybe<SpendAccountResponse>;
  spendOrganizationBankAccounts?: Maybe<SpendBankAccountsResponse>;
  spendOrganizationBankLinkTokenCreate?: Maybe<SpendBankLinkCreateResponse>;
  spendOrganizationById?: Maybe<SpendOrganization>;
  spendOrganizationCheckImage?: Maybe<SpendImageResponse>;
  spendOrganizationDebitCards?: Maybe<SpendDebitCardResponse>;
  spendOrganizationPayees?: Maybe<SpendPayeeResponse>;
  spendOrganizationPieChart?: Maybe<SpendOrganizationPieChartResponse>;
  spendOrganizations?: Maybe<SpendOrganizationsResponse>;
  spendPaginatedInvoices?: Maybe<SpendInvoiceResponse>;
  spendPastDueInvoices?: Maybe<SpendPastDueInvoicesOutput>;
  spendPaymentScheduleById?: Maybe<SpendPaymentSchedule>;
  spendPaymentSchedules?: Maybe<Array<Maybe<SpendPaymentSchedule>>>;
  spendRoleCurrent?: Maybe<SpendRole>;
  spendRoles?: Maybe<SpendRoleResponse>;
  spendRosters?: Maybe<SpendRosterResponse>;
  spendRostersFiltered?: Maybe<SpendRosterResponse>;
  spendSeason?: Maybe<SpendSeason>;
  spendSettings?: Maybe<SpendSettings>;
  spendSystemNotifications?: Maybe<SpendSystemNotificationsResponse>;
  spendTransaction?: Maybe<SpendBankTransactionResponse>;
  spendTransactionAttachment?: Maybe<TransactionAttachmentsResponse>;
  spendTransactionAttachmentRaw?: Maybe<SpendFileResponse>;
  spendTransactionAttachmentsByPaymentId?: Maybe<TransactionAttachmentsResponse>;
  spendTransactionNotes?: Maybe<TransactionNotesResponse>;
  spendTransactionNotesByPaymentId?: Maybe<TransactionNotesResponse>;
  /** @deprecated Use spendTransactionsFiltered */
  spendTransactions?: Maybe<SpendBankTransactionsResponse>;
  spendTransactionsExport?: Maybe<ExportFile>;
  spendTransactionsFiltered?: Maybe<SpendBankTransactionsResponse>;
  spendTransactionsLegacy?: Maybe<SpendLegacyTransactionsResponse>;
  spendUserAccountBearerToken?: Maybe<SpendAccountBearerTokenResponse>;
  spendUserBankAccounts?: Maybe<SpendBankAccountsResponse>;
  spendUserBankAccountsList?: Maybe<SpendBankAccountsListResponse>;
  spendUserBankLinkTokenCreate?: Maybe<SpendBankLinkCreateResponse>;
  spendUserExportEmails?: Maybe<ExportFile>;
  spendUserNotificationSetting?: Maybe<SpendUserNotificationSettingResponse>;
  spendUserVerificationToken?: Maybe<SpendVerificationTokenResponse>;
  sponsorDataForHome: HmSponsorData;
  storeAllBrands?: Maybe<StoreBrandsUnionType>;
  storeAllColors?: Maybe<StoreColorsUnionType>;
  storeBestSeller?: Maybe<StoreBaseSellerUnionType>;
  storeByScopeId?: Maybe<MagentoStoreUnionType>;
  storeCampaignPayableInfo?: Maybe<StoreCampaignPayableInfoUnionType>;
  storeDataForHome: HmStore;
  storeEarnedPointsGraph?: Maybe<MagentoStorePointsEarnedUnionType>;
  storeGLCampaigns?: Maybe<StoreUserCampaignUnionType>;
  storeGLParticipants?: Maybe<StoreUserParticipantUnionType>;
  storeOrder?: Maybe<StoreOrderResult>;
  storeOrderFilter?: Maybe<OrdersFilterUnionType>;
  storeOrderItemImages?: Maybe<StoreOrderItemsUnionType>;
  storeOrderSalesGraph?: Maybe<StoreOrderSalesGraphUnionType>;
  storeOrderSummary?: Maybe<OrdersSummaryUnionType>;
  storeOrdersCsv?: Maybe<MagentoOrderUnionType>;
  storePayableFilter?: Maybe<StorePayableFilterUnionType>;
  /** @deprecated Use storePointActivityFilterAdvance Query */
  storePointActivityFilter?: Maybe<PointActivityFilterUnionType>;
  storePointActivityFilterAdvance?: Maybe<PointActivityFilterUnionType>;
  /** @deprecated Use storePointsActivityCsvAdvance Query */
  storePointsActivityCsv?: Maybe<MagentoPointActivityUnionType>;
  storePointsActivityCsvAdvance?: Maybe<MagentoPointActivityUnionType>;
  storePointsWithdrawalRequestFilter?: Maybe<StorePointsWithdrawalFilterUnionType>;
  storeProfitPercentage?: Maybe<ProfitPercentage>;
  storeScopePayableInfo?: Maybe<StorePayableInfoUnionType>;
  storeSubscribedUser?: Maybe<StoreSubscribedUserUnionType>;
  storeTickets?: Maybe<StoreTicketUnionType>;
  storeUserDetails?: Maybe<StoreUserInfoUnionType>;
  storeUserPreference?: Maybe<UserPreference>;
  storesByCampaignIds?: Maybe<StoreFilterUnionType>;
  storesByGLEmail?: Maybe<StoreFilterUnionType>;
  storesSummaryByCampaignIds?: Maybe<StoresSummaryUnionType>;
  storesSummaryByGLEmail?: Maybe<StoresSummaryUnionType>;
  team: Team;
  teamOfficials?: Maybe<Array<Maybe<TemplateOfficial>>>;
  teamOrgSchedules?: Maybe<Array<Maybe<CommonSchedules>>>;
  teamPreparations?: Maybe<Array<Maybe<TemplatePreparation>>>;
  teamWorkers?: Maybe<Array<Maybe<TemplateWorker>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
  /** An example field added by the generator */
  testField: Scalars['String']['output'];
  transactionDetail?: Maybe<TransactionDetail>;
  transactions: Array<Maybe<Transaction>>;
  transactionsByAccount: Array<Maybe<Transaction>>;
  transactionsByAccountPaginated: AccountTransactionsPaginatedOutput;
  transactionsBySpendDestination: Array<Maybe<Transaction>>;
  transactionsBySpendSource: Array<Maybe<Transaction>>;
  twispCardBalances?: Maybe<Array<Maybe<TwispCardBalances>>>;
  twispCards?: Maybe<Array<Maybe<TwispCards>>>;
  twispStripeHooks?: Maybe<Array<Maybe<TwispStripeHooks>>>;
  twispTransactions?: Maybe<Array<Maybe<TwispTransactions>>>;
  unconfirmedEvents?: Maybe<UnconfirmedEventsList>;
  updateSavedSalesRep: UserSavedRep;
  user?: Maybe<User>;
  /** Get all Programs/Activities that a User leads, grouped by parent Org. */
  userActivityLeadership: Array<ProgramLeadership>;
  userAffiliations: Array<OrgAffiliation>;
  userApiKey?: Maybe<ApiKey>;
  userApiKeyBySecret?: Maybe<ApiKey>;
  userApiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  /** Get all Org ids that are associated with a single User and a specific product, as well all the Campaign ids in each hierarchy. */
  userAssociationCampaigns: Array<OrgCampaignIds>;
  /** Get all Orgs that are associated with a User and a specific product. */
  userAssociations: Array<Maybe<Org>>;
  userBySecret?: Maybe<UserWithPermissions>;
  userChallenge?: Maybe<UserChallenge>;
  /** Get all Financial Account data associated with a single User Directory ID. */
  userFinancialAccounts: Array<FinancialAcctUser>;
  userFitting?: Maybe<UserFitting>;
  /** Get a list of Campaigns grouped by Org that User has been a Group Leader for. If you do not pass in a User ID, we will use the logged in User's ID. */
  userGroupLeadership: Array<OrgCampaignAffiliation>;
  /** Get a list of implied affiliations based on GroupLeader relationships. */
  userImpliedAffiliations: Array<OrgCampaignAffiliation>;
  /** Get all Insights configurations for current user */
  userInsightsConfigurations: Array<Maybe<UserInsightsConfig>>;
  userInvites?: Maybe<InvitesResponse>;
  /** Get all titles that are associated with a User and a specific Org. */
  userOrgAffiliation: Array<UserAffiliation>;
  /** Get all Org relationships that are associated with a User. */
  userOrgRelationships: UserOrgRelationships;
  userParentsPublicInfo: Array<UserParentsPublicInfoResult>;
  /** Get a list Campaigns grouped by Org that User has been a Participant in. */
  userParticipation: Array<OrgCampaignAffiliation>;
  userPermissions?: Maybe<UserPermissionsList>;
  /** @deprecated use userPublicInfo instead */
  userPublic?: Maybe<UserPublic>;
  userPublicInfo?: Maybe<Array<Maybe<UserPublicInfoResult>>>;
  /** Get all Teams that a User leads. */
  userTeamLeadership: Array<TeamLeadership>;
  userToken: Auth;
  /** Get counts of unconfirmed LEADS, AFFILIATIONS, and IMPLIED affiliations for a User. */
  userUnconfirmedAffiliations: UserAffiliationCounts;
  users?: Maybe<Users>;
  vaultAccountPayoutFrequencySetAuditEntries?: Maybe<Array<VaultAccountPayoutFrequencySetAuditEntry>>;
  vaultAccountStatus: VaultAccountStatus;
  vaultCard: Card;
  vaultCreateKybKycSubmitAuditEntries?: Maybe<Array<VaultCreateKybKycSubmitAuditEntry>>;
  vaultKyb: VaultKyb;
  vaultKyc: VaultKyc;
  vaultOrgConfirmationSubmitAuditEntries?: Maybe<Array<VaultOrgConfirmationSubmitAuditEntry>>;
  vaultOwners?: Maybe<Array<VaultOwner>>;
  vaultStripeConnectAccountBankAccountListFetch?: Maybe<Array<Maybe<VaultBankAccount>>>;
  vaultStripeConnectAccountFetch?: Maybe<Scalars['JSONObject']['output']>;
  vaultStripeConnectAccountPaymentMethodDefaultSetAuditEntries?: Maybe<Array<VaultStripeConnectAccountPaymentMethodDefaultSetAuditEntry>>;
  vaultStripeConnectAccountsBankAccountListFetch?: Maybe<Scalars['JSONObject']['output']>;
  vaultStripeInvalidRepresentative?: Maybe<Scalars['ID']['output']>;
  vaultVgsValue: Scalars['String']['output'];
  vehicle: Vehicle;
  vehicles?: Maybe<Array<Maybe<Vehicle>>>;
  workerPoolById: WorkerPool;
  workerPools?: Maybe<Array<Maybe<WorkerPool>>>;
};


export type QueryAccountTransactionsArgs = {
  input: AccountTransactionsInput;
};


export type QueryAuditLogsArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};


export type QueryBusSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryCampaignDatesArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryCampaignFundraiserApprovalSubmissionsArgs = {
  campaignRaiseIds: Array<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<FundraiserApprovalSubmissionStatus>>>;
};


export type QueryCampaignHistoryListGetArgs = {
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCampaignKycArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryCampaignMembershipsArgs = {
  basicStatus?: InputMaybe<BasicCampaignStatus>;
  fundraiserId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCampaignsArgs = {
  accountManagerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  daysClosed?: InputMaybe<Scalars['String']['input']>;
  daysToLaunch?: InputMaybe<Scalars['String']['input']>;
  fetchAllCampaigns?: InputMaybe<Scalars['Boolean']['input']>;
  getTeamSelling?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  salesrepIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<SortField>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCommsContactArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCommsHtmlArgs = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  mjml: Scalars['String']['input'];
};


export type QueryCommsMessageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCommsTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCommsTemplatesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<SnapService>;
  transport?: InputMaybe<MessageTransportType>;
};


export type QueryCommsUnsubscribeGroupsArgs = {
  service?: InputMaybe<SnapService>;
};


export type QueryCompetitionListArgs = {
  input?: InputMaybe<GetCompetitionListInput>;
};


export type QueryConferenceArgs = {
  conferenceSlug?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryConsumersArgs = {
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDailyCalendarBusSchedulesArgs = {
  input?: InputMaybe<GetDailyCalendarBusScheduleByDateInput>;
};


export type QueryDailyCalendarEventsArgs = {
  input?: InputMaybe<GetDailyCalendarEventsByDateInput>;
};


export type QueryDailyCalendarOfficialsArgs = {
  input?: InputMaybe<GetDailyCalendarOfficialsByDateInput>;
};


export type QueryDailyCalendarPreparationsArgs = {
  input?: InputMaybe<GetDailyCalendarPreparationsByDateInput>;
};


export type QueryDailyCalendarWorkersArgs = {
  input?: InputMaybe<GetDailyCalendarWorkersByDateInput>;
};


export type QueryDaySchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryDonationLevelsArgs = {
  raiseFundraiserId: Scalars['Int']['input'];
};


export type QueryDonationLevelsByFundraiserIdArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryDonationTopEarnerArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryDonationsQueryArgs = {
  fundraiserId: Scalars['Int']['input'];
  participantId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDonorAlumniArgs = {
  donateIntentId: Scalars['String']['input'];
};


export type QueryDonorDonateIntentArgs = {
  browserSecret?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};


export type QueryDonorFundraiserDataArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDonorPersonListEntriesArgs = {
  fundraiserId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryDonorsCheerwallArgs = {
  fundraiserId: Scalars['Int']['input'];
  participantId?: InputMaybe<Scalars['Int']['input']>;
  take: Scalars['Int']['input'];
};


export type QueryDonorsCheerwallsArgs = {
  fundraiserId: Scalars['Int']['input'];
  participantId?: InputMaybe<Scalars['Int']['input']>;
  take: Scalars['Int']['input'];
};


export type QueryDonorsDonationArgs = {
  donateIntentId?: InputMaybe<Scalars['String']['input']>;
  donationId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDonorsDonationStatsArgs = {
  where: DonorsDonationStatsWhereInput;
};


export type QueryDonorsFundraiserPayablesArgs = {
  input: DonorFundraiserPayablesInput;
};


export type QueryDonorsGeneratePdfFlyerArgs = {
  participantId?: InputMaybe<Scalars['ID']['input']>;
  slug: Scalars['String']['input'];
};


export type QueryDonorsGiftShopIntentArgs = {
  purchaseIntentId: Scalars['String']['input'];
};


export type QueryDonorsGiftShopPurchaseArgs = {
  purchaseId: Scalars['String']['input'];
};


export type QueryDonorsOrgsArgs = {
  pagination?: InputMaybe<DonorsOrgsSearchPagination>;
  where: DonorsOrgsSearchInput;
};


export type QueryDonorsParticipantOtkIncentivesArgs = {
  fundraiserId: Scalars['String']['input'];
  participantId: Scalars['String']['input'];
};


export type QueryDonorsParticipantsGiftShopArgs = {
  fundraiserId: Scalars['String']['input'];
  participantId: Scalars['String']['input'];
};


export type QueryDonorsRedirectsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDriveCampaignDetailsArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type QueryDriveCampaignSearchStatsArgs = {
  activityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignName?: InputMaybe<Scalars['String']['input']>;
  campaignStatuses?: InputMaybe<Array<CampaignStatus>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
  hasIncentive?: InputMaybe<Scalars['Boolean']['input']>;
  maxTeamSize?: InputMaybe<Scalars['Int']['input']>;
  maxTotalRaisedCents?: InputMaybe<Scalars['Int']['input']>;
  minTeamSize?: InputMaybe<Scalars['Int']['input']>;
  minTotalRaisedCents?: InputMaybe<Scalars['Int']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  organizationTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  salesrepIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  territories?: InputMaybe<Array<Scalars['String']['input']>>;
  usStates?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryDriveEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDriveEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<DriveEventSearchInput>;
};


export type QueryDriveGetEarlyAccessFundsArgs = {
  activityType: Scalars['String']['input'];
  campaignStatus?: InputMaybe<Scalars['String']['input']>;
  fundraiserId: Scalars['Int']['input'];
  participantCount: Scalars['Int']['input'];
  state: Scalars['String']['input'];
};


export type QueryDriveGetListActivitiesAvailableListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListActivitiesNotAvailableListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListCampaignHistoryListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListCurrentCustomersListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListHubspotProspectsListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListOrgListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListOrganizationSummaryArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['Int']['input'];
};


export type QueryDriveGetListPotentialCustomersListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListWinbackListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetProjectedRaisedArgs = {
  activityType: Scalars['String']['input'];
  participantCount: Scalars['Int']['input'];
  state: Scalars['String']['input'];
};


export type QueryDriveOrgUserTrackingsArgs = {
  orgId: Scalars['String']['input'];
  where?: InputMaybe<DriveOrgUserTrackingSearchInput>;
};


export type QueryDriveSalesrepsByManagerArgs = {
  type?: InputMaybe<SalesrepType>;
};


export type QueryDriveSmsInviteDataArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type QueryDriveUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DriveUserSearchInput>;
};


export type QueryDriveVenuesArgs = {
  where?: InputMaybe<DriveVenueSearchInput>;
};


export type QueryEventArgs = {
  input?: InputMaybe<GetEventByIdInput>;
};


export type QueryEventCalendarArgs = {
  input?: InputMaybe<EventCalendarFiltersInput>;
};


export type QueryEventContractItemsArgs = {
  input?: InputMaybe<EventContractItemInput>;
};


export type QueryEventOfficialArgs = {
  input?: InputMaybe<GetOfficialByIdInput>;
};


export type QueryEventParticipantsArgs = {
  input?: InputMaybe<GetEventParticipantsByIdInput>;
};


export type QueryEventPreparationsArgs = {
  input?: InputMaybe<GetEventPreparationsByIdInput>;
};


export type QueryEventPreparationsByEventArgs = {
  input?: InputMaybe<GetEventPreparationsByEventIdInput>;
};


export type QueryEventResultArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventTransportDetailsByEventOrDatesArgs = {
  input?: InputMaybe<GetEventTransportDetailsByEventOrDatesInput>;
};


export type QueryEventWorkerArgs = {
  input?: InputMaybe<GetWorkerByIdInput>;
};


export type QueryEventWorkersByEventArgs = {
  input?: InputMaybe<GetWorkersByEventIdInput>;
};


export type QueryFacilityArgs = {
  input?: InputMaybe<GetFacilityByPlaceNameInput>;
};


export type QueryFacilityCalendarArgs = {
  input?: InputMaybe<CommonCalendarFiltersInput>;
};


export type QueryFacilityGridArgs = {
  input?: InputMaybe<CommonGridFiltersInput>;
};


export type QueryFacilitySchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryFeeReportSheetsArgs = {
  input?: InputMaybe<CommonSheetFiltersInput>;
};


export type QueryFinancialAcctAcctIdArgs = {
  acctId: Scalars['ID']['input'];
};


export type QueryFinancialAcctOrgArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryFinancialAcctOrgByKybArgs = {
  kybId: Scalars['ID']['input'];
};


export type QueryFinancialAcctRootArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryFinancialAcctUsersArgs = {
  finAcctId: Scalars['ID']['input'];
};


export type QueryFinancialAcctUsersByKycArgs = {
  kycId: Scalars['ID']['input'];
};


export type QueryFundraiserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFundraiserApprovalSubmissionsArgs = {
  approverId?: InputMaybe<Scalars['String']['input']>;
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  formId?: InputMaybe<Scalars['Int']['input']>;
  latest?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<FundraiserApprovalSubmissionStatus>;
  submitterId: Scalars['String']['input'];
};


export type QueryFundraiserApprovalSubmissionsHistoryArgs = {
  formId?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFundraiserApprovalSubmissionsOrgNamesArgs = {
  submissionId?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFundraiserCheerwallArgs = {
  fundraiserId: Scalars['String']['input'];
  take: Scalars['Int']['input'];
};


export type QueryFundraiserCustomRewardsDetailsArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryFundraiserDataByCoachJoinCodeArgs = {
  coachJoinCode: Scalars['String']['input'];
};


export type QueryFundraiserEntityResourcesArgs = {
  resourceId: Scalars['Int']['input'];
};


export type QueryFundraiserGroupsArgs = {
  joinCode: Scalars['String']['input'];
};


export type QueryFundraiserMediaGalleryArgs = {
  fundraiserId: Scalars['ID']['input'];
};


export type QueryFundraiserRaisedAmountArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryFundraiserRewardsDetailsArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryFundraiserTopDonationArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryFundraiserUserRoleArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type QueryFundraisersActivationEligibilityArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryFundraisersFinalizationEligibilityArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryFundraisersShippingAddressConfirmedArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryGalleryItemsArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryGearLogoConfigurationApprovedArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryGetCampaignRosterArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryGetInsightsUserOrgsArgs = {
  userId: Scalars['Int']['input'];
};


export type QueryGetSalesRepHomeArgs = {
  zip: Scalars['String']['input'];
};


export type QueryGetUserSavedSalesRepArgs = {
  udId: Scalars['String']['input'];
};


export type QueryGroupLeaderFundraiserArgs = {
  fundraiserId: Scalars['ID']['input'];
};


export type QueryGroupLeaderFundraiserManagerArgs = {
  fundraiserId: Scalars['ID']['input'];
};


export type QueryGroupLeaderFundraiserManagerAlertArgs = {
  alertType?: InputMaybe<FundraiserManagerAlertType>;
  fundraiserId: Scalars['ID']['input'];
};


export type QueryGroupLeaderFundraiserParticipantsArgs = {
  fundraiserId: Scalars['ID']['input'];
};


export type QueryGroupLeaderFundraiserPdfArgs = {
  fundraiserId: Scalars['ID']['input'];
  type: GroupLeaderFundraiserPdfType;
};


export type QueryGroupLeaderFundraisersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  roles?: InputMaybe<Array<FundraiserGroupLeaderRoles>>;
  status?: InputMaybe<Array<FundraiserStatus>>;
};


export type QueryGroupLeaderRosterArgs = {
  fundraiserId: Scalars['ID']['input'];
  rosterId: Scalars['ID']['input'];
};


export type QueryGroupLeadersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  groupLeaderId?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInfoSheetsArgs = {
  input?: InputMaybe<CommonSheetFiltersInput>;
};


export type QueryInsightsCampaignDonationsArgs = {
  campaignIds: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type QueryInsightsCampaignRaiseEntityInfoArgs = {
  campaignId: Scalars['Int']['input'];
};


export type QueryInsightsCampaignSettlementsArgs = {
  campaignIds: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type QueryInsightsCampaignsDataArgs = {
  campaignIds: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type QueryInsightsDonorParticipantContactsArgs = {
  campaignIds: Array<InputMaybe<Scalars['Int']['input']>>;
};


export type QueryInsightsNotificationPreferencesArgs = {
  userId: Scalars['String']['input'];
};


export type QueryInsightsOrgsCampaignsSummaryArgs = {
  orgCampaignIds: Array<InputMaybe<InsOrgCampaignSummaryInput>>;
};


export type QueryInsightsPreApprovedContactsArgs = {
  orgIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryInsightsPreApprovedContactsEmailTestArgs = {
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  orgId: Scalars['String']['input'];
};


export type QueryInsightsSalesRepInfoArgs = {
  salesRepEmail: Scalars['String']['input'];
};


export type QueryInsightsUserPreferencesArgs = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  orgIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  queryType: UserPreferenceQueryType;
  userId: Scalars['String']['input'];
};


export type QueryInviteArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInvitesArgs = {
  filter?: InputMaybe<InviteFilter>;
  limit?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryInvitesListArgs = {
  currentPage?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryJoinCodeTypeArgs = {
  joinCode: Scalars['String']['input'];
};


export type QueryLegacyTransactionsArgs = {
  input: LegacyTransactionsInput;
};


export type QueryLevelArgs = {
  input?: InputMaybe<GetLevelByIdInput>;
};


export type QueryLevelGridArgs = {
  input?: InputMaybe<CommonGridFiltersInput>;
};


export type QueryManageCoachListOldArgs = {
  filter?: InputMaybe<ManageCoachListFilterOld>;
};


export type QueryManageCoachOldArgs = {
  id: Scalars['Int']['input'];
};


export type QueryManageEventListOldArgs = {
  filter?: InputMaybe<ManageEventListFilterOld>;
};


export type QueryManageEventOldArgs = {
  id: Scalars['Int']['input'];
};


export type QueryManageOrganizationListOldArgs = {
  filter?: InputMaybe<ManageOrganizationOldFilter>;
};


export type QueryManagePlayerListOldArgs = {
  filter?: InputMaybe<ManagePlayerListFilterOld>;
};


export type QueryManageProgramListOldArgs = {
  filter?: InputMaybe<ManageProgramListFilterOld>;
};


export type QueryManageProgramOldArgs = {
  id: Scalars['Int']['input'];
};


export type QueryManageSeasonListOldArgs = {
  filter: ManageSeasonListFilterOld;
};


export type QueryManageSeasonOldArgs = {
  seasonId: Scalars['Int']['input'];
};


export type QueryManageSportDetailOldArgs = {
  id: Scalars['Int']['input'];
};


export type QueryManageSportDetailsListOldArgs = {
  filter?: InputMaybe<ManageSportDetailsListFilterOld>;
};


export type QueryManageUserArgs = {
  input?: InputMaybe<ManageUserInput>;
};


export type QueryManageUsersListArgs = {
  input?: InputMaybe<ManageUsersListOptions>;
};


export type QueryMessagesChannelArgs = {
  assignment?: InputMaybe<AssignmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMessagesChannelInvitesArgs = {
  id: Scalars['String']['input'];
};


export type QueryMessagesChannelMembersArgs = {
  id: Scalars['String']['input'];
};


export type QueryMessagesChannelOrgArgs = {
  id: Scalars['String']['input'];
};


export type QueryMessagesChannelsArgs = {
  filters?: InputMaybe<MessagesChannelsFilterInput>;
};


export type QueryOfficialAssignmentsArgs = {
  input?: InputMaybe<GetOfficialAssignmentsInput>;
};


export type QueryOfficialDutyArgs = {
  input?: InputMaybe<GetOfficialDutyByIdInput>;
};


export type QueryOfficialListsReportArgs = {
  input?: InputMaybe<EventOfficialReportFilter>;
};


export type QueryOfficialPoolByIdArgs = {
  input?: InputMaybe<GetOfficialPoolByIdInput>;
};


export type QueryOfficialSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryOpponentArgs = {
  input?: InputMaybe<GetOpponentBySchoolCodeInput>;
};


export type QueryOpponentEventsApplyFiltersArgs = {
  input?: InputMaybe<GetEventsFilteredByOpponentStartAndEndDate>;
};


export type QueryOpponentSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryOrgArgs = {
  hierarchy?: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type QueryOrgCampaignIdArgs = {
  campaignId: Scalars['ID']['input'];
  hierarchy?: Scalars['Boolean']['input'];
  label?: OrgType;
};


export type QueryOrgCampaignsArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryOrgFundraiserApprovalSubmissionsArgs = {
  orgIds: Array<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<FundraiserApprovalSubmissionStatus>>>;
};


export type QueryOrgHubspotIdArgs = {
  hubspotId: Scalars['String']['input'];
};


export type QueryOrgIdArgs = {
  raiseId: Scalars['Int']['input'];
};


export type QueryOrgPayableArgs = {
  campaignId: Scalars['ID']['input'];
};


export type QueryOrgSearchArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  ein?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<OrgType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  matchStyle?: InputMaybe<MatchStyle>;
  name: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  stateCode?: InputMaybe<StateCode>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrgStaffRosterArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryOrgUserAssociationsArgs = {
  orgId: Scalars['ID']['input'];
  product: Product;
};


export type QueryOrgsArgs = {
  label?: InputMaybe<OrgType>;
  nameIncludes: Scalars['String']['input'];
};


export type QueryOrgsSearchArgs = {
  pagination?: InputMaybe<OrgsSearchPaginationInput>;
  where?: InputMaybe<OrgsSearchWhereInput>;
};


export type QueryOtkFundraiserDataArgs = {
  fundraiserId: Scalars['ID']['input'];
};


export type QueryOtkParticipantDetailsArgs = {
  fundraiserId: Scalars['ID']['input'];
};


export type QueryOtkPastParticipantDetailsArgs = {
  fundraiserId: Scalars['ID']['input'];
};


export type QueryParticipantCampaignConfigurationDataArgs = {
  campaignID: Scalars['ID']['input'];
};


export type QueryParticipantCheerwallArgs = {
  fundraiserId: Scalars['String']['input'];
  participantId: Scalars['String']['input'];
  take: Scalars['Int']['input'];
};


export type QueryParticipantDonationInvitesArgs = {
  raiseId: Scalars['Int']['input'];
};


export type QueryParticipantDonationsRaisedArgs = {
  fundraiserId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantEarnedRewardsDetailsArgs = {
  fundraiserId: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantFundraiserArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryParticipantFundraiserConfigurationArgs = {
  fundraiserId?: InputMaybe<Scalars['String']['input']>;
  raiseFundraiserId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryParticipantFundraiserParticipantArgs = {
  fundraiserId?: InputMaybe<Scalars['String']['input']>;
  raiseFundraiserId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryParticipantFundraiserRewardArgs = {
  raiseId: Scalars['Int']['input'];
};


export type QueryParticipantFundraisersArgs = {
  udid: Scalars['String']['input'];
};


export type QueryParticipantPublicArgs = {
  fundraiserId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantRewardsDetailsArgs = {
  fundraiserId: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantSsoIdArgs = {
  fundraiserId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantShareMessageArgs = {
  fundraiserId: Scalars['String']['input'];
  msgType: Scalars['String']['input'];
};


export type QueryParticipantSmsDonationInvitesArgs = {
  where?: InputMaybe<ParticipantSmsDonationInvitesWhereInput>;
};


export type QueryParticipantTextTemplateArgs = {
  fundraiserId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantTopDonationArgs = {
  fundraiserId: Scalars['String']['input'];
  participantId: Scalars['String']['input'];
};


export type QueryParticipantsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  participantId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryParticipantsListV2Args = {
  fundraiserId: Scalars['Int']['input'];
};


export type QueryParticipantsPublicArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type QueryPaymentsApiCustomerByEmailArgs = {
  input: PaymentsApiCustomerInput;
};


export type QueryPayoutsArgs = {
  campaignId: Scalars['Int']['input'];
};


export type QueryPreparationArgs = {
  input?: InputMaybe<GetPreparationByIdInput>;
};


export type QueryPreparationReportSheetsArgs = {
  input?: InputMaybe<CommonSheetFiltersInput>;
};


export type QueryPrimaryGroupLeaderArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryPublicFundraiserDataArgs = {
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPublicUserDataArgs = {
  userId: Scalars['Int']['input'];
};


export type QueryRaiseAdminFundraiserPastArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRegistrationRosterArgs = {
  input: RegistrationRosterInput;
};


export type QueryRoleArgs = {
  id: Scalars['String']['input'];
};


export type QueryRolesArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRosterArgs = {
  rosterId: Scalars['ID']['input'];
};


export type QueryRostersArgs = {
  orgId: Scalars['ID']['input'];
  product?: InputMaybe<RosterCreator>;
};


export type QueryScheduleEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScheduleEventsArgs = {
  filter?: InputMaybe<EventFilter>;
  orgsId: Scalars['String']['input'];
  programId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySchoolInfoArgs = {
  input?: InputMaybe<GetSchoolInfoByIdInput>;
};


export type QuerySearchDriveCampaignsArgs = {
  activityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignName?: InputMaybe<Scalars['String']['input']>;
  campaignStatuses?: InputMaybe<Array<CampaignStatus>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
  hasIncentive?: InputMaybe<Scalars['Boolean']['input']>;
  isPendingSettlement?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxTeamSize?: InputMaybe<Scalars['Int']['input']>;
  maxTotalRaisedCents?: InputMaybe<Scalars['Int']['input']>;
  minTeamSize?: InputMaybe<Scalars['Int']['input']>;
  minTotalRaisedCents?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  organizationTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  salesrepIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<SortField>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  territories?: InputMaybe<Array<Scalars['String']['input']>>;
  usStates?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QuerySearchDriveOrganizationsByNameArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySeasonArgs = {
  input?: InputMaybe<SeasonByIdInput>;
};


export type QuerySeasonByYearAndTeamArgs = {
  input?: InputMaybe<SeasonByYearTeamInput>;
};


export type QuerySeasonEventWizardArgs = {
  input?: InputMaybe<SeasonEventWizardInput>;
};


export type QuerySettlementMethodChangeEligibilityArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryShipmentsArgs = {
  externalId?: InputMaybe<ShipmentInput>;
};


export type QuerySpendAccountExportArgs = {
  status: Scalars['String']['input'];
};


export type QuerySpendAdminGetArchivedGroupsArgs = {
  orgId: Scalars['String']['input'];
};


export type QuerySpendBudgetArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type QuerySpendBudgetTransactionsArgs = {
  input?: InputMaybe<SpendBudgetTransactionInput>;
};


export type QuerySpendBudgetsArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendBudgetsSummaryArgs = {
  groupId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
};


export type QuerySpendBudgetsSummaryV2Args = {
  groupId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
};


export type QuerySpendCategoriesArgs = {
  filterBy?: InputMaybe<CategoryFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendDataForHomeArgs = {
  udid: Scalars['String']['input'];
};


export type QuerySpendDebitCardInvitesArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendGetApprovalDetailsArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendGetApprovalsByTabArgs = {
  input?: InputMaybe<SpendGetApprovalInput>;
};


export type QuerySpendGetCounterpartyByIdArgs = {
  counterpartyId: Scalars['String']['input'];
};


export type QuerySpendGetGroupCounterpartiesArgs = {
  groupOrOrgId: Scalars['String']['input'];
};


export type QuerySpendGetInstitutionByRoutingNumberArgs = {
  routingNumber: Scalars['String']['input'];
};


export type QuerySpendGetOrganizationCounterpartiesArgs = {
  groupOrOrgId: Scalars['String']['input'];
};


export type QuerySpendGetPayeeByIdArgs = {
  id: Scalars['String']['input'];
};


export type QuerySpendGroupBankAccountsArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendGroupBankLinkTokenCreateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendGroupByIdArgs = {
  id: Scalars['String']['input'];
};


export type QuerySpendGroupDebitCardsArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendGroupPayeesArgs = {
  groupId: Scalars['String']['input'];
};


export type QuerySpendGroupRostersArgs = {
  filterBy?: InputMaybe<GroupRosterFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendGroupRostersBySeasonArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  seasonId: Scalars['String']['input'];
};


export type QuerySpendGroupRostersBySeasonV2Args = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<SpendPaginationInput>;
  seasonId: Scalars['String']['input'];
  sort?: InputMaybe<SpendSortInput>;
};


export type QuerySpendGroupRostersExportArgs = {
  filters: SpendRostersFilter;
};


export type QuerySpendGroupsArgs = {
  includeArchive?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySpendGroupsFilteredArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  where?: InputMaybe<SpendGroupsWhereInput>;
};


export type QuerySpendGroupsOverviewDashboardArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
};


export type QuerySpendGroupsPublicArgs = {
  orgId: Scalars['String']['input'];
};


export type QuerySpendGuardianInvoicesArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendGuardianInvoiceFilter>>>;
};


export type QuerySpendInviteArgs = {
  id: Scalars['String']['input'];
};


export type QuerySpendInvitesArgs = {
  filterBy?: InputMaybe<InviteFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendInvitesFilteredArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  where?: InputMaybe<SpendInviteWhereInput>;
};


export type QuerySpendInvoiceByIdArgs = {
  id: Scalars['String']['input'];
};


export type QuerySpendInvoiceHistoryArgs = {
  input?: InputMaybe<SpendInvoiceHistoryInput>;
};


export type QuerySpendInvoicesArgs = {
  filterBy?: InputMaybe<InvoiceFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  parentSignUp?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySpendInvoicesExportArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySpendInvoicesExportV2Args = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  sort?: InputMaybe<SpendSortInput>;
};


export type QuerySpendInvoicesFilteredArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySpendOrganizationAccountLimitsArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendOrganizationByIdArgs = {
  id: Scalars['String']['input'];
};


export type QuerySpendOrganizationCheckImageArgs = {
  input: SpendCheckImageRequest;
};


export type QuerySpendOrganizationPayeesArgs = {
  orgId: Scalars['String']['input'];
};


export type QuerySpendOrganizationsArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  where?: InputMaybe<SpendOrganizationsWhereInput>;
};


export type QuerySpendPaginatedInvoicesArgs = {
  filters?: InputMaybe<Array<InputMaybe<SpendInvoiceFilter>>>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
};


export type QuerySpendPastDueInvoicesArgs = {
  interval?: InputMaybe<SpendPastDueInterval>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendPastDueSort>;
};


export type QuerySpendPaymentScheduleByIdArgs = {
  id: Scalars['String']['input'];
};


export type QuerySpendPaymentSchedulesArgs = {
  groupIdOrSeasonId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendRoleCurrentArgs = {
  sessionId: Scalars['String']['input'];
};


export type QuerySpendRostersArgs = {
  filterBy?: InputMaybe<RosterFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendRostersFilteredArgs = {
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  where?: InputMaybe<SpendGroupRostersWhereInput>;
};


export type QuerySpendSeasonArgs = {
  id: Scalars['String']['input'];
};


export type QuerySpendTransactionArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendTransactionAttachmentArgs = {
  id: Scalars['String']['input'];
};


export type QuerySpendTransactionAttachmentRawArgs = {
  input: SpendRawFileInput;
};


export type QuerySpendTransactionAttachmentsByPaymentIdArgs = {
  id: Scalars['String']['input'];
};


export type QuerySpendTransactionNotesArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendTransactionNotesByPaymentIdArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendTransactionsArgs = {
  filterBy?: InputMaybe<TransactionFilterEnum>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySpendTransactionsExportArgs = {
  allAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<Array<InputMaybe<SpendTransactionFilter>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySpendTransactionsFilteredArgs = {
  allAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  dateRange?: InputMaybe<DateRangeEnum>;
  disableCache?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<Array<InputMaybe<SpendTransactionFilter>>>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  where?: InputMaybe<SpendTransactionWhereInput>;
};


export type QuerySpendTransactionsLegacyArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<SpendPaginationInput>;
};


export type QuerySpendUserAccountBearerTokenArgs = {
  input?: InputMaybe<SpendAccountBearerToken>;
};


export type QuerySpendUserBankLinkTokenCreateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySpendUserExportEmailsArgs = {
  input: SpendExportEmailsInput;
};


export type QuerySponsorDataForHomeArgs = {
  orgId: Scalars['String']['input'];
};


export type QueryStoreBestSellerArgs = {
  scopeId: Scalars['Int']['input'];
};


export type QueryStoreByScopeIdArgs = {
  scopeId: Scalars['Int']['input'];
};


export type QueryStoreCampaignPayableInfoArgs = {
  input?: InputMaybe<StoreCampaignPayableInfoInput>;
};


export type QueryStoreDataForHomeArgs = {
  fundraiserId?: InputMaybe<Array<Scalars['Int']['input']>>;
  uEmail: Scalars['String']['input'];
};


export type QueryStoreEarnedPointsGraphArgs = {
  input?: InputMaybe<StorePointEarnedGraphInput>;
};


export type QueryStoreGlCampaignsArgs = {
  input?: InputMaybe<StoreUserCampaignsInput>;
};


export type QueryStoreGlParticipantsArgs = {
  input?: InputMaybe<StoreUserParticipantsInput>;
};


export type QueryStoreOrderArgs = {
  netsuiteId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStoreOrderFilterArgs = {
  input?: InputMaybe<OrderFilterInput>;
};


export type QueryStoreOrderItemImagesArgs = {
  itemIds: Array<Scalars['Int']['input']>;
};


export type QueryStoreOrderSalesGraphArgs = {
  input?: InputMaybe<OrderGraphInput>;
};


export type QueryStoreOrderSummaryArgs = {
  scopeId: Scalars['Int']['input'];
};


export type QueryStoreOrdersCsvArgs = {
  scopeId: Scalars['Int']['input'];
};


export type QueryStorePayableFilterArgs = {
  input?: InputMaybe<StoreBaseFilterInput>;
};


export type QueryStorePointActivityFilterArgs = {
  input?: InputMaybe<PointsActivityFilterInput>;
};


export type QueryStorePointActivityFilterAdvanceArgs = {
  input?: InputMaybe<PointsActivityFilterInput>;
};


export type QueryStorePointsActivityCsvArgs = {
  scopeId: Scalars['Int']['input'];
};


export type QueryStorePointsActivityCsvAdvanceArgs = {
  scopeId: Scalars['Int']['input'];
};


export type QueryStorePointsWithdrawalRequestFilterArgs = {
  input?: InputMaybe<StoreBaseFilterInput>;
};


export type QueryStoreProfitPercentageArgs = {
  storeId: Scalars['Int']['input'];
};


export type QueryStoreScopePayableInfoArgs = {
  scopeId: Scalars['Int']['input'];
};


export type QueryStoreTicketsArgs = {
  scopeId: Scalars['Int']['input'];
};


export type QueryStoreUserDetailsArgs = {
  email: Scalars['String']['input'];
};


export type QueryStoreUserPreferenceArgs = {
  userId: Scalars['String']['input'];
};


export type QueryStoresByCampaignIdsArgs = {
  input?: InputMaybe<StoreByCampaignIdsInput>;
};


export type QueryStoresByGlEmailArgs = {
  input?: InputMaybe<StoreByGlEmailInput>;
};


export type QueryStoresSummaryByCampaignIdsArgs = {
  input?: InputMaybe<StoreSummaryByCampaignIdsInput>;
};


export type QueryStoresSummaryByGlEmailArgs = {
  input?: InputMaybe<StoresSummaryByGlEmailInput>;
};


export type QueryTeamArgs = {
  input?: InputMaybe<TeamByCodeInput>;
};


export type QueryTeamOfficialsArgs = {
  input?: InputMaybe<OfficialByIdInput>;
};


export type QueryTeamOrgSchedulesArgs = {
  input?: InputMaybe<CommonSchedulesFiltersInput>;
};


export type QueryTeamPreparationsArgs = {
  input?: InputMaybe<PreparationByIdInput>;
};


export type QueryTeamWorkersArgs = {
  input?: InputMaybe<WorkerByIdInput>;
};


export type QueryTransactionDetailArgs = {
  transactionId: Scalars['UUID']['input'];
};


export type QueryTransactionsArgs = {
  input: TransactionsInput;
};


export type QueryTransactionsByAccountArgs = {
  input: AccountTransactionsInput;
};


export type QueryTransactionsByAccountPaginatedArgs = {
  input: AccountTransactionsPaginatedInput;
};


export type QueryTransactionsBySpendDestinationArgs = {
  input: TransactionsInput;
};


export type QueryTransactionsBySpendSourceArgs = {
  input: TransactionsInput;
};


export type QueryTwispCardBalancesArgs = {
  index?: InputMaybe<TwispCardBalances_Indexes>;
  limit?: InputMaybe<Scalars['Int32']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};


export type QueryTwispCardsArgs = {
  index?: InputMaybe<TwispCards_Indexes>;
  limit?: InputMaybe<Scalars['Int32']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};


export type QueryTwispStripeHooksArgs = {
  index?: InputMaybe<TwispStripeHooks_Indexes>;
  limit?: InputMaybe<Scalars['Int32']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};


export type QueryTwispTransactionsArgs = {
  index?: InputMaybe<TwispTransactions_Indexes>;
  limit?: InputMaybe<Scalars['Int32']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Sort>;
  where?: InputMaybe<Where>;
};


export type QueryUnconfirmedEventsArgs = {
  input?: InputMaybe<UnconfirmedEventsFiltersInput>;
};


export type QueryUpdateSavedSalesRepArgs = {
  snapRepEmail: Scalars['String']['input'];
  udId: Scalars['String']['input'];
};


export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserActivityLeadershipArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserAffiliationsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserApiKeyArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserApiKeyBySecretArgs = {
  secret: Scalars['String']['input'];
};


export type QueryUserAssociationCampaignsArgs = {
  product: Product;
  userId: Scalars['ID']['input'];
};


export type QueryUserAssociationsArgs = {
  id: Scalars['ID']['input'];
  product: Product;
};


export type QueryUserBySecretArgs = {
  secret: Scalars['String']['input'];
};


export type QueryUserChallengeArgs = {
  challengeId: Scalars['String']['input'];
};


export type QueryUserFinancialAccountsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserGroupLeadershipArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserImpliedAffiliationsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserInvitesArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryUserOrgAffiliationArgs = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryUserOrgRelationshipsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserParentsPublicInfoArgs = {
  id: Array<Scalars['ID']['input']>;
};


export type QueryUserParticipationArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserPermissionsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserPublicArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserPublicInfoArgs = {
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryUserTeamLeadershipArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserTokenArgs = {
  expirationTime?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryUserUnconfirmedAffiliationsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  snapRaiseId?: InputMaybe<Scalars['Int']['input']>;
  snapSpendId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVaultAccountPayoutFrequencySetAuditEntriesArgs = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryVaultAccountStatusArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
  accountRepresentativeGatewayId?: InputMaybe<Scalars['String']['input']>;
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  beneficialOwnersGatewayIds?: InputMaybe<Array<Scalars['String']['input']>>;
  provider: VaultFinancialProvider;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type QueryVaultCardArgs = {
  id: Scalars['String']['input'];
};


export type QueryVaultCreateKybKycSubmitAuditEntriesArgs = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryVaultKybArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVaultKycArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVaultOrgConfirmationSubmitAuditEntriesArgs = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryVaultOwnersArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryVaultStripeConnectAccountBankAccountListFetchArgs = {
  stripeConnectAccountId: Scalars['String']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type QueryVaultStripeConnectAccountFetchArgs = {
  stripeConnectAccountId: Scalars['ID']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type QueryVaultStripeConnectAccountPaymentMethodDefaultSetAuditEntriesArgs = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryVaultStripeConnectAccountsBankAccountListFetchArgs = {
  stripeConnectAccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type QueryVaultStripeInvalidRepresentativeArgs = {
  stripeConnectAccountId: Scalars['ID']['input'];
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};


export type QueryVaultVgsValueArgs = {
  value: Scalars['String']['input'];
};


export type QueryVehicleArgs = {
  input?: InputMaybe<GetVehicleByIdInput>;
};


export type QueryWorkerPoolByIdArgs = {
  input?: InputMaybe<GetWorkerPoolByIdInput>;
};

export type RaiseAdminFundraiser = {
  __typename?: 'RaiseAdminFundraiser';
  /** End date of upcoming fundraiser */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** Raise Resource Entity ID of the fundraiser */
  entityId?: Maybe<Scalars['Int']['output']>;
  /** Int of participants from previous year */
  groupSize?: Maybe<Scalars['Int']['output']>;
  /** Fundraiser ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Fundraiser Image URL */
  image?: Maybe<Scalars['String']['output']>;
  /** Fundraiser name */
  name?: Maybe<Scalars['String']['output']>;
  /** A query for raise admin users to get upcoming resign participants */
  participantListForFundraiser?: Maybe<RaiseAdminParticipantList>;
  /** Has the fundraiser been rolled over? */
  rollover?: Maybe<Scalars['Boolean']['output']>;
  /** Start date of upcoming fundraiser */
  startDate?: Maybe<Scalars['DateTime']['output']>;
};


export type RaiseAdminFundraiserParticipantListForFundraiserArgs = {
  filter?: InputMaybe<RaiseAdminParticipantFilter>;
};

export type RaiseAdminFundraiserPast = {
  __typename?: 'RaiseAdminFundraiserPast';
  /** Current year's fundraiser */
  current?: Maybe<RaiseAdminFundraiser>;
  /** Last year's fundraiser */
  past?: Maybe<RaiseAdminFundraiser>;
};

export type RaiseAdminParticipant = {
  __typename?: 'RaiseAdminParticipant';
  /** Participant email */
  email?: Maybe<Scalars['String']['output']>;
  /** Participant first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Participant ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Participant last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Participant's Profile Image */
  profileImage?: Maybe<Scalars['String']['output']>;
};

export type RaiseAdminParticipantFilter = {
  /** Conditions to order by */
  orderBy?: InputMaybe<RaiseAdminParticipantOrderInput>;
  /** Conditions to filter by */
  where?: InputMaybe<RaiseAdminParticipantWhereInput>;
};

export type RaiseAdminParticipantList = {
  __typename?: 'RaiseAdminParticipantList';
  /** Total number of fundraisers */
  count: Scalars['Int']['output'];
  /** List of fundraisers */
  list?: Maybe<Array<Maybe<RaiseAdminParticipant>>>;
};

export type RaiseAdminParticipantOrderInput = {
  email?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
};

export type RaiseAdminParticipantWhereInput = {
  /** Nullable String Filter for Participant email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Nullable String Filter for Participant name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Nullable Boolean Filter for Participant Rollover */
  rollover?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RaiseFundraiserUsersCreateManyInput = {
  fundraiser_id: Scalars['Int']['input'];
  user_ids: Array<Scalars['Int']['input']>;
};

export type RaiseUser = {
  __typename?: 'RaiseUser';
  email?: Maybe<Scalars['String']['output']>;
  /** SSO User ID */
  id?: Maybe<Scalars['String']['output']>;
};

export enum RawFileTypeEnum {
  Attachment = 'Attachment',
  LegacyAttachment = 'LegacyAttachment',
  Statement = 'Statement'
}

export type ReceiptResult = {
  __typename?: 'ReceiptResult';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  transactionId: Scalars['ID']['output'];
};

export type ReconcileBudget = {
  amount: Scalars['Int']['input'];
  budgetItemId: Scalars['String']['input'];
  invoiceId?: InputMaybe<Scalars['String']['input']>;
};

export type ReconcileInvoice = {
  amount: Scalars['Int']['input'];
  budgetItemId?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['String']['input'];
};

export type ReconcileInvoiceWithBudget = {
  amount: Scalars['Int']['input'];
  budgetItemId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type Redirect = {
  __typename?: 'Redirect';
  count: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  fundraiser?: Maybe<DonorFundraiserData>;
  fundraiserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  path: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Registration = {
  __typename?: 'Registration';
  activity?: Maybe<RegistrationActivity>;
  comment?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  heightFormatted?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  paid?: Maybe<Scalars['Boolean']['output']>;
  participant?: Maybe<RegistrationPerson>;
  participationStatus?: Maybe<Scalars['String']['output']>;
  registeredBy?: Maybe<RegistrationPerson>;
  season?: Maybe<Scalars['Boolean']['output']>;
  shortSize?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
  tryout?: Maybe<Scalars['Boolean']['output']>;
  tshirtSize?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

export type RegistrationActivity = {
  __typename?: 'RegistrationActivity';
  athleticSeason?: Maybe<Scalars['String']['output']>;
  currentSeason?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  kind?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<RegistrationOrganization>;
  registerable?: Maybe<Scalars['Boolean']['output']>;
};

export type RegistrationGroup = {
  __typename?: 'RegistrationGroup';
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  level?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rosters: Array<RegistrationRoster>;
  rosterwebserviceAccess?: Maybe<Scalars['Boolean']['output']>;
  sportName?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['Int']['output']>;
};

export type RegistrationGroupRegistration = {
  __typename?: 'RegistrationGroupRegistration';
  finalizeDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  group?: Maybe<RegistrationGroup>;
  id: Scalars['ID']['output'];
  jerseyNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  registration?: Maybe<Registration>;
  roster?: Maybe<RegistrationRoster>;
  state?: Maybe<Scalars['String']['output']>;
};

export type RegistrationOrganization = {
  __typename?: 'RegistrationOrganization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgsId: Scalars['String']['output'];
  schoolSlug: Scalars['String']['output'];
};

export type RegistrationPerson = {
  __typename?: 'RegistrationPerson';
  accountType?: Maybe<Scalars['String']['output']>;
  children: Array<RegistrationPerson>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  parents: Array<RegistrationPerson>;
  udid?: Maybe<Scalars['ID']['output']>;
};

export type RegistrationRoster = {
  __typename?: 'RegistrationRoster';
  final?: Maybe<Scalars['Boolean']['output']>;
  group?: Maybe<RegistrationGroup>;
  groupRegistrations: Array<RegistrationGroupRegistration>;
  id: Scalars['ID']['output'];
  season?: Maybe<Scalars['String']['output']>;
  teamRegistrations: Array<RegistrationGroupRegistration>;
};

/** Attributes for finding a roster */
export type RegistrationRosterInput = {
  gender: Scalars['String']['input'];
  level: Scalars['String']['input'];
  orgsId: Scalars['ID']['input'];
  season?: InputMaybe<Scalars['String']['input']>;
  sportName: Scalars['String']['input'];
};

export type RelationshipTitles = {
  __typename?: 'RelationshipTitles';
  createdAt: Scalars['DateTime']['output'];
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum RequestStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Bounce = 'BOUNCE',
  Canceled = 'CANCELED',
  Click = 'CLICK',
  Deferred = 'DEFERRED',
  Delivered = 'DELIVERED',
  DeliveredWithFailed = 'DELIVERED_WITH_FAILED',
  Dropped = 'DROPPED',
  Failed = 'FAILED',
  Open = 'OPEN',
  Pending = 'PENDING',
  Resubscribe = 'RESUBSCRIBE',
  Sent = 'SENT',
  SpamReport = 'SPAM_REPORT',
  Unsubscribe = 'UNSUBSCRIBE'
}

export type ResendReceiptsResult = {
  __typename?: 'ResendReceiptsResult';
  results: Array<ReceiptResult>;
};

export enum ResponseStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type RevisionFundraiserApprovalSubmissionData = {
  __typename?: 'RevisionFundraiserApprovalSubmissionData';
  isSsoApprover: Scalars['Boolean']['output'];
  submitterName: Scalars['String']['output'];
};

export type RevisionFundraiserApprovalSubmissionResult = InvalidToken | RevisionFundraiserApprovalSubmissionData | UnprocessableSubmission;

export enum RewardStatus {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Sent = 'SENT'
}

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Maybe<RolePermission>>>;
  scope?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RoleArguments = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type RolePermission = {
  __typename?: 'RolePermission';
  id?: Maybe<Scalars['String']['output']>;
  permission?: Maybe<Permission>;
  permissionId?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
};

export type Roster = {
  __typename?: 'Roster';
  id?: Maybe<Scalars['ID']['output']>;
  members?: Maybe<Array<Maybe<RosterMember>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RosterCloneInput = {
  activity?: InputMaybe<ProgramActivity>;
  description?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<TeamGender>;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  product: RosterCreator;
  rosterId: Scalars['ID']['input'];
  startYear: Scalars['Int']['input'];
  tier?: InputMaybe<TeamTier>;
};

export type RosterCreateInput = {
  activity?: InputMaybe<ProgramActivity>;
  description?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<TeamGender>;
  members?: Array<RosterUserInput>;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  product: RosterCreator;
  startYear: Scalars['Int']['input'];
  tier?: InputMaybe<TeamTier>;
};

export enum RosterCreator {
  Admin = 'ADMIN',
  Messaging = 'MESSAGING',
  Raise = 'RAISE',
  Registration = 'REGISTRATION',
  Spend = 'SPEND'
}

export enum RosterFilterEnum {
  GroupName = 'groupName',
  MemberName = 'memberName',
  RosterName = 'rosterName',
  SeasonName = 'seasonName'
}

export type RosterMember = {
  __typename?: 'RosterMember';
  association?: Maybe<CampaignMemberAssociation>;
  id?: Maybe<Scalars['ID']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  wasDeleted?: Maybe<Scalars['Boolean']['output']>;
};

export type RosterUpdateInput = {
  activity?: InputMaybe<ProgramActivity>;
  description?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<TeamGender>;
  members?: Array<RosterUserInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  rosterId: Scalars['ID']['input'];
  startYear?: InputMaybe<Scalars['Int']['input']>;
};

export type RosterUser = {
  __typename?: 'RosterUser';
  id: Scalars['ID']['output'];
  internal: Scalars['Boolean']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isDependent: Scalars['Boolean']['output'];
  isGuardian: Scalars['Boolean']['output'];
  type: Array<Scalars['String']['output']>;
};

export type RosterUserInput = {
  id: Scalars['ID']['input'];
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isDependent?: InputMaybe<Scalars['Boolean']['input']>;
  isGuardian?: InputMaybe<Scalars['Boolean']['input']>;
};

export type S3PresignedInsightsApprovalsUrlResult = {
  __typename?: 'S3PresignedInsightsApprovalsUrlResult';
  expires: Scalars['DateTime']['output'];
  filename: Scalars['String']['output'];
  s3Filename: Scalars['String']['output'];
  status: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SdPagination = {
  __typename?: 'SDPagination';
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['BigInt']['output']>;
};

/** Owned by Vault Only used in legacy createCard */
export enum Shipping_Service {
  Express = 'express',
  Priority = 'priority',
  Standard = 'standard'
}

/** Owned by Vault Only used in legacy createCard */
export enum Spending_Limit_Interval {
  AllTime = 'all_time',
  Daily = 'daily',
  Monthly = 'monthly',
  PerAuthorization = 'per_authorization',
  Weekly = 'weekly',
  Yearly = 'yearly'
}

export enum Support_Ticket_Department {
  Accounts = 'ACCOUNTS',
  Hubspot = 'HUBSPOT',
  It = 'IT',
  Pep = 'PEP',
  Sales = 'SALES',
  Sponsor = 'SPONSOR',
  Store = 'STORE',
  Wallet = 'WALLET',
  Warehouse = 'WAREHOUSE'
}

export enum SalesrepType {
  AccountManager = 'ACCOUNT_MANAGER',
  SalesRep = 'SALES_REP'
}

export type ScheduleEvent = {
  __typename?: 'ScheduleEvent';
  data?: Maybe<Scalars['JSON']['output']>;
  endDatetime?: Maybe<Scalars['DateTime']['output']>;
  eventResults?: Maybe<Array<Maybe<EventResult>>>;
  eventType: EventType;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  location?: Maybe<Scalars['String']['output']>;
  orgsId: Scalars['String']['output'];
  programId?: Maybe<Scalars['String']['output']>;
  startDatetime: Scalars['DateTime']['output'];
};

export type ScheduleEventCreateResult = {
  __typename?: 'ScheduleEventCreateResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  event?: Maybe<ScheduleEvent>;
};

export type ScheduleEventInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  endDatetime?: InputMaybe<Scalars['DateTime']['input']>;
  eventType: EventType;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  orgsId: Scalars['String']['input'];
  programId?: InputMaybe<Scalars['String']['input']>;
  startDatetime: Scalars['DateTime']['input'];
};

export type ScheduleEventUpdateResult = {
  __typename?: 'ScheduleEventUpdateResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  event?: Maybe<ScheduleEvent>;
};

/** Information of a emails scheduled for cron jobs */
export type ScheduledReleaseInfo = {
  __typename?: 'ScheduledReleaseInfo';
  /** The date and time for release */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars['String']['output']>;
};

export type School = {
  __typename?: 'School';
  ad?: Maybe<Scalars['String']['output']>;
  adEmail?: Maybe<Scalars['String']['output']>;
  adExt?: Maybe<Scalars['String']['output']>;
  adHomeAddress?: Maybe<Scalars['String']['output']>;
  adHomeCity?: Maybe<Scalars['String']['output']>;
  adHomePhone?: Maybe<Scalars['String']['output']>;
  adHomeState?: Maybe<Scalars['String']['output']>;
  adHomeZip?: Maybe<Scalars['String']['output']>;
  adPhone?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  assistantAd?: Maybe<Scalars['String']['output']>;
  assistantEmail?: Maybe<Scalars['String']['output']>;
  assistantPhone?: Maybe<Scalars['String']['output']>;
  boc?: Maybe<Scalars['Boolean']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  conferenceId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  directory?: Maybe<Scalars['Boolean']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  map?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  principal?: Maybe<Scalars['String']['output']>;
  schoolId?: Maybe<Scalars['Boolean']['output']>;
  secretary?: Maybe<Scalars['String']['output']>;
  secretaryExt?: Maybe<Scalars['String']['output']>;
  secretaryPhone?: Maybe<Scalars['String']['output']>;
  showName?: Maybe<Scalars['String']['output']>;
  showOnWebsite?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
  superintendent?: Maybe<Scalars['String']['output']>;
  trackScores?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export enum SchoolCategory {
  Charter = 'CHARTER',
  Magnet = 'MAGNET',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type SchoolInfo = {
  __typename?: 'SchoolInfo';
  Schoolname?: Maybe<Scalars['String']['output']>;
  ad?: Maybe<Scalars['String']['output']>;
  ad_contract_signee?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  ccemail?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  conf_text_type?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_reminder?: Maybe<Scalars['String']['output']>;
  email_reminder_officials?: Maybe<Scalars['String']['output']>;
  email_reminder_workers?: Maybe<Scalars['String']['output']>;
  enable_cc_email_as_origin?: Maybe<Scalars['String']['output']>;
  enable_ml_updates?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mascot?: Maybe<Scalars['String']['output']>;
  message_board_read_at?: Maybe<Scalars['Date']['output']>;
  ml_key?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  principal?: Maybe<Scalars['String']['output']>;
  school_timezone?: Maybe<Scalars['String']['output']>;
  secondary_ad_email?: Maybe<Scalars['String']['output']>;
  secondary_ad_name?: Maybe<Scalars['String']['output']>;
  signed_contract_notification?: Maybe<Scalars['String']['output']>;
  ssn_on_file?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  state_org?: Maybe<Scalars['String']['output']>;
  state_org_abbreviation?: Maybe<Scalars['String']['output']>;
  use_security?: Maybe<Scalars['String']['output']>;
  web_password?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ScopeProductColor = {
  __typename?: 'ScopeProductColor';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
  visual?: Maybe<Scalars['String']['output']>;
};

export type ScoreForOrganizationFilterOld = {
  orderBy?: InputMaybe<ScoreForOrganizationOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScoreForOrganizationWhereOld>;
};

/** Specifies the order in which the scores list should be sorted. */
export type ScoreForOrganizationOrderByOld = {
  /** Orders scores based on their confirmation status. */
  event_date?: InputMaybe<SortOrderEnum>;
};

export type ScoreForOrganizationWhereOld = {
  /** Specifies the list of gs in which the scores list should be filter. */
  gs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specifies the years which the scores list should be filter. */
  years?: InputMaybe<Scalars['String']['input']>;
};

export type Season = {
  __typename?: 'Season';
  budget?: Maybe<Scalars['Float']['output']>;
  created_at?: Maybe<Scalars['Date']['output']>;
  default_time_for_event?: Maybe<Scalars['String']['output']>;
  default_time_for_practice?: Maybe<Scalars['String']['output']>;
  home_field?: Maybe<Scalars['String']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  preview?: Maybe<Scalars['String']['output']>;
  season?: Maybe<Scalars['String']['output']>;
  season_id: Scalars['Int']['output'];
  sport?: Maybe<Team>;
  sport_code?: Maybe<Scalars['String']['output']>;
  web_password?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type SeasonByIdInput = {
  season_id: Scalars['Int']['input'];
};

export type SeasonByYearTeamInput = {
  sport_code?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type SeasonCreateInput = {
  budget?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['Date']['input']>;
  default_time_for_event?: InputMaybe<Scalars['String']['input']>;
  default_time_for_practice?: InputMaybe<Scalars['String']['input']>;
  home_field?: InputMaybe<Scalars['String']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  preview?: InputMaybe<Scalars['String']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  season_id: Scalars['Int']['input'];
  sport_code?: InputMaybe<Scalars['String']['input']>;
  web_password?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type SeasonDeleteInput = {
  season_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type SeasonEventWizardInput = {
  season_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type SeasonModifyInput = {
  budget?: InputMaybe<Scalars['Float']['input']>;
  created_at?: InputMaybe<Scalars['Date']['input']>;
  default_time_for_event?: InputMaybe<Scalars['String']['input']>;
  default_time_for_practice?: InputMaybe<Scalars['String']['input']>;
  home_field?: InputMaybe<Scalars['String']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  preview?: InputMaybe<Scalars['String']['input']>;
  season?: InputMaybe<Scalars['String']['input']>;
  season_id: Scalars['Int']['input'];
  sport_code?: InputMaybe<Scalars['String']['input']>;
  web_password?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type SeasonPostponeUnpostponeInput = {
  event_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  season_id?: InputMaybe<Scalars['Int']['input']>;
};

export type SeasonScheduleTeamsCreateInput = {
  sport_codes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type SendEmailResponse = {
  __typename?: 'SendEmailResponse';
  success: Scalars['Boolean']['output'];
};

export type SendNotificationInput = {
  android?: InputMaybe<AndroidNotificationInput>;
  appIcon: Scalars['String']['input'];
  ios?: InputMaybe<IosNotificationInput>;
  message: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  web?: InputMaybe<WebNotificationInput>;
};

export enum SetUserPreferenceFlag {
  Off = 'OFF',
  On = 'ON'
}

/** Settlement details for closed campaign. */
export type SettlementDetails = {
  __typename?: 'SettlementDetails';
  /** The amount in cents that campaigns was advanced in Wallet. */
  advanceAmount?: Maybe<Scalars['Int']['output']>;
  /** Address to send out check to */
  checkingAddress?: Maybe<Scalars['String']['output']>;
  /** The type of snap fee being collected. e.g. 'standard', '1+1', '20%/15%', etc. */
  feeType?: Maybe<Scalars['String']['output']>;
  /** Total cost of incentives in cents */
  gearCost?: Maybe<Scalars['Int']['output']>;
  /** Code to build logo */
  incLogo?: Maybe<Scalars['String']['output']>;
  /** Primary color to build logo */
  incLogoPrimaryColor?: Maybe<Scalars['String']['output']>;
  /** Script to build logo */
  incLogoScript?: Maybe<Scalars['String']['output']>;
  /** Secondary color to build logo */
  incLogoSecondaryColor?: Maybe<Scalars['String']['output']>;
  /** Filename of Logo of Group of Campaign */
  logoImage?: Maybe<Scalars['String']['output']>;
  /** The amount in cents that campaigns was manual-advanced in Raise. */
  manualAdvanceAmount?: Maybe<Scalars['Int']['output']>;
  /** Net raised amount in cents. Equals totalRaised minus snapFee and adds the otkBonus. It is the amount client received in their check/deposit. */
  netRaisedTotal?: Maybe<Scalars['Int']['output']>;
  /** Notes of campaign */
  notes?: Maybe<Scalars['String']['output']>;
  /** Legal Name of Organization */
  organizationLegalName?: Maybe<Scalars['String']['output']>;
  /** TIN of Organization */
  organizationTin?: Maybe<Scalars['String']['output']>;
  /** Total bonus amount that team will receive through otk purchase cost (usually 10%) in cents. */
  otkBonus?: Maybe<Scalars['Int']['output']>;
  /** Link to OTK Logo */
  otkLogoUrl?: Maybe<Scalars['String']['output']>;
  /** Percentage of participation of campaign */
  participationPercent?: Maybe<Scalars['Int']['output']>;
  /** Notes for settlement for this campaign */
  settlementNotes?: Maybe<Scalars['String']['output']>;
  /** Default Shipping address to send out gears/merchandise/check */
  shippingAddress?: Maybe<Scalars['String']['output']>;
  /** Total Fee in cents that Raise collects from campaigns */
  snapFee?: Maybe<Scalars['Int']['output']>;
};

export enum SettlementMethod {
  DirectDeposit = 'DIRECT_DEPOSIT',
  MailedCheck = 'MAILED_CHECK'
}

export type SettlementMethodChangeEligibilityResponse = {
  __typename?: 'SettlementMethodChangeEligibilityResponse';
  id: Scalars['String']['output'];
  settlementMethodChangeEligibility: Scalars['Boolean']['output'];
  settlementMethodChangeEligibilityFailedReasons?: Maybe<Array<Scalars['String']['output']>>;
};

/** Fixed statuses for a campaigns settlement status */
export enum SettlementStatus {
  InReview = 'IN_REVIEW',
  Unsubmitted = 'UNSUBMITTED',
  Verified = 'VERIFIED'
}

/** Attributes for settlement status */
export type SettlementStatusUpdate = {
  __typename?: 'SettlementStatusUpdate';
  fundraiserId?: Maybe<Scalars['Int']['output']>;
  settlementStatus?: Maybe<SettlementStatus>;
};

export type Shipment = {
  __typename?: 'Shipment';
  carrier?: Maybe<ShipmentCarrier>;
  customer: Scalars['String']['output'];
  destination?: Maybe<Scalars['String']['output']>;
  externalId: ShipmentExternalId;
  netsuiteId: Scalars['String']['output'];
  netsuiteSoTranId?: Maybe<Scalars['String']['output']>;
  netsuiteTranId?: Maybe<Scalars['String']['output']>;
  shippedDate?: Maybe<Scalars['Timestamp']['output']>;
  source?: Maybe<ShipmentSource>;
  status?: Maybe<ShipmentStatus>;
  trackingNumbers: Array<Maybe<Scalars['String']['output']>>;
  updated: Scalars['Timestamp']['output'];
  workflowStage: Scalars['String']['output'];
};

export type ShipmentCampaignId = {
  __typename?: 'ShipmentCampaignId';
  campaignId: Scalars['Int']['output'];
};

export enum ShipmentCarrier {
  Fedex = 'FEDEX',
  Unknown = 'UNKNOWN',
  Usps = 'USPS'
}

export type ShipmentExternalId = ShipmentCampaignId | ShipmentOtherId;

export type ShipmentInput = {
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
};

export type ShipmentOtherId = {
  __typename?: 'ShipmentOtherId';
  externalId: Scalars['UUID']['output'];
};

export type ShipmentResponse = {
  __typename?: 'ShipmentResponse';
  shipments: Array<Maybe<Shipment>>;
  status: ResponseStatus;
};

export enum ShipmentSource {
  Otf = 'OTF',
  OtkIncentive = 'OTK_INCENTIVE',
  Store = 'STORE'
}

export enum ShipmentStatus {
  Processing = 'PROCESSING',
  Shipped = 'SHIPPED'
}

export enum ShippingProvider {
  Dhl = 'DHL',
  FedEx = 'FedEx',
  Osm = 'OSM',
  Ups = 'UPS',
  Usps = 'USPS'
}

export type SignUpResponseWithAvailableTransport = {
  __typename?: 'SignUpResponseWithAvailableTransport';
  availableTransport: Array<TransportEnum>;
  result: Array<TransportType>;
};

export type SignupAgreement = {
  __typename?: 'SignupAgreement';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SmsInviteData = {
  __typename?: 'SmsInviteData';
  count?: Maybe<Scalars['Int']['output']>;
  isReleased?: Maybe<Scalars['Boolean']['output']>;
  smsInviteReleasedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum SnapFeePayer {
  Customer = 'CUSTOMER',
  Snap = 'SNAP'
}

export type SnapMobileOneAccess = {
  __typename?: 'SnapMobileOneAccess';
  activatedProducts: Array<SnapMobileOneProduct>;
  level: Scalars['Int']['output'];
  orgId: Scalars['ID']['output'];
  requestedLevel: SnapMobileOneAccessLevelRequest;
  requestedProducts: Array<SnapMobileOneProduct>;
  userOnboardedProducts: Array<SnapMobileOneProduct>;
};

export type SnapMobileOneAccessInput = {
  accessLevel: Scalars['Int']['input'];
  orgId: Scalars['ID']['input'];
};

export type SnapMobileOneAccessLevelRequest = {
  __typename?: 'SnapMobileOneAccessLevelRequest';
  level?: Maybe<Scalars['Int']['output']>;
  requestedAt?: Maybe<Scalars['DateTime']['output']>;
  requestedBy?: Maybe<Scalars['String']['output']>;
};

export type SnapMobileOneContactSupportInput = {
  description: Scalars['NonEmptyString']['input'];
  details: Scalars['NonEmptyString']['input'];
  inquiryType: ContactSupportInquiryType;
  orgId: Scalars['ID']['input'];
};

export enum SnapMobileOneProduct {
  AdAssist = 'AD_ASSIST',
  AppPortal = 'APP_PORTAL',
  ControlPanel = 'CONTROL_PANEL',
  Insights = 'INSIGHTS',
  LeagueKeeper = 'LEAGUE_KEEPER',
  Raise = 'RAISE',
  Registration = 'REGISTRATION',
  Spend = 'SPEND',
  Store = 'STORE'
}

export type SnapMobileOneProductInput = {
  orgId: Scalars['ID']['input'];
  products: Array<SnapMobileOneProduct>;
};

export enum SnapService {
  Campaigns = 'campaigns',
  Comms = 'comms',
  Connect = 'connect',
  Donors = 'donors',
  Drive = 'drive',
  Fanx = 'fanx',
  Fundraisers = 'fundraisers',
  Home = 'home',
  Insights = 'insights',
  Manage = 'manage',
  Messages = 'messages',
  Orgs = 'orgs',
  Raise = 'raise',
  Spend = 'spend',
  Sponsor = 'sponsor',
  Store = 'store',
  Userdirectory = 'userdirectory',
  Vault = 'vault',
  Wallet = 'wallet'
}

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortField {
  AdvancedFunds = 'ADVANCED_FUNDS',
  ClosedStatus = 'CLOSED_STATUS',
  DaysLeft = 'DAYS_LEFT',
  EndDate = 'END_DATE',
  KycStatus = 'KYC_STATUS',
  Name = 'NAME',
  Participation = 'PARTICIPATION',
  Preload = 'PRELOAD',
  SettlementDetails = 'SETTLEMENT_DETAILS',
  StartDate = 'START_DATE',
  Status = 'STATUS',
  TotalRaised = 'TOTAL_RAISED'
}

export enum SortOrderEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type SpendAchCreditResponse = {
  __typename?: 'SpendACHCreditResponse';
  approvalId?: Maybe<Scalars['String']['output']>;
  paymentId: Scalars['String']['output'];
};

export type SpendAccount = {
  __typename?: 'SpendAccount';
  /** @deprecated removed later */
  account?: Maybe<Scalars['String']['output']>;
  available?: Maybe<Scalars['Int']['output']>;
  balance?: Maybe<Scalars['Int']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  groupStatus?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated removed later */
  routing?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SpendAccountBearerToken = {
  verificationCode?: InputMaybe<Scalars['String']['input']>;
  verificationToken?: InputMaybe<Scalars['String']['input']>;
};

export type SpendAccountBearerTokenResponse = {
  __typename?: 'SpendAccountBearerTokenResponse';
  expiresIn?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type SpendAccountId = {
  __typename?: 'SpendAccountID';
  approvalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type SpendAccountLimitsResponse = {
  __typename?: 'SpendAccountLimitsResponse';
  ach?: Maybe<SpendAchLimitsData>;
  bookTransfer?: Maybe<SpendBookTransferLimitsData>;
  card?: Maybe<SpendCardLimitsData>;
  checkDeposit?: Maybe<SpendCheckDepositLimitsData>;
  checkPayment?: Maybe<SpendCheckPaymentLimitData>;
  wire?: Maybe<SpendWireLimitsData>;
};

export type SpendAccountResponse = {
  __typename?: 'SpendAccountResponse';
  accounts?: Maybe<Array<Maybe<SpendAccount>>>;
  count?: Maybe<Scalars['Int']['output']>;
  groupsBalance?: Maybe<SpendGroupAccountBalances>;
  totalBalance?: Maybe<Scalars['Int']['output']>;
};

export type SpendAccountTransferInput = {
  amount: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  fromGroupIdOrOrgId: Scalars['String']['input'];
  toGroupIdOrOrgId: Scalars['String']['input'];
};

export type SpendAchLimits = {
  __typename?: 'SpendAchLimits';
  dailyCredit?: Maybe<Scalars['Int']['output']>;
  dailyDebit?: Maybe<Scalars['Int']['output']>;
  monthlyCredit?: Maybe<Scalars['Int']['output']>;
  monthlyDebit?: Maybe<Scalars['Int']['output']>;
};

export type SpendAchLimitsData = {
  __typename?: 'SpendAchLimitsData';
  limits: SpendAchLimits;
};

export type SpendAchPaymentCancelInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type SpendAchPaymentId = {
  __typename?: 'SpendAchPaymentID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendAchPaymentInput = {
  authorizedAt: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
  invoiceIds: Array<InputMaybe<Scalars['String']['input']>>;
  paymentAmount: Scalars['Int']['input'];
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};

export type SpendAchPaymentResponse = {
  __typename?: 'SpendAchPaymentResponse';
  amount: Scalars['Int']['output'];
  descriptor: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  transactionIdList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SpendAddress = {
  __typename?: 'SpendAddress';
  city?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type SpendAddressInput = {
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type SpendAdminBulkAddUnitInput = {
  customerId: Scalars['String']['input'];
  users: Array<AdminUser>;
};

export type SpendAgreementId = {
  __typename?: 'SpendAgreementID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendAllInviteIds = {
  __typename?: 'SpendAllInviteIds';
  count: Scalars['Int']['output'];
  inviteIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type SpendAppliedInvoices = {
  amount: Scalars['Int']['input'];
  invoiceId: Scalars['String']['input'];
};

export type SpendApprovalDetailResponse = {
  __typename?: 'SpendApprovalDetailResponse';
  approval?: Maybe<ApprovalDetailType>;
  id: Scalars['String']['output'];
};

export type SpendApprovalResponse = {
  __typename?: 'SpendApprovalResponse';
  approvalCount: Scalars['Int']['output'];
  approvals?: Maybe<Array<ApprovalType>>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  staff?: Maybe<Array<Scalars['String']['output']>>;
};

export type SpendApprovalUpdateInput = {
  id: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
};

export type SpendApprovalUpdateResponse = {
  __typename?: 'SpendApprovalUpdateResponse';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendArchiveSeasonMembersInput = {
  groupRosterIds: Array<InputMaybe<Scalars['String']['input']>>;
  isArchived: Scalars['Boolean']['input'];
};

export type SpendArchiveSeasonMembersResponse = {
  __typename?: 'SpendArchiveSeasonMembersResponse';
  success: Scalars['Boolean']['output'];
};

export type SpendAuthorizeAutoPayResponse = {
  __typename?: 'SpendAuthorizeAutoPayResponse';
  invoiceIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type SpendAutoPayInput = {
  cardId?: InputMaybe<Scalars['String']['input']>;
  counterpartyId?: InputMaybe<Scalars['String']['input']>;
  groupRosterId: Scalars['String']['input'];
  invoiceIds: Array<Scalars['String']['input']>;
  paymentMethodSource: Scalars['String']['input'];
};

export type SpendBankAccessCreateResponse = {
  __typename?: 'SpendBankAccessCreateResponse';
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendBankAccessDeleteResponse = {
  __typename?: 'SpendBankAccessDeleteResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SpendBankAccount = {
  __typename?: 'SpendBankAccount';
  /** @deprecated Moved to details */
  accountId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Moved to details */
  availableBalance?: Maybe<Scalars['Float']['output']>;
  counterpartyId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Moved to details */
  currentBalance?: Maybe<Scalars['Float']['output']>;
  details?: Maybe<SpendBankAccountDetails>;
  entityId: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  /** @deprecated Moved to details */
  institution?: Maybe<SpendInstitution>;
  /** @deprecated Moved to details */
  mask?: Maybe<Scalars['String']['output']>;
  /** @deprecated Moved to details */
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated Moved to details */
  officialName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  /** @deprecated Moved to details */
  subtype?: Maybe<Scalars['String']['output']>;
  /** @deprecated Moved to details */
  type?: Maybe<Scalars['String']['output']>;
};

export type SpendBankAccountDetails = {
  __typename?: 'SpendBankAccountDetails';
  accountId?: Maybe<Scalars['String']['output']>;
  availableBalance?: Maybe<Scalars['Float']['output']>;
  currentBalance?: Maybe<Scalars['Float']['output']>;
  institution?: Maybe<SpendInstitution>;
  mask?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  officialName?: Maybe<Scalars['String']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SpendBankAccountsListResponse = {
  __typename?: 'SpendBankAccountsListResponse';
  externalAccounts?: Maybe<Array<SpendBankAccount>>;
};

export type SpendBankAccountsResponse = {
  __typename?: 'SpendBankAccountsResponse';
  externalAccounts?: Maybe<Array<Maybe<SpendBankAccount>>>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SpendBankLinkCreateResponse = {
  __typename?: 'SpendBankLinkCreateResponse';
  expiration?: Maybe<Scalars['String']['output']>;
  linkToken?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['String']['output']>;
};

export type SpendBankTransaction = {
  __typename?: 'SpendBankTransaction';
  amount?: Maybe<Scalars['Int']['output']>;
  attachments?: Maybe<Array<TransactionAttachment>>;
  canCancel?: Maybe<Scalars['Boolean']['output']>;
  correlationId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  creditMemo?: Maybe<SpendMemoTransaction>;
  /** @deprecated Can be found in metadata */
  description?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  effective?: Maybe<Scalars['String']['output']>;
  external?: Maybe<SpendJoinedExternal>;
  externalId?: Maybe<Scalars['String']['output']>;
  groupRoster?: Maybe<SpendGroupRoster>;
  hasAttachments?: Maybe<Scalars['Boolean']['output']>;
  history?: Maybe<Array<Maybe<SpendBankTransactionHistory>>>;
  id?: Maybe<Scalars['String']['output']>;
  isReconciled?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<SpendBankTransactionMetaData>;
  payee?: Maybe<SpendJoinedPayee>;
  processor?: Maybe<Scalars['String']['output']>;
  reconciliation?: Maybe<SpendReconciledTransaction>;
  snapAmount?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalApplied?: Maybe<Scalars['Int']['output']>;
  totalReconciled?: Maybe<Scalars['Int']['output']>;
  transactionNote?: Maybe<Scalars['String']['output']>;
  transactionStatus?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  typeReconciled?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SpendBankTransactionDetail = {
  __typename?: 'SpendBankTransactionDetail';
  amount?: Maybe<Scalars['Int']['output']>;
  canCancel?: Maybe<Scalars['Boolean']['output']>;
  correlationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Can be found in metadata */
  description?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  disputeDetail?: Maybe<SpendDisputeDetail>;
  effective?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  groupRoster?: Maybe<SpendGroupRoster>;
  history?: Maybe<Array<Maybe<SpendBankTransactionHistory>>>;
  id?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<SpendBankTransactionMetaData>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  processor?: Maybe<Scalars['String']['output']>;
  reconciliation?: Maybe<SpendReconciledTransaction>;
  snapAmount?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SpendBankTransactionHistory = {
  __typename?: 'SpendBankTransactionHistory';
  date?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type SpendBankTransactionMetaData = {
  __typename?: 'SpendBankTransactionMetaData';
  account?: Maybe<Scalars['String']['output']>;
  addenda?: Maybe<Scalars['String']['output']>;
  balance?: Maybe<Scalars['String']['output']>;
  checkNumber?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Can be found in tags as SpendInvoiceIds */
  invoiceId?: Maybe<Scalars['String']['output']>;
  originalTransaction?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Scalars['String']['output']>;
  processor?: Maybe<Scalars['String']['output']>;
  /** @deprecated Can be found in tags */
  product?: Maybe<Scalars['String']['output']>;
  returnedTransaction?: Maybe<Scalars['String']['output']>;
  snapAmount?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<SpendTransactionTags>;
};

export type SpendBankTransactionResponse = {
  __typename?: 'SpendBankTransactionResponse';
  transactionDetail?: Maybe<SpendBankTransactionDetail>;
};

export type SpendBankTransactionsResponse = {
  __typename?: 'SpendBankTransactionsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  transactions?: Maybe<Array<Maybe<SpendBankTransaction>>>;
};

export type SpendBookTransferLimitsData = {
  __typename?: 'SpendBookTransferLimitsData';
  limits: SpendAchLimits;
};

export type SpendBudget = {
  __typename?: 'SpendBudget';
  category: SpendCategory;
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoices?: Maybe<Array<SpendInvoice>>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  reconciledBudgetTotal?: Maybe<Scalars['Int']['output']>;
  reconciledInvoicesTotal?: Maybe<Scalars['Int']['output']>;
  reconciledTotal?: Maybe<Scalars['Int']['output']>;
  reconciledTransactions?: Maybe<Array<SpendReconciledBudgetTransaction>>;
  season?: Maybe<SpendSeason>;
  targetAmount: Scalars['Int']['output'];
  targetDateAt: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  vaultId?: Maybe<Scalars['String']['output']>;
};

export type SpendBudgetId = {
  __typename?: 'SpendBudgetID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendBudgetInput = {
  categoryId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
  targetAmount: Scalars['Int']['input'];
  targetDateAt: Scalars['String']['input'];
  vaultId?: InputMaybe<Scalars['String']['input']>;
};

export type SpendBudgetReconciledTotal = {
  __typename?: 'SpendBudgetReconciledTotal';
  id: Scalars['String']['output'];
  reconciledAmount: Scalars['Int']['output'];
  seasonId?: Maybe<Scalars['String']['output']>;
  targetAmount: Scalars['Int']['output'];
};

export type SpendBudgetResponse = {
  __typename?: 'SpendBudgetResponse';
  budget?: Maybe<SpendBudget>;
};

export type SpendBudgetSummaryResponse = {
  __typename?: 'SpendBudgetSummaryResponse';
  summaryByCategory?: Maybe<Array<Maybe<SpendCategory>>>;
  summaryUnreconciled?: Maybe<SpendUnreconciledSummary>;
};

export type SpendBudgetSummaryV2Response = {
  __typename?: 'SpendBudgetSummaryV2Response';
  reconciledTotals?: Maybe<Array<Maybe<SpendReconciledTotals>>>;
  summaryByCategory?: Maybe<Array<Maybe<SpendCategory>>>;
  summaryUnreconciled?: Maybe<SpendUnreconciledSummary>;
};

export type SpendBudgetTransactionInput = {
  transactionIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SpendBudgetTransactionsOutput = {
  __typename?: 'SpendBudgetTransactionsOutput';
  budgetTransactions: Array<Maybe<BudgetTransaction>>;
};

export type SpendBudgetsResponse = {
  __typename?: 'SpendBudgetsResponse';
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type SpendCancelCheckId = {
  __typename?: 'SpendCancelCheckID';
  id: Scalars['String']['output'];
};

export type SpendCardLimits = {
  __typename?: 'SpendCardLimits';
  dailyCardTransaction: Scalars['Int']['output'];
  dailyDeposit: Scalars['Int']['output'];
  dailyPurchase: Scalars['Int']['output'];
  dailyWithdrawal: Scalars['Int']['output'];
};

export type SpendCardLimitsData = {
  __typename?: 'SpendCardLimitsData';
  limits: SpendCardLimits;
};

export type SpendCategory = {
  __typename?: 'SpendCategory';
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  createdAt: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  isDefault: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type SpendCategoryId = {
  __typename?: 'SpendCategoryID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendCategoryInput = {
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CategoryTypeEnum>;
};

export type SpendCategoryResponse = {
  __typename?: 'SpendCategoryResponse';
  categories?: Maybe<Array<Maybe<SpendCategory>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type SpendCheckDepositLimits = {
  __typename?: 'SpendCheckDepositLimits';
  daily: Scalars['Int']['output'];
  monthly: Scalars['Int']['output'];
};

export type SpendCheckDepositLimitsData = {
  __typename?: 'SpendCheckDepositLimitsData';
  limits: SpendCheckDepositLimits;
};

export type SpendCheckDepositTagsResponse = {
  __typename?: 'SpendCheckDepositTagsResponse';
  success: Scalars['Boolean']['output'];
};

export type SpendCheckImageAccess = {
  __typename?: 'SpendCheckImageAccess';
  checkId: Scalars['String']['output'];
  checkType: SpendCheckImageType;
  id: Scalars['String']['output'];
};

export type SpendCheckImageAllowance = {
  checkId: Scalars['String']['input'];
  checkType: SpendCheckImageType;
};

export type SpendCheckImageAllowanceResponse = {
  __typename?: 'SpendCheckImageAllowanceResponse';
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SpendCheckImageRequest = {
  id: Scalars['String']['input'];
  side: SpendCheckImageSide;
};

export enum SpendCheckImageSide {
  Back = 'BACK',
  Front = 'FRONT'
}

export enum SpendCheckImageType {
  CheckDeposit = 'CHECK_DEPOSIT',
  CheckPayment = 'CHECK_PAYMENT'
}

export type SpendCheckInput = {
  address: SpendAddressInput;
  amount: Scalars['Int']['input'];
  attachmentKey?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  payeeId: Scalars['String']['input'];
  referenceId: Scalars['String']['input'];
  sendAt?: InputMaybe<Scalars['String']['input']>;
};

export type SpendCheckPaymentLimit = {
  __typename?: 'SpendCheckPaymentLimit';
  dailySent: Scalars['Int']['output'];
  monthlySent: Scalars['Int']['output'];
};

export type SpendCheckPaymentLimitData = {
  __typename?: 'SpendCheckPaymentLimitData';
  limits?: Maybe<SpendCheckPaymentLimit>;
};

export type SpendCheckSendResponse = {
  __typename?: 'SpendCheckSendResponse';
  approvalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SpendCounterpartiesResponse = {
  __typename?: 'SpendCounterpartiesResponse';
  counterparties: Array<SpendCounterparty>;
};

export type SpendCounterparty = {
  __typename?: 'SpendCounterparty';
  accountLastFour: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  einNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
};

export type SpendCounterpartyCreateInput = {
  accountNumber: Scalars['String']['input'];
  accountType: Scalars['String']['input'];
  einNumber?: InputMaybe<Scalars['String']['input']>;
  entityType: Scalars['String']['input'];
  groupOrOrgId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
};

export type SpendCounterpartyCreateResponse = {
  __typename?: 'SpendCounterpartyCreateResponse';
  id: Scalars['String']['output'];
};

export type SpendCreditMemoCreateResponse = {
  __typename?: 'SpendCreditMemoCreateResponse';
  ids: Array<Scalars['String']['output']>;
};

export type SpendCreditMemoInput = {
  creditAmount: Scalars['Int']['input'];
  dateToApply: Scalars['String']['input'];
  invoicesApplied: Array<SpendAppliedInvoices>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type SpendCreditMemoUpdateInput = {
  creditApplied: Scalars['Int']['input'];
  dateToApply: Scalars['String']['input'];
  id: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  isArchived: Scalars['Boolean']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type SpendCreditMemoUpdateResponse = {
  __typename?: 'SpendCreditMemoUpdateResponse';
  id: Scalars['String']['output'];
};

export type SpendDeauthorizeResponse = {
  __typename?: 'SpendDeauthorizeResponse';
  id?: Maybe<Scalars['String']['output']>;
  notifSuccess?: Maybe<Scalars['Boolean']['output']>;
};

export type SpendDebitCard = {
  __typename?: 'SpendDebitCard';
  expiration?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastFour?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type SpendDebitCardId = {
  __typename?: 'SpendDebitCardID';
  approvalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendDebitCardInput = {
  dateOfBirth: Scalars['String']['input'];
  shipping?: InputMaybe<SpendDebitCardShippingInput>;
  userId: Scalars['String']['input'];
};

export type SpendDebitCardResponse = {
  __typename?: 'SpendDebitCardResponse';
  cards?: Maybe<Array<Maybe<SpendDebitCard>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type SpendDebitCardShippingInput = {
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
};

export type SpendDebitCardShippingType = {
  __typename?: 'SpendDebitCardShippingType';
  city: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
};

export type SpendDeleteCounterpartyResponse = {
  __typename?: 'SpendDeleteCounterpartyResponse';
  success: Scalars['Boolean']['output'];
};

export type SpendDismissInviteResponse = {
  __typename?: 'SpendDismissInviteResponse';
  inviteId: Scalars['String']['output'];
};

export type SpendDisputeDetail = {
  __typename?: 'SpendDisputeDetail';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  originalMetadata?: Maybe<SpendTransactionTags>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SpendExportEmailsInput = {
  emails: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SpendExternalTransferFee = {
  __typename?: 'SpendExternalTransferFee';
  base: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  maxAverageBalance?: Maybe<Scalars['Float']['output']>;
  minAverageBalance?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  percentage: Scalars['Float']['output'];
};

export type SpendExternalTransferResponse = {
  __typename?: 'SpendExternalTransferResponse';
  amount?: Maybe<Scalars['Int']['output']>;
  approvalId?: Maybe<Scalars['String']['output']>;
  descriptor?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SpendFileResponse = {
  __typename?: 'SpendFileResponse';
  content?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
};

export type SpendGetApprovalInput = {
  filter?: InputMaybe<ApprovalFilterInput>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  pagination?: InputMaybe<SpendPaginationInput>;
  sort?: InputMaybe<SpendSortInput>;
  tab: Scalars['String']['input'];
};

export type SpendGroup = {
  __typename?: 'SpendGroup';
  accountId?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  discountCutOffDate?: Maybe<Scalars['String']['output']>;
  enableDiscount?: Maybe<Scalars['Boolean']['output']>;
  hasAccount?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isRequireAgreement?: Maybe<Scalars['Boolean']['output']>;
  latestSeason?: Maybe<SpendSeason>;
  legacyAccountId?: Maybe<Scalars['String']['output']>;
  minimumDiscountPurchase?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationFees?: Maybe<SpendOrganizationFee>;
  organizationId?: Maybe<Scalars['String']['output']>;
  programId?: Maybe<Scalars['String']['output']>;
  seasons?: Maybe<Array<Maybe<SpendSeason>>>;
  seasonsList?: Maybe<Array<Maybe<SpendSeason>>>;
  sharedAccount?: Maybe<Scalars['Boolean']['output']>;
  unitAmount?: Maybe<Scalars['Int']['output']>;
};

export type SpendGroupAchCredit = {
  amount: Scalars['Int']['input'];
  counterpartyId: Scalars['String']['input'];
  counterpartyName: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
  note: Scalars['String']['input'];
};

export type SpendGroupAccountBalances = {
  __typename?: 'SpendGroupAccountBalances';
  active?: Maybe<Scalars['Int']['output']>;
  archived?: Maybe<Scalars['Int']['output']>;
};

export type SpendGroupArchiveResponse = {
  __typename?: 'SpendGroupArchiveResponse';
  archiveResult?: Maybe<SpendGroupArchiveResult>;
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendGroupArchiveResult = {
  __typename?: 'SpendGroupArchiveResult';
  group: Scalars['Boolean']['output'];
  invites?: Maybe<Scalars['Boolean']['output']>;
  invoiceAndPms?: Maybe<Scalars['Boolean']['output']>;
  rosters?: Maybe<Scalars['Boolean']['output']>;
};

export type SpendGroupCheckDepositTagsInput = {
  checkDepositId: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
};

export type SpendGroupExternalTransferInput = {
  accountId: Scalars['String']['input'];
  amount: Scalars['Int']['input'];
  direction: ExternalTransferDirectionEnum;
  id: Scalars['String']['input'];
  note: Scalars['String']['input'];
};

export type SpendGroupId = {
  __typename?: 'SpendGroupID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendGroupInput = {
  discountAmount?: InputMaybe<Scalars['Int']['input']>;
  discountCutOffDate?: InputMaybe<Scalars['String']['input']>;
  enableDiscount: Scalars['Boolean']['input'];
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  isBudgetShared?: InputMaybe<Scalars['Boolean']['input']>;
  isLinkEnabled: Scalars['Boolean']['input'];
  isRequireAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  minimumDiscountPurchase?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  seasonEndAt: Scalars['String']['input'];
  seasonName?: InputMaybe<Scalars['String']['input']>;
  seasonStartAt: Scalars['String']['input'];
  shareAccount: Scalars['Boolean']['input'];
};

export type SpendGroupResponse = {
  __typename?: 'SpendGroupResponse';
  count?: Maybe<Scalars['Int']['output']>;
  groups?: Maybe<Array<Maybe<SpendGroup>>>;
};

export type SpendGroupRoster = {
  __typename?: 'SpendGroupRoster';
  archivedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  group?: Maybe<SpendGroup>;
  groupId?: Maybe<Scalars['String']['output']>;
  guardianName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invite?: Maybe<SpendInvite>;
  inviteId?: Maybe<Scalars['String']['output']>;
  invoices?: Maybe<Array<Maybe<SpendInvoice>>>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  joinedAt?: Maybe<Scalars['String']['output']>;
  pastDueDays?: Maybe<Scalars['Int']['output']>;
  paymentScheduleStatus?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  roster?: Maybe<SpendRoster>;
  rosterId?: Maybe<Scalars['String']['output']>;
  season?: Maybe<SpendSeason>;
  seasonId?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<SpendGroupRosterSettings>;
  status?: Maybe<SpendGroupRosterStatusEnum>;
  total?: Maybe<SpendTransactionTotals>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type SpendGroupRosterId = {
  __typename?: 'SpendGroupRosterID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendGroupRosterInput = {
  email: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
};

export type SpendGroupRosterResponse = {
  __typename?: 'SpendGroupRosterResponse';
  count?: Maybe<Scalars['Int']['output']>;
  groupRosters?: Maybe<Array<Maybe<SpendGroupRoster>>>;
};

export type SpendGroupRosterSettings = {
  __typename?: 'SpendGroupRosterSettings';
  isAutoPayAuthorized?: Maybe<Scalars['Boolean']['output']>;
};

export type SpendGroupRosterSettingsResponse = {
  __typename?: 'SpendGroupRosterSettingsResponse';
  groupRosterId?: Maybe<Scalars['String']['output']>;
  settingsId?: Maybe<Scalars['String']['output']>;
};

export enum SpendGroupRosterStatusEnum {
  Accepted = 'accepted',
  Archived = 'archived',
  AutopayStop = 'autopay_stop',
  AwaitingApproval = 'awaiting_approval',
  Canceled = 'canceled',
  Expired = 'expired',
  NoInviteSent = 'no_invite_sent',
  NotSignedUp = 'not_signed_up',
  Paid = 'paid',
  PastDue = 'past_due'
}

export type SpendGroupRostersInput = {
  groupId: Scalars['String']['input'];
  rosters?: InputMaybe<Array<InputMaybe<SpendRosterUserInput>>>;
  seasonId: Scalars['String']['input'];
};

export type SpendGroupRostersWhereInput = {
  groupNameIncludes?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  nameIncludes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SpendGroupRosterStatusEnum>;
};

export type SpendGroupsOverview = {
  __typename?: 'SpendGroupsOverview';
  balance?: Maybe<Scalars['Int']['output']>;
  cards?: Maybe<Array<Maybe<SpendDebitCard>>>;
  collected?: Maybe<Scalars['Int']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pastDue?: Maybe<Scalars['Int']['output']>;
  paymentScheduleInvoices?: Maybe<Array<Maybe<SpendPaymentSchedule>>>;
  seasonEndAt?: Maybe<Scalars['String']['output']>;
  seasonName?: Maybe<Scalars['String']['output']>;
  seasonStartAt?: Maybe<Scalars['String']['output']>;
  sharedAccount?: Maybe<Scalars['Boolean']['output']>;
};

export type SpendGroupsResponse = {
  __typename?: 'SpendGroupsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  groups?: Maybe<Array<Maybe<SpendGroup>>>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
};

export type SpendGroupsWhereInput = {
  /** Search archived groups only */
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by group name only */
  groupNameIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Search by list of group id */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by group name or latest season name in group */
  nameIncludes?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  /** Search by payment schedule status of latest season in group. Support multiple values. (OR operation) */
  paymentScheduleStatus?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SpendGuardianActiveGroup = {
  __typename?: 'SpendGuardianActiveGroup';
  /** @deprecated No longer required */
  dueDate?: Maybe<Scalars['String']['output']>;
  group?: Maybe<SpendGuardianGroup>;
  /** @deprecated No longer required */
  roster?: Maybe<Array<Maybe<SpendRoster>>>;
};

export type SpendGuardianComingSoonInvoice = {
  __typename?: 'SpendGuardianComingSoonInvoice';
  authorizedAt?: Maybe<Scalars['String']['output']>;
  creditMemos?: Maybe<Array<SpendMemo>>;
  groupId?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  groupRosterId?: Maybe<Scalars['String']['output']>;
  invoiceAmountDue?: Maybe<Scalars['String']['output']>;
  invoiceAutoPayAuthorized?: Maybe<Scalars['Boolean']['output']>;
  invoiceAutoPayStopped?: Maybe<Scalars['Boolean']['output']>;
  invoiceDescription?: Maybe<Scalars['String']['output']>;
  invoiceDueDate?: Maybe<Scalars['String']['output']>;
  invoiceId?: Maybe<Scalars['String']['output']>;
  invoiceNote?: Maybe<Scalars['String']['output']>;
  invoiceOptedOutAt?: Maybe<Scalars['String']['output']>;
  invoicePaymentMethod?: Maybe<Scalars['String']['output']>;
  invoiceStatus?: Maybe<Scalars['String']['output']>;
  isAuthorized?: Maybe<Scalars['Boolean']['output']>;
  isOptional?: Maybe<Scalars['Boolean']['output']>;
  optedIn?: Maybe<Scalars['Boolean']['output']>;
  rosterName?: Maybe<Scalars['String']['output']>;
  seasonId?: Maybe<Scalars['String']['output']>;
  seasonName?: Maybe<Scalars['String']['output']>;
};

export type SpendGuardianCounts = {
  __typename?: 'SpendGuardianCounts';
  activeGroups?: Maybe<Scalars['Int']['output']>;
  comingSoonInvoices?: Maybe<Scalars['Int']['output']>;
  unauthorizedInvoices?: Maybe<Scalars['Int']['output']>;
};

export type SpendGuardianGroup = {
  __typename?: 'SpendGuardianGroup';
  accountId?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  hasAccount?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  latestSeason?: Maybe<SpendSeason>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  programId?: Maybe<Scalars['String']['output']>;
  seasons?: Maybe<Array<Maybe<SpendGuardianSeason>>>;
};

export type SpendGuardianHighlight = {
  __typename?: 'SpendGuardianHighlight';
  activeGroups?: Maybe<Array<Maybe<SpendGuardianActiveGroup>>>;
  comingSoonInvoices?: Maybe<Array<Maybe<SpendGuardianComingSoonInvoice>>>;
  counts?: Maybe<SpendGuardianCounts>;
  unauthorizedInvoices?: Maybe<Array<Maybe<SpendGuardianComingSoonInvoice>>>;
};

export type SpendGuardianInvoice = {
  __typename?: 'SpendGuardianInvoice';
  amount: Scalars['Int']['output'];
  authorizedAt?: Maybe<Scalars['String']['output']>;
  balanceDue: Scalars['Int']['output'];
  creditMemos?: Maybe<Array<SpendMemo>>;
  description: Scalars['String']['output'];
  discountAmount: Scalars['Int']['output'];
  dueDate: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  groupRosterId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  isAutoPayAuthorized: Scalars['Boolean']['output'];
  isAutoPayStopped: Scalars['Boolean']['output'];
  isOptional: Scalars['Boolean']['output'];
  isRefunded: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  optedIn: Scalars['Boolean']['output'];
  optedOutAt?: Maybe<Scalars['String']['output']>;
  paid: Scalars['Boolean']['output'];
  paidDate?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  paymentMethodSource?: Maybe<Scalars['String']['output']>;
  refundDate?: Maybe<Scalars['String']['output']>;
  rosterId: Scalars['String']['output'];
  rosterName: Scalars['String']['output'];
  seasonId: Scalars['String']['output'];
  seasonName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type SpendGuardianInvoiceFilter = {
  by: InvoiceGuardianFilterEnum;
  value: Scalars['String']['input'];
};

export type SpendGuardianInvoiceResponse = {
  __typename?: 'SpendGuardianInvoiceResponse';
  count: Scalars['Int']['output'];
  invoices?: Maybe<Array<SpendGuardianInvoice>>;
};

export type SpendGuardianRecentTransaction = {
  __typename?: 'SpendGuardianRecentTransaction';
  amountDue?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  invoiceDescription?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
  rosterName?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type SpendGuardianSeason = {
  __typename?: 'SpendGuardianSeason';
  endDateAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isBudgetShared?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roster?: Maybe<Array<Maybe<SpendRoster>>>;
  startDateAt?: Maybe<Scalars['String']['output']>;
};

export type SpendGuardianSignupInput = {
  groupId: Scalars['String']['input'];
  rosterName: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
};

export type SpendImageResponse = {
  __typename?: 'SpendImageResponse';
  imageContent?: Maybe<Scalars['String']['output']>;
  imageFound?: Maybe<Scalars['Boolean']['output']>;
  imageId?: Maybe<Scalars['String']['output']>;
  imageName?: Maybe<Scalars['String']['output']>;
};

export type SpendInstitution = {
  __typename?: 'SpendInstitution';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SpendInstitutionResponse = {
  __typename?: 'SpendInstitutionResponse';
  address: Scalars['String']['output'];
  isACHSupported: Scalars['Boolean']['output'];
  isWireSupported: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  routingNumber: Scalars['String']['output'];
};

export type SpendInvite = {
  __typename?: 'SpendInvite';
  createdAt?: Maybe<Scalars['String']['output']>;
  debitCard?: Maybe<Scalars['String']['output']>;
  debitCards?: Maybe<Array<SpendDebitCard>>;
  email?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  group?: Maybe<SpendGroup>;
  groupId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isApprover?: Maybe<Scalars['Boolean']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isDeliverable?: Maybe<Scalars['Boolean']['output']>;
  isDismissed?: Maybe<Scalars['Boolean']['output']>;
  isUser?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  orgName?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  seasonId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum SpendInviteAllType {
  Group = 'group',
  Guardian = 'guardian',
  Program = 'program'
}

export type SpendInviteId = {
  __typename?: 'SpendInviteID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendInviteInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  seasonId?: InputMaybe<Scalars['String']['input']>;
  type: SpendInviteType;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SpendInviteResendAllInput = {
  orgId: Scalars['String']['input'];
  seasonId?: InputMaybe<Scalars['String']['input']>;
  userType?: InputMaybe<SpendInviteAllType>;
};

export type SpendInviteResendInput = {
  email: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
  groupRosterId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SpendInviteResponse = {
  __typename?: 'SpendInviteResponse';
  count?: Maybe<Scalars['Int']['output']>;
  invites?: Maybe<Array<Maybe<SpendInvite>>>;
};

export enum SpendInviteType {
  GroupObserver = 'group_observer',
  GroupStaff = 'group_staff',
  Guardian = 'guardian',
  ProgramAdmin = 'program_admin',
  ProgramObserver = 'program_observer',
  ProgramStaff = 'program_staff'
}

export type SpendInviteWhereInput = {
  /** Search invitation which is archived */
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by group id */
  groupId?: InputMaybe<Scalars['String']['input']>;
  /** Search by invite name */
  nameIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Search by roster name only */
  rosterNameIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Search by invite status: accepted, pending, undeliverable */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Search by invite types. Valid values: guardian,group_staff,program_staff, program_observer,program_admin */
  type?: InputMaybe<Array<SpendInviteType>>;
  /** Search by invite type context: group or program. This will be affect invite type filter. */
  typeContext?: InputMaybe<Scalars['String']['input']>;
};

export type SpendInvoice = {
  __typename?: 'SpendInvoice';
  amount?: Maybe<Scalars['Int']['output']>;
  authorizedAt?: Maybe<Scalars['String']['output']>;
  balanceDue?: Maybe<Scalars['Int']['output']>;
  budgetItem?: Maybe<SpendBudget>;
  budgetItemId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  creditMemos?: Maybe<Array<SpendMemo>>;
  description?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  feesAmount?: Maybe<Scalars['Int']['output']>;
  groupRoster?: Maybe<SpendGroupRoster>;
  groupRosterId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAutoPayAuthorized?: Maybe<Scalars['Boolean']['output']>;
  isAutoPayStopped?: Maybe<Scalars['Boolean']['output']>;
  isOptional?: Maybe<Scalars['Boolean']['output']>;
  isPending?: Maybe<Scalars['Boolean']['output']>;
  isReconciled?: Maybe<Scalars['Boolean']['output']>;
  isRefunded?: Maybe<Scalars['Boolean']['output']>;
  lastNotifyDate?: Maybe<Scalars['String']['output']>;
  lastNotifyId?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  notificationAttempts?: Maybe<Scalars['Int']['output']>;
  optedIn?: Maybe<Scalars['Boolean']['output']>;
  optedOutAt?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['Boolean']['output']>;
  paidDate?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  paymentMethodSource?: Maybe<Scalars['String']['output']>;
  paymentScheduleInvoiceId?: Maybe<Scalars['String']['output']>;
  paymentScheduleStatus?: Maybe<Scalars['String']['output']>;
  pendingMessage?: Maybe<SpendInvoicePendingMessage>;
  reconciledTransactions?: Maybe<Array<Maybe<SpendReconciledInvoiceTransaction>>>;
  refundDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transaction?: Maybe<SpendTransaction>;
  transactionRejectReason?: Maybe<Scalars['String']['output']>;
  transactionSettledDate?: Maybe<Scalars['String']['output']>;
  transactionStatus?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type SpendInvoiceDiscountInput = {
  discountAmount: Scalars['Int']['input'];
  invoiceId: Scalars['String']['input'];
};

export type SpendInvoiceFilter = {
  by: InvoiceReportFilterEnum;
  value: Scalars['String']['input'];
};

export type SpendInvoiceHistoryInput = {
  email: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SpendInvoiceId = {
  __typename?: 'SpendInvoiceID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendInvoiceIDs = {
  __typename?: 'SpendInvoiceIDs';
  ids?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SpendInvoiceInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  balanceDue?: InputMaybe<Scalars['Int']['input']>;
  budgetItemId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  groupRosterId?: InputMaybe<Scalars['String']['input']>;
  isOptional?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  optedIn?: InputMaybe<Scalars['Boolean']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  paidDate?: InputMaybe<Scalars['String']['input']>;
  paymentScheduleInvoiceId?: InputMaybe<Scalars['String']['input']>;
};

export type SpendInvoicePaymentDeauthorizeInput = {
  invoiceId?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};

export type SpendInvoicePaymentMethodUpdate = {
  ids: Array<Scalars['String']['input']>;
  isAutoPayAuthorized?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethodId: Scalars['String']['input'];
  paymentMethodSource: Scalars['String']['input'];
};

export type SpendInvoicePendingMessage = {
  __typename?: 'SpendInvoicePendingMessage';
  nextStep?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type SpendInvoiceRefundInput = {
  amount: Scalars['Int']['input'];
  invoiceId: Scalars['String']['input'];
};

export type SpendInvoiceRefundResponse = {
  __typename?: 'SpendInvoiceRefundResponse';
  invoiceId?: Maybe<Scalars['String']['output']>;
  spendTransactionId?: Maybe<Scalars['String']['output']>;
};

export type SpendInvoiceReminderInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  failedPayment?: InputMaybe<Scalars['Boolean']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  invoiceId?: InputMaybe<Scalars['String']['input']>;
  pastDue?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpendInvoiceRequestChangeInput = {
  invoiceId: Scalars['String']['input'];
  note: Scalars['String']['input'];
};

export type SpendInvoiceResponse = {
  __typename?: 'SpendInvoiceResponse';
  count?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  invoices?: Maybe<Array<Maybe<SpendInvoice>>>;
};

export type SpendInvoiceUpdateResponse = {
  __typename?: 'SpendInvoiceUpdateResponse';
  success: Scalars['Boolean']['output'];
};

export type SpendIsApproverUpdateInput = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  isEnabled: Scalars['Boolean']['input'];
  type: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SpendJoinedExternal = {
  __typename?: 'SpendJoinedExternal';
  externalId: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  invoiceDescription: Scalars['String']['output'];
  playerName: Scalars['String']['output'];
  seasonName: Scalars['String']['output'];
};

export type SpendJoinedPayee = {
  __typename?: 'SpendJoinedPayee';
  groupName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  payeeId: Scalars['String']['output'];
  payeeName: Scalars['String']['output'];
  referenceId: Scalars['String']['output'];
};

export type SpendLegacyTransaction = {
  __typename?: 'SpendLegacyTransaction';
  amount?: Maybe<Scalars['Int']['output']>;
  attachments?: Maybe<Array<TransactionAttachment>>;
  correlationId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  effective: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  note?: Maybe<TransactionNotes>;
  processor?: Maybe<Scalars['String']['output']>;
  reconciliation?: Maybe<SpendReconciledTransaction>;
  snapAmount?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  transactionNote?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SpendLegacyTransactionsResponse = {
  __typename?: 'SpendLegacyTransactionsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  transactions?: Maybe<Array<Maybe<SpendLegacyTransaction>>>;
};

export type SpendMember = {
  __typename?: 'SpendMember';
  child?: Maybe<SpendMemberChild>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  signedUp?: Maybe<Scalars['Boolean']['output']>;
};

export type SpendMemberChild = {
  __typename?: 'SpendMemberChild';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SpendMemo = {
  __typename?: 'SpendMemo';
  creditAmount: Scalars['Int']['output'];
  creditApplied: Scalars['Int']['output'];
  dateToApply: Scalars['String']['output'];
  groupName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type SpendMemoInvoice = {
  __typename?: 'SpendMemoInvoice';
  amount: Scalars['Int']['output'];
  balanceDue: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  discountAmount: Scalars['Int']['output'];
  dueDate: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  participantName: Scalars['String']['output'];
  rosterId: Scalars['String']['output'];
  seasonId: Scalars['String']['output'];
  seasonName: Scalars['String']['output'];
  status: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type SpendMemoTransaction = {
  __typename?: 'SpendMemoTransaction';
  creditAmount: Scalars['Int']['output'];
  creditApplied: Scalars['Int']['output'];
  dateToApply: Scalars['String']['output'];
  groupName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoice?: Maybe<SpendMemoInvoice>;
  isArchived: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type SpendNotificationId = {
  __typename?: 'SpendNotificationID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendNotificationInput = {
  email: Array<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  isEmailCCed: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type SpendNotificationStatus = {
  __typename?: 'SpendNotificationStatus';
  status?: Maybe<Scalars['String']['output']>;
};

export type SpendOptInOrOutInput = {
  invoiceId: Scalars['String']['input'];
  optedIn: Scalars['Boolean']['input'];
};

export type SpendOrgAchCredit = {
  amount: Scalars['Int']['input'];
  counterpartyId: Scalars['String']['input'];
  counterpartyName: Scalars['String']['input'];
  note: Scalars['String']['input'];
};

export type SpendOrgAdminUpdateInput = {
  email: Scalars['String']['input'];
  keepAsAuthorizedUser: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
};

export type SpendOrgAdminUpdateResponse = {
  __typename?: 'SpendOrgAdminUpdateResponse';
  createdAuthorizedUser?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendOrganization = {
  __typename?: 'SpendOrganization';
  accountId?: Maybe<Scalars['String']['output']>;
  achBaseFee?: Maybe<Scalars['Int']['output']>;
  achPercent?: Maybe<Scalars['Float']['output']>;
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  cardBaseFee?: Maybe<Scalars['Int']['output']>;
  cardPercent?: Maybe<Scalars['Float']['output']>;
  checkImageAccess?: Maybe<Array<SpendCheckImageAccess>>;
  city: Scalars['String']['output'];
  customerDetail?: Maybe<SpendOrganizationCustomerDetail>;
  debitCards?: Maybe<SpendOrganizationDebitCardCount>;
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  externalTransferFee?: Maybe<SpendExternalTransferFee>;
  externalTransferFeeId?: Maybe<Scalars['String']['output']>;
  externalTransferOutEnabled?: Maybe<Scalars['Boolean']['output']>;
  groupBanksEnabled?: Maybe<Scalars['Boolean']['output']>;
  groups?: Maybe<Array<Maybe<SpendGroup>>>;
  hasLinkedAccount?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isApproverEnabled?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  legacyAccountId?: Maybe<Scalars['String']['output']>;
  legacyExternalId?: Maybe<Scalars['String']['output']>;
  legalName: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<User>;
  phone: Scalars['String']['output'];
  spendBaseFee?: Maybe<Scalars['Int']['output']>;
  spendPercent?: Maybe<Scalars['Float']['output']>;
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export type SpendOrganizationCheckDepositTagsInput = {
  checkDepositId: Scalars['String']['input'];
};

export type SpendOrganizationCustomerDetail = {
  __typename?: 'SpendOrganizationCustomerDetail';
  activeAccounts?: Maybe<Scalars['Int']['output']>;
  averageDaily?: Maybe<Scalars['Int']['output']>;
  days?: Maybe<Scalars['Int']['output']>;
};

export type SpendOrganizationDebitCardCount = {
  __typename?: 'SpendOrganizationDebitCardCount';
  activated?: Maybe<Scalars['Int']['output']>;
  assigned?: Maybe<Scalars['Int']['output']>;
  shipped?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SpendOrganizationExternalTransferInput = {
  accountId: Scalars['String']['input'];
  amount: Scalars['Int']['input'];
  direction: ExternalTransferDirectionEnum;
  note: Scalars['String']['input'];
};

export type SpendOrganizationFee = {
  __typename?: 'SpendOrganizationFee';
  achBaseFee?: Maybe<Scalars['Int']['output']>;
  achPercent?: Maybe<Scalars['Float']['output']>;
  cardBaseFee?: Maybe<Scalars['Int']['output']>;
  cardPercent?: Maybe<Scalars['Float']['output']>;
  spendBaseFee?: Maybe<Scalars['Int']['output']>;
  spendPercent?: Maybe<Scalars['Float']['output']>;
};

export type SpendOrganizationId = {
  __typename?: 'SpendOrganizationID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendOrganizationInput = {
  achBaseFee: Scalars['Int']['input'];
  achPercent: Scalars['Float']['input'];
  cardBaseFee: Scalars['Int']['input'];
  cardPercent: Scalars['Float']['input'];
  email: Scalars['String']['input'];
  externalTransferOutEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  groupBanksEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isVerified: Scalars['Boolean']['input'];
  legalName: Scalars['String']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  spendBaseFee: Scalars['Int']['input'];
  spendPercent: Scalars['Float']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type SpendOrganizationOwner = {
  __typename?: 'SpendOrganizationOwner';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type SpendOrganizationStatus = {
  __typename?: 'SpendOrganizationStatus';
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type SpendOrganizationsResponse = {
  __typename?: 'SpendOrganizationsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  organizations?: Maybe<Array<SpendOrganization>>;
};

export type SpendOrganizationsWhereInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  nameIncludes?: InputMaybe<Scalars['String']['input']>;
  /** orgId is equivalent to externalId or id from orgs-api */
  orgIds?: InputMaybe<Array<Scalars['String']['input']>>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<OrganizationFilterEnum>;
};

export type SpendPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum SpendPastDueInterval {
  Days1_15 = 'days1_15',
  Days16_30 = 'days16_30',
  Days31plus = 'days31plus'
}

export type SpendPastDueInvoice = {
  __typename?: 'SpendPastDueInvoice';
  amount: Scalars['Int']['output'];
  creditMemos?: Maybe<Array<Maybe<CreditMemoAmounts>>>;
  description: Scalars['String']['output'];
  discountAmount: Scalars['Int']['output'];
  dueDate: Scalars['String']['output'];
  email: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  guardianStatus: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rosterId: Scalars['String']['output'];
  seasonId: Scalars['String']['output'];
  seasonName: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type SpendPastDueInvoicesOutput = {
  __typename?: 'SpendPastDueInvoicesOutput';
  count: Scalars['Int']['output'];
  invoices: Array<Maybe<SpendPastDueInvoice>>;
};

export type SpendPastDueSort = {
  order?: InputMaybe<SpendSortOrderEnum>;
  sortBy?: InputMaybe<PastDueSortOptions>;
};

export type SpendPayNowResponse = {
  __typename?: 'SpendPayNowResponse';
  paymentId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SpendPayeeCreateResponse = {
  __typename?: 'SpendPayeeCreateResponse';
  payeeId?: Maybe<Scalars['String']['output']>;
};

export type SpendPayeeInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  einNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  referenceId: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type SpendPayeeResponse = {
  __typename?: 'SpendPayeeResponse';
  payees?: Maybe<Array<SpendPayees>>;
};

export type SpendPayeeUpdateInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  id: Scalars['String']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  referenceId: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type SpendPayees = {
  __typename?: 'SpendPayees';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  einNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type SpendPaymentMethodDetach = {
  paymentMethodId: Scalars['String']['input'];
};

export type SpendPaymentMethodDetachResponse = {
  __typename?: 'SpendPaymentMethodDetachResponse';
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  updatedInvoiceIdList?: Maybe<Array<Scalars['String']['output']>>;
};

export type SpendPaymentMethodInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  authorizedAt?: InputMaybe<Scalars['String']['input']>;
  discountAmounts?: InputMaybe<Array<SpendInvoiceDiscountInput>>;
  hasApprovedAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  inviteId?: InputMaybe<Scalars['String']['input']>;
  invoiceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isAutoPayAuthorized?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodSource?: InputMaybe<Scalars['String']['input']>;
  paymentMethodTiming?: InputMaybe<Scalars['String']['input']>;
};

export type SpendPaymentMethodResponse = {
  __typename?: 'SpendPaymentMethodResponse';
  groupRosterSetting?: Maybe<SpendGroupRosterSettingsResponse>;
  payment?: Maybe<SpendPayNowResponse>;
  updatedInvoiceIds: Array<Maybe<Scalars['String']['output']>>;
};

export type SpendPaymentSchedule = {
  __typename?: 'SpendPaymentSchedule';
  amountDue?: Maybe<Scalars['Int']['output']>;
  budgetItem?: Maybe<SpendBudget>;
  budgetItemId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  group?: Maybe<SpendGroup>;
  groupId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isOptional?: Maybe<Scalars['Boolean']['output']>;
  lastPublishedState?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  season?: Maybe<SpendSeason>;
  seasonId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SpendPaymentScheduleBySeasonInput = {
  seasonId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type SpendPaymentScheduleId = {
  __typename?: 'SpendPaymentScheduleID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendPaymentScheduleInput = {
  amountDue?: InputMaybe<Scalars['Int']['input']>;
  budgetItemId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  isOptional?: InputMaybe<Scalars['Boolean']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type SpendPendingInvite = {
  __typename?: 'SpendPendingInvite';
  expiresAt: Scalars['String']['output'];
  groupId?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDismissed: Scalars['Boolean']['output'];
  organizationId: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  rosterName?: Maybe<Scalars['String']['output']>;
  seasonId?: Maybe<Scalars['String']['output']>;
  seasonName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type SpendRawFileInput = {
  id: Scalars['String']['input'];
  type: RawFileTypeEnum;
};

export type SpendReconcileTransactionId = {
  __typename?: 'SpendReconcileTransactionID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendReconciledBudget = {
  __typename?: 'SpendReconciledBudget';
  amount: Scalars['Int']['output'];
  budgetId: Scalars['String']['output'];
  budgetName: Scalars['String']['output'];
  categoryId: Scalars['String']['output'];
  categoryName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  groupId?: Maybe<Scalars['String']['output']>;
  groupName: Scalars['String']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
  playerName: Scalars['String']['output'];
  seasonId: Scalars['String']['output'];
  seasonName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SpendReconciledBudgetTransaction = {
  __typename?: 'SpendReconciledBudgetTransaction';
  amount?: Maybe<Scalars['Int']['output']>;
  budgetItemId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoiceId?: Maybe<Scalars['String']['output']>;
  reconciledTransaction?: Maybe<SpendReconciledTransaction>;
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type SpendReconciledInvoiceTransaction = {
  __typename?: 'SpendReconciledInvoiceTransaction';
  amount?: Maybe<Scalars['Int']['output']>;
  creditMemos?: Maybe<Array<Maybe<SpendMemo>>>;
  id?: Maybe<Scalars['String']['output']>;
  invoiceId?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  reconciledTransaction?: Maybe<SpendReconciledTransaction>;
  rosterId?: Maybe<Scalars['String']['output']>;
  rosterName?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
};

export type SpendReconciledTotals = {
  __typename?: 'SpendReconciledTotals';
  budgets?: Maybe<Array<SpendBudgetReconciledTotal>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SpendReconciledTransaction = {
  __typename?: 'SpendReconciledTransaction';
  amount?: Maybe<Scalars['Int']['output']>;
  budgetTransactions?: Maybe<Array<Maybe<SpendReconciledBudgetTransaction>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoiceTransactions?: Maybe<Array<Maybe<SpendReconciledInvoiceTransaction>>>;
  legacyTransaction?: Maybe<SpendLegacyTransaction>;
  paymentId?: Maybe<Scalars['String']['output']>;
  reconciledTo?: Maybe<Array<Maybe<SpendReconciledBudget>>>;
  transaction?: Maybe<SpendBankTransaction>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type SpendRemoveSeasonMemberInput = {
  groupRosterIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SpendRemoveSeasonMemberResponse = {
  __typename?: 'SpendRemoveSeasonMemberResponse';
  success: Scalars['Boolean']['output'];
};

export type SpendRole = {
  __typename?: 'SpendRole';
  groupId?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isApprover?: Maybe<Scalars['Boolean']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isNotSignedUp?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  permissions: Array<Maybe<Scalars['String']['output']>>;
  seasonId?: Maybe<Scalars['String']['output']>;
};

export type SpendRoleId = {
  __typename?: 'SpendRoleID';
  id?: Maybe<Scalars['String']['output']>;
};

export enum SpendRoleNameEnum {
  GroupObserver = 'group_observer',
  GroupStaff = 'group_staff',
  Guardian = 'guardian',
  ProgramObserver = 'program_observer',
  ProgramStaff = 'program_staff'
}

export type SpendRoleResponse = {
  __typename?: 'SpendRoleResponse';
  count?: Maybe<Scalars['Int']['output']>;
  roles?: Maybe<Array<Maybe<SpendRole>>>;
};

export type SpendRoster = {
  __typename?: 'SpendRoster';
  email?: Maybe<Scalars['String']['output']>;
  groupRosters?: Maybe<Array<Maybe<SpendGroupRoster>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  total?: Maybe<SpendTransactionTotals>;
};

export type SpendRosterResponse = {
  __typename?: 'SpendRosterResponse';
  count?: Maybe<Scalars['Int']['output']>;
  rosters?: Maybe<Array<Maybe<SpendRoster>>>;
};

export type SpendRosterUpdate = {
  __typename?: 'SpendRosterUpdate';
  id?: Maybe<Scalars['String']['output']>;
  invitesUpdated?: Maybe<Array<Scalars['String']['output']>>;
};

export type SpendRosterUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SpendRosterUserUpdate = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rosterId: Scalars['String']['input'];
};

export type SpendRostersFilter = {
  seasonId?: InputMaybe<Scalars['String']['input']>;
};

export type SpendSeason = {
  __typename?: 'SpendSeason';
  budgets?: Maybe<Array<Maybe<SpendBudget>>>;
  endDateAt?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  groupRoster?: Maybe<Array<Maybe<SpendGroupRoster>>>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBudgetShared?: Maybe<Scalars['Boolean']['output']>;
  isLinkEnabled?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentScheduleInvoices?: Maybe<Array<Maybe<SpendPaymentSchedule>>>;
  paymentScheduleStatus?: Maybe<Scalars['String']['output']>;
  playerCount?: Maybe<Scalars['Int']['output']>;
  startDateAt?: Maybe<Scalars['String']['output']>;
  transactionTotals?: Maybe<SpendTransactionTotals>;
};

export type SpendSeasonId = {
  __typename?: 'SpendSeasonID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendSeasonInput = {
  copyBudgetItems?: InputMaybe<Scalars['Boolean']['input']>;
  copyPaymentSchedule?: InputMaybe<Scalars['Boolean']['input']>;
  endDateAt?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  isBudgetShared?: InputMaybe<Scalars['Boolean']['input']>;
  isLinkEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentScheduleStatus?: InputMaybe<PaymentScheduleStatus>;
  startDateAt?: InputMaybe<Scalars['String']['input']>;
};

export type SpendSession = {
  __typename?: 'SpendSession';
  expiresAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  inviteId?: Maybe<Scalars['String']['output']>;
  isDismissed?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  newInvite?: Maybe<Scalars['Boolean']['output']>;
  pendingInvites?: Maybe<Array<SpendPendingInvite>>;
  role?: Maybe<SpendRole>;
  status?: Maybe<Scalars['String']['output']>;
  systemNotifications?: Maybe<Array<SpendSystemNotification>>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type SpendSessionId = {
  __typename?: 'SpendSessionID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendSettings = {
  __typename?: 'SpendSettings';
  approversRequired?: Maybe<Scalars['Int']['output']>;
  debitCardApproval?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated duplicate of SpendOrganization.groupBanksEnabled */
  enableGroupBanks?: Maybe<Scalars['Boolean']['output']>;
  enableProgramAgreements?: Maybe<Scalars['Boolean']['output']>;
  externalTransferApproval?: Maybe<Scalars['Boolean']['output']>;
  externalTransferLimit?: Maybe<Scalars['Int']['output']>;
  fileUploadEnabled?: Maybe<Scalars['Boolean']['output']>;
  internalTransferApproval?: Maybe<Scalars['Boolean']['output']>;
  internalTransferLimit?: Maybe<Scalars['Int']['output']>;
  notifyBankActivityAdmins?: Maybe<Scalars['Boolean']['output']>;
  notifyDueFrequencies?: Maybe<Array<Scalars['String']['output']>>;
  notifyFailedAchAdmins?: Maybe<Scalars['Boolean']['output']>;
  notifyPastDueNonUsers?: Maybe<Scalars['Boolean']['output']>;
  notifyUpcomingNonUsers?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated replaced by notifyDueFrequencies */
  pastDueFrequency?: Maybe<Scalars['Int']['output']>;
  sendAchApproval?: Maybe<Scalars['Boolean']['output']>;
  sendAchLimit?: Maybe<Scalars['Int']['output']>;
  sendCheckApproval?: Maybe<Scalars['Boolean']['output']>;
  sendCheckLimit?: Maybe<Scalars['Int']['output']>;
  signUpAgreement?: Maybe<SignupAgreement>;
  signUpLink?: Maybe<Scalars['String']['output']>;
};

export type SpendSettingsInput = {
  approversRequired?: InputMaybe<Scalars['Int']['input']>;
  debitCardApproval?: InputMaybe<Scalars['Boolean']['input']>;
  enableGroupBanks?: InputMaybe<Scalars['Boolean']['input']>;
  enableProgramAgreements?: InputMaybe<Scalars['Boolean']['input']>;
  externalTransferApproval?: InputMaybe<Scalars['Boolean']['input']>;
  externalTransferLimit?: InputMaybe<Scalars['Int']['input']>;
  internalTransferApproval?: InputMaybe<Scalars['Boolean']['input']>;
  internalTransferLimit?: InputMaybe<Scalars['Int']['input']>;
  notifyBankActivityAdmins?: InputMaybe<Scalars['Boolean']['input']>;
  notifyDueFrequencies?: InputMaybe<Array<Scalars['String']['input']>>;
  notifyFailedAchAdmins?: InputMaybe<Scalars['Boolean']['input']>;
  notifyPastDueNonUsers?: InputMaybe<Scalars['Boolean']['input']>;
  notifyUpcomingNonUsers?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated: pastDueFrequency is replaced with notifyDueFrequencies */
  pastDueFrequency?: InputMaybe<Scalars['Int']['input']>;
  sendAchApproval?: InputMaybe<Scalars['Boolean']['input']>;
  sendAchLimit?: InputMaybe<Scalars['Int']['input']>;
  sendCheckApproval?: InputMaybe<Scalars['Boolean']['input']>;
  sendCheckLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type SpendSignUpInput = {
  groupId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  participantName: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
};

export type SpendSignUpResponse = {
  __typename?: 'SpendSignUpResponse';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendSignupAgreementInput = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SpendSignupFormResponse = {
  __typename?: 'SpendSignupFormResponse';
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SpendSortInput = {
  field: Scalars['String']['input'];
  order?: InputMaybe<SpendSortOrderEnum>;
};

export enum SpendSortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SpendStopCheckPaymentResponse = {
  __typename?: 'SpendStopCheckPaymentResponse';
  id: Scalars['String']['output'];
};

export type SpendSystemNotification = {
  __typename?: 'SpendSystemNotification';
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  groupStaff?: Maybe<Scalars['Boolean']['output']>;
  guardian?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  programAdmin?: Maybe<Scalars['Boolean']['output']>;
  programStaff?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<SystemNotificationStatus>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SpendSystemNotificationCreateInput = {
  description: Scalars['String']['input'];
  groupStaff: Scalars['Boolean']['input'];
  guardian: Scalars['Boolean']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  programAdmin: Scalars['Boolean']['input'];
  programStaff: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

export type SpendSystemNotificationIdResponse = {
  __typename?: 'SpendSystemNotificationIdResponse';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendSystemNotificationUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupStaff?: InputMaybe<Scalars['Boolean']['input']>;
  guardian?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  programAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  programStaff?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<SystemNotificationStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type SpendSystemNotificationsResponse = {
  __typename?: 'SpendSystemNotificationsResponse';
  systemNotifications?: Maybe<Array<SpendSystemNotification>>;
};

export type SpendTransaction = {
  __typename?: 'SpendTransaction';
  externalId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoiceId?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type SpendTransactionAttachmentId = {
  __typename?: 'SpendTransactionAttachmentID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendTransactionAttachmentInput = {
  /** Base-64 encoded file */
  content: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  /** Filename with extension */
  name: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
};

export type SpendTransactionBudgetUnreconcileInput = {
  reconciledBudgetTransactionIds: Array<Scalars['String']['input']>;
  reconciledTransactionId: Scalars['String']['input'];
};

export type SpendTransactionFilter = {
  by: TransactionReportFilterEnum;
  value: Scalars['String']['input'];
};

export type SpendTransactionInput = {
  authorizedAt?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
  discountAmounts?: InputMaybe<Array<SpendInvoiceDiscountInput>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  externalPaymentId?: InputMaybe<Scalars['String']['input']>;
  invoiceIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type SpendTransactionInvoiceUnreconcileInput = {
  reconciledInvoiceTransactionIds: Array<Scalars['String']['input']>;
  reconciledTransactionId: Scalars['String']['input'];
};

export type SpendTransactionNoteId = {
  __typename?: 'SpendTransactionNoteID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendTransactionReconcileInput = {
  budgets?: InputMaybe<Array<ReconcileBudget>>;
  invoices?: InputMaybe<Array<ReconcileInvoice>>;
  ledgerTransactionAmount: Scalars['Int']['input'];
  ledgerTransactionId: Scalars['String']['input'];
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpendTransactionReconcileV2Input = {
  budgets?: InputMaybe<Array<ReconcileBudget>>;
  invoices?: InputMaybe<Array<ReconcileInvoiceWithBudget>>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  ledgerTransactionAmount: Scalars['Int']['input'];
  ledgerTransactionId: Scalars['String']['input'];
  legacyTarget?: InputMaybe<Scalars['String']['input']>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpendTransactionResponse = {
  __typename?: 'SpendTransactionResponse';
  count?: Maybe<Scalars['Int']['output']>;
  transactions?: Maybe<Array<Maybe<SpendTransaction>>>;
};

export type SpendTransactionTags = {
  __typename?: 'SpendTransactionTags';
  achPaymentAmount?: Maybe<Scalars['String']['output']>;
  achPaymentId?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['String']['output']>;
  attachmentKey?: Maybe<Scalars['String']['output']>;
  disputeId?: Maybe<Scalars['String']['output']>;
  disputeTransferId?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['String']['output']>;
  spendAutoPay?: Maybe<Scalars['Boolean']['output']>;
  spendDestinationId?: Maybe<Scalars['String']['output']>;
  spendDisputeId?: Maybe<Scalars['String']['output']>;
  spendDisputedChargeId?: Maybe<Scalars['String']['output']>;
  spendExternalId?: Maybe<Scalars['String']['output']>;
  spendGroupRosterId?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer used due to being too long */
  spendInvoiceIds?: Maybe<Scalars['String']['output']>;
  spendOriginalPaymentId?: Maybe<Scalars['String']['output']>;
  spendPaymentType?: Maybe<Scalars['String']['output']>;
  spendSourceId?: Maybe<Scalars['String']['output']>;
  spendUserId?: Maybe<Scalars['String']['output']>;
  stopPaymentIssued?: Maybe<Scalars['String']['output']>;
  submittedBy?: Maybe<Scalars['String']['output']>;
};

export type SpendTransactionTotals = {
  __typename?: 'SpendTransactionTotals';
  credited?: Maybe<Scalars['Int']['output']>;
  dueToday?: Maybe<Scalars['Int']['output']>;
  paid?: Maybe<Scalars['Int']['output']>;
  pastDue?: Maybe<Scalars['Int']['output']>;
  pastDueDays?: Maybe<Scalars['Int']['output']>;
  processing?: Maybe<Scalars['Int']['output']>;
  statuses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  upcoming?: Maybe<Scalars['Int']['output']>;
};

export type SpendTransactionWhereInput = {
  dateAfter?: InputMaybe<Scalars['String']['input']>;
  dateBefore?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<ExternalTransferDirectionEnum>;
  groupIdOrgId: Scalars['String']['input'];
  hasAttachment?: InputMaybe<Scalars['Boolean']['input']>;
  method?: InputMaybe<Array<Scalars['String']['input']>>;
  nameIncludes?: InputMaybe<Scalars['String']['input']>;
  reconciled?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SpendTransactionsIdList = {
  __typename?: 'SpendTransactionsIdList';
  transactionIdList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SpendTranscationNoteInput = {
  content: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
};

export type SpendUnitApplicationResponse = {
  __typename?: 'SpendUnitApplicationResponse';
  url?: Maybe<Scalars['String']['output']>;
};

export type SpendUnreconciledSummary = {
  __typename?: 'SpendUnreconciledSummary';
  credits?: Maybe<TransactionTotalCount>;
  debits?: Maybe<TransactionTotalCount>;
};

export type SpendUpdateInvoices = {
  invoices?: InputMaybe<Array<UpdateInvoices>>;
};

export type SpendUpdateResponse = {
  __typename?: 'SpendUpdateResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SpendUpsertCategoryInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  isDefault: Scalars['Boolean']['input'];
  isHidden: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  type: CategoryTypeEnum;
};

export type SpendUserAccountUpdateInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  prevEmail: Scalars['String']['input'];
  udid: Scalars['String']['input'];
};

export type SpendUserAccountUpdateResponse = {
  __typename?: 'SpendUserAccountUpdateResponse';
  invitesUpdatedCount?: Maybe<Scalars['Int']['output']>;
  rostersUpdatedCount?: Maybe<Scalars['Int']['output']>;
  unitAccountUpdated?: Maybe<Scalars['Boolean']['output']>;
};

export type SpendUserAcknowledgeNotificationInput = {
  notificationId: Scalars['String']['input'];
};

export type SpendUserAcknowledgeNotificationResponse = {
  __typename?: 'SpendUserAcknowledgeNotificationResponse';
  success: Scalars['Boolean']['output'];
};

export type SpendUserEmailUpdateInput = {
  newEmail: Scalars['String']['input'];
  oldEmail: Scalars['String']['input'];
};

export type SpendUserEmailUpdateResponse = {
  __typename?: 'SpendUserEmailUpdateResponse';
  invitesUpdatedCount?: Maybe<Scalars['Int']['output']>;
  rostersUpdatedCount?: Maybe<Scalars['Int']['output']>;
};

export type SpendUserId = {
  __typename?: 'SpendUserID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendUserLoginAuditInput = {
  action: Scalars['String']['input'];
  isImpersonated: Scalars['Boolean']['input'];
  udId: Scalars['String']['input'];
};

export type SpendUserLoginAuditInputV2 = {
  action: Scalars['String']['input'];
  impersonatedBy?: InputMaybe<Scalars['String']['input']>;
  udId: Scalars['String']['input'];
};

export type SpendUserLoginAuditResponse = {
  __typename?: 'SpendUserLoginAuditResponse';
  id: Scalars['String']['output'];
};

export type SpendUserNotificationSetting = {
  __typename?: 'SpendUserNotificationSetting';
  copyPastDueInvoices: Scalars['Boolean']['output'];
  groupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  notifyOnBankActivity: Scalars['Boolean']['output'];
  notifyOnFailedCardPayments: Scalars['Boolean']['output'];
  notifyOnInvoicePayment: Scalars['Boolean']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  roleName?: Maybe<Scalars['String']['output']>;
  userRoleId: Scalars['String']['output'];
};

export type SpendUserNotificationSettingId = {
  __typename?: 'SpendUserNotificationSettingID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendUserNotificationSettingInput = {
  copyPastDueInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  notifyOnBankActivity?: InputMaybe<Scalars['Boolean']['input']>;
  notifyOnFailedCardPayments?: InputMaybe<Scalars['Boolean']['input']>;
  notifyOnInvoicePayment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpendUserNotificationSettingResponse = {
  __typename?: 'SpendUserNotificationSettingResponse';
  notificationSetting?: Maybe<SpendUserNotificationSetting>;
};

export type SpendUserRoleId = {
  __typename?: 'SpendUserRoleID';
  id?: Maybe<Scalars['String']['output']>;
};

export type SpendUserSignupInput = {
  groupId: Scalars['String']['input'];
  inviteId?: InputMaybe<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type SpendVerificationTokenResponse = {
  __typename?: 'SpendVerificationTokenResponse';
  verificationToken?: Maybe<Scalars['String']['output']>;
};

export type SpendWireLimits = {
  __typename?: 'SpendWireLimits';
  dailyTransfer: Scalars['Int']['output'];
  dailyTransferSoft: Scalars['Int']['output'];
  monthlyTransfer: Scalars['Int']['output'];
  monthlyTransferSoft: Scalars['Int']['output'];
};

export type SpendWireLimitsData = {
  __typename?: 'SpendWireLimitsData';
  limits?: Maybe<SpendWireLimits>;
};

export type Sport = {
  __typename?: 'Sport';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  hasSelectWinner?: Maybe<Scalars['Boolean']['output']>;
  hasTies?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lowScoreWin?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type Standing = {
  __typename?: 'Standing';
  division?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  played?: Maybe<Scalars['String']['output']>;
  record?: Maybe<Scalars['String']['output']>;
  winPercentage?: Maybe<Scalars['String']['output']>;
};

export enum StateCode {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Um = 'UM',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export enum Status {
  Canceled = 'Canceled',
  Clearing = 'Clearing',
  Pending = 'PENDING',
  PendingReview = 'PendingReview',
  Rejected = 'Rejected',
  Returned = 'Returned',
  Settled = 'SETTLED'
}

export type Store = {
  __typename?: 'Store';
  code?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  faviconUrl?: Maybe<Scalars['String']['output']>;
  headerLogoUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type StoreBaseFilterInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  searchValue?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreBaseSellerUnionType = OnException | StoreScopeBestSeller;

export type StoreBestSeller = {
  __typename?: 'StoreBestSeller';
  productId?: Maybe<Scalars['Int']['output']>;
  productImage?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  productPrice?: Maybe<Scalars['Float']['output']>;
  productQuantity?: Maybe<Scalars['Int']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
};

export type StoreBrands = {
  __typename?: 'StoreBrands';
  brands?: Maybe<Array<Maybe<Brand>>>;
};

export type StoreBrandsUnionType = OnException | StoreBrands;

export type StoreBuildRequest = {
  __typename?: 'StoreBuildRequest';
  email?: Maybe<Scalars['String']['output']>;
  referenceTicket?: Maybe<Scalars['Int']['output']>;
  storeName?: Maybe<Scalars['String']['output']>;
  storeRequest?: Maybe<Scalars['Boolean']['output']>;
  userSsoId?: Maybe<Scalars['String']['output']>;
};

export type StoreBuildRequestUnionType = OnException | StoreBuildRequest;

export type StoreBuildRequester = {
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type StoreByCampaignIdsInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  campaignIds: Array<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderBySort?: InputMaybe<Scalars['String']['input']>;
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type StoreByGlEmailInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderBySort?: InputMaybe<Scalars['String']['input']>;
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type StoreCampaign = {
  __typename?: 'StoreCampaign';
  accountManagerId?: Maybe<Scalars['Int']['output']>;
  commissionEligibleSalesrepId?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['Int']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  gool?: Maybe<Scalars['Int']['output']>;
  groupLeaderEmail?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  initialGoalCents?: Maybe<Scalars['Int']['output']>;
  joinCode?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  originalSalesrepId?: Maybe<Scalars['Int']['output']>;
  salesrepId?: Maybe<Scalars['Int']['output']>;
  settlementConfirmationStatus?: Maybe<Scalars['String']['output']>;
  settlementMethod?: Maybe<Scalars['String']['output']>;
  settlementStatus?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  teamSize?: Maybe<Scalars['Int']['output']>;
  totalRaisedCents?: Maybe<Scalars['Int']['output']>;
  userDirectoryId?: Maybe<Scalars['String']['output']>;
};

export type StoreCampaignPayableInfoInput = {
  campaignId: Scalars['Int']['input'];
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoreCampaignPayableInfoUnionType = OnException | PayableInfo;

export type StoreColors = {
  __typename?: 'StoreColors';
  colors?: Maybe<Array<Maybe<ScopeProductColor>>>;
};

export type StoreColorsUnionType = OnException | StoreColors;

export type StoreCreatePaymentIntent = {
  amount: Scalars['Float']['input'];
  email: Scalars['String']['input'];
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  userSsoId: Scalars['String']['input'];
};

export type StoreCustomerInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type StoreEditInfo = {
  __typename?: 'StoreEditInfo';
  code?: Maybe<Scalars['String']['output']>;
  scopeId?: Maybe<Scalars['Int']['output']>;
};

export type StoreEditInfoInput = {
  activityType?: InputMaybe<Scalars['String']['input']>;
  brands?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  productColors?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  scopeId: Scalars['Int']['input'];
  storeCode: Scalars['String']['input'];
  storeName?: InputMaybe<Scalars['String']['input']>;
  themeColor?: InputMaybe<Scalars['String']['input']>;
};

export type StoreEditInfoUnionType = OnException | StoreEditInfo;

export type StoreFilterUnionType = OnException | StoresFilter;

export type StoreGlInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type StoreInput = {
  accountManager?: InputMaybe<Scalars['String']['input']>;
  accountManagerEmail?: InputMaybe<Scalars['String']['input']>;
  accountManagerUDID?: InputMaybe<Scalars['String']['input']>;
  activityType: Scalars['String']['input'];
  city: Scalars['String']['input'];
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
  groupLeader?: InputMaybe<Scalars['String']['input']>;
  groupLeaderEmail?: InputMaybe<Scalars['String']['input']>;
  groupLeaderUDID?: InputMaybe<Scalars['String']['input']>;
  groupName: Scalars['String']['input'];
  logoDigitalUrl: Scalars['String']['input'];
  logoEmbroideryUrl: Scalars['String']['input'];
  logoHatUrl: Scalars['String']['input'];
  /** Pair of primary colors in format #RRGGBB|#RRGGBB */
  logoPrimaryColor: Scalars['String']['input'];
  logoWebHeaderUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  organizationLegalName: Scalars['String']['input'];
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  pointsPercentage?: Scalars['Int']['input'];
  salesRep?: InputMaybe<Scalars['String']['input']>;
  salesRepEmail?: InputMaybe<Scalars['String']['input']>;
  salesRepUDID?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  storeCode?: InputMaybe<Scalars['String']['input']>;
  storeUrl: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type StoreManagerUpdatePoints = {
  managerEmail: Scalars['String']['input'];
  managerId: Scalars['Int']['input'];
  points: Scalars['Float']['input'];
  scopeId: Scalars['Int']['input'];
};

export type StoreManagerUpdatePointsUnionType = MagentoStoreManager | OnException;

export type StoreOrderItemImage = {
  __typename?: 'StoreOrderItemImage';
  imageUrl?: Maybe<Scalars['String']['output']>;
  itemId?: Maybe<Scalars['BigInt']['output']>;
  selectedLogo?: Maybe<Scalars['String']['output']>;
};

export type StoreOrderItems = {
  __typename?: 'StoreOrderItems';
  items?: Maybe<Array<Maybe<StoreOrderItemImage>>>;
};

export type StoreOrderItemsUnionType = OnException | StoreOrderItems;

export type StoreOrderResult = {
  __typename?: 'StoreOrderResult';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  netsuiteId?: Maybe<Scalars['String']['output']>;
  shippingReceivedAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<StoreOrderStatus>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type StoreOrderSalesGraphs = {
  __typename?: 'StoreOrderSalesGraphs';
  graphs?: Maybe<Array<Maybe<StoreOrderSalesGraph>>>;
};

export enum StoreOrderStatus {
  Closed = 'CLOSED',
  Created = 'CREATED',
  Design = 'DESIGN',
  Hold = 'HOLD',
  MarcoRejected = 'MARCO_REJECTED',
  Rejected = 'REJECTED',
  Shipped = 'SHIPPED'
}

export type StoreParticipant = {
  __typename?: 'StoreParticipant';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type StoreParticipantInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type StorePayableFilter = {
  __typename?: 'StorePayableFilter';
  pagination?: Maybe<SdPagination>;
  payables?: Maybe<Array<Maybe<PayableInfo>>>;
};

export type StorePayableFilterUnionType = OnException | StorePayableFilter;

export type StorePayableInfoUnionType = OnException | PayableInfo;

export type StorePaymantIntentUnionType = OnException | StorePaymentIntent;

export type StorePaymentIntent = {
  __typename?: 'StorePaymentIntent';
  clientSecret?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  userSsoId?: Maybe<Scalars['String']['output']>;
};

export type StorePointEarnedGraphInput = {
  interval?: InputMaybe<Scalars['Int']['input']>;
  managerId: Scalars['Int']['input'];
  scopeId: Scalars['Int']['input'];
};

export type StorePointsWithdrawalFilter = {
  __typename?: 'StorePointsWithdrawalFilter';
  pagination?: Maybe<SdPagination>;
  withdrawalRequests?: Maybe<Array<Maybe<StorePointsWithdrawalRequest>>>;
};

export type StorePointsWithdrawalFilterUnionType = OnException | StorePointsWithdrawalFilter;

export type StorePointsWithdrawalRequest = {
  __typename?: 'StorePointsWithdrawalRequest';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  expactedWithdrawalDate?: Maybe<Scalars['String']['output']>;
  payableInfo?: Maybe<PayableInfo>;
  pointsType?: Maybe<Scalars['String']['output']>;
  requesterInfo?: Maybe<StorePointsWithdrawalRequester>;
  scopeInfo?: Maybe<StoresWithdrawalPointsScope>;
  ticketId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type StorePointsWithdrawalRequestInput = {
  changeCampaignPayableDetails: Scalars['Boolean']['input'];
  payableDetails: StoreWithdrawalPointsPayableInput;
  payableType: Scalars['String']['input'];
  pointsType: Scalars['String']['input'];
  requester: StorePointsWithdrawalRequesterInput;
  scope: StoresWithdrawalPointsScopeInput;
  taxDocument?: InputMaybe<Scalars['String']['input']>;
  withdrawalPointsAmount: Scalars['Int']['input'];
};

export type StorePointsWithdrawalRequestUnionType = OnException | StorePointsWithdrawalRequest;

export type StorePointsWithdrawalRequester = {
  __typename?: 'StorePointsWithdrawalRequester';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userSsoId?: Maybe<Scalars['String']['output']>;
};

export type StorePointsWithdrawalRequesterInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  userSsoId: Scalars['String']['input'];
};

export type StoreProfitPercentageUpdated = {
  __typename?: 'StoreProfitPercentageUpdated';
  updated_at?: Maybe<Scalars['String']['output']>;
  updated_product_price_percentage?: Maybe<Scalars['String']['output']>;
};

export type StoreReceiverData = {
  __typename?: 'StoreReceiverData';
  email?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  receiverId?: Maybe<Scalars['BigInt']['output']>;
};

export type StoreResult = {
  __typename?: 'StoreResult';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status: StoreStatus;
};

export type StoreSaveTransaction = {
  amount: Scalars['Float']['input'];
  email: Scalars['String']['input'];
  points: Scalars['Float']['input'];
  scopeId: Scalars['Int']['input'];
  transactionInfo: Scalars['String']['input'];
  userSsoId: Scalars['String']['input'];
};

export type StoreScopeBestSeller = {
  __typename?: 'StoreScopeBestSeller';
  bestSellers?: Maybe<Array<Maybe<StoreBestSeller>>>;
};

export type StoreSender = {
  email: Scalars['String']['input'];
  scopeId: Scalars['Int']['input'];
  senderId: Scalars['Int']['input'];
};

export type StoreSenderData = {
  __typename?: 'StoreSenderData';
  email?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  senderId?: Maybe<Scalars['BigInt']['output']>;
};

export enum StoreStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export type StoreStatusInput = {
  reasonWhy?: InputMaybe<StoreStatusReasonWhy>;
  scopeId: Scalars['Int']['input'];
  status: Scalars['Boolean']['input'];
};

export type StoreStatusReasonWhy = {
  comment?: InputMaybe<Scalars['String']['input']>;
  commentOption?: InputMaybe<Scalars['String']['input']>;
};

export type StoreSubscribedUser = {
  __typename?: 'StoreSubscribedUser';
  isSubscribed?: Maybe<Scalars['Boolean']['output']>;
};

export type StoreSubscribedUserUnionType = OnException | StoreSubscribedUser;

export type StoreSummaryByCampaignIdsInput = {
  campaignIds: Array<Scalars['Int']['input']>;
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  timeline?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreTicket = {
  __typename?: 'StoreTicket';
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  scopeId?: Maybe<Scalars['Int']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  ticketId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type StoreTicketInput = {
  message: Scalars['String']['input'];
  requester: StoreTicketRequester;
  scopeId: Scalars['Int']['input'];
  storeCode: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type StoreTicketRequester = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type StoreTicketUnionType = OnException | StoreTickets;

export type StoreTickets = {
  __typename?: 'StoreTickets';
  tickets?: Maybe<Array<Maybe<StoreTicket>>>;
};

export type StoreTicketsUnionType = OnException | StoreTicket;

export type StoreTransaction = {
  __typename?: 'StoreTransaction';
  amount?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  scopeId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  userSsoId?: Maybe<Scalars['String']['output']>;
};

export type StoreTransactionUnionType = OnException | StoreTransaction;

export type StoreTransferCustomer = {
  customer?: InputMaybe<StoreCustomerInput>;
  points: Scalars['Float']['input'];
  sender?: InputMaybe<StoreSender>;
};

export type StoreTransferGl = {
  groupLeader?: InputMaybe<StoreGlInput>;
  points: Scalars['Float']['input'];
  sender?: InputMaybe<StoreSender>;
};

export type StoreTransferParticipant = {
  participant?: InputMaybe<StoreParticipantInput>;
  points: Scalars['Float']['input'];
  sender?: InputMaybe<StoreSender>;
};

export type StoreTransferPointsUnionType = OnException | StoreTransferReturn;

export type StoreTransferReturn = {
  __typename?: 'StoreTransferReturn';
  points?: Maybe<Scalars['Float']['output']>;
  receiver?: Maybe<StoreReceiverData>;
  sender?: Maybe<StoreSenderData>;
  status?: Maybe<Scalars['String']['output']>;
};

export type StoreUpdatePayableInput = {
  ein: Scalars['Int']['input'];
  fullAddressOne: Scalars['String']['input'];
  fullAddressTwo?: InputMaybe<Scalars['String']['input']>;
  payableName: Scalars['String']['input'];
};

export type StoreUser = {
  __typename?: 'StoreUser';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['BigInt']['output']>;
};

export type StoreUserCampaignUnionType = OnException | StoreUserCampaigns;

export type StoreUserCampaigns = {
  __typename?: 'StoreUserCampaigns';
  campaigns?: Maybe<Array<Maybe<StoreCampaign>>>;
  pagination?: Maybe<SdPagination>;
};

export type StoreUserCampaignsInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  demo?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderBySort?: InputMaybe<Scalars['String']['input']>;
  searchBy?: InputMaybe<Scalars['String']['input']>;
  searchValue?: InputMaybe<Scalars['ID']['input']>;
};

export type StoreUserInfoUnionType = OnException | StoreUser;

export type StoreUserParticipantUnionType = OnException | StoreUserParticipants;

export type StoreUserParticipants = {
  __typename?: 'StoreUserParticipants';
  pagination?: Maybe<SdPagination>;
  participants?: Maybe<Array<Maybe<StoreParticipant>>>;
};

export type StoreUserParticipantsInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  campaignIds: Array<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreWithdrawalPointsPayableInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  ein: Scalars['Int']['input'];
  fullAddressOne: Scalars['String']['input'];
  fullAddressTwo?: InputMaybe<Scalars['String']['input']>;
  identityId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  payableName: Scalars['String']['input'];
  region: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['Int']['input'];
};

export type StoresFilter = {
  __typename?: 'StoresFilter';
  pagination?: Maybe<SdPagination>;
  stores?: Maybe<Array<Maybe<MagentoStore>>>;
};

export type StoresSummary = {
  __typename?: 'StoresSummary';
  baseSales?: Maybe<Scalars['Float']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  orders?: Maybe<Scalars['BigInt']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  sales?: Maybe<Scalars['Float']['output']>;
  stores?: Maybe<Scalars['BigInt']['output']>;
};

export type StoresSummaryByGlEmailInput = {
  email: Scalars['String']['input'];
  scopeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  timeline?: InputMaybe<Scalars['Int']['input']>;
};

export type StoresSummaryUnionType = OnException | StoresSummary;

export type StoresWithdrawalPointsScope = {
  __typename?: 'StoresWithdrawalPointsScope';
  managerEmail?: Maybe<Scalars['String']['output']>;
  managerId?: Maybe<Scalars['Int']['output']>;
  scopeCode?: Maybe<Scalars['String']['output']>;
  scopeId?: Maybe<Scalars['Int']['output']>;
  teamName?: Maybe<Scalars['String']['output']>;
};

export type StoresWithdrawalPointsScopeInput = {
  scopeCode: Scalars['String']['input'];
  scopeId: Scalars['Int']['input'];
  teamName: Scalars['String']['input'];
};

/** Filtering options for nullable String type */
export type StringNullableFilter = {
  /** Contains operation */
  contains?: InputMaybe<Scalars['String']['input']>;
  /** Ends With operation */
  endsWith?: InputMaybe<Scalars['String']['input']>;
  /** Equals operation */
  equals?: InputMaybe<Scalars['String']['input']>;
  /** Greater Than operation */
  gt?: InputMaybe<Scalars['String']['input']>;
  /** Greater Than or Equal operation */
  gte?: InputMaybe<Scalars['String']['input']>;
  /** In operation for multiple values */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Less Than operation */
  lt?: InputMaybe<Scalars['String']['input']>;
  /** Less Than or Equal operation */
  lte?: InputMaybe<Scalars['String']['input']>;
  /** Not Equal operation */
  not?: InputMaybe<Scalars['String']['input']>;
  /** Not In operation for multiple values */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Starts With operation */
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  accountId: Scalars['String']['output'];
  accountType?: Maybe<AccountType>;
  campaignId: Scalars['String']['output'];
};

export enum StripeEnv {
  Raise = 'RAISE',
  Spend = 'SPEND',
  Sponsor = 'SPONSOR'
}

/** metadata passed to Stripe API */
export type StripeMetadata = {
  __typename?: 'StripeMetadata';
  activityType?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  fundraiserId?: Maybe<Scalars['String']['output']>;
  fundraiserId_legacy?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
};

/** metadata to be passed to Stripe API */
export type StripeMetadataInput = {
  activityType?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  fundraiserId?: InputMaybe<Scalars['String']['input']>;
  fundraiserId_legacy?: InputMaybe<Scalars['String']['input']>;
  paymentType?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type Summary = {
  __typename?: 'Summary';
  date?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  record?: Maybe<Scalars['String']['output']>;
  schoolId?: Maybe<Scalars['String']['output']>;
  schoolName?: Maybe<Scalars['String']['output']>;
  team1?: Maybe<School>;
  team1Name?: Maybe<Scalars['String']['output']>;
  team1Score?: Maybe<Scalars['String']['output']>;
  team2?: Maybe<School>;
  team2Name?: Maybe<Scalars['String']['output']>;
  team2Score?: Maybe<Scalars['String']['output']>;
};

export enum SupportedEmailProviders {
  Sendgrid = 'sendgrid',
  Test = 'test'
}

export enum SupportedSmsProviders {
  Test = 'test',
  Twilio = 'twilio'
}

/** See CampaignMembership for details */
export type SupporterCampaign = {
  __typename?: 'SupporterCampaign';
  id: Scalars['ID']['output'];
};

export enum SystemNotificationStatus {
  Draft = 'draft',
  Published = 'published'
}

export type Team = {
  __typename?: 'Team';
  facility?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  groupval?: Maybe<Scalars['String']['output']>;
  home_field?: Maybe<Scalars['String']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  level1?: Maybe<Scalars['String']['output']>;
  sport_code: Scalars['String']['output'];
  sport_description?: Maybe<Scalars['String']['output']>;
  sport_name: Scalars['String']['output'];
};

export enum TeamAge {
  Adult = 'ADULT',
  Preteen = 'PRETEEN',
  Teen = 'TEEN',
  Youth = 'YOUTH'
}

export type TeamByCodeInput = {
  sport_code: Scalars['String']['input'];
};

export type TeamCreateInput = {
  facility?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  groupval?: InputMaybe<Scalars['String']['input']>;
  home_field?: InputMaybe<Scalars['String']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  level1?: InputMaybe<Scalars['String']['input']>;
  sport_code?: InputMaybe<Scalars['String']['input']>;
  sport_description?: InputMaybe<Scalars['String']['input']>;
  sport_name?: InputMaybe<Scalars['String']['input']>;
};

export type TeamDeleteInput = {
  sport_code: Scalars['String']['input'];
};

export enum TeamGender {
  Coed = 'COED',
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum TeamGroupType {
  Club = 'CLUB',
  Sport = 'SPORT'
}

export type TeamLeadership = {
  __typename?: 'TeamLeadership';
  org: Org;
  teams: Array<TeamWithTitle>;
};

export type TeamModifyInput = {
  facility?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  groupval?: InputMaybe<Scalars['String']['input']>;
  home_field?: InputMaybe<Scalars['String']['input']>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  level1?: InputMaybe<Scalars['String']['input']>;
  sport_code?: InputMaybe<Scalars['String']['input']>;
  sport_description?: InputMaybe<Scalars['String']['input']>;
  sport_name?: InputMaybe<Scalars['String']['input']>;
};

export type TeamOfficialCreateInput = {
  id: Scalars['Int']['input'];
  pay?: InputMaybe<Scalars['Float']['input']>;
  pay_code?: InputMaybe<Scalars['String']['input']>;
  sport: Scalars['String']['input'];
  worker_duty?: InputMaybe<Scalars['String']['input']>;
};

export type TeamOfficialDeleteInput = {
  id: Scalars['Int']['input'];
};

export type TeamOfficialModifyInput = {
  id: Scalars['Int']['input'];
  pay?: InputMaybe<Scalars['Float']['input']>;
  pay_code?: InputMaybe<Scalars['String']['input']>;
  worker_duty?: InputMaybe<Scalars['String']['input']>;
};

export type TeamPreparationCreateInput = {
  id: Scalars['Int']['input'];
  prep: Scalars['Int']['input'];
  qty?: InputMaybe<Scalars['String']['input']>;
  sport: Scalars['String']['input'];
};

export type TeamPreparationDeleteInput = {
  id: Scalars['Int']['input'];
};

export type TeamPreparationModifyInput = {
  id: Scalars['Int']['input'];
  prep?: InputMaybe<Scalars['Int']['input']>;
  qty?: InputMaybe<Scalars['String']['input']>;
};

export enum TeamTier {
  First = 'FIRST',
  Freshman = 'FRESHMAN',
  FullProgram = 'FULL_PROGRAM',
  JuniorVarsity = 'JUNIOR_VARSITY',
  JuniorVarsityC = 'JUNIOR_VARSITY_C',
  Second = 'SECOND',
  Varsity = 'VARSITY'
}

export enum TeamTitle {
  AssistantCoach = 'ASSISTANT_COACH',
  BoosterClubLeader = 'BOOSTER_CLUB_LEADER',
  BoosterClubMember = 'BOOSTER_CLUB_MEMBER',
  ClubSportsAdministrator = 'CLUB_SPORTS_ADMINISTRATOR',
  ClubSportsDirector = 'CLUB_SPORTS_DIRECTOR',
  ClubSportsEmployee = 'CLUB_SPORTS_EMPLOYEE',
  Coach = 'COACH',
  FineArtsDirector = 'FINE_ARTS_DIRECTOR',
  SchoolClubAdvisor = 'SCHOOL_CLUB_ADVISOR',
  TeacherInstructor = 'TEACHER_INSTRUCTOR'
}

export type TeamWithTitle = {
  __typename?: 'TeamWithTitle';
  team: Org;
  titles: Array<Leader>;
};

export type TeamWorkerCreateInput = {
  home_field?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  pay?: InputMaybe<Scalars['Float']['input']>;
  pay_code?: InputMaybe<Scalars['String']['input']>;
  sport: Scalars['String']['input'];
  worker_duty?: InputMaybe<Scalars['String']['input']>;
  worker_name?: InputMaybe<Scalars['String']['input']>;
};

export type TeamWorkerDeleteInput = {
  id: Scalars['Int']['input'];
};

export type TeamWorkerModifyInput = {
  home_field?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  pay?: InputMaybe<Scalars['Float']['input']>;
  pay_code?: InputMaybe<Scalars['String']['input']>;
  worker_duty?: InputMaybe<Scalars['String']['input']>;
  worker_name?: InputMaybe<Scalars['String']['input']>;
};

export type TemplateOfficial = {
  __typename?: 'TemplateOfficial';
  id: Scalars['Int']['output'];
  pay?: Maybe<Scalars['Float']['output']>;
  pay_code?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  worker_duty?: Maybe<Scalars['String']['output']>;
};

export type TemplatePreparation = {
  __typename?: 'TemplatePreparation';
  id: Scalars['Int']['output'];
  prep?: Maybe<Scalars['Int']['output']>;
  preparation_id?: Maybe<Scalars['String']['output']>;
  preparation_name?: Maybe<Scalars['String']['output']>;
  qty?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
};

export type TemplateWorker = {
  __typename?: 'TemplateWorker';
  home_field?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  pay?: Maybe<Scalars['Float']['output']>;
  pay_code?: Maybe<Scalars['String']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  worker_duty?: Maybe<Scalars['String']['output']>;
  worker_name?: Maybe<Scalars['String']['output']>;
};

export type Tokens = {
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type TopDonation = {
  __typename?: 'TopDonation';
  donorName?: Maybe<Scalars['String']['output']>;
  subtotalCents?: Maybe<Scalars['Float']['output']>;
};

export type Total = {
  __typename?: 'Total';
  salary?: Maybe<Scalars['String']['output']>;
  woker_name?: Maybe<Scalars['String']['output']>;
};

export type TotalDonationsRaised = {
  __typename?: 'TotalDonationsRaised';
  numberOfDonations?: Maybe<Scalars['Int']['output']>;
  subtotalCents?: Maybe<Scalars['Float']['output']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount?: Maybe<Scalars['Decimal']['output']>;
  correlationId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  effective: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  processor?: Maybe<Scalars['String']['output']>;
  snapAmount?: Maybe<Scalars['Decimal']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type TransactionAttachment = {
  __typename?: 'TransactionAttachment';
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isLegacy?: Maybe<Scalars['Boolean']['output']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TransactionAttachmentsResponse = {
  __typename?: 'TransactionAttachmentsResponse';
  attachments?: Maybe<Array<Maybe<TransactionAttachment>>>;
};

export type TransactionDetail = {
  __typename?: 'TransactionDetail';
  amount?: Maybe<Scalars['Decimal']['output']>;
  correlationId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  destination?: Maybe<Scalars['String']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  effective: Scalars['Timestamp']['output'];
  externalId: Scalars['String']['output'];
  history: Array<Maybe<TransactionHistoryEvent>>;
  id: Scalars['UUID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  processor?: Maybe<Scalars['String']['output']>;
  snapAmount?: Maybe<Scalars['Decimal']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  status: Status;
  type?: Maybe<Scalars['String']['output']>;
};

export enum TransactionFilterEnum {
  Date = 'date',
  GroupName = 'groupName',
  Method = 'method',
  ReconciledStatus = 'reconciledStatus',
  SeasonName = 'seasonName',
  Status = 'status',
  Type = 'type'
}

export enum TransactionFilterType {
  ApplicationFee = 'ApplicationFee',
  Transfer = 'Transfer',
  UserPayment = 'UserPayment'
}

export type TransactionHistoryEvent = {
  __typename?: 'TransactionHistoryEvent';
  date: Scalars['Timestamp']['output'];
  status: Scalars['String']['output'];
  transactionId: Scalars['UUID']['output'];
};

export type TransactionInput = {
  emailOverride?: InputMaybe<Scalars['String']['input']>;
  /** It's either going to be a donationId or a purchaseId */
  transactionId: Scalars['ID']['input'];
  transactionType: TransactionType;
};

export type TransactionNotes = {
  __typename?: 'TransactionNotes';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastUpdatedByUserId?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type TransactionNotesResponse = {
  __typename?: 'TransactionNotesResponse';
  notes?: Maybe<Array<Maybe<TransactionNotes>>>;
};

export enum TransactionReportFilterEnum {
  DateAfter = 'dateAfter',
  DateBefore = 'dateBefore',
  GroupIdOrgId = 'groupIdOrgId',
  Method = 'method',
  NameIncludes = 'nameIncludes',
  Status = 'status',
  Type = 'type'
}

export type TransactionTotalCount = {
  __typename?: 'TransactionTotalCount';
  count?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum TransactionType {
  Donation = 'Donation',
  Purchase = 'Purchase'
}

export type TransactionsInput = {
  createdAfter?: InputMaybe<Scalars['Timestamp']['input']>;
  createdBefore?: InputMaybe<Scalars['Timestamp']['input']>;
  destination?: InputMaybe<Scalars['String']['input']>;
  exclude?: InputMaybe<Array<InputMaybe<TransactionFilterType>>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
};

export type TransformedDailyCalendarBusSchedule = {
  __typename?: 'TransformedDailyCalendarBusSchedule';
  calendar?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  events?: Maybe<Array<Maybe<DailyCalendarBusScheduleEvents>>>;
  exportdata?: Maybe<Array<Maybe<DailyCalendarBusScheduleExportData>>>;
};

export type TransformedDailyCalendarEventReturn = {
  __typename?: 'TransformedDailyCalendarEventReturn';
  activity?: Maybe<Scalars['String']['output']>;
  activityLevel?: Maybe<Scalars['String']['output']>;
  activityType?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  bus_fee?: Maybe<Scalars['Int']['output']>;
  bus_time?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  conference?: Maybe<Scalars['String']['output']>;
  conference_event_id?: Maybe<Scalars['Int']['output']>;
  conference_id?: Maybe<Scalars['Int']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  confirmedStatusBoolean?: Maybe<Scalars['Boolean']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  departure_location?: Maybe<Scalars['String']['output']>;
  directions?: Maybe<Scalars['String']['output']>;
  duty?: Maybe<Scalars['String']['output']>;
  early_dismissal_required?: Maybe<Scalars['String']['output']>;
  early_dismissal_time?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  estimated_return_time?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  eventOfficial?: Maybe<Scalars['String']['output']>;
  eventOfficialCell?: Maybe<Scalars['String']['output']>;
  eventOfficialEmail?: Maybe<Scalars['String']['output']>;
  eventTiming?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  exists_in_mls?: Maybe<Scalars['Int']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  formatedEventDate?: Maybe<Scalars['String']['output']>;
  formatedEventDateSystem?: Maybe<Scalars['String']['output']>;
  formatedEventDay?: Maybe<Scalars['String']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  gate_revenue?: Maybe<Scalars['Int']['output']>;
  groupval?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  impact_event?: Maybe<Scalars['String']['output']>;
  isDuplicate?: Maybe<Scalars['String']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  loss_points?: Maybe<Scalars['Int']['output']>;
  noofgames?: Maybe<Scalars['String']['output']>;
  num_buses?: Maybe<Scalars['Int']['output']>;
  num_buses_text?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  opponent_code?: Maybe<Scalars['String']['output']>;
  opponent_score?: Maybe<Scalars['String']['output']>;
  pay_code?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  prep_setup?: Maybe<Scalars['String']['output']>;
  promote?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Scalars['String']['output']>;
  revenue?: Maybe<Scalars['Int']['output']>;
  rollover?: Maybe<Scalars['String']['output']>;
  rolloverStatusBoolean?: Maybe<Scalars['Boolean']['output']>;
  salary?: Maybe<Scalars['Int']['output']>;
  seasonInfo?: Maybe<Scalars['String']['output']>;
  seasonSportCode?: Maybe<Scalars['String']['output']>;
  season_team?: Maybe<Scalars['Int']['output']>;
  serialnumber?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  sportDescription?: Maybe<Scalars['String']['output']>;
  sportGender?: Maybe<Scalars['String']['output']>;
  sportLevel?: Maybe<Scalars['String']['output']>;
  sportName?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  team_score?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tournament?: Maybe<Scalars['String']['output']>;
  trans_id?: Maybe<Scalars['Int']['output']>;
  transportDetails?: Maybe<Scalars['String']['output']>;
  transport_comments?: Maybe<Scalars['String']['output']>;
  transportation?: Maybe<Scalars['String']['output']>;
  vehicle_count?: Maybe<Scalars['String']['output']>;
  vehiclesTransportDetails?: Maybe<Array<Maybe<DailyCalendarEventTransportDetails>>>;
  web_dir?: Maybe<Scalars['String']['output']>;
  weekdayname?: Maybe<Scalars['String']['output']>;
  win_points?: Maybe<Scalars['Int']['output']>;
  years?: Maybe<Scalars['String']['output']>;
};

export type TransformedDailyCalendarOfficials = {
  __typename?: 'TransformedDailyCalendarOfficials';
  exportdata?: Maybe<Array<Maybe<DailyCalendarOfficialExport>>>;
  message?: Maybe<Array<Maybe<DailyCalendarOfficialMessage>>>;
};

export type TransformedDailyCalendarPreparation = {
  __typename?: 'TransformedDailyCalendarPreparation';
  exportdata?: Maybe<Array<Maybe<DailyCalendarPreparationExportData>>>;
  message?: Maybe<Array<Maybe<DailyCalendarPreparationMessage>>>;
};

export type TransformedDailyCalendarWorkers = {
  __typename?: 'TransformedDailyCalendarWorkers';
  exportdata?: Maybe<Array<Maybe<DailyCalendarWorkerExportData>>>;
  message?: Maybe<Array<Maybe<DailyCalendarWorkerMessage>>>;
};

export type TransformedEventReturn = {
  __typename?: 'TransformedEventReturn';
  activity?: Maybe<Scalars['String']['output']>;
  activityLevel?: Maybe<Scalars['String']['output']>;
  activityType?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  bus_fee?: Maybe<Scalars['Int']['output']>;
  bus_time?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  conference?: Maybe<Scalars['String']['output']>;
  conference_event_id?: Maybe<Scalars['Int']['output']>;
  conference_id?: Maybe<Scalars['Int']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  confirmedStatusBoolean?: Maybe<Scalars['Boolean']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  departure_location?: Maybe<Scalars['String']['output']>;
  directions?: Maybe<Scalars['String']['output']>;
  duty?: Maybe<Scalars['String']['output']>;
  early_dismissal_required?: Maybe<Scalars['String']['output']>;
  early_dismissal_time?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  estimated_return_time?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  eventOfficial?: Maybe<Scalars['String']['output']>;
  eventOfficialCell?: Maybe<Scalars['String']['output']>;
  eventOfficialEmail?: Maybe<Scalars['String']['output']>;
  eventTiming?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  exists_in_mls?: Maybe<Scalars['Int']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  formatedEventDate?: Maybe<Scalars['String']['output']>;
  formatedEventDateSystem?: Maybe<Scalars['String']['output']>;
  formatedEventDay?: Maybe<Scalars['String']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  gate_revenue?: Maybe<Scalars['Int']['output']>;
  groupval?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  impact_event?: Maybe<Scalars['String']['output']>;
  isDuplicate?: Maybe<Scalars['String']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  loss_points?: Maybe<Scalars['Int']['output']>;
  noofgames?: Maybe<Scalars['String']['output']>;
  num_buses?: Maybe<Scalars['Int']['output']>;
  num_buses_text?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  opponent_code?: Maybe<Scalars['String']['output']>;
  opponent_score?: Maybe<Scalars['String']['output']>;
  pay_code?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  prep_setup?: Maybe<Scalars['String']['output']>;
  promote?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Scalars['String']['output']>;
  revenue?: Maybe<Scalars['Int']['output']>;
  rollover?: Maybe<Scalars['String']['output']>;
  rolloverStatusBoolean?: Maybe<Scalars['Boolean']['output']>;
  salary?: Maybe<Scalars['Int']['output']>;
  seasonInfo?: Maybe<Scalars['String']['output']>;
  seasonSportCode?: Maybe<Scalars['String']['output']>;
  season_team?: Maybe<Scalars['Int']['output']>;
  serialnumber?: Maybe<Scalars['String']['output']>;
  sportCode?: Maybe<Scalars['String']['output']>;
  sportDescription?: Maybe<Scalars['String']['output']>;
  sportGender?: Maybe<Scalars['String']['output']>;
  sportLevel?: Maybe<Scalars['String']['output']>;
  sportName?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  team_score?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tournament?: Maybe<Scalars['String']['output']>;
  trans_id?: Maybe<Scalars['Int']['output']>;
  transportDetails?: Maybe<Scalars['String']['output']>;
  transport_comments?: Maybe<Scalars['String']['output']>;
  transportation?: Maybe<Scalars['String']['output']>;
  vehicle_count?: Maybe<Scalars['String']['output']>;
  web_dir?: Maybe<Scalars['String']['output']>;
  weekdayname?: Maybe<Scalars['String']['output']>;
  win_points?: Maybe<Scalars['Int']['output']>;
  years?: Maybe<Scalars['String']['output']>;
};

export type TransformedOfficialAssignment = {
  __typename?: 'TransformedOfficialAssignment';
  duty?: Maybe<Scalars['String']['output']>;
  eventActivity?: Maybe<Scalars['String']['output']>;
  eventDate?: Maybe<Scalars['String']['output']>;
  eventHomeAway?: Maybe<Scalars['String']['output']>;
  eventLocation?: Maybe<Scalars['String']['output']>;
  eventOpponent?: Maybe<Scalars['String']['output']>;
  eventTime?: Maybe<Scalars['String']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['String']['output']>;
  pay_code?: Maybe<Scalars['String']['output']>;
  salary?: Maybe<Scalars['Int']['output']>;
  seasonSportCode?: Maybe<Scalars['String']['output']>;
  sportDescription?: Maybe<Scalars['String']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
};

export enum TransportEnum {
  Email = 'email',
  Sms = 'sms'
}

export type TransportType = {
  __typename?: 'TransportType';
  recipient?: Maybe<Scalars['String']['output']>;
  transport?: Maybe<TransportEnum>;
};

export type UnconfirmedEvents = {
  __typename?: 'UnconfirmedEvents';
  activity?: Maybe<Scalars['String']['output']>;
  bus_count?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  driver_name?: Maybe<Scalars['String']['output']>;
  driver_phone?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  facility?: Maybe<Scalars['String']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  home_field?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  levels?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  season_id?: Maybe<Scalars['Int']['output']>;
  sports_code?: Maybe<Scalars['String']['output']>;
  sports_description?: Maybe<Scalars['String']['output']>;
  sports_group?: Maybe<Scalars['String']['output']>;
  sports_name?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  vehicle_id?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

export type UnconfirmedEventsFiltersInput = {
  activity?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  levels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  opponents?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type UnconfirmedEventsList = {
  __typename?: 'UnconfirmedEventsList';
  items?: Maybe<Array<Maybe<UnconfirmedEvents>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  totalFilteredItems?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
  totalRows?: Maybe<Scalars['Int']['output']>;
};

export type UnprocessableSubmission = Error & {
  __typename?: 'UnprocessableSubmission';
  message: Scalars['String']['output'];
};

export type UnreadCount = {
  __typename?: 'UnreadCount';
  totalUnreadCount: Scalars['Int']['output'];
};

export type UnreadCountError = Error & {
  __typename?: 'UnreadCountError';
  message: Scalars['String']['output'];
};

export type UpdateInvoices = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  dueDate: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type UpdateParticipantFundraiserConfigurationInput = {
  autoImportEmails?: InputMaybe<ParticipantFundraiserConfigStates>;
  autoImportTexts?: InputMaybe<ParticipantFundraiserConfigStates>;
  fundraiserId?: InputMaybe<Scalars['String']['input']>;
  giftShop?: InputMaybe<ParticipantFundraiserConfigStates>;
  guardianSetup?: InputMaybe<ParticipantFundraiserConfigStates>;
  profileSetup?: InputMaybe<ParticipantFundraiserConfigStates>;
  raiseFundraiserId?: InputMaybe<Scalars['Int']['input']>;
  rewards?: InputMaybe<ParticipantFundraiserConfigStates>;
};

export type UpdatedFundraiserStoreUrl = {
  __typename?: 'UpdatedFundraiserStoreUrl';
  id?: Maybe<Scalars['Int']['output']>;
};

export type UpdatedParticipantGroupCount = {
  __typename?: 'UpdatedParticipantGroupCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export type UpsertEventParticipantsCount = {
  __typename?: 'UpsertEventParticipantsCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export type UpsertEventParticipantsInput = {
  eventParticipantsArray: Array<InputMaybe<EventParticipantsInput>>;
};

export type UpsertEventPreparationsCount = {
  __typename?: 'UpsertEventPreparationsCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export type UpsertEventPreparationsInput = {
  eventPreparationsArray: Array<InputMaybe<EventPreparationsInput>>;
};

export type UpsertEventTransportDetailsCount = {
  __typename?: 'UpsertEventTransportDetailsCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export type UpsertEventTransportDetailsInput = {
  eventTransportDetailsArray: Array<InputMaybe<EventTransportDetailsInput>>;
};

export type UpsertFacilitiesInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  directions?: InputMaybe<Scalars['String']['input']>;
  facility_name: Scalars['String']['input'];
  location_id?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertManyWorkersInput = {
  workersArray: Array<InputMaybe<CreateWorkerInput>>;
};

export type UpsertOfficialDutiesInput = {
  duty?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UpsertOfficialsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  cell_phone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  home_phone?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  official_id: Scalars['String']['input'];
  ssn?: InputMaybe<Scalars['String']['input']>;
  work_phone?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertOpponentsInput = {
  ad_name?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  school_code: Scalars['String']['input'];
  school_name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertWorkersInput = {
  cell_phone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  home_phone?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  pay_rate?: InputMaybe<Scalars['Float']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  worker_type?: InputMaybe<WorkerTypes>;
};

export type UrgentAnnouncementFilterOld = {
  orderBy?: InputMaybe<ManageAnnouncementOrderByOld>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ManageAnnouncementWhereOld>;
};

export type User = {
  __typename?: 'User';
  apps?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  kyc?: Maybe<VaultKyc>;
  language?: Maybe<Scalars['String']['output']>;
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<UserOccupation>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** @deprecated will be removed in the next release */
  snapRaiseId?: Maybe<Scalars['String']['output']>;
  /** @deprecated will be removed in the next release */
  snapSpendId?: Maybe<Scalars['String']['output']>;
};

export type UserAffiliation = {
  __typename?: 'UserAffiliation';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  title: AffiliationTitle;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserAffiliationCounts = {
  __typename?: 'UserAffiliationCounts';
  affiliatedCount: Scalars['Int']['output'];
  impliedCount: Scalars['Int']['output'];
  leadsCount: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum UserApps {
  Connect = 'connect',
  Drive = 'drive',
  Fanx = 'fanx',
  Home = 'home',
  Insights = 'insights',
  Manage = 'manage',
  Raise = 'raise',
  Spend = 'spend',
  SpendNew = 'spend_new',
  Sponsor = 'sponsor',
  Store = 'store',
  Wallet = 'wallet'
}

export type UserChallenge = {
  __typename?: 'UserChallenge';
  completedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  params?: Maybe<Scalars['JSONObject']['output']>;
  skippedAt?: Maybe<Scalars['String']['output']>;
  status: UserChallengeStatus;
};

export enum UserChallengeStatus {
  Awaiting = 'AWAITING',
  Completed = 'COMPLETED',
  Skipped = 'SKIPPED'
}

export type UserConfirmProfileChallengeData = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  occupation?: InputMaybe<UserOccupation>;
  phoneNumber: Scalars['String']['input'];
};

export type UserFitting = {
  __typename?: 'UserFitting';
  fitting?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export enum UserFittingPreference {
  Mens = 'MENS',
  Womens = 'WOMENS'
}

export type UserInitiateProfileChallengeData = {
  phoneNumber: Scalars['String']['input'];
};

export type UserInsightsConfig = {
  __typename?: 'UserInsightsConfig';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['NonEmptyString']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['NonEmptyString']['output'];
  value: Scalars['NonEmptyString']['output'];
};

export type UserInsightsConfigInput = {
  name: Scalars['NonEmptyString']['input'];
  value?: InputMaybe<Scalars['NonEmptyString']['input']>;
};

export type UserInsightsConfigResult = {
  __typename?: 'UserInsightsConfigResult';
  messages: Array<Maybe<Scalars['String']['output']>>;
  status: Scalars['String']['output'];
};

export type UserInviteParams = {
  __typename?: 'UserInviteParams';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<Scalars['String']['output']>;
  orgsAffiliation?: Maybe<Scalars['JSON']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type UserInviteResponse = {
  __typename?: 'UserInviteResponse';
  redirect?: Maybe<Scalars['String']['output']>;
};

export enum UserInviteStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type UserLoginStepResponse = {
  __typename?: 'UserLoginStepResponse';
  exists: Scalars['Boolean']['output'];
  pendingPasswordReset: Scalars['Boolean']['output'];
};

export type UserNode = {
  __typename?: 'UserNode';
  id: Scalars['ID']['output'];
  internal?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum UserOccupation {
  DistrictAdministrator = 'district_administrator',
  FinancialAdministrator = 'financial_administrator',
  GroupLeader = 'group_leader',
  Other = 'other',
  Parent = 'parent',
  SchoolDirector = 'school_director',
  StateAdministrator = 'state_administrator',
  StudentOrParticipant = 'student_or_participant',
  Unknown = 'unknown'
}

export type UserOrgAffiliationPayload = {
  orgId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UserOrgInvitationPayload = {
  email?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UserOrgInvitationResult = {
  __typename?: 'UserOrgInvitationResult';
  id?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['JSON']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UserOrgRelationships = {
  __typename?: 'UserOrgRelationships';
  governingOrgs: Array<Org>;
  orgRelationships: Array<OrgRelationships>;
};

export type UserParentsPublicError = Error & {
  __typename?: 'UserParentsPublicError';
  message: Scalars['String']['output'];
};

export type UserParentsPublicInfo = {
  __typename?: 'UserParentsPublicInfo';
  parents: Array<UserPublic>;
  user?: Maybe<UserPublic>;
};

export type UserParentsPublicInfoResult = UserParentsPublicError | UserParentsPublicInfo;

export type UserPermission = {
  __typename?: 'UserPermission';
  id: Scalars['String']['output'];
  scope?: Maybe<Scalars['String']['output']>;
};

export type UserPermissionAssignment = {
  id: Scalars['String']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
};

export type UserPermissionsList = {
  __typename?: 'UserPermissionsList';
  id?: Maybe<Scalars['String']['output']>;
  negativePermissions?: Maybe<Array<Maybe<UserPermission>>>;
  permissions?: Maybe<Array<Maybe<UserPermission>>>;
  roleIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  featureTourCompleted?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum UserPreferenceQueryType {
  All = 'ALL',
  Campaign = 'CAMPAIGN',
  Org = 'ORG'
}

export enum UserProviders {
  Google = 'google'
}

export type UserPublic = {
  __typename?: 'UserPublic';
  createdAt?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  notMigrated?: Maybe<Scalars['Boolean']['output']>;
  occupation?: Maybe<UserOccupation>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type UserPublicInfo = {
  __typename?: 'UserPublicInfo';
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  notMigrated?: Maybe<Scalars['Boolean']['output']>;
};

export type UserPublicInfoResult = {
  __typename?: 'UserPublicInfoResult';
  info?: Maybe<UserPublicInfo>;
  user?: Maybe<UserPublic>;
};

export type UserResetPasswordByAdminResponse = {
  __typename?: 'UserResetPasswordByAdminResponse';
  user?: Maybe<User>;
};

export type UserResetPasswordResponseWithAvailableTransport = {
  __typename?: 'UserResetPasswordResponseWithAvailableTransport';
  availableTransport: Array<TransportEnum>;
  info?: Maybe<Scalars['String']['output']>;
  result: Array<TransportType>;
};

export type UserSavedRep = {
  __typename?: 'UserSavedRep';
  snapRepEmail?: Maybe<Scalars['String']['output']>;
  uuId?: Maybe<Scalars['String']['output']>;
};

export enum UserTypeConfiguration {
  Default = 'DEFAULT',
  GuardianLead = 'GUARDIAN_LEAD'
}

export type UserWithPermissions = {
  __typename?: 'UserWithPermissions';
  apps?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  hasFamily?: Maybe<Scalars['Boolean']['output']>;
  hsGradYear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  kyc?: Maybe<VaultKyc>;
  language?: Maybe<Scalars['String']['output']>;
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<UserOccupation>;
  /** @deprecated use parents instead of parent */
  parent?: Maybe<Scalars['String']['output']>;
  parents?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** @deprecated will be removed in the next release */
  snapRaiseId?: Maybe<Scalars['String']['output']>;
  /** @deprecated will be removed in the next release */
  snapSpendId?: Maybe<Scalars['String']['output']>;
};

export type Users = {
  __typename?: 'Users';
  nextPage?: Maybe<Scalars['Boolean']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type UsersFilter = {
  userIds: Array<Scalars['ID']['input']>;
};

export type VaultAccountPayoutFrequencySetAuditEntry = {
  __typename?: 'VaultAccountPayoutFrequencySetAuditEntry';
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orgId?: Maybe<Scalars['ID']['output']>;
  updateAccountResponse: Scalars['JSONObject']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
};

export type VaultAccountStatus = {
  __typename?: 'VaultAccountStatus';
  accountRepresentativeRequirements?: Maybe<VaultEntityRequirements>;
  beneficialOwnersRequirements?: Maybe<Array<VaultEntityRequirements>>;
  kybRequirements?: Maybe<VaultEntityRequirements>;
  valid: Scalars['Boolean']['output'];
};

/** Used for addresses in VaultKyb and VaultKyc */
export type VaultAddress = {
  __typename?: 'VaultAddress';
  city: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  region: Scalars['String']['output'];
  street: Scalars['String']['output'];
  unit?: Maybe<Scalars['String']['output']>;
};

/** Used for addresses in VaultKyb, VaultKyc and latestCreateCard */
export type VaultAddressInput = {
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  region: Scalars['String']['input'];
  street: Scalars['String']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
};

/** used in vaultKybUpdate and vaultKycUpdate */
export type VaultAddressUpdateInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type VaultBankAccount = {
  __typename?: 'VaultBankAccount';
  account_holder_name?: Maybe<Scalars['String']['output']>;
  account_holder_type?: Maybe<Scalars['String']['output']>;
  bank_name: Scalars['String']['output'];
  country: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  default_for_currency: Scalars['Boolean']['output'];
  fingerprint: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  last4: Scalars['String']['output'];
  routing_number: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type VaultBeneficialOwnerCreateInput = {
  kycData?: InputMaybe<VaultKycCreateInput>;
  kycId?: InputMaybe<Scalars['ID']['input']>;
  stripePersonId?: InputMaybe<Scalars['ID']['input']>;
};

/** Additional data passed into the Stripe api Can be read in Stripe dashboard */
export type VaultCardMetadata = {
  __typename?: 'VaultCardMetadata';
  description?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

/** Additional data passed into the Stripe api Can be read in Stripe dashboard */
export type VaultCardMetadataInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  walletId?: InputMaybe<Scalars['String']['input']>;
};

/** Current status of card */
export enum VaultCardStatus {
  Active = 'active',
  /** To deactivate a card set it to canceled */
  Canceled = 'canceled',
  /** Cards ship inactive */
  Inactive = 'inactive'
}

/** Is the card physical (needs to mailed out) or virtual */
export enum VaultCardType {
  Physical = 'physical',
  Virtual = 'virtual'
}

export type VaultCreateKybKycBeneficialOwnerAuditEntry = {
  __typename?: 'VaultCreateKybKycBeneficialOwnerAuditEntry';
  addressCity: Scalars['String']['output'];
  addressPostalCode: Scalars['String']['output'];
  addressRegion: Scalars['String']['output'];
  addressStreet: Scalars['String']['output'];
  addressUnit?: Maybe<Scalars['String']['output']>;
  createStripePersonResponse: Scalars['JSONObject']['output'];
  director?: Maybe<Scalars['Boolean']['output']>;
  dobDay: Scalars['String']['output'];
  dobMonth: Scalars['String']['output'];
  dobYear: Scalars['String']['output'];
  email: Scalars['String']['output'];
  executive?: Maybe<Scalars['Boolean']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kycId?: Maybe<Scalars['ID']['output']>;
  lastName: Scalars['String']['output'];
  owner?: Maybe<Scalars['Boolean']['output']>;
  percentOwnership?: Maybe<Scalars['Float']['output']>;
  phoneNumber: Scalars['String']['output'];
  representative?: Maybe<Scalars['Boolean']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type VaultCreateKybKycStripeInput = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars['String']['input']>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type VaultCreateKybKycSubmitAuditEntry = {
  __typename?: 'VaultCreateKybKycSubmitAuditEntry';
  accountId?: Maybe<Scalars['String']['output']>;
  beneficialOwners?: Maybe<Array<VaultCreateKybKycBeneficialOwnerAuditEntry>>;
  createStripeConnectAccountPayableOrgResponse?: Maybe<Scalars['JSONObject']['output']>;
  createStripeConnectAccountResponse?: Maybe<Scalars['JSONObject']['output']>;
  createStripeRepresentativePayableOrgResponse?: Maybe<Scalars['JSONObject']['output']>;
  createStripeRepresentativeResponse?: Maybe<Scalars['JSONObject']['output']>;
  created: Scalars['DateTime']['output'];
  financialAccountNodeId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  kybAddressCity: Scalars['String']['output'];
  kybAddressPostalCode: Scalars['String']['output'];
  kybAddressRegion: Scalars['String']['output'];
  kybAddressStreet: Scalars['String']['output'];
  kybAddressUnit?: Maybe<Scalars['String']['output']>;
  kybCustomerFacingName: Scalars['String']['output'];
  kybDescription?: Maybe<Scalars['String']['output']>;
  kybEmail?: Maybe<Scalars['String']['output']>;
  kybId?: Maybe<Scalars['ID']['output']>;
  kybLegalName: Scalars['String']['output'];
  kybPhoneNumber: Scalars['String']['output'];
  kybRaiseFundraiserId?: Maybe<Scalars['ID']['output']>;
  kybStructure: Kyb_Structure;
  kybTaxId: Scalars['String']['output'];
  kybType: Kyb_Type;
  kybUrl?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['ID']['output']>;
  ownersProvidedPayableOrgResponse?: Maybe<Scalars['JSONObject']['output']>;
  ownersProvidedResponse?: Maybe<Scalars['JSONObject']['output']>;
  representativeAddressCity?: Maybe<Scalars['String']['output']>;
  representativeAddressPostalCode?: Maybe<Scalars['String']['output']>;
  representativeAddressRegion?: Maybe<Scalars['String']['output']>;
  representativeAddressStreet?: Maybe<Scalars['String']['output']>;
  representativeAddressUnit?: Maybe<Scalars['String']['output']>;
  representativeDirector?: Maybe<Scalars['Boolean']['output']>;
  representativeDobDay?: Maybe<Scalars['String']['output']>;
  representativeDobMonth?: Maybe<Scalars['String']['output']>;
  representativeDobYear?: Maybe<Scalars['String']['output']>;
  representativeEmail?: Maybe<Scalars['String']['output']>;
  representativeExecutive?: Maybe<Scalars['Boolean']['output']>;
  representativeFirstName?: Maybe<Scalars['String']['output']>;
  representativeKycId?: Maybe<Scalars['ID']['output']>;
  representativeLastName?: Maybe<Scalars['String']['output']>;
  representativeOwner?: Maybe<Scalars['Boolean']['output']>;
  representativePercentOwnership?: Maybe<Scalars['Float']['output']>;
  representativePhoneNumber?: Maybe<Scalars['String']['output']>;
  representativeRepresentative?: Maybe<Scalars['Boolean']['output']>;
  representativeSsn?: Maybe<Scalars['String']['output']>;
  representativeStripePersonId?: Maybe<Scalars['ID']['output']>;
  representativeTitle?: Maybe<Scalars['String']['output']>;
  saveToPayableOrgId?: Maybe<Scalars['ID']['output']>;
  statementDescriptor?: Maybe<Scalars['String']['output']>;
  stripeEnv?: Maybe<Scalars['String']['output']>;
  stripeMetadataActivityType?: Maybe<Scalars['String']['output']>;
  stripeMetadataEntity?: Maybe<Scalars['String']['output']>;
  stripeMetadataFundraiserId?: Maybe<Scalars['String']['output']>;
  stripeMetadataFundraiserIdLegacy?: Maybe<Scalars['String']['output']>;
  stripeMetadataPaymentType?: Maybe<Scalars['String']['output']>;
  stripeMetadataTransactionId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

/** Day, Month and Year of birth */
export type VaultDob = {
  __typename?: 'VaultDob';
  day?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['String']['output']>;
};

/** Day, Month and Year of birth */
export type VaultDobInput = {
  day: Scalars['String']['input'];
  month: Scalars['String']['input'];
  year: Scalars['String']['input'];
};

export enum VaultDocumentProvider {
  Stripe = 'stripe',
  Unit = 'unit'
}

export type VaultEntityRequirements = {
  __typename?: 'VaultEntityRequirements';
  missingFields: Array<Scalars['String']['output']>;
  requiredDocuments: Array<VaultRequiredDocument>;
};

/** Third party financial providers */
export enum VaultFinancialProvider {
  Stripe = 'stripe',
  Unit = 'unit'
}

export type VaultFormKybInput = {
  accountId: Scalars['ID']['input'];
  financialAccountNodeId?: InputMaybe<Scalars['ID']['input']>;
  kybId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type VaultFormMutationError = {
  __typename?: 'VaultFormMutationError';
  details?: Maybe<Scalars['JSONObject']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  fieldMessage?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  retryable: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type VaultFormMutationResponse = {
  __typename?: 'VaultFormMutationResponse';
  errors?: Maybe<Array<VaultFormMutationError>>;
  success: Scalars['Boolean']['output'];
  successData?: Maybe<VaultFormSuccessData>;
};

export type VaultFormStripeInput = {
  metadata?: InputMaybe<StripeMetadataInput>;
  statementDescriptor?: InputMaybe<Scalars['String']['input']>;
  stripeEnv?: InputMaybe<VaultStripeEnv>;
};

export type VaultFormSuccessData = {
  __typename?: 'VaultFormSuccessData';
  beneficialOwners?: Maybe<Array<VaultKycSuccessData>>;
  kybData: VaultKybSuccessData;
  payableOrgNode?: Maybe<VaultPayableOrgSuccessData>;
  representative: VaultKycSuccessData;
  stripeEnv?: Maybe<VaultStripeEnv>;
};

/** Organizations/Companies */
export type VaultKyb = {
  __typename?: 'VaultKyb';
  address: VaultAddress;
  customerFacingName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  entityDocument?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  legalName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  structure: Kyb_Structure;
  taxId: Scalars['String']['output'];
  type: Kyb_Type;
  url?: Maybe<Scalars['String']['output']>;
};

export type VaultKybCreateInput = {
  address: VaultAddressInput;
  customerFacingName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  legalName: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  phoneNumber: Scalars['String']['input'];
  structure: Kyb_Structure;
  taxId: Scalars['String']['input'];
  type: Kyb_Type;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type VaultKybInput = {
  accountId: Scalars['ID']['input'];
  financialAccountNodeId?: InputMaybe<Scalars['ID']['input']>;
  kybId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type VaultKybSuccessData = {
  __typename?: 'VaultKybSuccessData';
  financialAccountId?: Maybe<Scalars['ID']['output']>;
  kybId: Scalars['ID']['output'];
  metadata?: Maybe<StripeMetadata>;
  orgId?: Maybe<Scalars['ID']['output']>;
  stripeConnectAccountId?: Maybe<Scalars['String']['output']>;
};

/** Individuals */
export type VaultKyc = {
  __typename?: 'VaultKyc';
  address: VaultAddress;
  director?: Maybe<Scalars['Boolean']['output']>;
  dob: VaultDob;
  email: Scalars['String']['output'];
  executive?: Maybe<Scalars['Boolean']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  owner?: Maybe<Scalars['Boolean']['output']>;
  /** If owner=true, percentOwnership is required */
  percentOwnership?: Maybe<Scalars['Int']['output']>;
  phoneNumber: Scalars['String']['output'];
  representative?: Maybe<Scalars['Boolean']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type VaultKycCreateInput = {
  address: VaultAddressInput;
  director?: InputMaybe<Scalars['Boolean']['input']>;
  dob: VaultDobInput;
  email: Scalars['String']['input'];
  executive?: InputMaybe<Scalars['Boolean']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  owner?: InputMaybe<Scalars['Boolean']['input']>;
  percentOwnership?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber: Scalars['String']['input'];
  representative?: InputMaybe<Scalars['Boolean']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type VaultKycSuccessData = {
  __typename?: 'VaultKycSuccessData';
  kycId: Scalars['ID']['output'];
  stripePersonId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type VaultMutationError = {
  __typename?: 'VaultMutationError';
  message: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type VaultMutationResponse = {
  __typename?: 'VaultMutationResponse';
  errors?: Maybe<Array<Maybe<VaultMutationError>>>;
  success: Scalars['Boolean']['output'];
};

export type VaultOrgConfirmationBeneficialOwnerAuditEntry = {
  __typename?: 'VaultOrgConfirmationBeneficialOwnerAuditEntry';
  addressCity: Scalars['String']['output'];
  addressPostalCode: Scalars['String']['output'];
  addressRegion: Scalars['String']['output'];
  addressStreet: Scalars['String']['output'];
  addressUnit?: Maybe<Scalars['String']['output']>;
  createStripePersonResponse: Scalars['JSONObject']['output'];
  director?: Maybe<Scalars['Boolean']['output']>;
  dobDay: Scalars['String']['output'];
  dobMonth: Scalars['String']['output'];
  dobYear: Scalars['String']['output'];
  email: Scalars['String']['output'];
  executive?: Maybe<Scalars['Boolean']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kycId?: Maybe<Scalars['ID']['output']>;
  lastName: Scalars['String']['output'];
  owner?: Maybe<Scalars['Boolean']['output']>;
  percentOwnership?: Maybe<Scalars['Float']['output']>;
  phoneNumber: Scalars['String']['output'];
  representative?: Maybe<Scalars['Boolean']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type VaultOrgConfirmationSubmitAuditEntry = {
  __typename?: 'VaultOrgConfirmationSubmitAuditEntry';
  accountId: Scalars['ID']['output'];
  beneficialOwners?: Maybe<Array<VaultOrgConfirmationBeneficialOwnerAuditEntry>>;
  created: Scalars['DateTime']['output'];
  financialAccountNodeId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  kybId: Scalars['ID']['output'];
  orgId?: Maybe<Scalars['ID']['output']>;
  replaceWorkflowCreateStripeRepresentativeResponse?: Maybe<Scalars['JSONObject']['output']>;
  replaceWorkflowDeleteStripePersonResponse?: Maybe<Scalars['JSONObject']['output']>;
  representativeAddressCity?: Maybe<Scalars['String']['output']>;
  representativeAddressPostalCode?: Maybe<Scalars['String']['output']>;
  representativeAddressRegion?: Maybe<Scalars['String']['output']>;
  representativeAddressStreet?: Maybe<Scalars['String']['output']>;
  representativeAddressUnit?: Maybe<Scalars['String']['output']>;
  representativeDirector?: Maybe<Scalars['Boolean']['output']>;
  representativeDobDay?: Maybe<Scalars['String']['output']>;
  representativeDobMonth?: Maybe<Scalars['String']['output']>;
  representativeDobYear?: Maybe<Scalars['String']['output']>;
  representativeEmail?: Maybe<Scalars['String']['output']>;
  representativeExecutive?: Maybe<Scalars['Boolean']['output']>;
  representativeFirstName?: Maybe<Scalars['String']['output']>;
  representativeKycId?: Maybe<Scalars['ID']['output']>;
  representativeLastName?: Maybe<Scalars['String']['output']>;
  representativeOwner?: Maybe<Scalars['Boolean']['output']>;
  representativePercentOwnership?: Maybe<Scalars['Int']['output']>;
  representativePhoneNumber?: Maybe<Scalars['String']['output']>;
  representativeRepresentative?: Maybe<Scalars['Boolean']['output']>;
  representativeSsn?: Maybe<Scalars['String']['output']>;
  representativeStripePersonId?: Maybe<Scalars['ID']['output']>;
  representativeTitle?: Maybe<Scalars['String']['output']>;
  representativeToReplaceKycId?: Maybe<Scalars['ID']['output']>;
  representativeToReplaceStripePersonId?: Maybe<Scalars['ID']['output']>;
  representativeToReplaceUserId?: Maybe<Scalars['ID']['output']>;
  representativeUserId?: Maybe<Scalars['ID']['output']>;
  saveToPayableOrgId?: Maybe<Scalars['ID']['output']>;
  statementDescriptor?: Maybe<Scalars['String']['output']>;
  stripeEnv?: Maybe<Scalars['String']['output']>;
  stripeMetadataActivityType?: Maybe<Scalars['String']['output']>;
  stripeMetadataEntity?: Maybe<Scalars['String']['output']>;
  stripeMetadataFundraiserId?: Maybe<Scalars['String']['output']>;
  stripeMetadataFundraiserIdLegacy?: Maybe<Scalars['String']['output']>;
  stripeMetadataPaymentType?: Maybe<Scalars['String']['output']>;
  stripeMetadataTransactionId?: Maybe<Scalars['String']['output']>;
  submittedOnYourBehalf: Scalars['Boolean']['output'];
  submittedOnYourBehalfCreateStripeConnectAccountResponse?: Maybe<Scalars['JSONObject']['output']>;
  submittedOnYourBehalfCreateStripeRepresentativeResponse?: Maybe<Scalars['JSONObject']['output']>;
  updateStatementDescriptorResponse?: Maybe<Scalars['JSONObject']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type VaultOwner = {
  __typename?: 'VaultOwner';
  createdAt: Scalars['DateTime']['output'];
  financialAccountId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kycId: Scalars['ID']['output'];
  orgId: Scalars['String']['output'];
  stripeConnectAccountId?: Maybe<Scalars['String']['output']>;
  stripePersonId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type VaultPayableOrgSuccessData = {
  __typename?: 'VaultPayableOrgSuccessData';
  beneficialOwners?: Maybe<Array<VaultKycSuccessData>>;
  kybData: VaultKybSuccessData;
  representative?: Maybe<VaultKycSuccessData>;
};

export enum VaultPayoutInterval {
  Daily = 'daily',
  Manual = 'manual',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

export type VaultRepresentativeCreateInput = {
  kycData?: InputMaybe<VaultKycCreateInput>;
  kycId?: InputMaybe<Scalars['ID']['input']>;
  stripePersonId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum VaultRequiredDocument {
  /** Utility bill, bank statement, lease agreement or current pay stub */
  AddressVerification = 'address_verification',
  /** Bank Statement or Void Check */
  BankAccountDocument = 'bank_account_document',
  /** Certificate of incorporation or certificate of assumed name */
  CertificateOfIncorporation = 'certificate_of_incorporation',
  /** IRS form 147c or IRS form CP-575 */
  EinConfirmation = 'ein_confirmation',
  /** Passport or Drivers License */
  IdDocument = 'id_document',
  /** Passport, Drivers License or State ID */
  IdDocumentOrPassport = 'id_document_or_passport',
  /** 501C3 or SS4 */
  IrsDeterminationLetter = 'irs_determination_letter',
  /** Passport */
  Passport = 'passport',
  /** Power of attorney */
  PowerOfAttorney = 'power_of_attorney',
  /** SSN Card */
  SsnCard = 'ssn_card'
}

/** How to send a physical VaultCard */
export enum VaultShippingService {
  Express = 'express',
  Priority = 'priority',
  Standard = 'standard'
}

/** The interval that the spending limit interval of a card is reset */
export enum VaultSpendingLimitInterval {
  AllTime = 'all_time',
  Daily = 'daily',
  Monthly = 'monthly',
  PerAuthorization = 'per_authorization',
  Weekly = 'weekly',
  Yearly = 'yearly'
}

export type VaultStripeConnectAccountPaymentMethodDefaultSetAuditEntry = {
  __typename?: 'VaultStripeConnectAccountPaymentMethodDefaultSetAuditEntry';
  createTokenResponse?: Maybe<Scalars['JSONObject']['output']>;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  updateAccountResponse?: Maybe<Scalars['JSONObject']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum VaultStripeEnv {
  Raise = 'RAISE',
  Sponsor = 'SPONSOR'
}

/** ip and date of Stripe ToS acceptance */
export type VaultTosAcceptance = {
  date: Scalars['DateTime']['input'];
  ip: Scalars['String']['input'];
};

export enum VaultWeeklyAnchor {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type Vehicle = {
  __typename?: 'Vehicle';
  id: Scalars['Int']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
};

export enum Vendor {
  Marco = 'MARCO',
  Raise = 'RAISE',
  RaiseMarco = 'RAISE_MARCO',
  Store = 'STORE'
}

export type WebNotificationInput = {
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Input object for index query filters. */
export type Where = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  eq?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gt?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gte?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lte?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  partition?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type WinbackDeals = {
  __typename?: 'WinbackDeals';
  activity?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['String']['output']>;
  closedLost?: Maybe<Scalars['String']['output']>;
  dealName?: Maybe<Scalars['String']['output']>;
  dealStage?: Maybe<Scalars['String']['output']>;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isWinbackPinned?: Maybe<Scalars['Boolean']['output']>;
  lastLaunch?: Maybe<Scalars['String']['output']>;
  leaderFirstName?: Maybe<Scalars['String']['output']>;
  leaderLastName?: Maybe<Scalars['String']['output']>;
  previousDealId?: Maybe<Scalars['String']['output']>;
  previousFundId?: Maybe<Scalars['String']['output']>;
};

export type Worker = {
  __typename?: 'Worker';
  duty?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Event>;
  event_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  organization?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['String']['output']>;
  pay_code?: Maybe<Scalars['String']['output']>;
  pay_rate?: Maybe<Scalars['Float']['output']>;
  salary?: Maybe<Scalars['Float']['output']>;
  ssn?: Maybe<Scalars['String']['output']>;
  woker_name?: Maybe<Scalars['String']['output']>;
  worker_end_time?: Maybe<Scalars['String']['output']>;
  worker_start_time?: Maybe<Scalars['String']['output']>;
  worker_type?: Maybe<Scalars['String']['output']>;
};

export type WorkerByIdInput = {
  id: Scalars['Int']['input'];
};

export type WorkerDeleteManyCount = {
  __typename?: 'WorkerDeleteManyCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export type WorkerPool = {
  __typename?: 'WorkerPool';
  Address?: Maybe<Scalars['String']['output']>;
  City?: Maybe<Scalars['String']['output']>;
  First?: Maybe<Scalars['String']['output']>;
  Home_Phone?: Maybe<Scalars['String']['output']>;
  ID: Scalars['Int']['output'];
  Last?: Maybe<Scalars['String']['output']>;
  SSN?: Maybe<Scalars['String']['output']>;
  State?: Maybe<Scalars['String']['output']>;
  Work_Phone?: Maybe<Scalars['String']['output']>;
  Zip?: Maybe<Scalars['String']['output']>;
  cell_phone?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  is_deleted?: Maybe<Scalars['String']['output']>;
  pay_rate?: Maybe<Scalars['Float']['output']>;
  worker_type?: Maybe<Scalars['String']['output']>;
};

export enum WorkerTypes {
  C = 'c',
  H = 'h'
}

export type WorkerUpsertManyCount = {
  __typename?: 'WorkerUpsertManyCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export type ApprovedFunds = {
  __typename?: 'approvedFunds';
  approvedFundsCents?: Maybe<Scalars['Int']['output']>;
  isPaidBack?: Maybe<Scalars['Boolean']['output']>;
};

export type BusSchedules = {
  __typename?: 'busSchedules';
  activity?: Maybe<Scalars['String']['output']>;
  bus_count?: Maybe<Scalars['Int']['output']>;
  bus_departure_location?: Maybe<Scalars['String']['output']>;
  bus_early_dismissal_time?: Maybe<Scalars['String']['output']>;
  bus_estimated_return_time?: Maybe<Scalars['String']['output']>;
  bus_time?: Maybe<Scalars['String']['output']>;
  cancellation_status?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['String']['output']>;
  end_time?: Maybe<Scalars['String']['output']>;
  event_date?: Maybe<Scalars['String']['output']>;
  event_transport_details?: Maybe<Array<Maybe<DailyCalendarEventTransportDetails>>>;
  facility?: Maybe<Scalars['String']['output']>;
  g_s?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  home_field?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  levels?: Maybe<Scalars['String']['output']>;
  opponent?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  season_id?: Maybe<Scalars['Int']['output']>;
  sports_code?: Maybe<Scalars['String']['output']>;
  sports_description?: Maybe<Scalars['String']['output']>;
  sports_group?: Maybe<Scalars['String']['output']>;
  sports_name?: Maybe<Scalars['String']['output']>;
  start_time?: Maybe<Scalars['String']['output']>;
  vehicle_type?: Maybe<Scalars['String']['output']>;
};

export type CompleteFundraiserApprovalSubmissionDocumentUploadResult = {
  __typename?: 'completeFundraiserApprovalSubmissionDocumentUploadResult';
  filename: Scalars['String']['output'];
  s3FileName: Scalars['String']['output'];
};

export type CreditMemoAmounts = {
  __typename?: 'creditMemoAmounts';
  creditApplied?: Maybe<Scalars['Int']['output']>;
};

export enum DateRangeEnum {
  EighteenMonths = 'eighteenMonths',
  SixMonths = 'sixMonths',
  ThirtyDays = 'thirtyDays',
  ThreeMonths = 'threeMonths',
  TwelveMonths = 'twelveMonths'
}

export type DriveGetListCurrentCustomersReturn = {
  __typename?: 'driveGetListCurrentCustomersReturn';
  offset?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<Maybe<CurrentCustomersDeals>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DriveGetListPotentialCustomersReturn = {
  __typename?: 'driveGetListPotentialCustomersReturn';
  offset?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<Maybe<PotentialCustomersDeals>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** Last clicked tracking information used by Drive's Get List Application */
export type DriveOrgUserTrackingReturn = {
  __typename?: 'driveOrgUserTrackingReturn';
  /** ID of the organization. */
  orgId?: Maybe<Scalars['String']['output']>;
  /** A unique tracking id */
  trackingId?: Maybe<Scalars['String']['output']>;
  trackingType?: Maybe<Scalars['String']['output']>;
  /** The value of the tracking type */
  trackingValue?: Maybe<Scalars['String']['output']>;
  trackingValueType?: Maybe<Scalars['String']['output']>;
  /** ID of the user. */
  userId?: Maybe<Scalars['String']['output']>;
};

export type EarlyAccess = {
  __typename?: 'earlyAccess';
  activityType?: Maybe<Scalars['String']['output']>;
  earlyAccessApprovedFundsMax?: Maybe<Scalars['Int']['output']>;
  earlyAccessApprovedFundsMin?: Maybe<Scalars['Int']['output']>;
  isNationalRpk?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  participantCount?: Maybe<Scalars['Int']['output']>;
  projectedRaisedMax?: Maybe<Scalars['Int']['output']>;
  projectedRaisedMin?: Maybe<Scalars['Int']['output']>;
};

export type EventContractItemInput = {
  event_id: Scalars['Int']['input'];
};

export type InsightsUserOrgsNotificationPreferencesInput = {
  __typename?: 'insightsUserOrgsNotificationPreferencesInput';
  userId?: Maybe<Scalars['String']['output']>;
};

export type InsightsUserOrgsNotificationPreferencesResponse = {
  __typename?: 'insightsUserOrgsNotificationPreferencesResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  orgNotificationPreferences: Array<Maybe<OrgNotificationPreference>>;
  status: Scalars['String']['output'];
  userNotificationPreferences: Array<Maybe<UserNotificationPreference>>;
};

export type OrgNotificationPreference = {
  __typename?: 'orgNotificationPreference';
  id?: Maybe<Scalars['Int']['output']>;
  org_id?: Maybe<Scalars['String']['output']>;
};

export type ParticipantCampaignConfiguration = {
  __typename?: 'participantCampaignConfiguration';
  autoImportEmails: ParticipantCampaignConfigStates;
  autoImportTexts: ParticipantCampaignConfigStates;
  campaignID: Scalars['String']['output'];
  giftShop: ParticipantCampaignConfigStates;
  guardianSetup: ParticipantCampaignConfigStates;
  id: Scalars['ID']['output'];
  profileSetup: ParticipantCampaignConfigStates;
  rewards: ParticipantCampaignConfigStates;
  updatedAt: Scalars['DateTime']['output'];
  userID: Scalars['String']['output'];
};

export enum Responses {
  Failed = 'FAILED',
  Ok = 'OK'
}

export type SpendAdminApprovalUpdateResponse = {
  __typename?: 'spendAdminApprovalUpdateResponse';
  success: Scalars['Boolean']['output'];
};

export type SpendAdminCustomerCloseResponse = {
  __typename?: 'spendAdminCustomerCloseResponse';
  success: Scalars['Boolean']['output'];
};

export type SpendOrganizationPieChartResponse = {
  __typename?: 'spendOrganizationPieChartResponse';
  credited: Scalars['Int']['output'];
  paid: Scalars['Int']['output'];
  pastDue: Scalars['Int']['output'];
  processing: Scalars['Int']['output'];
  upcoming: Scalars['Int']['output'];
};

export type StoreBuildRequestInput = {
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  primaryColor: Scalars['String']['input'];
  requester: StoreBuildRequester;
  secondaryColor: Scalars['String']['input'];
  storeActivity: Scalars['String']['input'];
  storeLogo: Scalars['String']['input'];
  storeName: Scalars['String']['input'];
};

export type StoreOrderSalesGraph = {
  __typename?: 'storeOrderSalesGraph';
  baseTotalSales?: Maybe<Scalars['Float']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  scopeId?: Maybe<Scalars['BigInt']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  totalDiscountAmount?: Maybe<Scalars['Float']['output']>;
  totalOrders?: Maybe<Scalars['BigInt']['output']>;
  totalSales?: Maybe<Scalars['Float']['output']>;
};

export type StoreOrderSalesGraphUnionType = OnException | StoreOrderSalesGraphs;

export type TwispCardBalances = {
  __typename?: 'twispCardBalances';
  allTxs?: Maybe<Array<Maybe<TwispTransactions>>>;
  authorization_balance?: Maybe<Scalars['Int64']['output']>;
  balance?: Maybe<Scalars['Int64']['output']>;
  card_id?: Maybe<Scalars['String']['output']>;
  card_limit?: Maybe<Scalars['Int64']['output']>;
  history?: Maybe<Array<Maybe<TwispCardBalances>>>;
  seq?: Maybe<Scalars['Uint64']['output']>;
  settled_balance?: Maybe<Scalars['Int64']['output']>;
  visibleTxs?: Maybe<Array<Maybe<TwispTransactions>>>;
};


export type TwispCardBalancesHistoryArgs = {
  limit?: InputMaybe<Scalars['Int32']['input']>;
  sort?: InputMaybe<Sort>;
};

/** Indexes for table twispCardBalances */
export enum TwispCardBalances_Indexes {
  CardId = 'card_id'
}

export type TwispCards = {
  __typename?: 'twispCards';
  card_id?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<Maybe<TwispCards>>>;
  last4?: Maybe<Scalars['String']['output']>;
  seq?: Maybe<Scalars['Uint64']['output']>;
};


export type TwispCardsHistoryArgs = {
  limit?: InputMaybe<Scalars['Int32']['input']>;
  sort?: InputMaybe<Sort>;
};

/** Indexes for table twispCards */
export enum TwispCards_Indexes {
  CardId = 'card_id'
}

export type TwispStripeHooks = {
  __typename?: 'twispStripeHooks';
  account?: Maybe<Scalars['String']['output']>;
  api_version?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Int64']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  history?: Maybe<Array<Maybe<TwispStripeHooks>>>;
  id?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<Scalars['String']['output']>;
  pending_webhooks?: Maybe<Scalars['Int64']['output']>;
  processed?: Maybe<Scalars['Timestamp']['output']>;
  request?: Maybe<Scalars['JSON']['output']>;
  seq?: Maybe<Scalars['Uint64']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};


export type TwispStripeHooksDataArgs = {
  expression?: InputMaybe<Scalars['String']['input']>;
};


export type TwispStripeHooksHistoryArgs = {
  limit?: InputMaybe<Scalars['Int32']['input']>;
  sort?: InputMaybe<Sort>;
};


export type TwispStripeHooksRequestArgs = {
  expression?: InputMaybe<Scalars['String']['input']>;
};

/** Indexes for table twispStripeHooks */
export enum TwispStripeHooks_Indexes {
  Id = 'id'
}

export type TwispTransactions = {
  __typename?: 'twispTransactions';
  amount?: Maybe<Scalars['Int64']['output']>;
  card_id?: Maybe<Scalars['String']['output']>;
  correlation?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Int64']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  event_created?: Maybe<Scalars['Int64']['output']>;
  history?: Maybe<Array<Maybe<TwispTransactions>>>;
  id?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  seq?: Maybe<Scalars['Uint64']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};


export type TwispTransactionsHistoryArgs = {
  limit?: InputMaybe<Scalars['Int32']['input']>;
  sort?: InputMaybe<Sort>;
};


export type TwispTransactionsMetaArgs = {
  expression?: InputMaybe<Scalars['String']['input']>;
};

/** Indexes for table twispTransactions */
export enum TwispTransactions_Indexes {
  CardId = 'card_id',
  CardIdViz = 'card_id_viz',
  Id = 'id'
}

export type UserNotificationPreference = {
  __typename?: 'userNotificationPreference';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};
