import { Module, GetterTree, ActionTree, MutationTree } from "vuex";
import {
  TreatmentsWithConfig,
  TreatmentWithConfig,
} from "@splitsoftware/splitio/types/splitio";

import { getClient, destroyClient, fetchTreatments } from "@/util/splitio";
import type { RootState } from "./index";

import * as FullStory from "@/util/fullStory";
import { isEqualObjects } from "@/util/helper";

// export type Treatments = Record<string, string>;

export type TreatmentsState = {
  treatments: TreatmentsWithConfig;
  userId?: string;
};

export type FeatureToggle = (name: string) => boolean;
export type FeaturesToggle = (name: Array<string>) => boolean;

export const TreatmentsDefaults: TreatmentsWithConfig = Object.fromEntries(
  Object.entries({
    "USER-381-not-allow-to-edit-child-email": "on",
    "USER-416-new-reset-password-flow": "on",
    "USER-461-use-full-story-custom-events": "on",
    "USER-523-add-role-selector-to-signup": "on",
    "USER-527-allow-to-skip-verification": "on",
    "USER-546-hide-optional-from-profile-picture": "on",
    "USER-569-role-selector-pre-populate": "on",
    "USER-656-redirect-parents-to-family-after-invite": "on",
    "USER-672-show-users-associations-on-my-profile": "off",
    "USER-704-allow-users-bulk-operation": "on",
    "USER-710-do-not-show-users-by-default": "on",
    "USER-711-show-learning-center-dialog": "on",
    "USER-713-set-org-challenge": "off",
    "USER-743-show-zendesk-help-widget": "off",
    "USER-740-confirm-phone-challenge": "off",
    "USER-761-allow-to-set-password": "on",
    "USER-762-reset-password-challenge": "on",
    "USER-770-allow-edit-org-associations": "off",
    "USER-796-access-to-api-keys": "on",
    "USER-799-allow-create-child-account": "off",
    "USER-799-allow-guardian-portal-for-parents": "off",
    "USER-801-use-snap-impersonation-mode": "off",
    "USER-817-show-graduation-year": "off",
    "USER-888-new-sign-up-view": "off",
    "USER-914-route-to-guardian-view": "off",
    "USER-916-new-image-on-external-pages": "off",
    "USER-934-show-staff-roster-page": "off",
    "USER-934-show-navigation-context": "off",
    "USER-979-update-signup-data-prefill": "off",
    "USER-1018-show-roster-tab": "off",
    "USER-1039-create-channels-and-invite-members": "off",
    "USER-1054-use-single-orgs-query-in-orgs-list": "off",
    "USER-1057-show-messaging-icon": "off",
    "USER-1079-sign-in-with-google": "off",
  }).map(([key, value]) => [key, { treatment: value, config: null }])
);

export const TreatmentsDefaultsKeys = Object.keys(TreatmentsDefaults);

const state: TreatmentsState = {
  treatments: TreatmentsDefaults,
  userId: undefined,
};

// HINT: In component should use:
// async beforeMount() {
//   await this.$store.dispatch("useTreatments");
// }

const parseTreatmentConfig = (config: string | null): object => {
  if (config && config !== "") {
    try {
      return JSON.parse(config);
    } catch {
      return {};
    }
  }

  return {};
};

const getters: GetterTree<TreatmentsState, RootState> = {
  treatments: (state) => state.treatments,
  treatment:
    (state) =>
    (name: string): TreatmentWithConfig =>
      state.treatments[name],
  treatmentConfig:
    (state) =>
    (name: string): object =>
      parseTreatmentConfig(state.treatments[name]?.config),

  featureOn: (state) => (name: string) =>
    state.treatments[name]?.treatment === "on",
  featureOff: (state) => (name: string) =>
    state.treatments[name]?.treatment !== "on",

  featuresOn: (state) => (names: Array<string>) =>
    names.every((name) => state.treatments[name]?.treatment === "on"),

  treatmentsUserId: (state) => state.userId,
};

const mutations: MutationTree<TreatmentsState> = {
  setUserId(state, userId) {
    state.userId = userId;
  },

  setTreatments(state, treatments: TreatmentsWithConfig = TreatmentsDefaults) {
    if (treatments && !isEqualObjects(state.treatments, treatments)) {
      const eventProperties: Record<string, string> = Object.fromEntries(
        Object.entries(treatments).map(([key, value]) => [
          key?.replace(/-/gi, "_"),
          value?.treatment,
        ])
      );

      FullStory.event("Split Treatments", eventProperties);
    }

    Object.assign(state.treatments, treatments);
  },

  clearTreatments(state) {
    Object.keys(TreatmentsDefaults).forEach(
      (name) => delete state.treatments[name]
    );
    state.userId = undefined;
  },
};

const actions: ActionTree<TreatmentsState, RootState> = {
  async useTreatments({ commit, getters }) {
    const trId = getters.treatmentsUserId;
    const meId = getters.me.id;

    if (trId === undefined || (meId !== undefined && trId !== meId)) {
      await destroyClient();

      const { client, key } = getClient(getters.me.id);
      commit("setUserId", key);

      client?.on(client?.Event.SDK_UPDATE, async () => {
        commit("setTreatments", await fetchTreatments(TreatmentsDefaultsKeys));
      });
    }

    commit("setTreatments", await fetchTreatments(TreatmentsDefaultsKeys));
  },

  async destroyTreatments({ commit }) {
    await destroyClient();
    commit("clearTreatments");
  },
};

export const treatments: Module<TreatmentsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
