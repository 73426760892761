<template>
  <div v-if="isLoading" class="inset-0 fixed flex items-center justify-center">
    <Loading></Loading>
  </div>

  <component :is="$route?.meta?.layout || 'div'" v-if="!isLoading">
    <router-view />
  </component>

  <Notification></Notification>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";

import { UserAuthState } from "@/types/user";

import Loading from "@/components/ui/Loading.vue";
import Notification from "@/components/ui/Notification.vue";

@Options({
  components: { Loading, Notification },
  computed: {
    ...mapGetters(["isLoading", "authState"]),
  },
})
export default class App extends Vue {
  isLoading!: boolean;
  authState!: UserAuthState;

  async beforeMount() {
    if (this.authState === UserAuthState.Unknown)
      await this.$store.dispatch("me");

    await this.$store.dispatch("useTreatments");
  }
}
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./scss/snap-ui";
@import "./scss/google";

* {
  scrollbar-width: thin;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

:root {
  font-family: "Inter", sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: "Inter var", sans-serif;
  }
}

.rounded-full {
  border-radius: 100%;
}
</style>
